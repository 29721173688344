import { useSelect } from "@mui/base";
import React from "react";
import { useSelector } from "react-redux";
import HOC from "../../../components/HOC/HOC";
import { useGetCatgeoriesQuery } from "../../../redux/features/categoryApis";
import Spinner from "../../spinner/Spinner";
import styles from "./Request.module.css";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { useGetsubCatgeoriesQuery } from "../../../redux/features/subCategoryApis";
import AddSubCategoryModal from "./AddSubCategoryModal";

const SingleRequestPage = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const { cardTheme } = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  //CATEGORY STORE
  let {
    data: dataCategories,
    isSuccess: successCategory,
    isLoading: isLoading,
  } = useGetsubCatgeoriesQuery(id);

  return (
    <HOC>
      <div>
        <div className="p-3">
          {/* Categories */}
          <div
            className="p-4 pb-5"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className={`text-center mt-3 mb-5 ${
                mode === "light" ? "text-dark" : "text-white"
              }`}
            >
              {lang === "en" ? "Sub Categories" : " الفئات الفرعيه"}
            </h1>
            <Grid container>
              {successCategory ? (
                dataCategories?.subCategories?.map((_, index) => (
                  <Grid item xl={4} lg={6} md={12} sm={12} xs={12} py="20px">
                    <Box
                      sx={{
                        backgroundColor: cardTheme[`bg_${mode}`],

                        width: {
                          xl: "92.5%",
                          lg: "92.5%",
                          md: "92.5%",
                          sm: "100%",
                          xs: "100%",
                        },
                        mx: "auto",
                        position: "relativce",
                        p: 2,
                        height: "150px",
                      }}
                    >
                      <h4
                        className={` fw-bold ${
                          mode === "light" ? "text-dark" : "text-white"
                        }`}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {_.subType}
                      </h4>
                      <p 
                        className={`lead fw-bold ${
                          mode === "light" ? "text-dark" : "text-white"
                        }`}
                        style={{
                          fontSize: "17px",
                          backgroundColor: cardTheme[`bg_${mode}`],

                        }}
                      >
                        {lang === "en" ? "Time" : "وقت"}
                      </p>
                      <p  className={`${
                          mode === "light" ? "text-dark" : "text-white"
      }`}>
                        {_.haveTime
                          ? lang === "en"
                            ? "have time"
                            : " يوجد وقت "
                          : lang === "en"
                          ? "don't have time"
                          : "لا يوجد وقت"}
                      </p>
                    </Box>
                  </Grid>
                ))
              ) : isLoading ? (
                <Spinner />
              ) : null}
            </Grid>
            <AddSubCategoryModal mode={mode} lang={lang} />
          </div>
        </div>
      </div>
    </HOC>
  );
};

export default SingleRequestPage;
