import React from "react";
import { ReactComponent as ArrowIcon } from "./images/arrow.svg";
import { ReactComponent as LikeIcon } from "./images/like.svg";
import { ReactComponent as DisLikeIcon } from "./images/dislike.svg";
import { ReactComponent as CommentIcon } from "./images/comment.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import styles from "./Blogs.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useDeleteBlogMutation,
  useGetMyBlogsQuery,
} from "../../../redux/features/blogsApi";
import { useGetMeQuery } from "../../../redux/features/userApi";
import Spinner from "../../spinner/Spinner";
import moment from "moment";
import { imageUrl } from "../../../App";
import { toast } from "react-toastify";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";

function MyBlogs({ value }) {
  const { value: lang } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;

  let { data: userData } = useGetMeQuery();
  let {
    data: BlogsData,
    isSuccess: BlogsSuccess,
    isLoading: blogLoading,
    
  } = useGetMyBlogsQuery();
  let [deleteBlog] = useDeleteBlogMutation();

  const checkAccessForBeforDelete = (userBlog) => {
    if (userData?.data.role == "admin" || userData?.data?.role == "root")
      return true;
    else if (userData?.data?.role == "employee") {
      if (userData?.data._id == userBlog?._id) return true;
      else return false;
    }
    return undefined;
  };
  const getTime = (blog) => {
    let time = ``;

    if (lang == "en") {
      time = `time ${moment(blog?.createdAt).format("hh:mm:ss")}`;
    } else {
      time = ` الوقت : ${moment(blog?.createdAt).format("hh:mm:ss")}`;
    }
    return time;
  };
  const handleDeleteBlog = (id) => {
    deleteBlog(id).then(({ data, error }) => {
      if (!error) {
        toast.success(
          lang == "en" ? "Blog Deleted Successfully" : "تم حذف المدونة بنجاح"
        );
      } else {
        toast.error(
          lang == "en" ? "Blog Can't Be Deleted " : "لا يمكن حذف المدونة"
        );
      }
    });
  };
  const getTheStatsForLikeAndCommentAndDislike = (blog) => {
    let stats = { comments: 0, like: 0, disLike: 0 };
    // get comments length
    stats["comments"] = blog?.comments.length;
    // get the likes stats
    stats["like"] = blog?.likes.filter(
      (like) => like.likeType == "like"
    ).length;
    stats["disLike"] = blog?.likes.filter(
      (like) => like.likeType == "disLike"
    ).length;
    return stats;
  };
  return (
    <TabPanel value={value}>
      <div>
        {/* <p className={mode === "light" ? "text-dark" : "text-white"}>
          {lang === "en" ? "This should work" : "ينبغي أن تعمل"}
        </p> */}
        <div className="row py-5 ">
          {/* ================================================Grid===================================================== */}
          { 
        
          blogLoading ? (
            <Spinner />
          ) : BlogsSuccess ? (
            [...BlogsData?.newBlog]?.map((blog, index) => {
              return (
                <div key={index} className="col-lg-6 p-3">
                  <div
                    className={`${styles.colContent} position-relative`}
                    style={{
                      backgroundColor: mode === "light" ? "#fff" : "#3E3E42",
                      borderRadius: "25px",
                    }}
                  >
                    <h3
                      className={`fw-bold my-3 ${
                        mode === "light" ? "text-dark" : "text-white"
                      }`}
                    >
                      {blog?.title}
                    </h3>
                    <Link to={`/blogDetails/${blog._id}`}>
                      {/* <div>
                    <img src={image} alt="" className="w-100" />
                  </div> */}
                    </Link>
                    {/* ==============================card body======================================== */}
                    <div>
                      <p
                        className={`mt-3 ${
                          mode === "light" ? "text-dark" : "text-white"
                        }`}
                      >
                        {blog?.description.slice(0, 50)}
                      </p>
                      <Link
                        to={`/blogDetails/${blog?._id}`}
                        style={{ color: "#00B4D8", fontSize: "12px" }}
                      >
                        {lang === "en" ? "Read More" : "قراءة المزيد"}
                        <ArrowIcon className="mx-1 " />
                      </Link>
                    </div>

                    {/* ===========================card footer======================================= */}
                    <Box
                      sx={{
                        display: {
                          xl: "flex",
                          lg: "flex",
                          md: "block",
                          sm: "block",
                          xs: "block",
                        },

                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="d-flex align-items-center mt-2">
                        <div
                          className="mx-2"
                          style={{
                            width: "4em",
                            height: "4em",
                          }}
                        >
                          <img
                            src={`${imageUrl}${blog?.image}`}
                            alt=""
                            className="w-100"
                            style={{
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="">
                          <h6
                            className={`w-100 fw-bold p-0 ${
                              mode === "light" ? "text-dark" : "text-white"
                            }`}
                          >
                            {blog?.user[`userName_${lang}`]}
                          </h6>
                          {
                            <span
                              className={`p-0 ${
                                mode === "light" ? "text-dark" : "text-white"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {getTime(blog)}
                            </span>
                          }
                        </div>
                      </div>

                      <div
                        className="d-flex   mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="mx-1">
                          <LikeIcon />
                          {getTheStatsForLikeAndCommentAndDislike(blog).like >
                            0 && (
                            <span
                              className={
                                mode === "light" ? "text-dark" : "text-white"
                              }
                            >
                              {
                                getTheStatsForLikeAndCommentAndDislike(blog)
                                  .like
                              }
                            </span>
                          )}
                        </div>
                        <div className="mx-1">
                          <DisLikeIcon />
                          {getTheStatsForLikeAndCommentAndDislike(blog)
                            .disLike > 0 && (
                            <span
                              className={
                                mode === "light" ? "text-dark" : "text-white"
                              }
                            >
                              {
                                getTheStatsForLikeAndCommentAndDislike(blog)
                                  .disLike
                              }
                            </span>
                          )}
                        </div>
                        <div className="mx-1">
                          <CommentIcon />
                          {getTheStatsForLikeAndCommentAndDislike(blog)
                            .comments > 0 && (
                            <span
                              className={
                                mode === "light" ? "text-dark" : "text-white"
                              }
                            >
                              {
                                getTheStatsForLikeAndCommentAndDislike(blog)
                                  .comments
                              }
                            </span>
                          )}
                        </div>
                      </div>
                    </Box>
                    {checkAccessForBeforDelete(blog?.user) ? (
                      <div className="deleteIconWrapper">
                        <HighlightOffIcon
                          sx={{
                            position: "absolute",
                            top: "20px",
                            right: lang == "en" ? "20px" : undefined,
                            left: lang == "ar" ? "20px" : undefined,
                            cursor: "pointer",
                            color: "#E04449",
                          }}
                          onClick={() => handleDeleteBlog(blog?._id)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </div>
    </TabPanel>
  );
}

export default MyBlogs;
