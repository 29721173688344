import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
const DateAndTimePicker = ({
  label,
  value,
  error,
  name,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          position: "relative",
          pb: "21px",
        }}
      >
        <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
          {label}
        </Typography>
        <DateTimePicker
          sx={{
            height: 0,
            maxHeight: 0,
            minHeight: 0,
          }}
          renderInput={(props) => (
            <TextField
              {...props}
              sx={{
                width: "100%",
                ".MuiInputBase-root ": {
                  backgroundColor: mode === "light" ? "#fff" : "#252526",
                  border: `1px solid ${
                    error && touched ? "red" : "transparent"
                  }`,
                  color: mode === "light" ? "#000" : "#ffff",
                  paddingY: 0,
                  svg: {
                    color: mode === "light" ? "#000" : "#fff",
                  },
                  height: "50px",
                },
                ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                  {
                    border: "0 !important",
                  },
                "&.Mui-focused": {
                  border: "0 !important",
                },
                ".MuiFormLabel-root": {
                  display: "none",
                },
              }}
            />
          )}
          inputFormat="YYYY/MM/DD hh:mm"
          value={value}
          onChange={(event) => handleChange(event?.$d)}
          onBlur={handleBlur}
        />
        <Typography
          sx={{
            color: "red",
            position: "absolute",
            bottom: 0,
            fontSize: "13px",
          }}
        >
          {error && touched
            ? lang === "en"
              ? "Enter time"
              : "أدخل زمن"
            : undefined}
        </Typography>
      </Box>
    </LocalizationProvider>
  );
};

export default DateAndTimePicker;
