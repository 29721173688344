import React from "react";
import { useSelector } from "react-redux";
import HOC from "../../../components/HOC/HOC";
import ShiftBreaks from "../../../components/singleShift/ShiftBreaks";
import ShiftOverTime from "../../../components/singleShift/ShiftOverTime";
import { useParams } from "react-router";
const SingleShiftPage = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const { id } = useParams();

  return (
    <HOC>
      <div className="p-3">
        <ShiftBreaks mode={mode} lang={lang} id={id} />
        <ShiftOverTime mode={mode} lang={lang} shiftId={id} />
      </div>
    </HOC>
  );
};

export default SingleShiftPage;
