import React from "react";
import { Route, Routes } from "react-router";
import LoginPage from "../../pagees/login/LoginPage";
import Home from "../../pagees/privates/home/Home";
import PrivateRoutes from "./PrivateRoutes";
import AttendeesPage from "../../pagees/privates/attendees/AttendeesPage";
import BranchesPage from "../../pagees/privates/branches/BranchesPage";
import RequestsPage from "../../pagees/privates/request/RequestsPage";
import TasksPage from "../../pagees/privates/tasks/TasksPage";
import BlogsPage from "../../pagees/privates/blogs/BlogsPage";
import AlertsPage from "../../pagees/privates/alerts/AlertsPage";
import ShiftsPage from "../../pagees/privates/shifts/ShiftsPage";
import ContractsPage from "../../pagees/privates/contracts/ContractsPage";
import BreaksPage from "../../pagees/privates/breaks/BreaksPage";
import SalariesPage from "../../pagees/privates/salaries/SalariesPage";
import PayrollsPage from "../../pagees/privates/payrolls/PayrollsPage";
import EmployeesPage from "../../pagees/privates/employees/EmployeesPage";
import OverTimePage from "../../pagees/privates/overTime/OverTimePage";

import SingleBranchPage from "../../pagees/privates/singleBranch/SingleBranchPage";
import SingleShiftPage from "../../pagees/privates/singleShift/SingleShiftPage";
import HOC from "../HOC/HOC";

import SingleAlertPage from "../../pagees/privates/singleAlert/SingleAlertPage";
import SingleRequestPage from "../../pagees/privates/singleRequest/SingleRequestPage";
import ProfilePage from "../../pagees/privates/profile/ProfilePage";
import SingleBlogPage from "../../pagees/privates/singleBlog/SingleBlogPage";
import { EmployeeProfile } from "../../pagees/privates/profile/profileEmployee";
import SingleBreakPage from "../../pagees/privates/singleBreak/SingleBreakPage";
import SingleOvertimePage from "../../pagees/privates/singleOvertime/SingleOvertimePage";
import SingleNotificationPage from "../../pagees/privates/singleNotification/SingleNotificationPage";
import NotificationsPage from "../../pagees/privates/singleNotification/SingleNotificationPage";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {/* just routes for the users have a company */}
        <Route element={<PrivateRoutes />}>
          <Route
            path="/"
            element={
              <HOC>
                <Home />
              </HOC>
            }
          />
          <Route path="/attendees" element={<AttendeesPage />} />
          <Route path="/branches" element={<BranchesPage />} />
          <Route path="/requests" element={<RequestsPage />} />
          <Route path="/tasks" element={<TasksPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/alerts" element={<AlertsPage />} />
          <Route path="/shifts" element={<ShiftsPage />} />
          <Route path="/contracts" element={<ContractsPage />} />
          <Route path="/breaks" element={<BreaksPage />} />
          <Route path="/salaries" element={<SalariesPage />} />
          <Route path="/payrolls" element={<PayrollsPage />} />
          <Route path="/employees" element={<EmployeesPage />} />
          <Route path="/overtime" element={<OverTimePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/branches/:Idbranch" element={<SingleBranchPage />} />
          <Route path="/branches/:Idbranch/:id" element={<SingleShiftPage />} />
          <Route path="/alerts/:id" element={<SingleAlertPage />} />
          <Route path="/subCategory/:id" element={<SingleRequestPage />} />
          <Route path="/blogDetails/:blogId" element={<SingleBlogPage />} />
          <Route path="/employee/:id" element={<EmployeeProfile />} />
          <Route path="/allNotifications" element={<NotificationsPage />} />
          <Route
            path="/notifications/:NotificationId"
            element={<SingleNotificationPage />}
          />
          <Route
            path="/branches/:IdBranch/:id/breaks/:IdBreak"
            element={<SingleBreakPage />}
          />
          <Route
            path="/branches/:IdBranch/:id/overtime/:IdOvertime"
            element={<SingleOvertimePage />}
          />
        </Route>
      </Routes>
    </>
  );
};
export default AllRoutes;
