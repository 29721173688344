import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  FormControl,
  Grid,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { skipToken } from "@reduxjs/toolkit/query/react";

import * as Yup from "yup";

import { useGetBranchesQuery } from "../../../redux/features/branchesApi";

import CloseIcon from "@mui/icons-material/Close";
import {
  useAddTaskMutation,
  useDeleteTaskMutation,
  useGetTasksQuery,
  useUpdateTaskMutation,
} from "../../../redux/features/tasksApi";
import { useGetMeQuery } from "../../../redux/features/userApi";
import {
  useGetEmployeesByBranchQuery,
  useGetEmployeesByCompanyQuery,
} from "../../../redux/features/employeeApi";
import TaskEmployeeMenu from "./employeeMenu/TaskEmployeMenu";
import DateAndTimePicker from "../../../components/dateTimePicker/DateTimePicker";

export default function AddTaskModal({ lang, mode }) {
  const [open, setOpen] = useState(false);
  const [dispableDepartment, setDisableDeparment] = useState(true);
  const [dispableShift, setDisableShift] = useState(true);
  const [skipEmpbyBranchToken, setSkipEmpbyBranchToken] = useState(skipToken);
  const [skipBranchesToken, setSkipBranchesToken] = useState(skipToken);
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setDisableDeparment(true);
    setDisableShift(true);
  };

  const { modalTheme } = useTheme();
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      branch: "",
      start: null,
      end: null,
      to: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        lang == "en" ? "title is Required" : "العنوان مطلوب"
      ),
      description: Yup.string().required(
        lang == "en" ? "description is Required" : "الوصف مطلوب"
      ),
      branch:
        userSuccess && userData?.data?.role == "root"
          ? Yup.string().required(
              lang === "en" ? "Choose a branch" : "اختار فرع"
            )
          : Yup.string(),
      start: Yup.date().required(
        lang == "en" ? "start date is required" : "تاريخ البدء مطلوب"
      ),
      end: Yup.string().required(
        lang == "en" ? "end date is Required" : "تاريخ الانتهاء مطلوب"
      ),
      to: Yup.array().min(1).required(),
    }),

    onSubmit: () => {
      addTask({
        ...values,
        company: localStorage.getItem("companyId"),
      }).then(({ data, error }) => {
        if (data) {
          toast.success(
            lang == "en"
              ? "Task Created Successfully "
              : "تم إنشاء المهمة بنجاح"
          );
          handleClose();
        } else if (error) {
          toast.error(
            lang == "en" ? "Cant Add The Task " : "غير قادر على إضافة المهمة"
          );
        }
      });
    },
  });

  const ModalBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xl: "800px",
      sm: 0.9,
      xs: 0.9,
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    height: {
      xl: 0.6,
      lg: 0.7,
      md: 0.7,
      sm: 0.75,
      xs: 0.75,
    },
    overflowY: "scroll",
    direction: lang === "en" ? "ltr" : "rtl",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-track": {
      background: mode === "light" ? "#f1f1f1" : "#555",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: mode === "light" ? "#aac1fe" : "#000",
      transition: "0.5s all",
      borderRadius: "15px",
    },

    " &::-webkit-scrollbar-thumb:hover": {
      background: mode === "light" ? "#78a3e4" : "#000",
    },
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;
  const [modalShow, setModalShow] = useState();
  const [userValue, setUserValue] = useState([]);
  const [inputChange, setInputChange] = useState({});
  const [disableAssigning, setDisableAssigning] = useState(true);
  const [branch, setBranch] = useState(null);
  const [skipEmpByCompanyToken, setSkipEmpByCompanyToken] = useState(skipToken);
  let { data: branches, isSuccess: branchesSuccess } =
    useGetBranchesQuery(skipBranchesToken);
  let [updateTask] = useUpdateTaskMutation();
  let [deleteTask] = useDeleteTaskMutation();
  let { data: empsByBranch, isSuccess: EmpsBranchSuccess } =
    useGetEmployeesByBranchQuery(skipEmpbyBranchToken);
  let {
    data: dataTasks,
    isSuccess: tasksSuccess,
    isLoading: tasksLoading,
    isError: tasksError,
  } = useGetTasksQuery();

  let { data: empsByCompany, isSuccess: empsCompnaySuccess } =
    useGetEmployeesByCompanyQuery(skipEmpByCompanyToken);

  let [addTask] = useAddTaskMutation();

  const handleInputChange = (e) => {
    let copyObj = { ...inputChange };
    copyObj[e.target.name] = e.target.value;
    setInputChange((_) => copyObj);
  };
  // assigning to
  const handleSelectChange = (e) => {
    // check if the array dont have that user add it if have it delete it
    // the users should be string and separating user by ,
    let copyArr = [...userValue];
    if (!copyArr.includes(e.target.value.trim())) {
      copyArr.push(e.target.value.trim());
    } else {
      copyArr = copyArr.filter((ele) => {
        return ele == e.target.value;
      });
    }
    setUserValue([...copyArr]);
    setInputChange({ ...inputChange, to: [...copyArr] });
  };
  useEffect(() => {
    if (userSuccess) {
      if (
        userData?.data?.role == "employee" ||
        userData?.data?.role == "admin"
      ) {
        setSkipEmpbyBranchToken(userData?.data?.branch);
        formik.setFieldValue("branch", userData?.data?.branch);
      }
      setInputChange({ ...inputChange, branch: userData?.data?.branch });
      setDisableAssigning(false);
      setSkipBranchesToken(true);
    }
  }, [userSuccess, tasksSuccess, branchesSuccess, userData?.data?.role]);

  const handleBranch = (e) => {
    setBranch((_) => e.target.value);
    // this should determine which data to use
    // get all The Emps ByBranch
    setInputChange({ ...inputChange, branch: e.target.value });
    setDisableAssigning(false);
    setSkipEmpbyBranchToken(e.target.value);
  };
  const showModalShow = () => {
    setModalShow(true);
  };

  // const handleClose = () => {
  //   setModalShow(false);
  // };
  const chooseWhenToShow = (taskFrom) => {
    // based on the role if the role was admin or root return true
    // and if the role was employee only return true if the task.from == userData?.data._id
    if (userData?.data?.role == "admin" || userData?.data?.role == "root") {
      return true;
    } else if (
      userData?.data?.role == "employee" &&
      taskFrom._id == userData?.data?._id
    ) {
      return true;
    }
    return false;
  };

  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const handelUpdateTask = (task) => {
    console.log("task.status", task.status);
    updateTask({
      _id: task?._id,
      status: task?.status,
    }).then(() => {
      toast.success(
        lang == "en" ? "Task Updated Successfully" : "تم تحديث المهمة بنجاح"
      );
    });
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("the data to be submitted: ", {
  //     ...inputChange,
  //     company: localStorage.getItem("companyId"),
  //   });
  //   addTask({
  //     ...inputChange,
  //     company: localStorage.getItem("companyId"),
  //   }).then(() => {
  //     toast.success(
  //       lang == "en" ? "Task Created Successfully " : "تم إنشاء المهمة بنجاح"
  //     );
  //     handleClose();
  //   });
  // };

  const handleSelectBranch = (e) => {
    formik.setValues({ ...formik.values, branch: e.target.value });

    setDisableAssigning(false);
    setSkipEmpbyBranchToken(e.target.value);
  };
  const handleChangeStartDate = (dateValue) => {
    formik.setValues({
      ...formik.values,
      start: dateValue,
    });
  };
  const handleTouchStartDate = () => {
    if (values.start === null) {
      formik.setTouched({
        ...formik.touched,
        start: true,
      });
    } else {
      delete touched.start;
    }
  };
  const handleChangeEndDate = (dateValue) => {
    formik.setValues({
      ...formik.values,
      end: dateValue,
    });
  };
  const handleTouchEndDate = () => {
    if (values.end === null) {
      formik.setTouched({
        ...formik.touched,
        end: true,
      });
    } else {
      delete touched.end;
    }
  };
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        marginTop: "40px",
        padding: "0 30px",
      }}
    >
      <Box border={1} borderColor="divider">
        <Button
          sx={{
            display: "block",
            width: "100%",
            backgroundColor: "#0077B6",
            color: "#fff",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#0077B6",
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === "en" ? "Add Task" : "إضافة مهمة"}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <Box sx={ModalBoxStyle}>
            <Stack direction="row" justifyContent="flex-end" my="25px">
              <Button
                type="button"
                sx={{
                  bgcolor: "#1976D2",
                  color: "#fff",
                  minWidth: 0,
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  "&:hover": {
                    bgcolor: "#1976D2",
                    color: "#fff",
                  },
                }}
                disableRipple
                onClick={handleClose}
              >
                <CloseIcon
                  fontSize="small"
                  sx={{ color: mode === "dark" ? "#fff" : undefined }}
                />
              </Button>
            </Stack>
            {/*/------------------------------- inputs------------------------------------*/}

            <form onSubmit={handleSubmit}>
              <Typography
                variant="h5"
                sx={{
                  color: mode === "light" ? "#000" : "#fff",
                  my: "40px",
                  textAlign: "center",
                }}
              >
                {lang === "en" ? "create a new task" : "عمل مهمة جديدة"}
              </Typography>
              <Grid container marginTop="15px" spacing={2}>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <Box
                    sx={{
                      paddingBottom: "16px",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: lang === "en" ? "left" : "right",
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      {lang === "en" ? "Title" : "العنوان"}
                    </Typography>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        padding: "7px",
                        backgroundColor: mode === "light" ? "#fff" : "#252526",
                        color: mode === "light" ? "#000" : "#fff",
                        outline: 0,
                        border:
                          errors.title && touched.title
                            ? "1px solid red"
                            : "1px solid transparent",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "12px",
                      }}
                    >
                      {errors.title && touched.title && errors.title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <Box
                    sx={{
                      paddingBottom: "20px",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: lang === "en" ? "left" : "right",
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      {lang === "en" ? "branch" : "الفرع"}
                    </Typography>
                    <select
                      disabled={userData?.data?.role == "employee"}
                      style={{
                        outline: 0,
                        width: "100%",
                        backgroundColor: mode === "light" ? "#fff" : "#252526",
                        color: mode === "light" ? "#000" : "#fff",
                        padding: "8px 0",
                        marginTop: "3px",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        border:
                          errors.branch && touched.branch
                            ? "1px solid red"
                            : "1px solid transparent",
                      }}
                      value={values.category}
                      name="branch"
                      onChange={handleSelectBranch}
                      onBlur={handleBlur}
                    >
                      <option hidden defaultValue="Default Branches">
                        {userData?.data?.role !== "employee"
                          ? "Choose Branch"
                          : "Default employee Branch"}
                      </option>
                      {branchesSuccess
                        ? [...branches?.data].map((branch, index) => {
                            return (
                              <option value={branch._id} key={index}>
                                {branch?.name}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <Typography
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "12px",
                      }}
                    >
                      {errors.branch && touched.branch && errors.branch}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "15px",
                      textAlign: lang === "en" ? "left" : "right",
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                  >
                    {lang === "en" ? "Assign to" : "تعيين الي"}
                  </Typography>
                  <TaskEmployeeMenu
                    lang={lang}
                    mode={mode}
                    setValues={formik.setValues}
                    error={errors.to}
                    touched={touched.to}
                    disableAssigning={disableAssigning}
                    EmpsBranchSuccess={EmpsBranchSuccess}
                    empsByBranch={empsByBranch}
                    userData={userData}
                  />
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <DateAndTimePicker
                    label={lang === "en" ? "Start Date" : "تاريخ البدء"}
                    value={values.start}
                    error={errors.start}
                    touched={touched.start}
                    handleChange={handleChangeStartDate}
                    handleBlur={handleTouchStartDate}
                  />
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <DateAndTimePicker
                    label={lang === "en" ? "End Date" : "تاريخ الانتهاء"}
                    value={values.end}
                    error={errors.end}
                    touched={touched.end}
                    handleChange={handleChangeEndDate}
                    handleBlur={handleTouchEndDate}
                  />
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <Box
                    sx={{
                      paddingBottom: "16px",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "15px",
                        textAlign: lang === "en" ? "left" : "right",
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      {lang === "en" ? "Description" : "الوصف"}
                    </Typography>
                    <input
                      type="text"
                      style={{
                        marginTop: "6px",
                        width: "100%",
                        padding: "7px",
                        backgroundColor: mode === "light" ? "#fff" : "#252526",
                        color: mode === "light" ? "#000" : "#fff",
                        outline: 0,
                        border:
                          errors.description && touched.description
                            ? "1px solid red"
                            : "1px solid transparent",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "12px",
                      }}
                    >
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mt: "25px" }}>
                <Button
                  type="submit"
                  sx={{
                    bgcolor: "#1976D2",
                    color: "#fff",

                    paddingX: "40px",
                    "&:hover": {
                      bgcolor: "#1976D2",
                      color: "#fff",
                    },
                  }}
                >
                  {lang === "en" ? "Submit" : "تقديم"}
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </Box>
    </Stack>
  );
}
