import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { blogsApi } from "./features/blogsApi";
import { branchesApi } from "./features/branchesApi";
import { companyApi } from "./features/companyApi";
import { empolyeeApi } from "./features/employeeApi";
import { taskApi } from "./features/tasksApi";
import { UserApi } from "./features/userApi";
import LanguageSlice from "./slices/LanguageSlice";
import modelSlice from "./slices/modeSlice";
import { alertApi } from "./features/alertsApis";
import { uploadsApi } from "./features/uploadsApi";
import { shiftsApi } from "./features/shiftsApi";
import { categoryApis } from "./features/categoryApis";
import { subcategoryApis } from "./features/subCategoryApis";
import { departmentsApis } from "./features/departmentsApis";
import { requsetApis } from "./features/requestsApis";
import { commentsApi } from "./features/commentsApi";
import { contractsApi } from "./features/contractsApi";
import { attendApis } from "./features/attendeApis";
import { payroleApi } from "./features/payrolApi";
import { breaksApis } from "./features/breaksApis";
import { overtimeApis } from "./features/overTimeApis";
import { documentApi } from "./features/documentsApi";
import { notificationsApi } from "./features/notificationsApi";
import { subscriptionsApi } from "./features/subscrptionApis";


const appReducer = combineReducers(
  {
    [UserApi.reducerPath]: UserApi.reducer,
    [empolyeeApi.reducerPath]: empolyeeApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [branchesApi.reducerPath]: branchesApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [blogsApi.reducerPath]: blogsApi.reducer,
    [alertApi.reducerPath]: alertApi.reducer,
    [uploadsApi.reducerPath]: uploadsApi.reducer,
    [departmentsApis.reducerPath]: departmentsApis.reducer,
    [shiftsApi.reducerPath]: shiftsApi.reducer,
    [categoryApis.reducerPath]: categoryApis.reducer,
    [subcategoryApis.reducerPath]: subcategoryApis.reducer,
    [requsetApis.reducerPath]: requsetApis.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
    [attendApis.reducerPath]: attendApis.reducer,
    [payroleApi.reducerPath]: payroleApi.reducer,
    [breaksApis.reducerPath]: breaksApis.reducer,
    [overtimeApis.reducerPath]: overtimeApis.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,

    mode: modelSlice,
    lang: LanguageSlice
  },
)

const ReduxStore = configureStore({
  // reducer: slices,
  reducer: appReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware(

    ).concat(
      UserApi.middleware,
      empolyeeApi.middleware,
      companyApi.middleware,
      branchesApi.middleware,
      taskApi.middleware,
      alertApi.middleware,
      categoryApis.middleware,
      subcategoryApis.middleware,
      departmentsApis.middleware,
      requsetApis.middleware,
      blogsApi.middleware,
      alertApi.middleware,
      uploadsApi.middleware,
      shiftsApi.middleware,
      commentsApi.middleware,
      contractsApi.middleware,
      attendApis.middleware,
      payroleApi.middleware,
      breaksApis.middleware,
      overtimeApis.middleware,
      documentApi.middleware,
      notificationsApi.middleware,
      subscriptionsApi.middleware,
    )
  }
});
setupListeners(ReduxStore.dispatch)

export default ReduxStore;
