import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Employees.module.css";
import Modal from "react-bootstrap/Modal";
import UserImage from "./images/user.png";
import { ReactComponent as DotIcon } from "./images/Group 5015.svg";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-bootstrap";
import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { useFormik } from "formik";
import * as Yup from "yup";

import FilterEmployeesModal from "./mui/FilterEmployeesModal";
import { AiOutlinePlus } from "react-icons/ai";
import FormikInputAndErrors from "./formikInput_errors/FormikInputAndErrors";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
import {
  useCreateEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetEmployeesQuery,
} from "../../../redux/features/employeeApi";
import Spinner from "../../spinner/Spinner";
import { imageUrl } from "../../../App";
import EditEmployeesModal from "./mui/EditEmployeeModel";
import {
  Box,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import AddEmployeeModal from "../../../components/singleBranch/modals/AddEmployeeModel";
import { useGetMeQuery } from "../../../redux/features/userApi";
import DeleteIcon from "@mui/icons-material/Delete";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
function EmployeesPage() {
  let { data, isLoading, isSuccess: empsSuccess } = useGetEmployeesQuery();

  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();

  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;

  async function displaybranches() {}
  const navigate = useNavigate();
  useEffect(() => {
    displaybranches();
  }, []);

  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";

  const { cardTheme } = useTheme();

  const giveAccessToAdd = () => {
    if (userData?.data?.role == "admin" || userData?.data.role == "root")
      return true;
    return false;
  };
  const whoCanDeleteEmpolyee = () => {
    if (userData?.data?.role == "admin" || userData?.data.role == "root")
      return true;
    return false;
  };
  useEffect(() => {}, [userSuccess]);
  const [deleteEmployee] = useDeleteEmployeeMutation();

  const handleDeleteEmployee = (empId) => {
    deleteEmployee(empId).then(({ data, error }) => {
      if (data) {
        toast.success(
          lang == "en" ? "Employee Deleted Successfully" : "تم حذف الموظف بنجاح"
        );
      } else if (error) {
        toast.error(
          lang == "en" ? "Employee Cant Be Deleted " : "لا يمكن حذف الموظف"
        );
      }
    });
  };

  return (
    <HOC>
      <div>
        <div className={`overflow-hidden p-3  `}>
          <div
            className="p-4"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className="text-center my-5"
              style={{
                marginLeft: lang === "en" && "auto",
                marginRight: lang === "ar" && "auto",
                color: mode === "light" ? "#000" : "#fff",
                marginBottom: "30px",
              }}
            >
              {lang === "en" ? "Employees" : "الموظفيين"}
            </h1>

            <div className="row mb-4">
              <>
                {isLoading ? (
                  <Spinner />
                ) : (
                  empsSuccess &&
                  data?.data?.map(
                    (emp, index) =>
                      userData?.data?._id !== emp._id && (
                        <div
                          key={index}
                          className="col-md-6 col-lg-4 col-xl-3 p-2"
                        >
                          <div
                            className={`${styles.columContent} p-3 text-center`}
                            style={{
                              backgroundColor: cardTheme[`bg_${mode}`],

                              borderRadius: "25px",
                              height: "300px",
                            }}
                          >
                            <div className="d-flex justify-content-end align-items-center">
                              {giveAccessToAdd() && <EditEmployeesModal />}

                              {whoCanDeleteEmpolyee() ? (
                                <HighlightOffIcon
                                  sx={{
                                    color: "#E04449",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDeleteEmployee(emp?._id)}
                                />
                              ) : null}
                            </div>

                            <div
                              style={{
                                width: "3rem",
                                height: "3rem",
                                margin: "auto",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "3rem",
                                  cursor: `${
                                    giveAccessToAdd() ? "pointer" : "default"
                                  }`,
                                }}
                                src={imageUrl + "/" + emp?.image}
                                alt="mark"
                                onClick={() =>
                                  giveAccessToAdd() &&
                                  navigate(`/employee/${emp._id}`)
                                }
                              />
                            </div>

                            <div className="my-4">
                              <h3
                                className={
                                  mode === "light" ? "text-dark" : "text-white"
                                }
                                style={{
                                  wordWrap: "break-word",
                                  height: "40px",
                                }}
                              >
                                {lang === "en"
                                  ? emp.userName_en
                                  : emp.userName_ar}
                              </h3>
                              <h5
                                className={` fs-6 ${
                                  mode === "light" ? "text-dark" : "text-white"
                                }`}
                              >
                                <p
                                  className={`${
                                    mode === "dark" ? "#fff" : undefined
                                  } mb-1`}
                                >
                                  {emp?.position}
                                </p>
                                <p
                                  className={`${
                                    mode === "dark" ? "#fff" : undefined
                                  } mb-1`}
                                >
                                  {emp?.role}
                                </p>
                                <p
                                  className={`${
                                    mode === "dark" ? "#fff" : undefined
                                  } mb-1`}
                                >
                                  {emp?.branch?.name}
                                </p>
                                <p
                                  className={`${
                                    mode === "dark" ? "#fff" : undefined
                                  } mb-1`}
                                >
                                  {emp?.company?.name}
                                </p>
                                <p
                                  className={`${
                                    mode === "dark" ? "#fff" : undefined
                                  } mb-1`}
                                >
                                  {emp?.shift?.name}
                                </p>
                              </h5>
                            </div>
                          </div>
                        </div>
                      )
                  )
                )}
              </>
            </div>

            {giveAccessToAdd() ? (
              <Box
                sx={{
                  py: 4,
                }}
              >
                <AddEmployeeModal lang={lang} mode={mode} />
              </Box>
            ) : null}

            <div className={`row g-4`}></div>
          </div>
        </div>
      </div>
    </HOC>
  );
}
export default EmployeesPage;
