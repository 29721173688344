import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useCreateBlogMutation } from "../../../redux/features/blogsApi";
import { useUploadImageMutation } from "../../../redux/features/uploadsApi";

const NewBlogForm = ({ value, navigateToMyBlogs, panelValue }) => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const { modalTheme } = useTheme();
  const [createBlog, { isSuccess: blogSuccess, isError: blogError }] =
    useCreateBlogMutation();
  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        lang == "en" ? "title is Required" : "العنوان مطلوب"
      ),
      description: Yup.string().required(
        lang == "en" ? "description is Required" : "الوصف مطلوب"
      ),
      image: Yup.string().required(
        lang == "en" ? "image Path is Required" : "مطلوب مسار الصورة"
      ),
    }),
    onSubmit: () => {
      createBlog(formik.values);
      formik.resetForm();
      setImagePath("AddImage");
      toast.success(lang === "en" ? "Blog Added" : "تمت اضافة المدونة");
      navigateToMyBlogs();
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    handleReset,
  } = formik;

  const imageRef = useRef(null);
  const [uploadImage] = useUploadImageMutation();

  const [imagePath, setImagePath] = useState(
    lang === "en" ? "Add Image for the post" : "أضف صورة للمنشور"
  );

  const handleImageChange = (e) => {
    setImagePath((_) => e.target.files[0].name);
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    uploadImage(formData).then(({ data }) => {
      formik.setFieldValue("image", data.filename);
    });
  };
  useEffect(() => {
    formik.resetForm();
  }, [panelValue]);
  return (
    <TabPanel value={value}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            mt: "35px",
            width: {
              xl: "800px",
              sm: "100%",
              xs: "100%",
            },
            bgcolor: modalTheme.Box[`bg_${mode}`],
            border: 0,
            outline: 0,
            boxShadow: 4,
            p: {
              xl: 4,
              sm: 2,
              xs: 2,
            },
            borderRadius: "20px",
            direction: lang === "en" ? "ltr" : "rtl",
            height: "70%",
          }}
        >
          <Typography
            sx={{
              color: mode === "light" ? "#000" : "#fff",
              fontSize: "24px",
              my: "20px",
            }}
            variant="h6"
          >
            {lang === "en" ? "Create a new blog" : "عمل مدونة جديدة"}
          </Typography>
          <Box
            sx={{
              paddingBottom: "16px",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "15px",
                textAlign: lang === "en" ? "left" : "right",
                color: mode === "light" ? "#000" : "#fff",
              }}
            >
              {lang === "en" ? "title" : "العنوان"}
            </Typography>
            <input
              type="text"
              style={{
                marginTop: "6px",
                width: "100%",
                padding: "7px",
                backgroundColor: mode === "light" ? "#fff" : "#252526",
                color: mode === "light" ? "#000" : "#fff",
                outline: 0,
                border:
                  errors.title && touched.title
                    ? "1px solid red"
                    : "1px solid transparent",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Typography
              sx={{
                position: "absolute",
                bottom: 0,
                color: "red",
                fontWeight: "bolder",
                fontSize: "12px",
              }}
            >
              {errors.title && touched.title && errors.title}
            </Typography>
          </Box>
          <Box style={{ my: 5 }}>
            <Typography
              variant="h6"
              sx={{
                color: mode === "light" ? "#000" : "#fff",
                fontSize: "16px",
              }}
            >
              {lang === "en" ? "Image" : "صورة"}
            </Typography>

            <>
              <input
                ref={imageRef}
                type="file"
                id="myfile"
                style={{
                  display: "none",
                  width: "100%",
                  height: "50px",
                }}
                onChange={handleImageChange}
              />
              {!imagePath ? (
                <button
                  className={mode === "light" ? "text-dark" : "text-white"}
                  style={{
                    fontSize: "13px",
                  }}
                  onClick={() => imageRef.current.click()}
                >
                  {lang === "en" ? "Add Image" : "أضف صورة"}
                </button>
              ) : null}

              {imagePath ? (
                <p
                  style={{
                    cursor: "pointer",
                    color: mode === "light" ? "#000" : "#fff",
                    fontSize: "13px",
                  }}
                  onClick={() => imageRef.current.click()}
                >
                  {imagePath}
                </p>
              ) : null}
              {errors["image"] && (
                <p style={{ color: "red" }}>{errors["image"]}</p>
              )}
            </>
          </Box>

          <Box
            sx={{
              paddingBottom: "16px",
              position: "relative",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "15px",
                textAlign: lang === "en" ? "left" : "right",
                color: mode === "light" ? "#000" : "#fff",
              }}
            >
              {lang === "en" ? "Description" : "الوصف"}
            </Typography>
            <textarea
              type="text"
              style={{
                marginTop: "6px",
                width: "100%",
                padding: "7px",
                backgroundColor: mode === "light" ? "#fff" : "#252526",
                color: mode === "light" ? "#000" : "#fff",
                outline: 0,
                border:
                  errors.description && touched.description
                    ? "1px solid red"
                    : "1px solid transparent",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                height: "150px",
              }}
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Typography
              sx={{
                position: "absolute",
                bottom: 0,
                color: "red",
                fontWeight: "bolder",
                fontSize: "12px",
              }}
            >
              {errors.description && touched.description && errors.description}
            </Typography>
          </Box>
          <Button
            sx={{
              backgroundColor: "#0077B6",
              mt: "25px",
              color: "#fff",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#0077B6",
                color: "#fff",
              },
            }}
            type="submit"
          >
            {lang === "en" ? "Add" : "اضافة"}
          </Button>
        </Box>
      </form>
    </TabPanel>
  );
};

export default NewBlogForm;
