import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const taskApi = createApi({
    reducerPath: 'task',
    tagTypes: ['Task'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1',
        prepareHeaders: (headers) => {
            headers.set('Authentication', localStorage.getItem("userToken"))
            return headers
        },

    }),
    endpoints: (builder) => ({
        getTasks: builder.query({
            query: () => `task/?company=${localStorage.getItem('companyId')}`,
            providesTags: ['Task']
        }),
        addTask: builder.mutation({
            query: (data) => ({
                url: `/task`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Task']
        }),
        updateTask: builder.mutation({
            query: ({ status, _id }) => ({
                url: `/task/${_id}`,
                method: 'PUT',
                body: {
                    status
                }
            }),
            invalidatesTags: ['Task']
        }),
        deleteTask: builder.mutation({
            query: (taskId) => ({
                url: `task/${taskId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Task']
        })
    })

})


export const { useGetTasksQuery, useAddTaskMutation, useUpdateTaskMutation,
    useDeleteTaskMutation } = taskApi