import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useGetCountQuery } from "../../../redux/features/attendeApis";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function HomeAttendance({ lang, mode, chartBGColor }) {
  //GET ATTEND COUNT
  let { data: dataCount, isSuccess: successCount } = useGetCountQuery();
  const labels = [];
  const data = {
    labels,
    datasets: [
      {
        label: lang === "en" ? "Overdue" : " غير مكتمل",
        data: [],
        backgroundColor: ["#FF2366"],
        borderRadius: 30,
        barPercentage: 0.05,
      },
      {
        label: lang === "en" ? " Completed" : " مكتمل",
        data: [],
        backgroundColor: ["#02BC77"],
        barPercentage: 0.05,
        borderRadius: 30,
      },
    ],
  };
  const options = {
    scales: {
      x: {
        ticks: {
          color: mode == "light" ? "black" : "white",
          font: {
            size: 10,
          },
        },
        grid: {
          color: mode === "light" ? "#F5F5F5" : "#252525",

        },
      },
      y: {
        ticks: {
          color: mode == "light" ? "black" : "white",
          font: {
            size: 10,
          },
        },
        grid: {
          color: mode === "light" ? "#F5F5F5" : "#252525",
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: mode == "light" ? "black" : "white",
          boxHeight: 4,
          usePointStyle: true,
          pointStyle: "circle",
          padding: 10,
          font: {
            size: 10,
          },
        },
      },
    },
  };
  // get attend count with
  if (successCount) {
    for (let [key, value] of Object.entries(dataCount?.years)) {
      labels.push(key);
      data.datasets[1].data.push(value.complate);
      data.datasets[0].data.push(value.uncomplate);
    }
  }
  return (
    <div className={`h-100 home_attendance flow_direction_${lang}`}>
      <div
        className="p-3 h-100"
        style={{
          backgroundColor: chartBGColor,
          borderRadius: "20px",
        }}
      >
        <div>
          <Link
            to="/attendees"
            style={{
              color: mode === "light" ? "#000" : "#fff",
              textDecoration: "none",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {lang === "en" ? "Attendance" : "الحضور"}
          </Link>
        </div>
        <div className="container h-100">
          <div className="h-100">
            <Bar options={options} data={data} borderColor="#f00" />
          </div>
        </div>
      </div>
    </div>
  );
}
