import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useGetAttendesQuery } from "../../../redux/features/attendeApis";
import dayjs from "dayjs";

const AttendeesPayrolTabel = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  let { data, isSuccess } = useGetAttendesQuery();

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: "10px",
        backgroundColor: "transparent",
        border: 1,
        borderColor: mode === "light" ? "#F5F5F5" : "#252526",
        borderRadius: "17px",
      }}
    >
      <Table
        sx={{
          minWidth: "200px !important",
          overflow: "scroll",

          borderRadius: "6px",
        }}
        aria-label="simple table"
      >
        <TableHead
          sx={{
            backgroundColor: mode === "light" ? "#F2F8FD" : "#252525",
          }}
        >
          <TableRow>
            <TableCell
              align="center"
              sx={{
                color: mode === "light" ? "#000" : "#fff",
                border: 0,
              }}
            >
              {lang === "en" ? "Name" : "الاسم"}
            </TableCell>
            <TableCell
              sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
              align="center"
            >
              {lang === "en" ? "Date" : "التاريخ"}
            </TableCell>
            <TableCell
              sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
              align="center"
            >
              {lang === "en" ? "Late Hours" : "وقت التائخر"}
            </TableCell>
            <TableCell
              sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
              align="center"
            >
              {lang === "en" ? " Work Hours" : "ساعات العمل"}
            </TableCell>
            <TableCell
              sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
              align="center"
            >
              {lang === "en" ? "Original Time" : "الوقت الكلي للدوام"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isSuccess &&
            data?.attends?.map((_, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: mode === "light" ? "transparent" : "#3E3E42",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                  }}
                >
                  {_.member[`fullName_${lang}`]}
                </TableCell>
                <TableCell
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                  }}
                  align="center"
                >
                  {/* {_.signIn daj} */}
                  {dayjs(_.signIn).format("DD/MM-hh:m")}
                </TableCell>
                <TableCell
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                  }}
                  align="center"
                >
                  {_?.lateHours
                    ? _?.lateHours?.hours + ":" + _?.lateHours?.mins
                    : "0"}
                </TableCell>
                <TableCell
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                  }}
                  align="center"
                >
                  {new Date(Date.now()).getHours() -
                    new Date(_.signIn).getHours()}
                  {":"}
                  {Math.abs(
                    new Date(Date.now()).getMinutes() -
                      new Date(_.signIn).getMinutes()
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                  }}
                  align="center"
                >
                  {_.originalTime?.hours + ":" + _.originalTime?.mins}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AttendeesPayrolTabel;
