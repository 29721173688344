import React, { useEffect, useState } from "react";

import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import FilterContractsModal from "./FilterContractsModal";
import AddContractModal from "./AddContractModal";
import { useGetMeQuery } from "../../../redux/features/userApi";
import { use } from "i18next";
import { useGetCompaniesQuery } from "../../../redux/features/companyApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormControl } from "@mui/material";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import {
  contractsApi,
  useDeleteContractMutation,
  useGetAllContractsQuery,
} from "../../../redux/features/contractsApi";
import Spinner from "../../spinner/Spinner";
import { toast } from "react-toastify";
import ErrorScreen from "../../errorScreen/ErrorScreen";
import {
  departmentsApis,
  useGetDepartmentsByBranchQuery,
} from "../../../redux/features/departmentsApis";
import EditContractModal from "./EditContractModal";
import { AiFillDelete } from "react-icons/ai";
import { imageUrl } from "../../../App";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function ContractsPage() {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const { cardTheme } = useTheme();
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();

  let [skipContractSToken, setSkipContractsToken] = useState(undefined);

  let {
    data: contractsData,
    isSuccess: contractsSuccess,
    isLoading: contractsLoading,
    isError: contractsError,
    error: contsError,
    refetch: branchesRefetch,
  } = useGetAllContractsQuery(skipContractSToken);

  let {
    data: branchesData,
    isSuccess: branchesSuccess,
    isLoading: branchesLoading,
    isError: branchesError,
  } = useGetBranchesQuery();
  let [department, setDepartment] = useState("");
  let [skipDepartmentToken, setSkipDepartmentToken] = useState(skipToken);
  let {
    data: depsData,
    isSuccess: departmentsSuccess,
    isLoading: departmentLoding,
    isError: depError,
  } = useGetDepartmentsByBranchQuery(skipDepartmentToken);
  const [error, setError] = useState(false);

  let [branch, setBranch] = useState("");
  const handleBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleDepartment = (e) => {
    setDepartment(e.target.value);
  };

  const [deleteContract] = useDeleteContractMutation();
  const handleDeleteContract = (id) => {
    deleteContract(id).then(({ data, error }) => {
      if (data) {
        toast.success(
          lang == "en" ? "Contracts Deleted successfully" : "تم حذف العقد بنجاح"
        );
      } else if (error) {
        toast.error(
          lang == "en" ? "Contracts Cant Be Deleted" : "لا يمكن حذف العقد"
        );
      }
    });
  };
  useEffect(() => {
    if (userSuccess) {
      if (userData?.data?.role == "root") {
      } else if (userData?.data?.role == "admin") {
        setBranch((_) => userData?.data?.branch);
      }
      let query = "";
      if (branch) {
        setSkipDepartmentToken(branch);
        departmentsApis.util.invalidateTags(["Department"]);
        query += `?branch=${branch}`;
        if (branchesSuccess) {
          setSkipContractsToken(query);

          contractsApi.util.invalidateTags(["Contract"]);
        }
      }
      if (department) {
        query += `${branch ? `&` : "?"}department=${department}`;
        if (departmentsSuccess) {
          setSkipContractsToken(query);

          contractsApi.util.invalidateTags(["Contract"]);
        }
      }
      if (contractsSuccess) {
        setError(false);
      }
    }
  }, [
    userSuccess,
    branchesSuccess,
    contractsSuccess,
    branch,
    contractsError,
    department,
    departmentsSuccess,
  ]);
  return (
    <HOC>
      <div>
        <div className={`overflow-hidden p-3`}>
          {contractsError ? (
            <ErrorScreen
              data={
                lang === "en"
                  ? "Contracts Are Not Found"
                  : "لم يتم العثور على العقود"
              }
            />
          ) : contractsLoading || branchesLoading ? (
            <Spinner />
          ) : (
            <Box
              sx={{ backgroundColor: chartBGColor, borderRadius: "25px", p: 4 }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                  >
                    {lang === "en" ? "Contracts" : "كشف الراوتب"}
                  </Typography>
                </Box>
                <FilterContractsModal
                  lang={lang}
                  mode={mode}
                  setError={setError}
                />
              </Stack>

              <Grid
                container
                sx={{
                  my: "40px",
                }}
              >
                {contractsSuccess && !error ? (
                  [...contractsData?.contracts].map((cont, index) => (
                    <Grid
                      item
                      xl={3}
                      md={6}
                      xs={12}
                      sx={{
                        py: {
                          xl: "20px",
                          xs: "10px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          height: "350px",

                          width: {
                            xl: "90%",
                            md: "90%",
                            xs: "100%",
                          },
                          marginX: "auto",
                          backgroundColor: cardTheme[`bg_${mode}`],
                          borderRadius: 5,
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{
                            textAlign: lang == "en" ? "end" : "start",

                            pb: "10px",
                            pt: "4px",
                          }}
                        >
                          <EditContractModal
                            mode={mode}
                            lang={lang}
                            contract={cont}
                          />
                          <HighlightOffIcon
                            sx={{
                              color: "#E04449",
                              mx: "10px",
                            }}
                            onClick={() => handleDeleteContract(cont?._id)}
                          />
                        </Stack>

                        <Avatar
                          alt="Remy Sharp"
                          sx={{
                            mx: "auto",
                            height: "75px",
                            width: "75px",
                          }}
                          src={imageUrl + cont?.employee?.image}
                        />
                        <Box sx={{ height: "70px" }}>
                          <Typography
                            align="center"
                            variant="h6"
                            sx={{
                              fontSize: "20px",
                              mt: "25px",
                              color: mode === "light" ? "#000" : "#fff",
                            }}
                          >
                            {cont?.employee[`userName_${lang}`]}
                          </Typography>
                          <Typography
                            align="center"
                            paragraph
                            sx={{
                              mt: "0px",
                              color: mode === "light" ? "#8B8C89" : "#ccc",
                            }}
                          >
                            {cont?.employee?.position}
                          </Typography>
                        </Box>
                        <Button
                          disableRipple
                          sx={{
                            display: "block",
                            mx: "auto",
                            backgroundColor: "#81D8D0",
                            minWidth: 0,
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                            "&:hover": {
                              backgroundColor: "#81D8D0",
                            },
                          }}
                        >
                          <DescriptionIcon sx={{ color: "#fff" }} />
                        </Button>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <ErrorScreen
                    data={
                      lang == "en"
                        ? "Contarcts Are Not Found"
                        : "لم يتم العثور على العقود"
                    }
                  />
                )}
              </Grid>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              ></Stack>
            </Box>
          )}
        </div>
        <AddContractModal mode={mode} lang={lang} />
      </div>
    </HOC>
  );
}
export default ContractsPage;
