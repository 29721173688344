import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const blogsApi = createApi({
    reducerPath: 'blogs',
    tagTypes: ['Blog'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1',
        prepareHeaders: (headers) => {
            headers.set("Authentication", localStorage.getItem('userToken'))
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAllBlogs: builder.query({
            query: (state) => `blog/${state ? `?company=${localStorage.getItem('companyId')}` : ''}`,
            providesTags: ['Blog']
        }),
        getMyBlogs: builder.query({
            query: () => `blog/me`,
            providesTags: ['Blog']
        }),
        getBlogById: builder.query({
            query: (blogId) => `/blog/${blogId}`,
            providesTags: ['Blog']
        }),
        createBlog: builder.mutation({
            query: (blogData) => ({
                url: '/blog',
                body: { ...blogData, company: localStorage.getItem('companyId') },
                method: 'POST'
            }),
            invalidatesTags: ['Blog']
        }),
        addLike: builder.mutation({
            query: ({ blogId, likeType }) => ({
                url: `/blog/addLike/${blogId}`,
                method: 'PUT',
                body: { likeType }
            })
        }),
        deleteBlog: builder.mutation({
            query: (id) => ({
                url: `/blog/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Blog']
        })
    })
})

export const { useGetAllBlogsQuery,
    useCreateBlogMutation,
    useGetMyBlogsQuery,
    useDeleteBlogMutation,
    useGetBlogByIdQuery,
    useAddLikeMutation,
    useLazyGetBlogByIdQuery
} = blogsApi

