import { useTranslation } from "react-i18next";
import React from "react";
import { useState } from "react";
import { ReactComponent as UserIcon } from "./images/user.svg";
import styles from "./Attendance.module.css";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import HOC from "../../../components/HOC/HOC";
import { useGetCountQuery } from "../../../redux/features/attendeApis";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Typography,
} from "@mui/material";
import PayrollTabel from "../payrolls/PayrollTabel";
import AttendeesPayrolTabel from "./AttendeesPayrolTabel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function AttendeesPage() {
  // const mode = useSelector((state) => state.counter?.value);
  const { value: mode } = useSelector((state) => state).mode;
  // const [t, { language }] = useTranslation();
  const { value: lang } = useSelector((state) => state).lang;
  //GET ATTEND COUNT
  let { data: dataCount, isSuccess: successCount } = useGetCountQuery();
  const labels = [];
  const data = {
    labels,
    datasets: [
      {
        label: lang === "en" ? "Overdue" : " غير مكتمل",
        data: [],
        backgroundColor: ["#FF2366"],
        borderRadius: 30,
        barPercentage: 0.05,
      },
      {
        label: lang === "en" ? " Completed" : " مكتمل",
        data: [],
        backgroundColor: ["#02BC77"],

        barPercentage: 0.05,
        borderRadius: 30,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          color: mode == "light" ? "black" : "white",
          font: {
            size: 10,
          },
        },
        grid: {
          color: mode === "light" ? "#F5F5F5" : "#252525",
        },
      },
      y: {
        ticks: {
          color: mode == "light" ? "black" : "white",
          font: {
            size: 10,
          },
        },
        grid: {
          color: mode === "light" ? "#F5F5F5" : "#252525",
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: mode == "light" ? "black" : "white",
          boxHeight: 4,
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 10,
          },
        },
      },
    },
  };
  // get attend count with
  if (successCount) {
    for (let [key, value] of Object.entries(dataCount?.years)) {
      labels.push(key);
      data.datasets[1].data.push(value.complate);
      data.datasets[0].data.push(value.uncomplate);
    }
  }

  const [employees, setEmployees] = useState([
    {
      id: 0,
      fullname_en: "Ahmed Ali",
      fullname_ar: "أحمد علي",
      delayHours: 8,
      total: 10,
    },
    {
      id: 1,
      fullname_en: "Mohammed",
      fullname_ar: "محمد",
      delayHours: 12,
      total: 40,
    },
    {
      id: 2,
      fullname_en: "Eman Emad",
      fullname_ar: "إيمان عماد",
      delayHours: 3,
      total: 16,
    },
    {
      id: 3,
      fullname_en: "Ali Ali",
      fullname_ar: "علي علي",
      delayHours: 14,
      total: 14,
    },
    {
      id: 4,

      fullname_en: "Khalid fadl",
      fullname_ar: "خالد فضل",
      delayHours: 8,
      total: 11,
    },
  ]);
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <HOC>
      <div>
        <div className={`mx-auto  overflow-hidden p-3`}>
          <div
            className="p-4"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className={`text-center ${
                mode === "light" ? "text-dark" : "text-white"
              }`}
            >
              {lang === "en" ? "Attendance" : "الحضور"}
            </h1>
            <div className={` ${styles.container}`}>
              <div className="my-5">
                <Bar options={options} data={data} />
              </div>
            </div>
          </div>

          <div
            className="p-4 mt-4"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className={`text-center my-5 ${
                mode === "light" ? "text-dark" : "text-white"
              }`}
            >
              {lang === "en" ? "Attendance" : "الحضور"}
            </h1>

            {true ? (
              <div className="mt-5">
                <Accordion
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: 0,
                    marginTop: "20px",
                    border: 0,
                    overflow: "hidden",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                          border: 0,
                        }}
                      />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                      bgcolor: "transparent",
                      border: 1,
                      borderColor: mode === "light" ? "divider" : "#aaa",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0,
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      {lang == "en"
                        ? "Attendance for today"
                        : "الحضور لي اليوم"}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AttendeesPayrolTabel />
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <Typography
                severity="error"
                variant="h3"
                align="center"
                sx={{
                  color: "red",
                  fontSize: "22px",
                }}
              >
                {lang === "en" ? "Not data yet" : "لا يوجد بيانات حتي الان"}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </HOC>
  );
}
export default AttendeesPage;
