import React from "react";
import styles from "./Breaks.module.css";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import makeAnimated from "react-select/animated";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const Option = (props) => {
  return (
    <HOC>
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    </HOC>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

export const colourOptions = [
  { value: "ocean1", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" },
];

function BreaksPage() {
  const [alertValue, setAlertValue] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertList, setAlertList] = useState([]);

  // const { isLoading, error, data } = useQuery('branchList', getBranchesByCompany(companyId))

  const [branchList, setBranchList] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);

  const [userinfo, setUserInfo] = useState({
    employees: [],
    branches: [],
    departments: [],
  });

  //  -------------------------------------------------modal---------------------------------------

  // const [modalShow, setModalShow] = useState(false);

  const [search, setSearchParams] = useSearchParams();

  // const [modalState, setModalState] = useState();
  // const showModalState =()=>{
  //   setModalState(true);
  // }
  // const handleClose = () => {
  //   setModalShow(false);
  //   setModalState(false);
  //  }

  //  -------------------------------------------------modal---------------------------------------
  const [modalState, setModalState] = useState();
  const [modalShow, setModalShow] = useState();
  const showModalState = () => {
    setModalState(true);
  };
  const handleClose = () => {
    setModalShow(false);
    setModalState(false);
  };

  // -----------------------------------------------------------

  // -------------------------------------------------------------

  const [alertDetails, setAlertDetails] = useState({});

  function alertDetail(index) {
    setAlertList([...alertList]);
    setModalShow(true);

    setAlertDetails(alertList[index]);
  }

  const createOverTime = async () => {
    alert("Over time created");
  };

  // -----------------------------------------------------------------------------
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const navigate = useNavigate();
  function getAlertValue(e) {
    setAlertValue(e.target.value);
  }
  function getAlertTitle(e) {
    setAlertTitle(e.target.value);
  }

  const [optionSelected, setOptionSelected] = useState(null);

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  const [assignedEmployees, setAssignedEmployees] = useState([]);

  const submitRequest = async () => {
    alert("submitted");
  };

  const [shiftsList, setShiftsList] = useState([]);
  const [breaksList, setBreaksList] = useState([]);

  const [shiftDetails, setShiftDetails] = useState({});

  const handleGetShifts = async (branchId) => {
    // try {
    //   const {data} = await axios.get(`${BASE_URL}/shift/${branchId}`)
    //   setShiftsList(data.data)
    // } catch (error) {
    // }
  };

  const [startHours, setStartHours] = useState(null);
  const [endHours, setEndHours] = useState(null);

  const [startMins, setStartMins] = useState(null);
  const [endMins, setEndMins] = useState(null);
  const [durationHours, setDurationHours] = useState(null);
  const [durationMins, setDurationMins] = useState(null);

  const [isOpen, setIsOpen] = useState("no");

  const [shiftId, setShiftId] = useState(null);

  const handleSubmit = async () => {
    alert("submitted successfully");
  };
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  return (
    <HOC>
      <div className={styles.component}>
                {/*---------------------------------------------container------------------------------------------------*/}
        <div className={`container-fluid  overflow-hidden p-3  `}>
          <div
            className="p-5"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className="mb-5"
              style={{
                marginLeft: lang === "en" && "auto",
                marginRight: lang === "ar" && "auto",
                marginBottom: "30px",
                color: mode === "light" ? "#000" : "#fff",
              }}
            >
              {lang === "en" ? "Breaks" : "فترات الراحة"}
            </h1>
            <div className={`row g-4 p-3 ${styles.rows}`}>
              {/* branchList */}
              {[...Array(6)].map((_, index) => (
                <div key={index} className={` col-md-6 col-lg-4 col-xxl-3 `}>
                  <div
                    className={`${styles.columContent} p-3 rounded`}
                    style={{
                      backgroundColor: mode === "light" ? "#E4E4E4" : "#333",
                    }}
                  >
                    <div className="p-2 w-75 ">
                      <div className="d-flex justify-content-between align-items-center ">
                        <div className="d-flex flex-column mx-3  overflow-hidden">
                          <h4
                            style={{
                              color: mode === "light" ? "#000" : "#fff",
                            }}
                          >
                            {lang === "en"
                              ? `Break ${index + 1}`
                              : ` راحة ${index + 1}`}
                          </h4>

                          <p value={alertValue} className="w-100 p-3  ">
                            {/* {alert.alertValue} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`w-100  p-2 d-flex  ${styles.footer}`}>
                      <div className="d-flex flex-column">
                        {/* <span>Branche:{userinfo.branches}</span>
                      <span>Employees:{userinfo.employees}</span>

                      <span>Department:{userinfo.departments}</span> */}
                      </div>
                      <button
                        className={`py-1 px-2 fw-bold h-25 rounded ${
                          lang === "en" ? "ms-auto" : "me-auto"
                        }`}
                        style={{
                          color: mode === "dark" ? "#fff" : undefined,
                          borderColor: mode === "dark" ? "#fff" : undefined,
                        }}
                        // onClick={() => alertDetail(index)}
                        onClick={() => {
                          // handleGetShifts(index);
                          // navigate(`/breaks?branch=${index}`);
                          toast.success("zaz");
                        }}
                      >
                        {lang === "en" ? "Open" : "فتح"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              {/* Shifts */}
              <h1
                className={`${
                  mode === "light" ? "text-dark" : "text-white"
                }  my-5`}
              >
                {lang === "en" ? "Shifts" : "الدوام"}
              </h1>
              {[...Array(3)].map((_, index) => (
                <div key={index} className={` col-md-6 col-lg-4 col-xxl-3  `}>
                  <div
                    className={`${styles.columContent} p-3 rounded`}
                    style={{
                      backgroundColor: mode === "light" ? "#E4E4E4" : "#333",
                    }}
                  >
                    <div className="p-2 w-75 ">
                      <div className="d-flex justify-content-between align-items-center ">
                        <div className="d-flex flex-column mx-3  overflow-hidden">
                          <h4
                            style={{
                              color: mode === "light" ? "#000" : "#fff",
                            }}
                          >
                            {lang === "en"
                              ? `Shift ${index + 1}`
                              : ` الدوام ${index + 1}`}
                          </h4>

                          <p value={alertValue} className="w-100 p-3  ">
                            {/* {alert.alertValue} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`w-100  p-2 d-flex  ${styles.footer}`}>
                      <div className="d-flex flex-column">
                        {/* <span>Branche:{userinfo.branches}</span>
                      <span>Employees:{userinfo.employees}</span>

                      <span>Department:{userinfo.departments}</span> */}
                      </div>
                      <button
                        className={`py-1 px-2  fw-bold h-25 rounded 
                        ${lang === "en" ? "ms-auto" : "me-auto"}
                      `}
                        style={{
                          color: mode === "dark" ? "#fff" : undefined,
                          borderColor: mode === "dark" ? "#fff" : undefined,
                        }}
                        // onClick={() => alertDetail(index)}
                        // onClick={() => {
                        //   navigate({
                        //     pathname: "/breaks",
                        //     search: `?${createSearchParams({
                        //       branch: search.get("branch"),
                        //       shift: ,
                        //     })}`,
                        //   });
                        //   // setSearchParams({...search,'shift': _id})
                        // }}
                      >
                        {lang === "en" ? "Open" : "فتح"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              {search.get("branch") &&
                search.get("shift") &&
                breaksList?.map((alert, index) => (
                  <div key={index} className={` col-md-6 col-lg-4 col-xxl-3 `}>
                    <div
                      className={`  ${styles.columContent} p-3`}
                      style={{
                        backgroundColor: "var(--blue-background)",
                        borderRadius: "25px",
                      }}
                    >
                      <div className="p-2 w-75 ">
                        <div className="d-flex  justify-content-between align-items-center ">
                          <div className="d-flex flex-column mx-3  overflow-hidden">
                            <h4 style={{ color: "var( --text-color)" }}>
                              {alert.alertTitle}
                            </h4>

                            <p value={alertValue} className="w-100 p-3  ">
                              {alert.alertValue}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={`w-100  p-2 d-flex  ${styles.footer}`}>
                        <div className="d-flex flex-column">
                          <span>Branche:{userinfo.branches}</span>
                          <span>Employees:{userinfo.employees}</span>

                          <span>Department:{userinfo.departments}</span>
                        </div>
                        <button
                          className="n py-0 ms-auto h-25 rounded-pill  "
                          onClick={() => alertDetail(index)}
                        >
                          open
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* ===================================================================================================================================================================== */}

            {/*/---------------------------------------------- Alert modal-------------------------------------------*/}

            <div className={styles.iconContainer}>
              <button className="rounded p-2 fs-6 " onClick={showModalState}>
                {lang === "en" ? "Add Break" : "إضافة فترة راحة"}
                <i className="fa-solid fa-plus mx-2"></i>
              </button>
            </div>
            <Modal
              show={modalState}
              onHide={handleClose}
              size="lg"
              className={`p-4 ${styles.modal} `}
              style={{ overflow: "hidden", height: "fit-content" }}
            >
              <Modal.Header>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  className=" w-100 border-none"
                  style={{
                    direction: lang === "en" ? "ltr" : "rtl",
                  }}
                >
                  <h3
                    className="h1 text-center "
                    style={{ color: "var(--text-color)" }}
                  >
                    {lang === "en" ? "Breaks" : "فترات الراحة"}
                  </h3>
                </Modal.Title>
              </Modal.Header>

              {/*/---------------------------------------modal body-------------------------------------------------------------------*/}
              <Modal.Body
                style={{
                  color: "var(--text-color)",
                  overflow: "auto",
                  maxHeight: "50%",
                  direction: lang === "en" ? "ltr" : "rtl",
                }}
                className={styles.modalContent}
              >
                <div className="container">
                  <h3>{lang === "en" ? "New Break" : "فترة راحة جديدة"}</h3>

                  <div className=" col-md-6 mb-3">
                    <label
                      htmlFor="employeeAddress"
                      className="form-label text-dark fw-bold"
                    >
                      {lang === "en" ? "isOpen" : "تم الفتح"}
                    </label>
                    <div className="form-control d-flex justify-content-between align-items-center">
                      <select
                        className="border-0 outline-0 w-100"
                        value={isOpen}
                        onChange={(e) => setIsOpen(e.target.value)}
                      >
                        <option value={"yes"}>
                          {lang === "en" ? "true" : "صحيح"}
                        </option>
                        <option value={"no"}>
                          {lang === "en" ? "false" : "خاطاً"}
                        </option>
                      </select>
                    </div>
                  </div>

                  <ReactSelect
                    options={branchList.map((branch) => ({
                      label: branch.name,
                      value: branch._id,
                    }))}
                    // getValue={branchList.map(branch=>branch.name)}
                    // isMulti
                    isSearchable={true}
                    // isClearable={true}
                    closeMenuOnSelect={true}
                    // hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    //   MultiValue,
                    //   animatedComponents,
                    // }}
                    onChange={(selected) => {
                      setSelectedBranch(selected);
                    }}
                    allowSelectAll={true}
                    value={selectedBranch?.name}
                    menuShouldScrollIntoView={false}
                    placeholder={lang === "en" ? "Branch" : "فرع"}
                    // lassName="mx-2 "
                  />
                  {/* ------------------------------------department----------------------------------------- ===============================*/}
                  <ReactSelect
                    options={departmentList.map((department) => ({
                      label: department.name,
                      value: department._id,
                    }))}
                    // getValue={branchList.map(branch=>branch.name)}
                    // isMulti
                    isSearchable={true}
                    // isClearable={true}
                    closeMenuOnSelect={true}
                    // hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    //   MultiValue,
                    //   animatedComponents,
                    // }}
                    onChange={(selected) => {
                      setSelectedDepartment(selected);
                    }}
                    allowSelectAll={true}
                    value={selectedDepartment?.name}
                    menuShouldScrollIntoView={false}
                    placeholder={lang === "en" ? "Department" : "قسم"}
                    // lassName="mx-2 "
                  />

                  <div className=" col-md-6 mb-3">
                    <label
                      htmlFor="employeeAddress"
                      className="form-label text-dark fw-bold"
                    >
                      {lang === "en" ? "Department" : "قسم"}
                    </label>
                    <div className="form-control d-flex justify-content-between align-items-center">
                      <select
                        disabled={selectedBranch === ""}
                        className="border-0 outline-0 w-100"
                        value={selectedDepartment}
                        onChange={(e) => setSelectedDepartment(e.target.value)}
                      >
                        <option value="" selected>
                          {lang === "en" ? "Select a department" : "اختار قسم"}
                        </option>
                        {departmentList?.map((depart, index) => (
                          <option key={index} value={depart._id}>
                            {depart.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* ============================================= Employees ========================================================== */}
                  <ReactSelect
                    options={employeesList.map((employee) => ({
                      label: employee.fullName_en,
                      value: employee._id,
                    }))}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                      MultiValue,
                      animatedComponents,
                    }}
                    onChange={(selected) => {
                      setSelectedEmployees(selected);
                      const filteredIds = selected.map((employee) => {
                        return employee.value;
                      });

                      setAssignedEmployees(filteredIds);
                    }}
                    allowSelectAll={true}
                    value={selectedEmployees}
                    menuShouldScrollIntoView={false}
                    placeholder={lang === "en" ? "Employee" : "الموظف"}
                    lassName="mx-2 "
                  />

                  {/* ------------------------------------shift----------------------------------------- ===============================*/}
                  <div className=" col-md-6 mb-3">
                    <label
                      htmlFor="employeeAddress"
                      className="form-label text-dark fw-bold"
                    >
                      {lang === "en" ? "Shift" : "الدوام"}
                    </label>
                    <div className="form-control d-flex justify-content-between align-items-center">
                      <select
                        className="border-0 outline-0 w-100"
                        value={shiftId}
                        onChange={(e) => {
                          setShiftId(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          {lang === "en" ? "Select a Shift" : "اختر الدوام"}
                        </option>
                        {shiftsList?.map((shift, index) => (
                          <option key={index} value={shift._id}>
                            {/* days: from {shift.start_day} to {shift.end_day} */}
                            {shift.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* ======================================== Start ============================================= */}
                  {isOpen === "no" && (
                    <div className="  my-4">
                      <label className="form-label text-dark fw-bold">
                        {lang === "en" ? "Start" : "بدأ"}
                      </label>

                      <div className="d-flex">
                        <div className={`d-flex ${styles.spanContainer}`}>
                          <span>{lang === "en" ? "Hours" : "ساعات"}</span>
                          <select
                            size="1"
                            style={{ color: "gray" }}
                            value={startHours}
                            onChange={(e) => setStartHours(e.target.value)}
                          >
                            <option value={9}>
                              {lang === "en" ? "9 am" : "9 ص"}
                            </option>
                            <option value={10}>
                              {lang === "en" ? "10 am" : "10 ص"}
                            </option>
                            <option value={11}>
                              {lang === "en" ? "11 am" : "11 ص"}
                            </option>
                            <option value={12}>
                              {lang === "en" ? "12 am" : "12 ص"}
                            </option>
                            <option value={13}>
                              {lang === "en" ? "1 am" : "1 ص"}
                            </option>
                            <option value={14}>
                              {lang === "en" ? "2 am" : "2 ص"}
                            </option>
                            <option value={15}>
                              {lang === "en" ? "3 am" : "3 ص"}
                            </option>
                            <option value={16}>
                              {lang === "en" ? "4 am" : "4 ص"}
                            </option>
                            <option value={17}>
                              {lang === "en" ? "5 am" : "5 ص"}
                            </option>
                          </select>
                        </div>
                        <div className={`d-flex ${styles.spanContainer}`}>
                          <span>{lang === "en" ? "Mins" : "دقائق"}</span>
                          <select
                            size="1"
                            style={{ color: "gray" }}
                            value={startMins}
                            onChange={(e) => setStartMins(e.target.value)}
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={25}>25</option>
                            <option value={30}>30</option>
                            <option value={35}>35</option>
                            <option value={40}>40</option>
                            <option value={45}>45</option>
                            <option value={50}>50</option>
                            <option value={55}>55</option>
                            <option value={60}>60</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* ====================================== End ============================================== */}
                  {isOpen === "no" && (
                    <div className="  ">
                      <label className="form-label text-dark fw-bold">
                        {lang === "en" ? "End" : "انتهاء"}
                      </label>

                      <div className="d-flex">
                        <div className={`d-flex ${styles.spanContainer}`}>
                          <span>{lang === "en" ? "Hours" : "الساعات"}</span>
                          <select
                            size="1"
                            style={{ color: "gray" }}
                            value={endHours}
                            onChange={(e) => setEndHours(e.target.value)}
                          >
                            <option value={9}>
                              {lang === "en" ? "9 am" : "9 ص"}
                            </option>
                            <option value={10}>
                              {lang === "en" ? "10 am" : "10 ص"}
                            </option>
                            <option value={11}>
                              {lang === "en" ? "11 am" : "11 ص"}
                            </option>
                            <option value={12}>
                              {lang === "en" ? "12 am" : "12 ص"}
                            </option>
                            <option value={13}>
                              {lang === "en" ? "1 am" : "1 ص"}
                            </option>
                            <option value={14}>
                              {lang === "en" ? "2 am" : "2 ص"}
                            </option>
                            <option value={15}>
                              {lang === "en" ? "3 am" : "3 ص"}
                            </option>
                            <option value={16}>
                              {lang === "en" ? "4 am" : "4 ص"}
                            </option>
                            <option value={17}>
                              {lang === "en" ? "5 am" : "5 ص"}
                            </option>
                          </select>
                        </div>
                        <div
                          className={`d-flex ${styles.spanContainer}`}
                          value={endMins}
                          onChange={(e) => setEndMins(e.target.value)}
                        >
                          <span>{lang === "en" ? "Mins" : "دقائق"}</span>
                          <select size="1" style={{ color: "gray" }}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={25}>25</option>
                            <option value={30}>30</option>
                            <option value={35}>35</option>
                            <option value={40}>40</option>
                            <option value={45}>45</option>
                            <option value={50}>50</option>
                            <option value={55}>55</option>
                            <option value={60}>60</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* ========================================= duration ==================================== */}
                  {isOpen === "yes" && (
                    <div className="  ">
                      <label className="form-label text-dark fw-bold">
                        {lang === "en" ? "Hours" : "الساعات"}
                      </label>

                      <div className="d-flex">
                        <div className={`d-flex ${styles.spanContainer}`}>
                          <span>{lang === "en" ? "Hours" : "الساعات"}</span>
                          <select
                            size="1"
                            style={{ color: "gray" }}
                            value={durationHours}
                            onChange={(e) => setDurationHours(e.target.value)}
                          >
                            <option value={9}>
                              {lang === "en" ? "9 am" : "9 ص"}
                            </option>
                            <option value={10}>
                              {lang === "en" ? "10 am" : "10 ص"}
                            </option>
                            <option value={11}>
                              {lang === "en" ? "11 am" : "11 ص"}
                            </option>
                            <option value={12}>
                              {lang === "en" ? "12 am" : "12 ص"}
                            </option>
                            <option value={13}>
                              {lang === "en" ? "1 am" : "1 ص"}
                            </option>
                            <option value={14}>
                              {lang === "en" ? "2 am" : "2 ص"}
                            </option>
                            <option value={15}>
                              {lang === "en" ? "3 am" : "3 ص"}
                            </option>
                            <option value={16}>
                              {lang === "en" ? "4 am" : "4 ص"}
                            </option>
                            <option value={17}>
                              {lang === "en" ? "5 am" : "5 ص"}
                            </option>
                          </select>
                        </div>
                        <div className={`d-flex ${styles.spanContainer}`}>
                          <span>{lang === "en" ? "Mins" : "دقائق"}</span>
                          <select
                            size="1"
                            style={{ color: "gray" }}
                            value={durationMins}
                            onChange={(e) => setDurationMins(e.target.value)}
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={25}>25</option>
                            <option value={30}>30</option>
                            <option value={35}>35</option>
                            <option value={40}>40</option>
                            <option value={45}>45</option>
                            <option value={50}>50</option>
                            <option value={55}>55</option>
                            <option value={60}>60</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* ========================================= end of duration ==================================== */}
                </div>
              </Modal.Body>

              {/* <!-- //-----------------------------------modal footer------------------------------------------//  --> */}

              <Modal.Footer
                className="d-flex justify-content-between p-3"
                style={{
                  direction: lang === "en" ? "ltr" : "rtl",
                }}
              >
                <div className={styles.closeIcon}>
                  <button
                    className=" rounded-pill px-3 py-1"
                    onClick={handleSubmit}
                  >
                    {lang === "en" ? "Send" : "إرسال"}
                  </button>
                </div>
                <div className={styles.closeIcon}>
                  <button
                    className=" rounded-pill px-3 py-1"
                    onClick={handleClose}
                  >
                    {lang === "en" ? "Close" : "إلغاء"}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        {/* ---------------------------------------------------------------------------------------------------------------------------------------- */}

        {/* -----------------------------------------------------Alert Details modal------------------------------------------------------- */}
      </div>
    </HOC>
  );
}
export default BreaksPage;
