import HOC from "../../../components/HOC/HOC";
import styles from "./Tasks.module.css";

import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetMeQuery } from "../../../redux/features/userApi";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  useAddTaskMutation,
  useDeleteTaskMutation,
  useGetTasksQuery,
  useUpdateTaskMutation,
} from "../../../redux/features/tasksApi";
import Spinner from "../../spinner/Spinner";

import moment from "moment";
import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import {
  useGetEmployeesByBranchQuery,
  useGetEmployeesByCompanyQuery,
} from "../../../redux/features/employeeApi";
import { AiFillDelete, AiOutlineClose } from "react-icons/ai";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import AddTaskModal from "./AddTaskModal";
import { imageUrl } from "../../../App";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import ErrorScreen from "../../errorScreen/ErrorScreen";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const TasksPage = () => {
  const [modalShow, setModalShow] = useState();
  const [userValue, setUserValue] = useState([]);
  const [inputChange, setInputChange] = useState({});
  const [disableAssigning, setDisableAssigning] = useState(true);
  const [branch, setBranch] = useState(null);
  const [skipEmpByCompanyToken, setSkipEmpByCompanyToken] = useState(skipToken);
  const [skipEmpbyBranchToken, setSkipEmpbyBranchToken] = useState(skipToken);
  const [skipBranchesToken, setSkipBranchesToken] = useState(skipToken);

  let { data: branches, isSuccess: branchesSuccess } =
    useGetBranchesQuery(skipBranchesToken);
  let [updateTask] = useUpdateTaskMutation();
  let [deleteTask] = useDeleteTaskMutation();
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();
  let { data: empsByBranch, isSuccess: EmpsBranchSuccess } =
    useGetEmployeesByBranchQuery(skipEmpbyBranchToken);
  let {
    data: dataTasks,
    isSuccess: tasksSuccess,
    isLoading: tasksLoading,
    isError: tasksError,
  } = useGetTasksQuery();

  let { data: empsByCompany, isSuccess: empsCompnaySuccess } =
    useGetEmployeesByCompanyQuery(skipEmpByCompanyToken);

  let [addTask] = useAddTaskMutation();

  const handleInputChange = (e) => {
    let copyObj = { ...inputChange };
    copyObj[e.target.name] = e.target.value;
    setInputChange((_) => copyObj);
  };
  // assigning to
  const handleSelectChange = (e) => {
    // check if the array dont have that user add it if have it delete it
    // the users should be string and separating user by ,
    let copyArr = [...userValue];
    if (!copyArr.includes(e.target.value.trim())) {
      copyArr.push(e.target.value.trim());
    } else {
      copyArr = copyArr.filter((ele) => {
        return ele == e.target.value;
      });
    }
    setUserValue([...copyArr]);
    setInputChange({ ...inputChange, to: [...copyArr] });
  };
  useEffect(() => {
    if (userSuccess && !tasksError) {
      if (userData?.data?.role == "employee") {
        setSkipEmpbyBranchToken(userData?.data?.branch);
      }
      setInputChange({ ...inputChange, branch: userData?.data?.branch });
      setDisableAssigning(false);
      setSkipBranchesToken(true);
    }
    if (tasksError) {
    }
  }, [
    userSuccess,
    tasksSuccess,
    branchesSuccess,
    userData?.data?.role,
    tasksError,
  ]);

  const handleBranch = (e) => {
    setBranch((_) => e.target.value);
    // this should determine which data to use
    // get all The Emps ByBranch
    setInputChange({ ...inputChange, branch: e.target.value });
    setDisableAssigning(false);
    setSkipEmpbyBranchToken(e.target.value);
  };
  const showModalShow = () => {
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };
  const chooseWhenToShow = (taskFrom) => {
    // based on the role if the role was admin or root return true
    // and if the role was employee only return true if the task.from == userData?.data._id
    if (userData?.data?.role == "admin" || userData?.data?.role == "root") {
      return true;
    } else if (
      userData?.data?.role == "employee" &&
      taskFrom._id == userData?.data?._id
    ) {
      return true;
    }
    return false;
  };
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const handelUpdateTask = (task) => {
    updateTask({
      _id: task?._id,
      status: task?.status,
    }).then(() => {
      toast.success(
        lang == "en" ? "Task Updated Successfully" : "تم تحديث المهمة بنجاح"
      );
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    addTask({
      ...inputChange,
      company: localStorage.getItem("companyId"),
    }).then(() => {
      toast.success(
        lang == "en" ? "Task Created Successfully " : "تم إنشاء المهمة بنجاح"
      );
      handleClose();
    });
  };

  const handleDeleteTask = (taskId) => {
    deleteTask(taskId).then(() => {
      toast.success(
        lang == "en" ? "Task Deleted Successfully" : "تم حذف المهمة بنجاح"
      );
    });
  };
  const { cardTheme } = useTheme();
  return (
    <HOC>
      <div className="p-3">
        <div
          style={{
            backgroundColor: chartBGColor,
            borderRadius: "25px",
          }}
        >
          <h2
            className={`text-center py-5 ${
              mode === "light" ? "text-dark" : "text-white"
            }`}
          >
            {/* {t("All Tasks")} */}

            {dataTasks?.tasks.length === 0
              ? lang === "en"
                ? "No tasks yet"
                : "لا مهمات حتي الان"
              : lang === "en"
              ? "All Tasks"
              : "جميع المهمات"}
          </h2>

          {tasksError ? (
            <ErrorScreen
              data={lang == "en" ? "Tasks Not Found" : "لم يتم العثور على مهام"}
            />
          ) : tasksLoading ? (
            <Spinner />
          ) : (
            <div className={`overflow-hidden p-3`}>
              <div>
                {/* ============================================ tasks ==================================================================== */}
                <Grid
                  container
                  sx={{
                    mt: 1,
                  }}
                >
                  {/* TaskList */}

                  {tasksSuccess && !tasksError
                    ? [...dataTasks?.tasks].map((task, index) => (
                        <Grid
                          item
                          xl={3}
                          md={6}
                          xs={12}
                          sx={{
                            py: {
                              xl: "20px",
                              xs: "10px",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: {
                                xl: "90%",
                                md: "90%",
                                xs: "100%",
                              },
                              marginX: "auto",
                              backgroundColor: cardTheme[`bg_${mode}`],
                              borderRadius: 5,
                              p: "15px",

                              height: "380px",
                              position: "relative",
                            }}
                          >
                            {chooseWhenToShow(task?.from) ? (
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                sx={{
                                  position: "absolute",
                                  top: "10px",
                                  width: 1,
                                  left: 0,
                                  px: 4,
                                }}
                              >
                                <HighlightOffIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "#E04449",
                                    fontSize: "24px",
                                  }}
                                  onClick={() => handleDeleteTask(task?._id)}
                                />
                              </Stack>
                            ) : null}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: "10px",

                                // height: "120px",
                              }}
                            >
                              {task?.status === "completed" ? (
                                <Avatar
                                  alt="Remy Sharp"
                                  sx={{
                                    height: "75px",
                                    width: "75px",
                                    borderRadius: "8px",
                                  }}
                                  src="https://apps.odoo.com/web/image/loempia.module/131513/icon_image?unique=c0d17d9"
                                />
                              ) : (
                                <SyncProblemIcon
                                  sx={{
                                    color: "#E17203",
                                    fontSize: "70px",
                                  }}
                                />
                              )}
                              <Box
                                sx={{
                                  textAlign: "center",

                                  width: "70%",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  title={
                                    task?.title.length > 10
                                      ? task?.title
                                      : undefined
                                  }
                                  sx={{
                                    fontSize: "20px",
                                    color: mode === "light" ? "#000" : "#fff",
                                    textAlign: "center",
                                  }}
                                >
                                  {task?.title.length > 10
                                    ? task?.title.slice(0, 10) + "..."
                                    : task?.title}
                                </Typography>
                                <Typography
                                  align="center"
                                  component={`paragraph`}
                                  title={
                                    task?.description.length > 25
                                      ? task?.description
                                      : undefined
                                  }
                                  sx={{
                                    mt: "0px",
                                    color:
                                      mode === "light" ? "#8B8C89" : "#ccc",
                                    fontSize: "16px",

                                    overflow: "hidden",
                                  }}
                                >
                                  {task?.description.length > 25
                                    ? task?.description.slice(0, 25) + "..."
                                    : task?.description}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              variant="h6"
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                                mt: "20px",
                              }}
                            >
                              {lang === "en" ? "Assign to" : "تعيين الي"}
                            </Typography>
                            <AvatarGroup
                              max={4}
                              sx={{ justifyContent: "flex-end" }}
                            >
                              {task?.to?.map((_, index) => (
                                <Avatar
                                  key={index}
                                  alt="Remy Sharp"
                                  src={imageUrl + _.image}
                                  sx={{
                                    height: "35px",
                                    width: "35px",
                                  }}
                                />
                              ))}
                            </AvatarGroup>
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="10px"
                              marginTop="25px"
                            >
                              <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                              <Typography
                                sx={{
                                  color: mode === "light" ? "#000" : "#fff",
                                }}
                              >
                                {moment(task.start).format("YYYY/MM/DD:H:m")} -{" "}
                                {moment(task.end).format("YYYY/MM/DD:H:m")}
                              </Typography>
                            </Stack>
                            {chooseWhenToShow(task?.from) ? (
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: "20px",
                                }}
                              >
                                <button
                                  onClick={() => handelUpdateTask(task)}
                                  className="btn btn-sm"
                                  style={{
                                    background:
                                      task?.status === "completed"
                                        ? "#0FC900"
                                        : "#E17203",
                                    color: "white",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {task?.status === "completed"
                                    ? lang === "en"
                                      ? "Completed"
                                      : "مكتمل"
                                    : lang === "en"
                                    ? "In progress"
                                    : "في تقدم"}
                                </button>
                              </Box>
                            ) : null}
                          </Box>
                        </Grid>
                      ))
                    : null}
                </Grid>

                <div
                  className={styles.iconContainer}
                  onClick={showModalShow}
                ></div>
              </div>

              {/* =======================================================modal================================================ */}

              <Modal
                show={modalShow}
                onHide={handleClose}
                size="lg"
                className={`p-4 ${styles.modal} `}
                style={{ overflow: "hidden", maxHeight: "fit-content" }}
              >
                <form action="" className="submit" onSubmit={handleSubmit}>
                  <Modal.Header>
                    <Modal.Title
                      id="contained-modal-title-vcenter"
                      className=" w-100 border-none"
                    >
                      <h3
                        className=" text-center "
                        style={{ color: "var(--text-color)" }}
                      >
                        {lang === "en" ? "Create anew task" : "عمل مهمة جديدة"}:
                      </h3>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ color: "var(--text-color)" }}>
                    <div className="container  m-auto">
                      <div className="row">
                        {/* =================================================title input============================ */}
                        <div className=" col-lg-5 mb-3 ">
                          <label
                            htmlFor="taskTitle"
                            className="form-label text-dark fw-bold"
                          >
                            {lang === "en" ? "Title" : "العنوان"}:
                          </label>
                          <div className="form-control d-flex justify-content-between align-items-center">
                            <input
                              type="text"
                              placeholder="Write here...  "
                              className="border-0 outline-0"
                              id="taskTitle"
                              onChange={handleInputChange}
                              name="title"
                              style={{
                                backgroundColor: "#ddd",
                              }}
                            />
                            <CloseIcon style={{ cursor: "pointer" }} />
                          </div>
                        </div>

                        {/* ==========================================================Branch=================================================== */}
                        <div className="branch col-lg-5 mb-3 offset-lg-1">
                          <label
                            htmlFor="branches"
                            className="form-label text-dark fw-bold"
                          >
                            {lang === "en" ? "Branches" : "الفروع"}:
                          </label>
                          <select
                            disabled={userData?.data?.role == "employee"}
                            className="form-select "
                            onChange={handleBranch}
                            value={branch}
                          >
                            <>
                              <option hidden defaultValue="Default Branches">
                                {userData?.data?.role !== "employee"
                                  ? "Choose Branch"
                                  : "Default employee Branch"}
                              </option>
                              {branchesSuccess
                                ? [...branches?.data].map((branch, index) => {
                                    return (
                                      <option value={branch._id} key={index}>
                                        {branch?.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </>
                          </select>
                        </div>

                        <div className=" col-lg-5 mb-3 ">
                          <div className="d-flex justify-content-between">
                            <label
                              htmlFor="assignTo"
                              className="form-label text-dark fw-bold "
                            >
                              {/* {t("Assign to")} */}
                              {lang === "en" ? "Assign to" : "تعيين إلي"}:
                            </label>
                            <AiOutlineClose
                              size={24}
                              color={"black"}
                              style={{ cursor: "pointer" }}
                              onClick={() => setUserValue((_) => [])}
                            />
                          </div>
                          <select
                            disabled={!empsByBranch?.data.length}
                            style={{ maxHeight: "8vh" }}
                            name="to"
                            id=""
                            className="form-select"
                            multiple
                            value={userValue}
                            onChange={handleSelectChange}
                          >
                            {!disableAssigning &&
                              EmpsBranchSuccess &&
                              [...empsByBranch?.data].map((user, index) => {
                                return (
                                  <option key={index} value={user?._id}>
                                    {user[`userName_${lang}`]}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        {/* =================================================date input============================ */}

                        <div className=" col-lg-5 mb-3 offset-lg-1">
                          <label
                            htmlFor="taskTitle"
                            className="form-label text-dark fw-bold"
                          >
                            {/* {t("Start Date")} */}
                            {lang === "en" ? "Start Date" : "تاريخ البدأ"}:
                          </label>
                          <div className="form-control d-flex justify-content-between align-items-center">
                            <input
                              type="date"
                              name="start"
                              placeholder="choose Date...  "
                              onChange={handleInputChange}
                              className="w-100 border-0 outline-0"
                              id="taskTitle"
                              // onClick={ToggleClass}
                            />
                          </div>

                          {/* ============================================EndDate============================================== */}
                        </div>
                        <div className=" col-lg-5 mb-3  ">
                          <label
                            htmlFor="taskTitle"
                            className="form-label text-dark fw-bold"
                          >
                            {/* {t("Start Date")} */}
                            {lang === "en" ? "End Date" : "تاريخ الانتهاء"}:
                          </label>
                          <div className="form-control d-flex justify-content-between align-items-center">
                            <input
                              type="date"
                              name="end"
                              placeholder="choose Date...  "
                              onChange={handleInputChange}
                              className="w-100 border-0 outline-0"
                              id="taskTitle"
                              // onClick={ToggleClass}
                            />
                          </div>

                          {/* ============================================calender============================================== */}
                        </div>

                        <div className="col-lg-5 mb-3 offset-lg-1">
                          <label
                            htmlFor="taskSubject"
                            className="form-label text-dark fw-bold"
                          >
                            {/* {t("Subject")} */}
                            {lang === "en" ? "Subject" : "الموضوعات"}:
                          </label>
                          <div className="form-control d-flex justify-content-between align-items-center">
                            <input
                              type="text"
                              name="description"
                              onChange={handleInputChange}
                              placeholder="Write here..."
                              className="border-0 outline-0"
                              id="taskSubject"
                              // onChange={handleSubject}
                            />
                            <CloseIcon style={{ cursor: "pointer" }} />
                          </div>
                        </div>

                        {/* =================================================sub Task======================================================== */}
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="w-100">
                    <button
                      type="submit"
                      className="n py-1 ms-auto h-25 rounded-pill px-3 "
                      // onClick={taskSubmit}
                    >
                      {lang === "en" ? "Create" : "عمل"}:
                    </button>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>
          )}
          <div className="pb-4">
            <AddTaskModal lang={lang} mode={mode} />
          </div>
        </div>
      </div>
    </HOC>
  );
};
export default TasksPage;
