import TabPanel from "@mui/lab/TabPanel";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import InputTextProfile from "../InputTextProfile";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  useGetMeQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from "../../../../redux/features/userApi";
import moment from "moment";
import { imageUrl } from "../../../../App";
import { useParams } from "react-router";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Spinner } from "react-bootstrap";
import { useUploadImageMutation } from "../../../../redux/features/uploadsApi";
import { AiOutlinePlus } from "react-icons/ai";
import { useGetBranchesWitQuery } from "../../../../redux/features/branchesApi";
import { useGetDepartmentsByBranchQuery, useLazyGetDepartmentsByBranchQuery } from "../../../../redux/features/departmentsApis";
import { useGetShiftsByBranchQuery, useLazyGetShiftsByBranchQuery } from "../../../../redux/features/shiftsApi";
const ProfileEmp = ({ value }) => {
  const { value: lang } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;

  const [skipeId, setSkipeId] = useState(skipToken);
  const { id } = useParams();
  let { data: branches, isSuccess: branchesSuccess } = useGetBranchesWitQuery();





  const nationalities = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian/Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ];
  let {
    data: userData,
    isSuccess: userSuccess,
    isLoading,
  } = useGetUserByIdQuery(skipeId);
  let [updateUser] = useUpdateUserMutation();
  const formik = useFormik({
    initialValues: {
      _id: "",
      name: "",
      email: "",
      password: "",
      position: "",
      username: "",
      phone: "",
      role: "",
      birthDate: "",
      nationality: "",
      address: "",
      branch: "",
      department: "",
      shift: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      position: Yup.string().required(),
      username: Yup.string().required(),
      phone: Yup.string().required(),
      birthDate: Yup.date(),
      nationality: Yup.string().required(),
      address: Yup.string(),
    }),
    onSubmit: () => {
      let obj = { ...values };
      obj[lang == "en" ? "fullName_en" : "fullName_ar"] = values.name;
      delete obj["name"];

      toast.success("Submitted Successfully");
      updateUser(obj);
      // formik.resetForm();
    },
  });
  const onFileClicked = () => {
    imageRef.current.click();
  };
  let [uploadImage] = useUploadImageMutation();
  const [file, setFile] = useState(null);
  const imageRef = useRef(null);

  const handleUploadFile = (e) => {
    // call the upload method for uploading the image
    if (e.target.files[0]) {
      setFile((_) => e.target.files[0]);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      uploadImage(formData).then(({ data }) => {
        formik.setFieldValue("image", data.filename);
      });
    }
  };

  const renderFileLogic = () => {
    return (
      <Box
        sx={{
          height: "15em",
          background: "#E0F2FF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0.5em",
        }}
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="upload-img"
          onChange={handleUploadFile}
          ref={imageRef}
        />

        {file ? (
          <img
            src={URL.createObjectURL(file)}
            alt="personal Profile"
            style={{
              width: "90%",
              height: "90%",
              objectFit: "cover",
              cursor: "pointer",
              borderRadius: "0.5em",
            }}
            onClick={onFileClicked}
          />
        ) : userSuccess ? (
          <Box
            sx={{
              background: "#E0F2FF",
              height: "15em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0.5em",
            }}
          >
            <img
              src={imageUrl + userData?.data?.image}
              alt="personal Profile"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "0.5em",
              }}
              onClick={onFileClicked}
            />
          </Box>
        ) : (
          <Box
            sx={{
              background: "#E0F2FF",
              height: "15em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiOutlinePlus
              style={{ cursor: "pointer" }}
              onClick={onFileClicked}
              size={50}
              color={mode == "dark" ? "white" : "black"}
            />
          </Box>
        )}
      </Box>
    );
  };
  // mutate the data of the formik object on the run time


  useEffect(() => {
    if (id) {
      setSkipeId(id);
    }
    if (userData) {

      formik.setValues({
        _id: userData?.data?._id,
        name: userData?.data[`fullName_${lang}`],
        email: userData?.data?.email,
        position:
          userData?.data?.position ||
          (userData?.data?.role == "employee"
            ? "employee"
            : userData?.data?.role == "admin"
              ? "admin"
              : "Owner"),
        username: userData?.data[`userName_${lang}`],
        phone: userData?.data?.phone,

        birthDate: moment(userData?.data?.birthDate || new Date()).format(
          "YYYY-MM-DD"
        ),
        role: userData?.data?.role,
        nationality: userData?.data?.nationality,
        branch: userData?.data?.branch,
        department: userData?.data?.department,
        shift: userData?.data?.shift,
        address: userData?.data?.address,
      });

    }
  }, [userData]);

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    formik;

  let [getDepartmentByBranch, { data: depsData, isSuccess: depsSuccess }] = useLazyGetDepartmentsByBranchQuery()
  let [getShiftsByBranch, { isSuccess: shiftsSuccess, data: shiftsData }] = useLazyGetShiftsByBranchQuery()
  const getDepByBranch = (branchId) => {
    getDepartmentByBranch(branchId, false).then(({ data, error }) => {
      if (data) {
      }
      else if (error) {
      }
    })
  }
  const getBranchShifts = (branchId) => {
    getShiftsByBranch(branchId, false).then(({ data, error }) => {
      if (data) {

      }
      else if (error) {
      }
    })
  }
  useEffect(() => {
    if (values.branch) {
      getDepByBranch(values.branch)
      getShiftsByBranch(values.branch)
    }
  }, [userSuccess, values.branch])
  return isLoading ? (
    <Spinner />
  ) : userSuccess ? (
    <TabPanel value={value}>
      {/* Box Grid For test */}
      <Box
        sx={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Grid container>
          <Grid item xl={2} lg={2} md={4} sm={12}>
            {renderFileLogic()}
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          margin: "70px auto 0",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "name" : "الاسم"}
                name="name"
                value={values.name}
                error={errors.name}
                touched={touched.name}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "email" : "البريد الالكتروني"}
                name="email"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "password" : "كلمة المرور"}
                name="password"
                value={values.password}
                error={errors.password}
                touched={touched.password}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "position" : "المنصب"}
                name="position"
                value={values.position}
                error={errors.position}
                touched={touched.position}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "username" : "الاسم المستخدم"}
                name="username"
                value={values.username}
                error={errors.username}
                touched={touched.username}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "phone number" : "رقم الهاتف"}
                name="phone"
                value={values.phone}
                error={errors.phone}
                touched={touched.phone}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "role" : "الدور"}
                name="role"
                value={values.role}
                error={errors.role}
                touched={touched.role}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                type="date"
                label={lang === "en" ? "Birth Date" : "تاريخ الميلاد"}
                name="birthDate"
                value={values.birthDate}
                error={errors.birthDate}
                touched={touched.birthDate}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <Box
                with="100%"
                sx={{
                  paddingBottom: "20px",
                  position: "relative",
                }}
              >
                <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
                  {lang === "en" ? "nationality" : "الجنسية"}
                </Typography>
                <Box
                  sx={{
                    width: {
                      lg: "92%",
                      md: "95%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <select
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "8px",
                      borderRadius: "2",
                      outline: 0,
                      border: `1px solid ${errors.nationality && touched.nationality
                        ? "red"
                        : "transparent"
                        }  `,
                      backgroundColor:
                        errors.nationality && touched.nationality
                          ? undefined
                          : mode === "dark"
                            ? "#252525"
                            : "#E0F2FF",
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                    name="nationality"
                    value={values.nationality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option hidden select>
                      {lang === "en" ? "Select Nationality" : "اختار جنسية"}
                    </option>
                    {nationalities.map((nationality) => (
                      <option key={nationality} value={nationality}>
                        {nationality}
                      </option>
                    ))}
                  </select>
                </Box>
                <Typography
                  sx={{
                    color: "#f00",
                    fontWeight: 600,
                    margin: 0,
                    position: "absolute",
                    bottom: "0",
                    padding: "0 3px",
                    fontSize: "12px",
                  }}
                >
                  {errors.nationality && touched.nationality
                    ? errors.nationality
                    : undefined}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "address" : "العنوان"}
                name="address"
                value={values.address}
                error={errors.address}
                touched={touched.address}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <Box
                with="100%"
                sx={{
                  paddingBottom: "20px",
                  position: "relative",
                }}
              >
                <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
                  {lang === "en" ? "branch" : "فرع"}
                </Typography>
                <Box
                  sx={{
                    width: {
                      lg: "92%",
                      md: "95%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <select
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "8px",
                      borderRadius: "2",
                      outline: 0,
                      border: `1px solid ${errors.branch && touched.branch ? "red" : "transparent"
                        }  `,
                      backgroundColor: mode === "dark" ? "#252525" : "#E0F2FF",
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                    name="branch"
                    value={values.branch}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option hidden select>
                      {lang === "en" ? "Select branch" : "اختار فرع"}
                    </option>
                    {branches?.data?.map((branch) => (
                      <option
                        key={branch._id}
                        id={branch._id}
                        value={branch._id}
                      >
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </Box>
                <Typography
                  sx={{
                    color: "#f00",
                    fontWeight: 600,
                    margin: 0,
                    position: "absolute",
                    bottom: "0",
                    padding: "0 3px",
                    fontSize: "12px",
                  }}
                >
                  {errors.nationality && touched.nationality
                    ? errors.nationality
                    : undefined}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <Box
                with="100%"
                sx={{
                  paddingBottom: "20px",
                  position: "relative",
                }}
              >
                <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
                  {lang === "en" ? "Department" : "فرع"}
                </Typography>
                <Box
                  sx={{
                    width: {
                      lg: "92%",
                      md: "95%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <select
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "8px",
                      borderRadius: "2",
                      outline: 0,
                      border: `1px solid ${errors.department && touched.department
                        ? "red"
                        : "transparent"
                        }  `,
                      backgroundColor: mode === "dark" ? "#252525" : "#E0F2FF",
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                    name="department"
                    value={values.department}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option hidden select>
                      {lang === "en" ? "Select department" : "اختار قسم"}
                    </option>
                    {depsSuccess
                      ? [...depsData?.data].map((dep, index) => (
                        <option key={index} value={dep._id}>
                          {dep?.name}
                        </option>
                      ))
                      : null}
                  </select>
                </Box>
                <Typography
                  sx={{
                    color: "#f00",
                    fontWeight: 600,
                    margin: 0,
                    position: "absolute",
                    bottom: "0",
                    padding: "0 3px",
                    fontSize: "12px",
                  }}
                >
                  {errors.department && touched.department
                    ? errors.department
                    : undefined}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <Box
                with="100%"
                sx={{
                  paddingBottom: "20px",
                  position: "relative",
                }}
              >
                <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
                  {lang === "en" ? "Shift" : "دوام"}
                </Typography>
                <Box
                  sx={{
                    width: {
                      lg: "92%",
                      md: "95%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <select
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "8px",
                      borderRadius: "2",
                      outline: 0,
                      border: `1px solid ${errors.shift && touched.shift ? "red" : "transparent"
                        }  `,
                      backgroundColor: mode === "dark" ? "#252525" : "#E0F2FF",
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                    name="shift"
                    value={values.shift}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option hidden select>
                      {lang === "en" ? "Select Shift" : "اختار دوام"}
                    </option>
                    {shiftsSuccess
                      ? [...shiftsData.data].map((shift, index) => {
                        return (
                          <option
                            value={shift?._id}
                            key={index}
                            id={shift._id}
                          >
                            {shift.name}
                          </option>
                        );
                      })
                      : null}
                  </select>
                </Box>
                <Typography
                  sx={{
                    color: "#f00",
                    fontWeight: 600,
                    margin: 0,
                    position: "absolute",
                    bottom: "0",
                    padding: "0 3px",
                    fontSize: "12px",
                  }}
                >
                  {errors.shift && touched.shift ? errors.shift : undefined}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            type="submit"
            sx={{
              margin: "30px auto 0",
              padding: "10px 30px",
              "&:hover": {
                color: "#fff",
              },
            }}
          >
            {lang === "en" ? "Update" : "تحديث"}
          </Button>
        </form>
      </Box>
    </TabPanel>
  ) : null;
};

export default ProfileEmp;
