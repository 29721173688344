import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HOC from "../../../components/HOC/HOC";

import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import AssginBreak from "../../../components/singleShift/assing_model/assginUserBreakModel";
import { useParams } from "react-router";
import ErrorScreen from "../../errorScreen/ErrorScreen";
import Spinner from "../../spinner/Spinner";
import { imageUrl } from "../../../App";
import { useGetMeQuery } from "../../../redux/features/userApi";
import { useTheme } from "@emotion/react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import UnAssignBreak from "../../../components/singleShift/assing_model/unassignUserBreakModel";
import { useGetOverTimeQuery } from "../../../redux/features/overTimeApis";
import dayjs from "dayjs";
import AssingOverTimeMOdal from "../../../components/singleShift/assing_model/assginUserBreakModel";
import AssingUserOverTimeModal from "../../../components/singleShift/assing_model/assginUserOverTime";
import UnASsignOverTimeModal from "../../../components/singleShift/assing_model/unAssignOverTimeModal";

const SingleOverTimePage = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const [skipeId, setSkipeId] = useState(skipToken);
  const { IdBranch, id, IdOvertime } = useParams();

  let { data: DataMe, isSuccess: success } = useGetMeQuery();
  const { cardTheme } = useTheme();
  let IdShift;
  let {
    data: overTimeData,
    isSuccess: overTimeSuccess,
    isLoading: overTimeLoading,
    isError: overTimeError,
  } = useGetOverTimeQuery(skipeId);
  const deleteRecivedRequest = () => toast.success("Deleted");

  useEffect(() => {
    IdShift = id;
    setSkipeId({ shift: id, id: IdOvertime });

    if (overTimeSuccess) {
    }
  }, [IdShift, IdOvertime, overTimeSuccess]);
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";

  return (
    <HOC>
      <Box>
        {overTimeError ? (
          <ErrorScreen
            data={
              lang == "en"
                ? "Overtime is Not Found"
                : "لم يتم العثور على  العمل الإضافي"
            }
          />
        ) : overTimeLoading ? (
          <Spinner />
        ) : overTimeSuccess ? (
          <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
            <Box
              sx={{
                width: {
                  xl: 0.97,
                  lg: 0.97,
                  md: 0.97,
                  sm: 0.92,
                  xs: 0.92,
                },
                margin: "0 auto",
                px: 1,
                pt: 1,
                backgroundColor: chartBGColor,
                position: "relative",
                borderRadius: "15px",
              }}
            >
              {success && DataMe?.data?.role !== "employee" && (
                <>{/* <EditBreak mode={mode} lang={lang} data={data} /> */}</>
              )}
              <div className="p-2 w-100 ">
                <div
                  className="d-sm-flex d-block align-items-center gap-3"
                  style={{
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {/**name of shift */}
                  <div className="d-flex flex-column">
                    <h4
                      className={mode === "light" ? "text-dark" : "text-white"}
                    >
                      {overTimeData?.data.shift?.name?.toUpperCase()}
                    </h4>
                  </div>
                </div>

                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        md: "flex",
                        sm: "block",
                        xs: "block",
                      },
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                          fontSize: 17,
                          fontWeight: "600",
                        }}
                      >
                        {lang === "en" ? "From" : "من"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                        }}
                      >
                        {dayjs(overTimeData?.data?.start).format(
                          "DD-MM-YY-H:m"
                        )}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        md: "flex",
                        sm: "block",
                        xs: "block",
                      },
                      alignItems: "center",
                      gap: 2,
                      mx: "20px",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                          fontSize: 17,
                          fontWeight: "600",
                        }}
                      >
                        {lang === "en" ? "To" : "الي"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                        }}
                      >
                        {dayjs(overTimeData?.data?.end).format("DD-MM-YY-H:m")}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
                <AvatarGroup
                  sx={{
                    justifyContent: "flex-end",
                    my: "20px",
                  }}
                >
                  {overTimeData?.data?.users &&
                    overTimeData?.data?.users.map((emp) => (
                      <Avatar
                        alt="Remy Sharp"
                        src={`${imageUrl}${emp?.image}`}
                      />
                    ))}
                </AvatarGroup>
              </div>
              <Box
                sx={{
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "block",
                    xs: "block",
                  },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  p: 4,
                  pl: lang === "en" ? 1 : undefined,
                  pr: lang === "ar" ? 1 : undefined,
                }}
              >
                <AssingUserOverTimeModal
                  lang={lang}
                  mode={mode}
                  branchId={IdBranch}
                  shiftId={id}
                  overTimeId={IdOvertime}
                />
                <Typography
                  sx={{
                    fontSize: "17px",
                    mx: {
                      xl: 2,
                      sm: 1,
                    },
                    my: {
                      xl: 0,
                      lg: 0,
                      md: 0,
                      sm: 1.5,
                      xs: 1.5,
                    },
                    fontWeight: "bolder",
                    color: mode === "dark" ? "#fff" : undefined,
                  }}
                >
                  {lang === "en" ? "Or" : "أو"}
                </Typography>
                <UnASsignOverTimeModal
                  lang={lang}
                  mode={mode}
                  users={{ data: overTimeData?.data?.users }}
                />
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Box>
    </HOC>
  );
};

export default SingleOverTimePage;
