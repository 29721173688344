import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { imageUrl } from "../../../../App";

const TaskEmployeeMenu = ({
  lang,
  mode,
  setValues,
  error,
  touched,
  disableAssigning,
  EmpsBranchSuccess,
  empsByBranch,
  userData,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleCheckEmployee = (e, employeeId) => {
    const { checked: branchIsChecked } = e.target;
    branchIsChecked
      ? setValues((current) => ({
          ...current,
          to: [...current.to, employeeId],
        }))
      : setValues((currentValues) => ({
          ...currentValues,
          to: currentValues.to.filter((item) => item !== employeeId),
        }));
  };
  return (
    <Box>
      <Box
        sx={{
          paddingBottom: "25px",
          position: "relative",
        }}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            direction: lang === "en" ? "ltr" : "rtl",
            border: error && touched ? 1 : 0,
            borderColor: "red",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: mode === "dark" ? "#fff" : undefined }}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "#252526",
            }}
          >
            <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
              {lang === "en" ? "Employee" : "الموظف"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <List
              sx={{
                width: "100%",
                padding: 0,
              }}
            >
              {!disableAssigning &&
                EmpsBranchSuccess &&
                empsByBranch?.data.map((user, index) => {
                  return (
                    <ListItem
                      sx={{
                        backgroundColor:
                          mode === "light" ? "#f8f8f8" : "#252525",
                      }}
                      key={index}
                    >
                      <ListItemButton
                        disableRipple
                        sx={{
                          cursor: "default",
                          backgroundColor:
                            mode === "light" ? "#fff" : "#252526",
                          paddingX: "10px",
                          width: "100%",
                          "&:hover": {
                            backgroundColor:
                              mode === "light" ? "#fff" : "#252526",
                          },
                        }}
                      >
                        <ListItemText
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            textAlign: lang === "en" ? "left" : "right",
                          }}
                          id={user?._id}
                          primary={user[`userName_${lang}`]}
                        />
                        <Stack direction="row" alignItems="center">
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                height: "35px",
                                width: "35px",
                              }}
                              alt={user[`userName_en`]}
                              src={`${imageUrl}${user.image}`}
                            />
                          </ListItemAvatar>
                          {/**/}
                          <InputBase
                            type="checkbox"
                            name="to"
                            value={user?._id}
                            onChange={(e) => handleCheckEmployee(e, user?._id)}
                            sx={{
                              height: "15px",
                              width: "15px",
                              accentColor: "#00635D",
                              cursor: "pointer",
                            }}
                          />
                        </Stack>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Typography
          sx={{
            position: "absolute",
            bottom: 0,
            color: "red",
            fontWeight: "bolder",
            fontSize: "12px",
          }}
        >
          {error && touched && error}
        </Typography>
      </Box>
    </Box>
  );
};

export default TaskEmployeeMenu;
