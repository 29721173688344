import React, { useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import ChildEditModel from "./EditModel";
import AddChildModel from "./AddChildModel";
import {
  companyApi,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
} from "../../../../redux/features/companyApi";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Spinner from "../../../spinner/Spinner";
import ErrorScreen from "../../../errorScreen/ErrorScreen";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import DeleteModel from "./DeleteModel";
import { useNavigate } from "react-router";
import ReduxStore from "../../../../redux/store";
import { taskApi } from "../../../../redux/features/tasksApi";
import { alertApi } from "../../../../redux/features/alertsApis";
import { branchesApi } from "../../../../redux/features/branchesApi";
import { empolyeeApi } from "../../../../redux/features/employeeApi";
import { blogsApi } from "../../../../redux/features/blogsApi";
import { requsetApis } from "../../../../redux/features/requestsApis";
import { attendApis } from "../../../../redux/features/attendeApis";
import { contractsApi } from "../../../../redux/features/contractsApi";
import { shiftsApi } from "../../../../redux/features/shiftsApi";
import { breaksApis } from "../../../../redux/features/breaksApis";
import { departmentsApis } from "../../../../redux/features/departmentsApis";
import { UserApi } from "../../../../redux/features/userApi";
import { overtimeApis } from "../../../../redux/features/overTimeApis";
import { commentsApi } from "../../../../redux/features/commentsApi";
import { payroleApi } from "../../../../redux/features/payrolApi";
import { documentApi } from "../../../../redux/features/documentsApi";
import { notificationsApi } from "../../../../redux/features/notificationsApi";

function CompaniesPanel({ value }) {
  const navigate = useNavigate();
  let { data, isLoading, isError, isSuccess } = useGetCompaniesQuery();

  let [createCompany, { isLoading: createLoading, isError: createError }] =
    useCreateCompanyMutation();
  let [updateCompany, { isLoading: updateLoading, isError: updateError }] =
    useUpdateCompanyMutation();

  const { value: lang } = useSelector((state) => state).lang;
  const [confirmDelete, setConfirmDelete] = useState(null);

  const [dataModel, setDataModel] = useState(null);
  const [mutateCopmany, setUpdateCompany] = useState(null);

  const addCompany = (data) => {
    createCompany({ name: data }).then(({ data, error }) => {
      if (data) {
        toast.success(lang == 'en' ? "Company Added Successfully" : "تمت إضافة الشركة بنجاح")
      }
      else if (error) {
        toast.error(error[`error_${lang}`])
      }
    });
  };
  const handleUpdateCompany = (data) => {
    updateCompany(mutateCopmany)
      .then(({ data }) => {
        toast.success(
          lang == "en"
            ? "Company Updated Successfully"
            : "تم تحديث الشركة بنجاح"
        );
      })
      .catch((e) => {
        toast.error(
          lang == "en"
            ? "Error While Updating Company"
            : "خطأ أثناء تحديث الشركة"
        );
      });
  };

  const handleCompanyChoosed = (id) => {
    localStorage.setItem("companyId", id);
    ReduxStore.dispatch(UserApi.util.resetApiState());
    ReduxStore.dispatch(taskApi.util.resetApiState());
    ReduxStore.dispatch(alertApi.util.resetApiState());
    ReduxStore.dispatch(branchesApi.util.resetApiState());
    ReduxStore.dispatch(empolyeeApi.util.resetApiState());
    ReduxStore.dispatch(blogsApi.util.resetApiState());
    ReduxStore.dispatch(companyApi.util.resetApiState());
    ReduxStore.dispatch(requsetApis.util.resetApiState());
    ReduxStore.dispatch(attendApis.util.resetApiState());
    ReduxStore.dispatch(contractsApi.util.resetApiState());
    ReduxStore.dispatch(shiftsApi.util.resetApiState());
    ReduxStore.dispatch(breaksApis.util.resetApiState());
    ReduxStore.dispatch(overtimeApis.util.resetApiState());
    ReduxStore.dispatch(departmentsApis.util.resetApiState());
    ReduxStore.dispatch(shiftsApi.util.resetApiState());
    ReduxStore.dispatch(commentsApi.util.resetApiState());
    ReduxStore.dispatch(departmentsApis.util.resetApiState());
    ReduxStore.dispatch(payroleApi.util.resetApiState());
    ReduxStore.dispatch(breaksApis.util.resetApiState());
    ReduxStore.dispatch(documentApi.util.resetApiState());
    ReduxStore.dispatch(notificationsApi.util.resetApiState());
    toast.success(
      lang == "en" ? "company Choosed Successfully" : "تم اختيار الشركة بنجاح"
    );

    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  useEffect(() => {
    if (dataModel) {
      addCompany(dataModel);
    }
  }, [dataModel]);

  useEffect(() => {
    if (mutateCopmany) {
      handleUpdateCompany(mutateCopmany);
    }
  }, [mutateCopmany]);

  const [deleteCompany] = useDeleteCompanyMutation()
  const handleDeleteCompany = (id) => {
    // should delte the company
    deleteCompany(id).then(({ data, error }) => {
      if (data) {
        toast.success(lang == "en" ? "Company Deleted Successfully" : "تم حذف الشركة بنجاح")
      }
      else if (error) {
        toast.error(lang == 'en' ? "Error While Deleting Company" : "خطأ أثناء حذف الشركة")
      }
    })

  };
  useEffect(() => {
    if (confirmDelete) {


      handleDeleteCompany(confirmDelete);
    }
  }, [confirmDelete]);
  const { value: mode } = useSelector((state) => state).mode;
  return (
    <TabPanel value={value}>
      <Box
        sx={{
          width: "100%",
          margin: "0 auto",
        }}
      >
        {!localStorage.getItem("companyId") ? (
          <Typography
            style={{
              color: "red",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            {lang == "en"
              ? "before Moving You need to Choose company "
              : "قبل الانتقال تحتاج إلى اختيار شركة"}
          </Typography>
        ) : null}
        <Box
          sx={{
            mt: 3,
            borderRadius: 2,
            padding: {
              xl: "30px",
              lg: "20px",
              md: "15px",
              sm: "10px",
              xs: "10px ",
            },
          }}
        >
          <Grid container spacing={1}>
            {isSuccess
              ? [...data?.data].map((cmp, index) => (
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  index={index}
                  key={index}
                >
                  <Box
                    bgcolor="#fff"
                    sx={{
                      height: "150px",
                      borderRadius: 2,
                      backgroundColor:
                        mode === "light" ? "#E0F2FF" : "#252525",
                      width: {
                        lg: "90%",
                        md: "90%",
                        sm: "100%",
                        xs: "100%",
                      },
                      mt: "15px",
                      mx: "auto",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Stack direction="row" alignItems="center">
                        <ChildEditModel
                          setUpdateCompany={setUpdateCompany}
                          companyId={cmp._id}
                        />
                        <DeleteModel setConfirmDelete={setConfirmDelete} itemId={cmp?._id}
                          confirm_en={`Are You Sure You Want To Delete The Company , Cause it Will Delete All The Related Data 
        , Like All The Employees and The Branchs etc ...
        `}
                          confirm_ar={`هل أنت متأكد أنك تريد حذف الشركة ، لأنها ستحذف جميع البيانات ذات الصلة
        ، مثل جميع الموظفين والفروع وغيرها ...`}
                        />
                      </Stack>
                    </Stack>
                    <Typography
                      variant="h5"
                      sx={{
                        px: "15px",
                        color: mode === "dark" ? "#fff" : undefined,
                      }}
                    >
                      {cmp?.name}
                    </Typography>
                    <Box
                      onClick={() => handleCompanyChoosed(cmp._id)}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Button
                        variant="h6"
                        fontSize={"small"}
                        sx={{
                          margin: "20px 15px",
                          padding: "6px 9px",
                          borderRadius: "7px",
                          backgroundColor: "#0077B6  !important",
                          color: "#fff !important",
                        }}
                      >
                        {lang === "en" ? "Select" : "اختار"}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))
              : null}
            <Grid item xl={3} lg={4} md={6} sm={12} xs={12} sx={{ py: "10px" }}>
              <Stack
                bgcolor="#fff"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "150px",
                  borderRadius: 2,
                  backgroundColor: mode === "light" ? "#E0F2FF" : "#252525",
                  width: "90%",
                  mx: "auto",
                  mt: "15px",
                }}
              >
                <AddChildModel
                  setDataModel={setDataModel}
                  mode={mode}
                  lang={lang}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <ToastContainer autoClose={2000} />
      </Box>
    </TabPanel>
  );
}

export default CompaniesPanel;
