import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Badge, Box, Divider, Stack, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { useState } from "react";
import {
  useLazyGetAdminOrRootNotificationsQuery,
  useLazyGetEmployeesNotificationsQuery,
  useUpdateNotiMutation,
} from "../../../../redux/features/notificationsApi";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
const NotificationsMenu = ({ lang, mode, userData, userSuccess }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { NotificationId } = useParams();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [count, setCount] = React.useState(0);
  const [unSeenNotifications, setUnSeenNotifications] = useState([]);
  const [seenNotifications, setSeenNotifications] = useState([]);
  let [getEmpsNotification] = useLazyGetEmployeesNotificationsQuery();
  let [getAdminOrRootNotifications] = useLazyGetAdminOrRootNotificationsQuery();
  const getAllEmployeeNotification = () => {
    getEmpsNotification().then(({ data, error }) => {
      if (data) {
        if (data?.unSeen) {
          setUnSeenNotifications((_) => data?.unSeen);
          setCount((_) => data?.count);
        }
        if (data?.seen) {
          setSeenNotifications((_) => data?.seen);
        }
      } else if (error) {
        console.log(
          "errorWhile Getting Admin or Root Notification : ",
          error.data
        );
      } else if (error) {
      }
    });
  };
  const getAllAdminOrRootNotifications = () => {
    getAdminOrRootNotifications().then(({ data, error }) => {
      if (data?.unSeen) {
        setCount((_) => data?.count);

        setUnSeenNotifications((_) => data?.unSeen);
      }
      if (data?.seen) {
        setSeenNotifications((_) => data?.seen);
      } else if (error) {
        console.log(
          "errorWhile Getting ADmin or Root Notification : ",
          error.data
        );
      } else if (error) {
      }
    });
  };
  const [updateNotif] = useUpdateNotiMutation();
  React.useEffect(() => {
    if (userSuccess) {
      if (userData?.data?.role == "employee") {
        getAllEmployeeNotification();
      } else {
        getAllAdminOrRootNotifications();
      }
    }
    if (NotificationId) {
      updateNotif(NotificationId).then(({ data, error }) => {});
    }
  }, [userSuccess]);

  const handleNavigate = (notify) => {
    navigate(`/notifications/${notify?._id}`, { state: { notify, lang } });
    handleClose();
  };
  const responsiveHeight = {
    xl:
      unSeenNotifications.length + seenNotifications.length > 5
        ? "500px"
        : "auto",
    lg:
      unSeenNotifications.length + seenNotifications.length > 5
        ? "500px"
        : "auto",
    md:
      unSeenNotifications.length + seenNotifications.length > 5
        ? "500px"
        : "auto",
    sm:
      unSeenNotifications.length + seenNotifications.length > 5
        ? "75%"
        : "auto",
    xs:
      unSeenNotifications.length + seenNotifications.length > 5
        ? "75%"
        : "auto",
  };
  return (
    <Stack>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
        sx={{
          minWidth: "0",
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack spacing={2} direction="row">
          <Badge badgeContent={count} color="error">
            <NotificationsIcon
              sx={{
                color:
                  mode === "light"
                    ? open
                      ? "#000"
                      : "#757575"
                    : open
                    ? "#fff"
                    : "#757575",
                "&:hover": {
                  color: mode === "light" ? "#000" : "#fff",
                },
              }}
            />
          </Badge>
        </Stack>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiPaper-root": {
            backgroundColor: mode === "light" ? "#FFF" : "#252525",
            width: {
              xl: "350px",
              lg: "350px",
              md: "350px",
              sm: 0.95,
              xs: 0.95,
            },
            direction: lang === "en" ? "ltr" : "rtl",
            py: "5px",
            px: "10px",
            boxShadow: `${mode === "light" ? "#ccc" : "#333"} 0px 3px 8px`,
            scrollY: "scroll",
            height: responsiveHeight,
            "&::-webkit-scrollbar": {
              width: "10px",
              borderRadius: "15px",
            },

            "&::-webkit-scrollbar-track": {
              background: mode === "light" ? "#f1f1f1" : "#555",
              borderRadius: "15px",
            },

            "&::-webkit-scrollbar-thumb": {
              background: mode === "light" ? "#aac1fe" : "#000",
              transition: "0.5s all",
              borderRadius: "15px",
            },

            " &::-webkit-scrollbar-thumb:hover": {
              background: mode === "light" ? "#78a3e4" : "#000",
            },
          },
        }}
      >
        {seenNotifications.length + unSeenNotifications.length < 1 ? (
          <Typography
            sx={{
              textAlign: "center",
              color: mode === "dark" ? "#fff" : undefined,
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {lang === "en" ? "No Notifications" : "لا يوجد إشعارات"}
          </Typography>
        ) : (
          [...unSeenNotifications].map((notify, idx) => {
            return (
              <MenuItem
                key={idx}
                onClick={() => handleNavigate(notify)}
                style={{
                  backgroundColor: mode === "light" ? "#F2F8FD" : "#3E3E42",
                  position: "relative",
                  marginTop: idx !== 0 ? "10px" : 0,
                }}
              >
                <Box sx={{ mt: "20px" }}>
                  <Typography
                    sx={{
                      color: mode === "dark" ? "#fff" : undefined,
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    {notify[`title_${lang}`].slice(0, 25) + "..."}
                  </Typography>
                  <Typography
                    sx={{
                      color: mode === "dark" ? "#fff" : undefined,
                      fontSize: "14px",
                    }}
                  >
                    {notify[`des_${lang}`].slice(0, 25) + "..."}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: mode === "dark" ? "#fff" : undefined,
                    position: "absolute",
                    top: "5px",
                    left: lang === "ar" ? "10px" : undefined,
                    right: lang === "en" ? "10px" : undefined,
                    fontSize: "13px",
                  }}
                >
                  {notify?.createdAt
                    ? moment(notify?.createdAt).format("yyyy-mm-DD")
                    : moment(new Date()).format("yyyy-mm-DD")}
                </Typography>
              </MenuItem>
            );
          })
        )}

        {[...seenNotifications].map((notify, idx) => {
          return (
            <MenuItem
              key={idx}
              onClick={() => handleNavigate(notify)}
              style={{
                marginTop: "8px",
                backgroundColor: "transparent",
                position: "relative",
              }}
            >
              <Box sx={{ mt: "20px" }}>
                <Typography
                  sx={{
                    color: mode === "dark" ? "#fff" : undefined,
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {notify[`title_${lang}`].slice(0, 25) + "..."}
                </Typography>
                <Typography
                  sx={{
                    color: mode === "dark" ? "#fff" : undefined,

                    fontSize: "14px",
                  }}
                >
                  {notify[`des_${lang}`].slice(0, 25) + "..."}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: mode === "dark" ? "#fff" : undefined,
                  position: "absolute",
                  top: "5px",
                  left: lang === "ar" ? "10px" : undefined,
                  right: lang === "en" ? "10px" : undefined,
                  fontSize: "13px",
                }}
              >
                {notify?.createdAt
                  ? moment(notify?.createdAt).format("yyyy-mm-DD")
                  : moment(new Date()).format("yyyy-mm-DD")}
              </Typography>
            </MenuItem>
          );
        })}
        {/* <Typography
          sx={{
            color: "#6B97ED",
            cursor: "pointer",
            mt: "15px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
          onClick={() => navigate("/allNotifications")}
        >
          {lang === "en" ? "See All" : "مشاهدة الجميع"}
        </Typography> */}
      </Menu>
    </Stack>
  );
};

export default NotificationsMenu;
