import React from "react";
import styles from "./Blogs.module.css";
import { useTranslation } from "react-i18next";
import person from "./images/person.png";
import { ReactComponent as CloseIcon } from "./images/close.svg";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as PicIcon } from "./images/pic.svg";
import { ReactComponent as ArrowIcon } from "./images/arrow.svg";
import { ReactComponent as LikeIcon } from "./images/like.svg";
import { ReactComponent as DisLikeIcon } from "./images/dislike.svg";
import { ReactComponent as CommentIcon } from "./images/comment.svg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import NewBlogModal from "./NewBlogModal";
import { useCreateBlogMutation } from "../../../redux/features/blogsApi";
import AllBlogsPage from "./AllBlogsPage";
import MyBlogs from "./MyBlogs";
import NewBlogForm from "./NewBlogForm";
import TabList from "@mui/lab/TabList";
import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { FiLogOut } from "react-icons/fi";
import styled from "@emotion/styled";
import Tab from "@mui/material/Tab";

function BlogsPage() {
  const [tabsTarget, setTabsTarget] = useState(0);
  const [status, setStatus] = useState({
    backgroundColor: "var(--buttonBg)",
    color: "#FF2366",
    activeBtn: 1,
  });

  // const [modalShow, setModalShow] = useState();
  function handleStatus(btn, modal) {
    setStatus({
      backgroundColor: "var(--buttonBg)",
      color: "#FF2366",
      activeBtn: btn,
    });
    // setModalShow(modal);
  }

  const getAllTheTabs = () => {
    let tabs = document.querySelectorAll(`[data-bs-target^="#"]`);
    let tab = tabs[0];
    return tabs;
  };
  const resetAllTheTabs = (tabs) => {
    Array.from(tabs).map((tab) => {
      let div = document.querySelector(
        `div[id=${tab.getAttribute("aria-controls")}]`
      );
      div.classList.remove("show", "active");
    });
  };
  const targetSpecificTab = (tabs, choosed) => {
    let tab = Array.from(tabs)[choosed - 1];
    let div = document.querySelector(
      `div[id=${tab.getAttribute("aria-controls")}]`
    );
    div.classList.add("show", "active");
  };
  const handleTheTabs = (choosed) => {
    let tabs = getAllTheTabs();
    resetAllTheTabs(tabs);
    targetSpecificTab(tabs, choosed);
  };

  const { value: lang } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;
  const [value, setValue] = React.useState("allBlogs");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const TabStyled = styled(Tab)({
    color: "#8B7F7F",
    border: "0px",
    borderRadius: "6px",
    minHeight: 0,
    minWidth: 0,
    textTransform: "capitalize",
    marginLeft: lang === "en" ? "5px" : undefined,
    padding: "7px 12px",
    "&:hover": {
      backgroundColor: "transparent",
      color: mode === "light" ? "#000" : "#fff",
    },
    "&.Mui-selected": {
      backgroundColor: "#0077B6",
      color: "#fff",
    },
  });
  const tabs = [
    {
      label: lang === "en" ? "All Blogs" : "كل المدونات",
      value: "allBlogs",
    },
    {
      label: lang === "en" ? "My Blogs" : "مدوناتي",
      value: "myBlogs",
    },
    {
      label: lang === "en" ? "New Blog" : "مدونة جديدة",
      value: "newBlogForm",
    },
  ];

  return (
    <HOC>
      <div>
        <div className="container-fluid  overflow-hidden p-sm-3 p-1">
          {/* <div className="p-4">
            <ul
              className={`nav nav-pills mb-3 ${styles.list}`}
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item fw-bold" role="presentation">
                <button
                  className="nav-link"
                  style={{
                    backgroundColor:
                      status.activeBtn === 1
                        ? status.backgroundColor
                        : undefined,

                    color:
                      status.activeBtn === 1
                        ? "#fff"
                        : mode === "light"
                        ? "#000"
                        : "#fff",
                  }}
                  id="pills-allBlogs-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-allBlogs"
                  type="button"
                  role="tab"
                  aria-controls="pills-allBlogs"
                  aria-selected="true"
                  onClick={() => {
                    handleStatus(1);
                    handleTheTabs(1);
                  }}
                >
                  {lang === "en" ? "All Blogs" : "كل المدونات"}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link fw-bold"
                  style={{
                    backgroundColor:
                      status.activeBtn === 2
                        ? status.backgroundColor
                        : undefined,
                    color:
                      status.activeBtn === 2
                        ? "#fff"
                        : mode === "light"
                        ? "#000"
                        : "#fff",
                  }}
                  id="pills-myBlogs-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-myBlogs"
                  type="button"
                  role="tab"
                  aria-controls="pills-myBlogs"
                  aria-selected="false"
                  onClick={() => {
                    handleStatus(2);
                    handleTheTabs(2);
                  }}
                >
                  {lang === "en" ? "My Blogs" : "مدوناتي"}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link fw-bold"
                  style={{
                    backgroundColor:
                      status.activeBtn === 3
                        ? status.backgroundColor
                        : undefined,
                    color:
                      status.activeBtn === 3
                        ? "#fff"
                        : mode === "light"
                        ? "#000"
                        : "#fff",
                  }}
                  id="pills-newBlog-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-newBlog"
                  type="button"
                  role="tab"
                  aria-controls="pills-newBlog"
                  aria-selected="false"
                  onClick={() => {
                    handleStatus(3, true);
                  }}
                >
                  {lang === "en" ? "New Blog" : "مدونة جديدة"}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">

              <AllBlogsPage id="pills-allBlogs" />


              <div
                className="tab-pane fade"
                id="pills-myBlogs"
                role="tabpanel"
                aria-labelledby="pills-myBlogs-tab"
              >
                <MyBlogs />
              </div>

              <div
                className="tab-pane fade"
                id="pills-newBlog"
                role="tabpanel"
                aria-labelledby="pills-newBlog-tab"
              >
                <NewBlogForm />
              </div>
            </div>
          </div> */}
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              fontFamily: "'Baloo Bhaijaan 2', cursive",
            }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    p: "20px",
                    pb: 0,
                    ".MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  {tabs.map((tab, idx) => (
                    <TabStyled
                      key={idx}
                      label={tab.label}
                      value={tab.value}
                      sx={{
                        fontFamily: "'Baloo Bhaijaan 2', cursive",
                        fontWeight: "bolder",
                        "&.Mui-selected": {
                          backgroundColor: "transparent !important",
                          borderRadius: 0,
                          borderBottom: 1,
                          borderColor: mode === "light" ? "#000" : "#fff",
                          color:
                            mode === "light"
                              ? "#000 !important"
                              : "#fff !important",
                        },
                      }}
                    />
                  ))}
                </TabList>
              </Box>

              <AllBlogsPage value="allBlogs" />
              <MyBlogs value="myBlogs" />
              <NewBlogForm
                value="newBlogForm"
                panelValue={value}
                navigateToMyBlogs={() => setValue("myBlogs")}
              />
            </TabContext>
          </Box>
        </div>
      </div>
    </HOC>
  );
}
export default BlogsPage;
