import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../App'
export const subcategoryApis = createApi({
  reducerPath: 'subCategory',
  tagTypes: ['SubCategory'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getsubCatgeories: builder.query({
      query: (id) => `subCategory/all/${id}`,
      providesTags: ['SubCategory'],
    }),
    getsubCatgeoryById: builder.query({
      query: (id) => `subCategory/${id}`,
      providesTags: ['SubCategory'],
    }),

    // getAlertsCompany: builder.query({
    //   query: () =>
    //     `request/alerts?company=${localStorage.getItem('companyId')}`,
    //   providesTags: ['Category'],
    // }),
    createSubCategory: builder.mutation({
      query: (data) => ({
        url: `subCategory`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['SubCategory'],
    }),
    // updateEmployee: builder.mutation({
    //   query: (employeeData) => ({
    //     url: `user/employee/${employeeData._id}`,
    //     method: `PUT`,
    //     body: employeeData,
    //   }),
    //   invalidatesTags: ['Alert'],
    // }),

    // deleteEmployee: builder.mutation({
    //   query: (id) => ({
    //     url: `user/employee/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Alert'],
    // }),
  }),
})

export const {
  useGetsubCatgeoriesQuery,
  useGetsubCatgeoryByIdQuery,
  useCreateSubCategoryMutation
  // useGetEmployeesQuery,
  // useCreateEmployeeMutation,
  // useGetEmployeesByCompanyQuery
} = subcategoryApis
