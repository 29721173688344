import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import AddShiftModal from './modals/AddShiftModel'
import {
  shiftsApi,
  useDeleteShiftMutation,
  useGetShiftsByBranchQuery,
} from '../../redux/features/shiftsApi'
import Spinner from '../../pagees/spinner/Spinner'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import styles from './Shift.style.module.css'
import { useTheme } from '@emotion/react'
import DeleteIcon from '@mui/icons-material/Delete'
import { toast } from 'react-toastify'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditShiftModel from '../singleShift/edit_models/editShiftModel'
import { useGetMeQuery } from '../../redux/features/userApi'
import ReduxStore from '../../redux/store'
import DeleteModel from '../../pagees/privates/profile/mui/DeleteModel'

export default function BranchShifts({ mode, lang, id }) {
  const [skipeId, setSkipeId] = useState(skipToken)
  const { cardTheme } = useTheme()
  const chartBGColor = mode === 'light' ? '#fff' : '#3E3E42'
  let { data: DataMe, isSuccess: successME } = useGetMeQuery()

  const {
    data: data,
    isSuccess: success,
    isLoading: loading,
    error,
    isUninitialized,
  } = useGetShiftsByBranchQuery(skipeId)
  useEffect(() => {
    setSkipeId(skipToken)
    if (id) {
      setSkipeId(id)
    }
  }, [data, success, loading, isUninitialized])
  const navigate = useNavigate()
  const deleteRecivedRequest = () => toast.success('Deleted')
  const [confirmDelete, setConfirmDelete] = useState()

  const [deleteShift] = useDeleteShiftMutation()
  const { Idbranch } = useParams()
  useEffect(() => {
    if (confirmDelete) {
      deleteShift({ id: confirmDelete, branch: Idbranch }).then(
        ({ data, error }) => {
          if (data) {
            toast.success(lang == 'en' ? 'Shift Deleted Successfully' : '')
          } else if (error) {
            toast.error(error.data[`error_${lang}`])
          }
        },
      )
    }
  }, [confirmDelete])
  const days = {
    1: lang === 'en' ? 'Sunday' : 'الأحد',
    2: lang === 'en' ? 'Monday' : 'الاثنين',
    3: lang === 'en' ? 'Tuseday' : 'الثلاثاء',
    4: lang === 'en' ? 'Wednesday' : 'الأربعاء',
    5: lang === 'en' ? 'Thursday' : 'الخميس',
    6: lang === 'en' ? 'Friday' : 'جمعة',
    0: lang === 'en' ? 'Saturday' : 'السبت',
  }
  return (
    <div
      className="p-sm-4 p-2 mt-4"
      style={{ backgroundColor: chartBGColor, borderRadius: '25px' }}
    >
      <h1
        className={`text-center my-5 ${
          mode === 'light' ? 'text-dark' : 'text-white'
        }`}
      >
        {lang === 'en' ? 'Shifts' : 'الدوام'}
      </h1>
      <Grid container sx={{ mx: 0, px: 0 }}>
        {loading ? (
          <Spinner />
        ) : success ? (
          data?.data?.map((data, index) => (
            <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
              <Box
                key={index}
                sx={{
                  width: {
                    xl: '92.5%',
                    lg: '92.5%',
                    md: '92.5%',
                    sm: '90%',
                    xs: '90%',
                  },
                  margin: '0 auto',
                  height: {
                    xl: '590px',
                    lg: '620px',
                    xs: 'auto',
                  },
                }}
              >
                <div
                  className={`${styles.columContent} position-relative p-2`}
                  style={{
                    backgroundColor: cardTheme[`bg_${mode}`],
                    height: '100%',
                  }}
                >
                  {successME && DataMe?.data?.role !== 'employee' && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        left: 0,
                        width: 1,
                      }}
                    >
                      <EditShiftModel data={data} lang={lang} mode={mode} />

                      <DeleteModel
                        confirm_en={`are You Sure You Want To Delete 
                        The Shift Cause it Will Delete All The Related Data 
                        Such As breaks And Over Time etc ...
                        
                        `}
                        confirm_ar={`هل أنت متأكد أنك تريد حذف
                        سبب التحول سيؤدي إلى حذف جميع البيانات ذات الصلة
                        مثل فترات الراحة وبمرور الوقت وما إلى ذلك ...`}
                        itemId={data?._id}
                        setConfirmDelete={setConfirmDelete}
                      />
                    </Stack>
                  )}
                  <div className="p-2 mt-4 w-100 ">
                    <div
                      className="d-sm-flex d-block align-items-center gap-3"
                      style={{
                        color: mode === 'light' ? '#000' : '#fff',
                      }}
                    >
                      <div className="d-flex">
                        <h4
                          className={
                            mode === 'light' ? 'text-dark' : 'text-white'
                          }
                        >
                          {data.name.toUpperCase()}
                        </h4>
                      </div>
                    </div>
                    {/** Frist Grid */}

                    <Grid container sx={{ mx: 0, px: 0 }}>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        mt="25px"
                      >
                        <Typography
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            fontSize: 17,
                            fontWeight: '600',
                          }}
                        >
                          {lang === 'en' ? 'Days' : 'ايام الدوام'}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{
                            mt: '10px',
                          }}
                        >
                          <CalendarMonthIcon sx={{ color: '#FA6796' }} />
                          <Typography
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                              fontSize: '13px',
                            }}
                          >
                            {data?.origianalDays?.map((day, index) => {
                              return `${days[day]}${
                                data?.origianalDays.length - 1 !== index
                                  ? '-'
                                  : ''
                              } `
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        mt="25px"
                      >
                        <Typography
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            fontSize: 17,
                            fontWeight: '600',
                          }}
                        >
                          {lang === 'en' ? 'Time' : 'وقت الدوام'}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{
                            mt: '10px',
                            fontSize: '13px',
                          }}
                        >
                          <CalendarMonthIcon sx={{ color: '#FA6796' }} />
                          <Typography
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                            }}
                          >
                            {data.time.start_hour + ':' + data.time?.start_mins}
                            -{data.time.end_hour + ':' + data.time?.end_mins}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <br />
                    <Typography
                      sx={{
                        color: mode === 'light' ? '#000' : '#fff',
                        fontSize: 18,
                        fontWeight: '700',
                      }}
                    >
                      {lang === 'en' ? 'Allows' : ' الصلاحيات'}
                    </Typography>
                    {/** Second Grid */}
                    <Grid container sx={{ mx: 0, px: 0 }}>
                      <Grid item xl={4} lg={6} md={6} sm={12} xs={12} mt="25px">
                        <Typography
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            fontSize: 17,
                            fontWeight: '600',
                          }}
                        >
                          {lang === 'en' ? 'Late Time' : ' وقت التاخر'}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{
                            mt: '10px',
                          }}
                        >
                          <CalendarMonthIcon sx={{ color: '#FA6796' }} />
                          <Typography
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                            }}
                          >
                            {data.allows.lateTime.hours +
                              ':' +
                              data.allows.lateTime?.mins}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xl={4} lg={6} md={6} sm={12} xs={12} mt="25px">
                        <Typography
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            fontSize: 17,
                            fontWeight: '600',
                          }}
                        >
                          {lang === 'en' ? 'Leaving Time' : ' وقت المغادره'}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{
                            mt: '10px',
                          }}
                        >
                          <CalendarMonthIcon sx={{ color: '#FA6796' }} />
                          <Typography
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                            }}
                          >
                            {data.allows.leavingTime.hours +
                              ':' +
                              data.allows.leavingTime?.mins}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xl={4} lg={6} md={6} sm={12} xs={12} mt="25px">
                        <Typography
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            fontSize: 17,
                            fontWeight: '600',
                          }}
                        >
                          {lang === 'en' ? 'Working Hours' : 'عدد ساعات العمل'}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{
                            mt: '10px',
                          }}
                        >
                          <CalendarMonthIcon sx={{ color: '#FA6796' }} />
                          <Typography
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                            }}
                          >
                            {data?.time?.originalTime?.hours
                              ? data?.time?.originalTime?.hours
                              : 0 + ':' + data?.time?.originalTime?.mins}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    {/** Third Grid */}
                    <Typography
                      sx={{
                        color: mode === 'light' ? '#000' : '#fff',
                        fontSize: 18,
                        fontWeight: '700',
                        mt: 5,
                      }}
                    >
                      {lang === 'en' ? 'Weekly Holidays' : ' ايام الاجازه'}
                    </Typography>
                    <Grid container sx={{ mx: 0, px: 0 }}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap="10px"
                          sx={{
                            mt: '10px',
                            height: '60px',
                          }}
                        >
                          <CalendarMonthIcon sx={{ color: '#FA6796' }} />
                          <Typography
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                            }}
                          >
                            {data.allows.weeklyHolidays.map((day, index) => {
                              return `${days[day]}${
                                data.allows.weeklyHolidays.length - 1 !== index
                                  ? '-'
                                  : ''
                              } `
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      sx={{
                        position: 'absolute',
                        bottom: '5px',
                        left: 0,
                        width: 1,
                      }}
                    >
                      <Button
                        sx={{
                          backgroundColor: '#0077B6',
                          color: '#fff',
                          paddingY: {
                            md: 0.5,
                            xs: 0.4,
                          },
                          fontSize: { 
                            md: "16px",
                            xs : "13px"
                          },
                          marginX: 1,
                          '&:hover': {
                            backgroundColor: '#0077B6',
                            color: '#fff',
                          },
                        }}
                        onClick={() => navigate(`/branches/${id}/${data._id}`)}
                      >
                        {lang === 'en' ? 'open' : 'فتح'}
                      </Button>
                    </Stack>
                  </div>
                </div>
              </Box>
            </Grid>
          ))
        ) : (
          false
        )}
      </Grid>
      {successME && DataMe?.data?.role !== 'employee' && (
        <AddShiftModal lang={lang} mode={mode} />
      )}
    </div>
  )
}
