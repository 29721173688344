import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUpdateBranchMutation } from "../../../redux/features/branchesApi";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function EditBranchModal({ lang, mode, branchId }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const { modalTheme } = useTheme();

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const [updateBranch, { isError: updateError }] = useUpdateBranchMutation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const formik = useFormik({
    initialValues: {
      branchName: "",
    },
    validationSchema: Yup.object({
      branchName: Yup.string().required(
        lang === "en" ? "Branch name is required" : "اسم الفرع مطلوب"
      ),
    }),
    onSubmit: (values) => {
      updateBranch({ data: values.branchName, id: branchId }).then(
        ({ data, error }) => {
          if (data) {
            toast.success(
              lang == "en"
                ? "Branch updated Successfully"
                : "تم تحديث الفرع بنجاح"
            );
          }
        }
      );
      handleClose();
    },
  });
  useEffect(() => {
    if (updateError) {
      toast.error(
        lang == "en" ? "You Cant Update the Branch" : "لا يمكنك تحديث الفرع"
      );
    }
  }, [updateError]);
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    formik;
  return (
    <Stack sx={{}}>
      <Box id="Edit___Icon" sx={{ width: "fit-content", position: "relative" }}>
        <MoreHorizIcon
          onClick={handleOpen}
          size={24}
          sx={{
            cursor: "pointer",
            color: "#0360A5",
            margin: 0,
            padding: 0,
            width: "20px",
            maxWidth: "20px",
            fontSize: "23px",
          }}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                sx={{
                  color: mode === "light" ? "#000" : "#fff",
                }}
              >
                {lang === "en" ? "Create a new branch" : "عمل فرع جديدة"}
              </Typography>
              <Box
                marginTop={"30px"}
                pb={3}
                sx={{
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Branch Name" : "اسم الفرع"}
                </Typography>
                <input
                  type="text"
                  style={{
                    marginTop: "4px",
                    outline: 0,
                    display: "block",
                    borderRadius: 6,
                    width: "100%",
                    padding: "5px 10px",
                    direction: lang === "en" ? "ltr" : "rtl",
                    backgroundColor: mode === "light" ? "#fff" : "#252526",
                    color: mode === "light" ? "#000" : "#fff",
                    border:
                      errors.branchName && touched.branchName
                        ? "1px solid red"
                        : "1px solid transparent",
                  }}
                  value={values.branchName}
                  name="branchName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    color: "red",
                    fontWeight: "bolder",
                    fontSize: "small",
                  }}
                >
                  {errors.branchName && touched.branchName && errors.branchName}
                </Typography>
              </Box>
              <Stack
                marginTop="90px"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  type="submit"
                >
                  {lang === "en" ? "Add" : "اضافة"}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  onClick={handleClose}
                >
                  {lang === "en" ? "Close" : "إلغاء"}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  );
}
