import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Stack, Typography, useTheme } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function DeleteModel({
  setConfirmDelete,
  itemId,
  confirm_en,
  confirm_ar,
}) {
  const ConfirmMasseges = {
    en: confirm_en,
    ar: confirm_ar,
  };
  const [open, setOpen] = React.useState(false);
  const { value: lang } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;
  const { modalTheme } = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: modalTheme.Box[`bg_${mode}`],
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: "10px",
    direction: lang === "en" ? "ltr" : "rtl",
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteConfirm = () => {
    setConfirmDelete((_) => itemId);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const ButtonStyled = styled(Button)({
    backgroundColor: "#0077B6",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0077B6",
      color: "#fff",
    },
  });
  return (
    <Box>
      <Button
        onClick={handleOpen}
        disableRipple
        sx={{
          width: 0,
          minWidth: 0,
          mx: "15px",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <HighlightOffIcon
          sx={{
            color: "#E04449",
          }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "18px !important",
              color: mode === "dark" ? "#fff" : undefined,
            }}
          >
            {ConfirmMasseges[lang]}{" "}
          </Typography>

          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: "20px" }}
          >
            <ButtonStyled disableRipple onClick={() => handleDeleteConfirm()}>
              {lang === "en" ? "Confirm" : "تأكيد"}
            </ButtonStyled>
            <ButtonStyled disableRipple onClick={handleClose}>
              {lang === "en" ? "Cancel" : "إلغاء"}
            </ButtonStyled>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}
