import React from "react";
import styles from "./Alerts.module.css";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";

import makeAnimated from "react-select/animated";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import { Button, Card, Stack, Typography } from "@mui/material";

function ShiftsPage() {
  const [alertValue, setAlertValue] = useState("");
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";

  const [branchList, setBranchList] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState(null);

  // -----------------------------------handle inputs-------------------------------------

  const [userinfo, setUserInfo] = useState({
    employees: [],
    branches: [],
    departments: [],
  });

  //  -------------------------------------------------modal---------------------------------------
  const [modalState, setModalState] = useState();
  const [modalShow, setModalShow] = useState();
  const showModalState = () => {
    setModalState(true);
  };
  const handleClose = () => {
    setModalShow(false);
    setModalState(false);
  };

  // -----------------------------------------------------------

  // -------------------------------------------------------------

  const navigate = useNavigate();
  const [shiftName, setShiftName] = useState(null);

  const [startHour, setStartHour] = useState(null);
  const [endHour, setEndHour] = useState(null);

  const [startMins, setStartMins] = useState(null);
  const [endMins, setEndMins] = useState(null);

  const [lateHour, setLateHour] = useState(null);
  const [lateMins, setLateMins] = useState(null);

  const [leavingHour, setLeavingHour] = useState(null);
  const [leavingMins, setLeavingMins] = useState(null);

  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);

  const handleSubmit = async () => {};

  const [user, setUser] = useState({
    role: "root",
  });
  const [shifts, setShifts] = useState([
    { id: 236423, name: "Morning" },
    { id: 112341, name: "After Noon" },
    { id: 83291, name: "Night" },
  ]);

  return (
    <HOC>
      <div className={styles.component}>
        {/*---------------------------------------------container------------------------------------------------*/}
        <div className={`overflow-hidden p-3  `}>
          <div
            className="p-5"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1 className={mode === "light" ? "text-dark" : "text-white"}>
              {lang === "en" ? "Shifts" : "الدوام"}
            </h1>

            <Stack
              direction="row"
              flexWrap="wrap"
              gap={"20px"}
              marginTop="25px"
            >
              {shifts.map((shift) => (
                <Card
                  key={shift.id}
                  sx={{
                    width: {
                      sm: "345px",
                      xs: "100%",
                    },
                    padding: "20px",
                    backgroundColor: mode === "light" ? "#E4E4E4" : "#333333",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="600"
                    sx={{
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                  >
                    {shift.name}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    marginTop="25px"
                  >
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        "&:hover": {
                          color: "#fff",
                        },
                      }}
                      onClick={() => navigate(`/shifts/${shift.id}`)}
                    >
                      {lang === "en" ? "Open" : "فتح"}
                    </Button>
                  </Stack>
                </Card>
              ))}
            </Stack>
            {/*/---------------------------------------modal body-------------------------------------------------------------------*/}
            <Modal>
              <Modal.Body
                style={{
                  color: "var(--text-color)",
                  overflow: "auto",
                  maxHeight: "50%",
                  direction: lang === "en" ? "ltr" : "rtl",
                }}
                className={styles.modalContent}
              >
                <div className="container">
                  <h3>{lang === "en" ? "New Alerts" : "تنبيهات جديدة"}</h3>

                  <label
                    htmlFor=""
                    className="fw-bold my-3"
                    style={{ color: "var(--text-color)" }}
                  >
                    {lang === "en" ? "Title" : "العنوان"}
                  </label>
                  <input
                    type="text"
                    // placeholder={t("Write here....")}
                    placeholder={
                      lang === "en" ? "Write here...." : "اكتب هنا..."
                    }
                    className="form-control"
                    //   onChange={getAlertTitle}
                    onChange={(e) => setShiftName(e.target.value)}
                  />
                  {/* 
                <select>
                  {categoryList.map(({_id, categoryType})=>(<option value={_id} key={_id}>{categoryType}</option>))}
                </select>

                <select>
                  {subCategories.map(({_id , subType})=>(<option value={_id} key={_id}>{subType}</option>))}
                </select> */}

                  <ReactSelect
                    options={branchList.map((branch) => ({
                      label: branch.name,
                      value: branch._id,
                    }))}
                    // getValue={branchList.map(branch=>branch.name)}
                    // isMulti
                    isSearchable={true}
                    // isClearable={true}
                    closeMenuOnSelect={true}
                    // hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    //   MultiValue,
                    //   animatedComponents,
                    // }}
                    onChange={(selected) => {
                      setSelectedBranch(selected);
                    }}
                    allowSelectAll={true}
                    value={selectedBranch?.name}
                    menuShouldScrollIntoView={false}
                    placeholder={lang === "en" ? "Branch" : "الفرع"}
                    // lassName="mx-2 "
                  />

                  {/* -----------------------------------------------------days---------------------------------- */}

                  <div className="  my-4">
                    <label className="form-label text-dark fw-bold">
                      {lang === "en" ? "Days" : "أيام"}
                    </label>

                    <div className="d-flex">
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "From" : "من"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={startDay}
                          onChange={(e) => setStartDay(e.target.value)}
                        >
                          <option value={1}>
                            {lang === "en" ? "Sun" : "الاحد"}
                          </option>
                          <option value={2}>
                            {lang === "en" ? "Mon" : "الاثنين"}
                          </option>
                          <option value={3}>
                            {lang === "en" ? "Tue" : "الثلاثاء"}
                          </option>
                          <option value={4}>
                            {lang === "en" ? "Wed" : "الاربعاء"}
                          </option>
                          <option value={5}>
                            {lang === "en" ? "Thu" : "الخميس"}
                          </option>
                          <option value={6}>
                            {lang === "en" ? "Fri" : "الجمعة"}
                          </option>
                          <option value={0}>
                            {lang === "en" ? "Sat" : "السبت"}
                          </option>
                        </select>
                      </div>
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "To" : "إلي"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={endDay}
                          onChange={(e) => setEndDay(e.target.value)}
                        >
                          <option value={1}>
                            {lang === "en" ? "Sun" : "الاحد"}
                          </option>
                          <option value={2}>
                            {lang === "en" ? "Mon" : "الاثنين"}
                          </option>
                          <option value={3}>
                            {lang === "en" ? "Tue" : "الثلاثاء"}
                          </option>
                          <option value={4}>
                            {lang === "en" ? "Wed" : "الاربعاء"}
                          </option>
                          <option value={5}>
                            {lang === "en" ? "Thu" : "الخميس"}
                          </option>
                          <option value={6}>
                            {lang === "en" ? "Fri" : "الجمعة"}
                          </option>
                          <option value={0}>
                            {lang === "en" ? "Sat" : "السبت"}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* ---------------------------------------------------shift---------------------- */}
                  <div className="  ">
                    <label className="form-label text-dark fw-bold">
                      {lang === "en" ? "Hours" : "الساعات"}
                    </label>

                    <div className="d-flex">
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "From" : "من"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={startHour}
                          onChange={(e) => setStartHour(e.target.value)}
                        >
                          <option value={9}>
                            {lang === "en" ? "9 am" : "9 ص"}
                          </option>
                          <option value={10}>
                            {lang === "en" ? "10 am" : "10 ص"}
                          </option>
                          <option value={11}>
                            {lang === "en" ? "11 am" : "11 ص"}
                          </option>
                          <option value={12}>
                            {lang === "en" ? "12 am" : "12 ص"}
                          </option>
                          <option value={13}>
                            {lang === "en" ? "1 am" : "1 ص"}
                          </option>
                          <option value={14}>
                            {lang === "en" ? "2 am" : "2 ص"}
                          </option>
                          <option value={15}>
                            {lang === "en" ? "3 am" : "3 ص"}
                          </option>
                          <option value={16}>
                            {lang === "en" ? "4 am" : "4 ص"}
                          </option>
                          <option value={17}>
                            {lang === "en" ? "5 am" : "5 ص"}
                          </option>
                        </select>
                      </div>
                      <div
                        className={`d-flex ${styles.spanContainer}`}
                        value={endHour}
                        onChange={(e) => setEndHour(e.target.value)}
                      >
                        <span>{lang === "en" ? "To" : "إلي"}</span>
                        <select size="1" style={{ color: "gray" }}>
                          <option value={9}>
                            {lang === "en" ? "9 am" : "9 ص"}
                          </option>
                          <option value={10}>
                            {lang === "en" ? "10 am" : "10 ص"}
                          </option>
                          <option value={11}>
                            {lang === "en" ? "11 am" : "11 ص"}
                          </option>
                          <option value={12}>
                            {lang === "en" ? "12 am" : "12 ص"}
                          </option>
                          <option value={13}>
                            {lang === "en" ? "1 am" : "1 ص"}
                          </option>
                          <option value={14}>
                            {lang === "en" ? "2 am" : "2 ص"}
                          </option>
                          <option value={15}>
                            {lang === "en" ? "3 am" : "3 ص"}
                          </option>
                          <option value={16}>
                            {lang === "en" ? "4 am" : "4 ص"}
                          </option>
                          <option value={17}>
                            {lang === "en" ? "5 am" : "5 ص"}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* ---------------------------------------------------shift---------------------- */}
                  <div className="  ">
                    <label className="form-label text-dark fw-bold">
                      Hours
                    </label>

                    <div className="d-flex">
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "From" : "من"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={startMins}
                          onChange={(e) => setStartMins(e.target.value)}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                          <option value={20}>20</option>
                          <option value={25}>25</option>
                          <option value={30}>30</option>
                          <option value={35}>35</option>
                          <option value={40}>40</option>
                          <option value={45}>45</option>
                          <option value={50}>50</option>
                          <option value={55}>55</option>
                          <option value={60}>60</option>
                        </select>
                      </div>
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "To" : "إلي"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={endMins}
                          onChange={(e) => setEndMins(e.target.value)}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                          <option value={20}>20</option>
                          <option value={25}>25</option>
                          <option value={30}>30</option>
                          <option value={35}>35</option>
                          <option value={40}>40</option>
                          <option value={45}>45</option>
                          <option value={50}>50</option>
                          <option value={55}>55</option>
                          <option value={60}>60</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* ---------------------------------------------------late time---------------------- */}
                  <div className="">
                    <label className="form-label text-dark fw-bold">
                      {lang === "en" ? "Late Time" : "وقت التأخير"}
                    </label>

                    <div className="d-flex">
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "From" : "من"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={lateHour}
                          onChange={(e) => setLateHour(e.target.value)}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                        </select>
                      </div>
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "To" : "إلي"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={lateMins}
                          onChange={(e) => setLateMins(e.target.value)}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                          <option value={20}>20</option>
                          <option value={25}>25</option>
                          <option value={30}>30</option>
                          <option value={35}>35</option>
                          <option value={40}>40</option>
                          <option value={45}>45</option>
                          <option value={50}>50</option>
                          <option value={55}>55</option>
                          <option value={60}>60</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* ---------------------------------------------------Leaving time---------------------- */}
                  <div className="  ">
                    <label className="form-label text-dark fw-bold">
                      {lang === "en" ? "Leaving Time" : "وقت الانصراف"}
                    </label>

                    <div className="d-flex">
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "From" : "من"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={leavingHour}
                          onChange={(e) => setLeavingHour(e.target.value)}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                        </select>
                      </div>
                      <div className={`d-flex ${styles.spanContainer}`}>
                        <span>{lang === "en" ? "To" : "إلي"}</span>
                        <select
                          size="1"
                          style={{ color: "gray" }}
                          value={leavingMins}
                          onChange={(e) => setLeavingMins(e.target.value)}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={15}>15</option>
                          <option value={20}>20</option>
                          <option value={25}>25</option>
                          <option value={30}>30</option>
                          <option value={35}>35</option>
                          <option value={40}>40</option>
                          <option value={45}>45</option>
                          <option value={50}>50</option>
                          <option value={55}>55</option>
                          <option value={60}>60</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>

              {/* <!-- //-----------------------------------modal footer------------------------------------------//  --> */}

              <Modal.Footer
                className="d-flex justify-content-between p-3"
                style={{
                  direction: lang === "en" ? "ltr" : "rtl",
                }}
              >
                <div className={styles.closeIcon}>
                  <button
                    className=" rounded-pill px-3 py-1"
                    onClick={handleSubmit}
                  >
                    {lang === "en" ? "Send" : "إرسال"}
                  </button>
                </div>
                <div className={styles.closeIcon}>
                  <button
                    className=" rounded-pill px-3 py-1"
                    onClick={handleClose}
                  >
                    {lang === "en" ? "Close" : "إلغاء"}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </HOC>
  );
}
export default ShiftsPage;
