import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HOC from "../../../components/HOC/HOC";

import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import AssginBreak from "../../../components/singleShift/assing_model/assginUserBreakModel";
import { useParams } from "react-router";
import { useGetBreakQuery } from "../../../redux/features/breaksApis";
import ErrorScreen from "../../errorScreen/ErrorScreen";
import Spinner from "../../spinner/Spinner";
import { imageUrl } from "../../../App";
import { useGetMeQuery } from "../../../redux/features/userApi";
import { useTheme } from "@emotion/react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import UnAssignBreak from "../../../components/singleShift/assing_model/unassignUserBreakModel";

const SingleBrakPage = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const [skipeId, setSkipeId] = useState(skipToken);
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const { IdBranch, id: IdShift, IdBreak } = useParams();

  const {
    data: breakData,
    isSuccess: breakSuccess,
    isLoading: breakLoading,
    isError: breakError,
  } = useGetBreakQuery(skipeId);
  let { data: DataMe, isSuccess: success } = useGetMeQuery();
  const { cardTheme } = useTheme();

  const deleteRecivedRequest = () => toast.success("Deleted");

  useEffect(() => {
    setSkipeId({ shiftId: IdShift, breakId: IdBreak });
  }, [IdShift, IdBreak, breakSuccess]);
  return (
    <HOC>
      <Box>
        {breakError ? (
          <ErrorScreen
            data={
              lang == "en"
                ? "Break is Not Found"
                : "لم يتم العثور على عمل استراحة"
            }
          />
        ) : breakLoading ? (
          <Spinner />
        ) : breakSuccess ? (
          <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
            <Box
              sx={{
                width: "97%",
                margin: "0 auto",
                px: 1,
                pt: 1,
                backgroundColor: chartBGColor,
                position: "relative",
                borderRadius: "15px",
              }}
            >
              {success && DataMe?.data?.role !== "employee" && (
                <>{/* <EditBreak mode={mode} lang={lang} data={data} /> */}</>
              )}
              <div className="p-2 w-100">
                <div
                  className="d-sm-flex d-block align-items-center gap-3"
                  style={{
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {/**name of shift */}
                  <div className="d-flex flex-column">
                    <h4
                      className={mode === "light" ? "text-dark" : "text-white"}
                    >
                      {breakData?.data.shift?.name?.toUpperCase()}
                    </h4>
                  </div>
                </div>
                {!breakData?.data.isOpen ? (
                  <Grid container sx={{ mx: 0, px: 0 }}>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} mt="25px">
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        sx={{
                          mt: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            fontSize: 17,
                            fontWeight: "600",
                          }}
                        >
                          {lang === "en" ? "From" : "من"}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        sx={{
                          mt: "10px",
                        }}
                      >
                        <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                        <Typography
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                          }}
                        >
                          {breakData?.data?.start?.hours +
                            ":" +
                            breakData?.data?.start?.mins}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} mt="25px">
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        sx={{
                          mt: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            fontSize: 17,
                            fontWeight: "600",
                          }}
                        >
                          {lang === "en" ? "To" : "الي"}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        sx={{
                          mt: "10px",
                        }}
                      >
                        <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                        <Typography
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                          }}
                        >
                          {breakData?.data?.end.hours +
                            ":" +
                            breakData?.data?.end.mins}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container sx={{ mx: 0, px: 0 }}>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      mt="25px"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        sx={{
                          mt: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            fontSize: 17,
                            fontWeight: "600",
                          }}
                        >
                          {lang === "en" ? "Flexible  Break" : "استراحة مرنة"}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        sx={{
                          mt: "10px",
                        }}
                      >
                        <CalendarMonthIcon sx={{ color: "#FA6796" }} />

                        <Typography
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                          }}
                        >
                          {breakData?.data.duration.hours +
                            ":" +
                            breakData?.data.duration.mins}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                )}

                <AvatarGroup
                  sx={{
                    justifyContent: "flex-end",
                    my: "20px",
                  }}
                >
                  {breakData?.data?.users &&
                    breakData?.data?.users.map((emp) => (
                      <Avatar
                        alt="Remy Sharp"
                        src={`${imageUrl}${emp?.image}`}
                      />
                    ))}
                </AvatarGroup>
              </div>
              <Box
                sx={{
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "block",
                    xs: "block",
                  },
                  justifyContent: "flex-start",
                  alignItems: "center",
                  p: 4,
                  pl: lang === "en" ? 1 : undefined,
                  pr: lang === "ar" ? 1 : undefined,
                }}
              >
                <AssginBreak lang={lang} mode={mode} />
                <Typography
                  sx={{
                    fontSize: "17px",
                    mx: {
                      xl: 2,
                      sm: 1,
                    },
                    my: {
                      xl: 0,
                      lg: 0,
                      md: 0,
                      sm: 1.5,
                      xs: 1.5,
                    },
                    fontWeight: "bolder",
                    color: mode === "dark" ? "#fff" : undefined,
                  }}
                >
                  {lang === "en" ? "Or" : "أو"}
                </Typography>
                <UnAssignBreak
                  lang={lang}
                  mode={mode}
                  users={{ data: breakData?.data?.users }}
                />
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Box>
    </HOC>
  );
};

export default SingleBrakPage;
