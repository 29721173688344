import React from "react";
import TestIcon from "./images/TestIcon.png";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import { useGetEmployeesByCompanyQuery } from "../../../redux/features/employeeApi";
import { imageUrl } from "../../../App";
import { Link } from "react-router-dom";
import { Avatar, Box } from "@mui/material";
export default function HomeBranch({ lang, mode, chartBGColor }) {
  const { data, isSuccess } = useGetBranchesQuery();
  let { data: employess, isSuccess: successEmp } =
    useGetEmployeesByCompanyQuery();
  return (
    <div
      className="p-sm-5 mb-3 p-3   "
      style={{
        backgroundColor: chartBGColor,
        borderRadius: "20px",
      }}
    >
      <h5
        className={`fw-bolder ${mode === "light" ? "text-dark" : "text-white"}`}
      >
        {lang === "en" ? "Branches" : "الفروع"}
      </h5>
      <div className="mt-3">
        {isSuccess &&
          data?.data.slice(0, 3).map((data, brIndex) => (
            <div key={brIndex} className={brIndex !== 0 ? "mt-3" : undefined}>
              <Link
                className={`my-3 mx-4 fw-bold  ${
                  mode === "light" ? "text-dark" : "text-white"
                }`}
                to={`/branches/${data._id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                {data.name.toUpperCase()}
              </Link>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  borderRadius: "10px",
                  border: `1px solid  ${
                    mode === "light" ? "#F2F8FD" : "#252525"
                  }`,
                  marginTop: "4px",
                }}
              >
                {successEmp ? (
                  employess?.data?.length > 0 ? (
                    employess?.data?.map((employee, index) => {
                      return (
                        data?._id === employee?.branch?._id && (
                          <Box
                            key={index}
                            sx={{
                              width: {
                                xl: "90px",
                                lg: "90px",
                                md: "90px",
                                sm: 0.5,
                                xs: 0.5,
                              },
                              py: 2,
                            }}
                          >
                            <Avatar
                              src={imageUrl + employee.image}
                              sx={{
                                mx: "auto",
                              }}
                            />
                            <span
                              className="text-center d-block m-0 p-0 fw-bold w-100"
                              style={{
                                color: "#7f7f7f",
                              }}
                            >
                              {employee.fullName_en}
                            </span>
                          </Box>
                        )
                      );
                    })
                  ) : (
                    <h2 className={mode === "dark" ? "#fff" : undefined}>
                      {lang === "en"
                        ? "Not Departments yet"
                        : "لا يوجد اقسام حتي الان"}
                    </h2>
                  )
                ) : undefined}
              </Box>
            </div>
          ))}
      </div>
    </div>
  );
}
