import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const uploadsApi = createApi({
    reducerPath: 'uploads',
    tagTypes: ['Upload'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1',
    }),
    endpoints: (builder) => ({
        uploadImage: builder.mutation({
            query: (formData) => ({
                url: '/upload',
                method: 'POST',
                body: formData
            })
        })
    })
})

export const { useUploadImageMutation } = uploadsApi