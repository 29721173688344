import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const documentApi = createApi({
    reducerPath: 'document',
    tagTypes: ['Document'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
        prepareHeaders: (headers) => {
            return headers.set('Authentication', localStorage.getItem('userToken'))
        },

    }),
    endpoints: (builder) => ({
        createDocument: builder.mutation({
            query: (path) => ({
                url: `/document`,
                body: { path },
                method: 'POST'
            }),
            invalidatesTags: ['Document']
        }),
        getUserDocuments: builder.query({
            query: () => `/document`,
            providesTags: ['Document']
        }),
        deleteDocument: builder.mutation({
            query: (id) => ({
                url: `/document/${id}`,
                method: 'DELETE',

            }),
            invalidatesTags: ['Document']
        })
    })
})


export const { useCreateDocumentMutation, useGetUserDocumentsQuery, useDeleteDocumentMutation } = documentApi