import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../App'
export const empolyeeApi = createApi({
    reducerPath: 'employee',
    tagTypes: ['Employee', 'Alert', 'Like', "Notification", "Overtime", "Payrol", "Task", "Document", "Break", "Request", "Blog", "Attend", "Contract"],

    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
        prepareHeaders: (headers) => {
            headers.set('Authentication', localStorage.getItem('userToken'))
            return headers
        }
    }),
    endpoints: (builder) => ({
        getEmployees: builder.query({
            query: () => `user/employees/getAllByRole`,
            providesTags: ['Employee']
        }),
        getEmployeesByCompany: builder.query({
            query: (companyId) => `user/employees/company/${localStorage.getItem('companyId')}`,
            providesTags: ['Employee']
        }),
        getAllEmpsBYBranchAndCompany: builder.query({
            query: (query) => `user/employees/getAllWithBranchAndDepartment/${localStorage.getItem('companyId')}${query ? query : ''}`,
            providesTags: ['Employee']
        }),
        getEmployeesByDepartment: builder.query({
            query: (department) => `user/employees/department/${department}`,
            providesTags: ['Employee']
        }),
        getEmployeesByBranch: builder.query({
            query: (branchId) => `user/employees/branch/${branchId}`,
            providesTags: ['Employee']

        }),
        getEmployeesByShift: builder.query({
            query: (shift) => `user/employees/shift/${shift}`,
            providesTags: ['Employee']

        }),
        getEmployee: builder.query({
            query: (id) => `user/employee/${id}`,
            providesTags: ['Employee']
        }),
        createEmployee: builder.mutation({
            query: (employeeData) => ({
                url: `user/employees/`,
                method: 'POST',
                body: employeeData,
            }),
            invalidatesTags: ['Employee']
        }),
        updateEmployee: builder.mutation({
            query: (employeeData) => ({
                url: `user/employee/${employeeData._id}`,
                method: `PUT`,
                body: employeeData
            }),
            invalidatesTags: ['Employee']
        }),

        deleteEmployee: builder.mutation({
            query: (id) => ({
                url: `user/employee/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Employee', 'Alert', 'Like', "Notification", "Overtime", "Payrol", "Task", "Document", "Break", "Request", "Blog", "Attend", "Contract"]
        }),

    })
})

export const {
    useGetEmployeeQuery,
    useGetEmployeesQuery,
    useCreateEmployeeMutation,
    useGetEmployeesByCompanyQuery,
    useGetEmployeesByDepartmentQuery,
    useGetEmployeesByBranchQuery,
    useGetAllEmpsBYBranchAndCompanyQuery,
    useDeleteEmployeeMutation,
    useGetEmployeesByShiftQuery


} = empolyeeApi