import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  useDeleteOverTimeMutation,
  useGetAllOverTimeQuery,
} from "../../redux/features/overTimeApis";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Spinner } from "react-bootstrap";
import { useTheme } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { imageUrl } from "../../App";
import dayjs from "dayjs";
import AddShiftOvertimeModal from "./add_modals/AddShiftOvertimeModal";
import { useGetMeQuery } from "../../redux/features/userApi";
import EditOverTime from "./edit_models/editOverTime";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export default function ShiftOverTime({ mode, lang, shiftId: id }) {
  const { cardTheme } = useTheme();
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const [skipId, setSkipeId] = useState(skipToken);
  const navigate = useNavigate();
  let { data: DataMe, isSuccess: success } = useGetMeQuery();
  const { IdBranch } = useParams();
  let IdShift;
  useEffect(() => {
    if (id) {
      setSkipeId(id);
      IdShift = id;
    }
  }, [IdShift, IdBranch]);
  const { data, isSuccess, isLoading } = useGetAllOverTimeQuery(skipId);

  const [deleteOverTime] = useDeleteOverTimeMutation();
  const handleDeleteOverTime = (overTimeId) => {
    deleteOverTime({ shiftId: id, overTimeId }).then(({ data, error }) => {
      if (data) {
        toast.success(
          lang == "en"
            ? "overTime Deleted Successfully"
            : "تم حذف العمل الإضافي بنجاح"
        );
      } else if (error) {
        toast.error(
          lang == "en"
            ? "Cant Delete The Over time Some Error Happened"
            : "لا يمكن حذف العمل الإضافي حدث خطأ ما"
        );
      }
    });
  };

  return (
    <div
      className="p-sm-4 p-3 pb-5 mt-3"
      style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
    >
      <h3
        className={`text-sm-${
          lang === "en" ? "start" : "end"
        } text-center my-5 px-0  ${
          mode === "light" ? "text-dark" : "text-white"
        }`}
      >
        {lang === "en" ? "over time" : "اوقات اضافية"}
      </h3>
      <Grid container sx={{ mx: 0, px: 0 }}>
        {isLoading ? (
          <Spinner />
        ) : !isSuccess ? null : (
          data?.data?.map((data, index) => (
            <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
              <Box
                key={index}
                sx={{
                  width: {
                    xl: "92.5%",
                    lg: "92.5%",
                    md: "92.5%",
                    sm: "100%",
                    xs: "100%",
                  },
                  margin: "0 auto",
                  px: 1,
                  pt: 1,
                  backgroundColor: cardTheme[`bg_${mode}`],
                  position: "relative",
                  borderRadius: "15px",
                  height: "240px",
                }}
              >
                {success && DataMe?.data?.role !== "employee" && (
                  <>
                    <Button
                      sx={{
                        position: "absolute",
                        top: "5px",
                        right: lang === "en" ? "15px" : undefined,
                        left: lang === "ar" ? "15px" : undefined,
                        minWidth: 0,
                        width: 0,
                      }}
                      onClick={() => handleDeleteOverTime(data?._id)}
                    >
                      <HighlightOffIcon sx={{ color: "#E04449" }} />
                    </Button>
                    <EditOverTime mode={mode} lang={lang} data={data} />
                  </>
                )}
                <div className="p-2 w-100 ">
                  <div
                    className="d-sm-flex d-block align-items-center gap-3"
                    style={{
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                  >
                    {/**name of shift */}
                    <div className="d-flex flex-column">
                      <h4
                        className={
                          mode === "light" ? "text-dark" : "text-white"
                        }
                      >
                        {data?.shift?.name?.toUpperCase()}
                      </h4>
                    </div>
                  </div>
                  {/* old date */}
                  {/* <Grid container sx={{ mx: 0, px: 0 }}>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    mt="25px"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                          fontSize: 17,
                          fontWeight: "600",
                        }}
                      >
                        {lang === "en" ? "From" : "من"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                        }}
                      >
                        {dayjs(data?.start).format("DD MMM YY H:m")}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    mt="25px"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                          fontSize: 17,
                          fontWeight: "600",
                        }}
                      >
                        {lang === "en" ? "To" : "الي"}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      sx={{
                        mt: "10px",
                      }}
                    >
                      <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                      <Typography
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                        }}
                      >
                        {dayjs(data?.end).format("DD MMM YY H:m")}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid> */}
                  {/* new date  */}
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="10px"
                    marginTop="25px"
                  >
                    <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                    <Typography
                      sx={{ color: mode === "light" ? "#000" : "#fff" }}
                    >
                      {dayjs(data?.start).format("DD MMM YY H:m")}
                      {" - "}
                      {dayjs(data?.end).format("DD MMM YY H:m")}
                    </Typography>
                  </Stack>
                  <AvatarGroup
                    sx={{
                      justifyContent: "flex-end",
                      mt: "20px",
                    }}
                  >
                    {data?.users &&
                      data?.users
                        .slice(0, 5)
                        .map((emp) => (
                          <Avatar
                            alt="Remy Sharp"
                            src={`${imageUrl}${emp?.image}`}
                          />
                        ))}
                  </AvatarGroup>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{
                      mb: "20px",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: 1,
                      px: 2,
                    }}
                  >
                    {success && DataMe?.data?.role !== "employee" && (
                      <>
                        <Button
                          sx={{
                            backgroundColor: "#0077B6",
                            color: "#fff",
                            paddingY: 0.5,
                            marginX: 1,
                            "&:hover": {
                              backgroundColor: "#0077B6",
                              color: "#fff",
                            },
                          }}
                          onClick={() => navigate(`overtime/${data?._id}`)}
                        >
                          {lang === "en" ? "open" : "فتح"}
                        </Button>
                      </>
                    )}
                  </Stack>
                </div>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
      {success && DataMe?.data?.role !== "employee" && (
        <Box mt="40px">
          <AddShiftOvertimeModal lang={lang} mode={mode} />
        </Box>
      )}
    </div>
  );
}
