import styles from "./Request.module.css";

import React, { useEffect } from "react";

import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router";
import AddCategoryModal from "./mui/AddCategory";
import AddRequestModal from "./mui/AddRequestModal";

import {
  useDeleteRequestMutation,
  useGetRequsetsReciQuery,
  useGetRequsetsSendQuery,
  useUpdateRequestMutation,
} from "../../../redux/features/requestsApis";
import { Spinner } from "react-bootstrap";
import { imageUrl } from "../../../App";

import { useGetMeQuery } from "../../../redux/features/userApi";
import {
  useDeleteCategoryMutation,
  useGetCatgeoriesQuery,
} from "../../../redux/features/categoryApis";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ErrorScreen from "../../errorScreen/ErrorScreen";
const RequestsPage = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";

  const { cardTheme } = useTheme();
  const navigate = useNavigate();

  //GET REQUSTES SEND
  let {
    data: requsetsSend,
    isSuccess: requsetsSuccess,
    isLoading: requsetsLoading,
    error: errorRequset,
  } = useGetRequsetsSendQuery();
  //GET REQUSTES RECIVED
  let {
    data: requsetsReci,
    isSuccess: ReciSuccess,
    isLoading: ReciLoading,
    isError: recError,
  } = useGetRequsetsReciQuery();
  //UPDATE REQUEST STATUS
  const [updateRequest] = useUpdateRequestMutation();
  // ME INFO
  let { data: dataMe, isSuccess: successMe } = useGetMeQuery();
  //CATEGORY STORE
  let {
    data: dataCategories,
    isSuccess: successCategory,
    isError: categoryError,
  } = useGetCatgeoriesQuery();
  const updateStatus = (id, status) => {
    let data = {
      status: status,
      _id: id,
    };
    updateRequest(data).then((res) => {
      if (res.error) {
        res.error.data.error_en
          ? toast.error(res.error.data.error_en)
          : toast.error(res.error.data);
      } else {
        toast.success(res.data.message_en);
      }
    });
  };
  const [deleteRequest, { isError: reqError }] = useDeleteRequestMutation();
  const handleDeleteRequest = (id) => {
    deleteRequest(id).then(({ data }) => {
      if (data) {
        toast.success(
          lang == "en" ? "Request Deleted Successfully" : "تم حذف الطلب بنجاح"
        );
      }
    });
  };
  const [deleteCategory, { isError: catError }] = useDeleteCategoryMutation();
  const handleDeleteCategory = (id) => {
    deleteCategory(id).then(({ data, error }) => {
      if (data) {
        toast.success(
          lang == "en"
            ? "Category is Deleted Successfully"
            : "تم حذف الفئة بنجاح"
        );
      }
    });
  };

  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();
  useEffect(() => {
    if (catError) {
      toast.error(lang == "en" ? "Cant Delete Category" : "لا يمكن حذف الفئة");
    }
    if (reqError) {
      toast.error(lang == "en" ? "Cant Delete Request" : "لا يمكن حذف الطلب");
    }
  }, [catError, reqError]);
  const showDeleteIconBasedOnTheRole = (req) => {
    if (userSuccess) {
      if (userData?.data?.role == "admin" || userData?.data?.role == "root") {
        return true;
      } else if (userData?.data?._id === req?.from) {
        return true;
      }
    }
    return false;
  };

  return (
    <HOC>
      <div>
        <div className="p-3">
          {/* Categories */}
          {successMe && dataMe.data.role !== "employee" && (
            <div
              className=" pb-5"
              style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
            >
              <h1
                className={`text-center pt-4 mb-5 ${
                  mode === "light" ? "text-dark" : "text-white"
                }`}
              >
                {lang === "en" ? "Categories" : "الفئات"}
              </h1>

              <Grid container /* className={`row g-4 mt-3  `} */>
                {categoryError ? (
                  <ErrorScreen
                    data={
                      lang == "en" ? "Category not Found" : "الفئات غير موجودة"
                    }
                  />
                ) : successCategory ? (
                  dataCategories?.categories?.map((_, index) => (
                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12} mt="25px">
                      <Box
                        key={index}
                        sx={{
                          width: {
                            xl: "92.5%",
                            lg: "92.5%",
                            md: "92.5%",
                            sm: "94%",
                            xs: "94%",
                          },
                          margin: "0 auto",
                        }}
                      >
                        <div
                          className={` ${styles.columContent} position-relative`}
                          style={{
                            backgroundColor: cardTheme[`bg_${mode}`],
                            height: "130px",
                          }}
                        >
                          {localStorage.getItem("companyId") === _.company && (
                            <Button
                              sx={{
                                position: "absolute",
                                top: "5px",
                                right: lang === "en" ? "15px" : undefined,
                                left: lang === "ar" ? "15px" : undefined,
                                minWidth: 0,
                                width: 0,
                              }}
                              onClick={() => handleDeleteCategory(_?._id)}
                            >
                              <HighlightOffIcon sx={{ color: "#E04449" }} />
                            </Button>
                          )}
                          <div className="p-2 w-100">
                            <div
                              className="d-flex  align-items-center gap-3"
                              style={{
                                color: mode === "light" ? "#000" : "#fff",
                              }}
                            >
                              <div className="d-flex flex-column">
                                <h4
                                  className={
                                    mode === "light"
                                      ? "text-dark"
                                      : "text-white"
                                  }
                                >
                                  {_.categoryType}
                                </h4>
                              </div>
                            </div>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              marginY={"20px"}
                              sx={{
                                position: "absolute",
                                bottom: 0,
                              }}
                            >
                              <Button
                                sx={{
                                  backgroundColor: "#0077B6",
                                  color: "#fff",
                                  paddingY: 0.5,
                                  marginX: 1,
                                  "&:hover": {
                                    backgroundColor: "#0077B6",
                                    color: "#fff",
                                  },
                                }}
                                onClick={() =>
                                  navigate(`/subCategory/${_._id}`)
                                }
                              >
                                {lang === "en" ? "open" : "فتح"}
                              </Button>
                            </Stack>
                          </div>
                        </div>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Spinner />
                )}
              </Grid>
              <AddCategoryModal mode={mode} lang={lang} />
            </div>
          )}
          {/* Sent */}
          {successMe && dataMe.data.role !== "root" && (
            <div
              className="p-4 pb-5"
              style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
            >
              <h1
                className={`text-center mt-3 mb-5 ${
                  mode === "light" ? "text-dark" : "text-white"
                }`}
              >
                {lang === "en" ? "Sent Requests" : "الطلبات المرسلة"}
              </h1>
              <Grid container className={`row g-4 mt-3  `}>
                {requsetsLoading ? (
                  <Spinner />
                ) : requsetsSuccess ? (
                  requsetsSend.requests?.map((req, index) => (
                    <Grid
                      item
                      key={index}
                      xl={3}
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ pb: "21px" }}
                    >
                      <div
                        className={` ${styles.columContent} position-relative`}
                        style={{
                          backgroundColor: cardTheme[`bg_${mode}`],
                          height: "300px",
                        }}
                      >
                        <Button
                          sx={{
                            position: "absolute",
                            top: "3px",
                            right: lang === "en" ? "10px" : undefined,
                            left: lang === "ar" ? "10px" : undefined,
                            minWidth: 0,
                            width: 0,
                          }}
                          onClick={() => handleDeleteRequest(req?._id)}
                        >
                          <DeleteIcon sx={{ color: "#E04449" }} />
                        </Button>
                        <div className="p-2 w-100 ">
                          <div
                            className="d-sm-flex d-block mt-3"
                            style={{
                              color: mode === "light" ? "#000" : "#fff",
                            }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={`${imageUrl}${req?.from?.image}`}
                              sx={{
                                height: "70px",
                                width: "70px",
                              }}
                            />
                            <Box
                              style={{
                                width: "76%",
                                padding: "0 20px",
                                marginTop: {
                                  xl: 0,
                                  lg: 0,
                                  md: 5,
                                  sm: 4,
                                  xs: 4,
                                },
                              }}
                            >
                              <h5
                                className={
                                  "" + mode === "light"
                                    ? "text-dark"
                                    : "text-white"
                                }
                                style={{
                                  wordBreak: "break-word",
                                }}
                                title={req.title}
                              ></h5>
                              <p title={req.description}>
                                {" "}
                                {req.description.slice(0, 15)}
                                {req.description.length > 15 ? "..." : null}
                              </p>
                            </Box>
                          </div>
                          {/* <div className=" d-flex  my-3 justify-content-end">
                            <div
                              className="py-2 d-flex my-3 align-items-start "
                              style={{
                                backgroundColor:
                                  mode === "light" ? "#E0E0E0" : "#3E3E42",
                                marginLeft: "auto",
                                borderRadius: "15px",
                                padding: "0 10px",
                              }}
                            ></div>
                          </div> */}
                          {req.endDate ? (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="10px"
                              sx={{
                                mt: "30px",
                              }}
                            >
                              <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                              <Typography
                                sx={{
                                  color: mode === "light" ? "#000" : "#fff",
                                }}
                              >
                                {new Intl.DateTimeFormat("en-GB").format(
                                  new Date(req.endDate)
                                )}{" "}
                                -{" "}
                                {new Intl.DateTimeFormat("en-GB").format(
                                  new Date(req.startDate)
                                )}
                              </Typography>
                            </Stack>
                          ) : lang == "en" ? (
                            "Free"
                          ) : (
                            "بدون وقت"
                          )}{" "}
                          <Box
                            sx={{
                              my: "20px",
                              display: "flex",
                              justifyContent: "space-between",
                              position: "absolute",
                              bottom: 0,
                              width: 0.96,
                              left: "50%",
                              transform: "translateX(-50%)",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={"1px"}
                            >
                              {req.status ? (
                                <Button
                                  sx={{
                                    minWidth: 0,
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <CheckCircleOutlineIcon
                                    sx={{ color: "#00635D", fontSize: "27px" }}
                                  />
                                </Button>
                              ) : (
                                <Button
                                  sx={{
                                    height: 0,
                                    minWidth: 0,
                                    padding: 0,
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <HighlightOffIcon
                                    sx={{ color: "#B12727", fontSize: "27px" }}
                                  />
                                </Button>
                              )}
                            </Stack>
                            <Button
                              sx={{
                                backgroundColor: "#0077B6",
                                color: "#fff",
                                paddingY: 0.5,
                                marginX: 1,
                                "&:hover": {
                                  backgroundColor: "#0077B6",
                                  color: "#fff",
                                },
                              }}
                              onClick={() => navigate(`/alerts/${req._id}`)}
                            >
                              {lang === "en" ? "open" : "فتح"}
                            </Button>
                          </Box>
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : null}
              </Grid>
              <AddRequestModal lang={lang} mode={mode} />
            </div>
          )}
          {/* Recived */}
          <div
            className="p-4 pb-5 mt-4"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className={`text-center mt-3 mb-5 ${
                mode === "light" ? "text-dark" : "text-white"
              }`}
            >
              {lang === "en" ? "Recived Requests" : "الطلبات المستلمة"}
            </h1>

            <Grid container sx={{ mx: 0, px: 0 }}>
              {ReciLoading ? (
                <Spinner />
              ) : ReciSuccess ? (
                requsetsReci.requests.map((req, index) => (
                  <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
                    <Box
                      key={index}
                      sx={{
                        width: {
                          xl: "92.5%",
                          lg: "92.5%",
                          md: "92.5%",
                          sm: "100%",
                          xs: "100%",
                        },
                        margin: "0 auto",
                      }}
                    >
                      <div
                        className={` ${styles.columContent} position-relative`}
                        style={{
                          backgroundColor: cardTheme[`bg_${mode}`],
                        }}
                      >
                        {showDeleteIconBasedOnTheRole() ? (
                          <Button
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: lang === "en" ? "15px" : undefined,
                              left: lang === "ar" ? "15px" : undefined,
                              minWidth: 0,
                              width: 0,
                            }}
                            onClick={() => handleDeleteRequest(req?._id)}
                          >
                            <HighlightOffIcon sx={{ color: "#E04449" }} />
                          </Button>
                        ) : null}
                        <div className="p-2 w-100 ">
                          <div
                            className="d-sm-flex d-block align-items-center gap-3 "
                            style={{
                              color: mode === "light" ? "#000" : "#fff",
                            }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={`${imageUrl}${req.from.image}`}
                              sx={{
                                height: "70px",
                                width: "70px",
                              }}
                            />
                            <div className="d-flex flex-column">
                              <h4
                                className={
                                  mode === "light" ? "text-dark" : "text-white"
                                }
                                title={req?.title}
                              >
                                {req.title}
                              </h4>

                              <p>
                                {" "}
                                {req.description.slice(0, 15)}
                                {req.description.length > 15 ? "..." : null}
                              </p>
                            </div>
                          </div>
                          {req.endDate ? (
                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="10px"
                              sx={{
                                mt: "10px",
                              }}
                            >
                              <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                              <Typography
                                sx={{
                                  color: mode === "light" ? "#000" : "#fff",
                                }}
                              >
                                {new Intl.DateTimeFormat("en-GB").format(
                                  new Date(req.endDate)
                                )}
                                -{" "}
                                {new Intl.DateTimeFormat("en-GB").format(
                                  new Date(req.startDate)
                                )}
                              </Typography>
                            </Stack>
                          ) : (
                            <Typography>
                              {lang === "en" ? "Free" : "بدون وقت"}
                            </Typography>
                          )}

                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            marginY={"20px"}
                          >
                            <Button
                              sx={{
                                backgroundColor: req.status
                                  ? "#00635D"
                                  : "#766052",
                                color: "#fff",
                                paddingY: 0.5,
                                marginX: 1,
                                cursor: index !== 0 ? "all" : undefined,
                                "&:hover": {
                                  backgroundColor: req.status
                                    ? "#766052"
                                    : "#00635D",
                                  color: "#fff",
                                },
                              }}
                              onClick={() => updateStatus(req._id, !req.status)}
                            >
                              {req.status ? "Accept" : "pending"}
                            </Button>
                            <Button
                              sx={{
                                backgroundColor: "#0077B6",
                                color: "#fff",
                                paddingY: 0.5,
                                marginX: 1,
                                "&:hover": {
                                  backgroundColor: "#0077B6",
                                  color: "#fff",
                                },
                              }}
                              onClick={() => navigate(`/alerts/${req._id}`)}
                            >
                              {lang === "en" ? "open" : "فتح"}
                            </Button>
                          </Stack>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ))
              ) : (
                false
              )}
            </Grid>
          </div>
        </div>
      </div>
    </HOC>
  );
};
export default RequestsPage;
