import React from "react";

function ErrorScreen({ data }) {
  return (
    <div className="alert">
      <p className="lead text-danger fw-bold tex-center">{data}</p>
    </div>
  );
}

export default ErrorScreen;
