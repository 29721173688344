import React from "react";

import { useGetRequsetsReciQuery } from "../../../redux/features/requestsApis";
import dayjs from "dayjs";
import { imageUrl } from "../../../App";
import { Avatar, Box, Grid } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const RequestHome = ({ lang, mode, chartBGColor }) => {
  const { data, isSuccess } = useGetRequsetsReciQuery();
  return (
    <div>
      <Box
        sx={{
          backgroundColor: chartBGColor,
          borderRadius: "25px",
          p: {
            lg: 4,
            md: 2,
            sm: 2,
            xs: 2,
          },
        }}
      >
        <h5
          className={`fw-bolder mb-4 ${
            mode === "light" ? "text-dark" : "text-white"
          }`}
        >
          {lang === "en" ? "Requests" : "الطلبات"}
        </h5>
        <Grid container>
          {/* ==========================================mapping======================================== */}
          {isSuccess &&
            data?.requests?.slice(0, 4).map((data, index) => (
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                key={index}
                pb="20px"
              >
                <div
                  style={{
                    borderRadius: "15px",
                    backgroundColor: mode === "light" ? "#F2F8FD" : "#252525",
                    width: "95%",
                  }}
                  className="px-3 py-4"
                >
                  <div className="d-flex">
                    <div>
                      <Avatar src={imageUrl + data?.from?.image} alt="" />
                    </div>
                    <div className="mx-2">
                      <h5
                        className={`fw-bold ${
                          mode === "light" ? "text-dark" : "text-white"
                        }`}
                      >
                        {data?.from?.fullName_en}
                      </h5>
                      <p
                        className={
                          mode === "light" ? "text-dark" : "text-white"
                        }
                        style={{ fontSize: "14px" }}
                      >
                        {data.description.slice(0, 30)}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="p-2 d-flex align-items-center">
                      <div>
                        <CalendarMonthIcon
                          sx={{
                            color: "#FF2366",
                          }}
                        />
                      </div>
                      <div>
                        <span
                          className={
                            mode === "light" ? "text-dark" : "text-white"
                          }
                        >
                          {" "}
                          {dayjs(`${data?.createdAt}`).format("DD-MM-YYYY:H:m")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </Box>
    </div>
  );
};
export default RequestHome;
