import React from "react";
import { useGetAllContractsQuery } from "../../../redux/features/contractsApi";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";

export default function HomeSalaries({ lang, mode, chartBGColor }) {
  const { data, isSuccess } = useGetAllContractsQuery();
  return (
    <div className="mb-3">
      <Box
        sx={{
          backgroundColor: chartBGColor,
          borderRadius: "25px",
          p: {
            xl: 5,
            lg: 5,
            md: 5,
            sm: 2,
            xs: 2,
          },
        }}
      >
        <h5
          className={`fw-bolder mb-4 ${
            mode === "light" ? "text-dark" : "text-white"
          }`}
        >
          {lang === "en" ? "Salaries" : "الرواتب"}
        </h5>
        <TableContainer
          component={Paper}
          sx={{
            marginTop: "40px",
            backgroundColor: "transparent",
            border: 1,
            borderColor: mode === "light" ? "#F5F5F5" : "#252526",
            borderRadius: "6px",
          }}
        >
          <Table
            sx={{
              borderRadius: "6px",
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: mode === "light" ? "#F2F8FD" : "#252525",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                    fontFamily: "'Baloo Bhaijaan 2', cursive",
                    fontWeight: "bolder",
                  }}
                  align="center"
                >
                  {lang === "en" ? "Name" : " الاسم"}
                </TableCell>
                <TableCell
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                    fontFamily: "'Baloo Bhaijaan 2', cursive",
                    fontWeight: "bolder",
                  }}
                  align="center"
                >
                  {lang === "en" ? "Job" : " الوظيفة"}
                </TableCell>
                <TableCell
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                    fontFamily: "'Baloo Bhaijaan 2', cursive",
                    fontWeight: "bolder",
                  }}
                  align="center"
                >
                  {lang === "en" ? "Salary" : " المرتب"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess &&
                data?.contracts?.map((_, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        mode === "light" ? "transparent" : "#3E3E42",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      sx={{
                        color: mode === "light" ? "#000" : "#fff",
                        border: 0,
                        fontFamily: "'Baloo Bhaijaan 2', cursive",
                      }}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {_.employee[`fullName_${lang}`]}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: mode === "light" ? "#000" : "#fff",
                        border: 0,
                        fontFamily: "'Baloo Bhaijaan 2', cursive",
                      }}
                      align="center"
                    >
                      {_?.employee?.position}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: mode === "light" ? "#000" : "#fff",
                        border: 0,
                        fontFamily: "'Baloo Bhaijaan 2', cursive",
                      }}
                      align="center"
                    >
                      {_.salary}$
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
