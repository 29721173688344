import React, { useEffect, useState } from "react";

import "./newBlog.scss";

// import Comment from "./Comment";
// import Like from "./Like";

import HOC from "../../../components/HOC/HOC";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import Comment from "./Comment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { blogsApi, useAddLikeMutation, useLazyGetBlogByIdQuery } from "../../../redux/features/blogsApi";
import Spinner from "../../spinner/Spinner";
import { imageUrl } from "../../../App";
import { useGetMeQuery } from "../../../redux/features/userApi";
import { toast } from "react-toastify";
import moment from "moment";
import ReduxStore from "../../../redux/store";
function SingleBlogPage() {
  const { blogId } = useParams()

  const [getBlogById, { data: blogData, isSuccess: blogSuccess, isLoading: blogLoading, isError: blogsError }] = useLazyGetBlogByIdQuery()
  const [blog, setBlog] = useState(null)

  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const [like, setLike] = useState(false);
  const [disLike, setDisLike] = useState(false);
  const handleLike = () => {
    setLike(!like);
    if (disLike) setDisLike(false);
  };
  const handleDisLike = () => {
    setDisLike(!disLike);
    if (like) setLike(false);
  };
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const handleTime = (time) => {
    const startDate = moment(new Date())
    const endDate = moment(time)
    const diff = endDate.diff(startDate)
    const duration = moment.duration(diff)
    let timeStr = ``
    time = Math.abs(duration.hours());
    if (lang == 'en') {
      timeStr = time + ' hours ago ';
    }
    if (lang == 'ar') {
      timeStr = "منذ " + time + (time >= 1 && time <= 9 ? " ساعات  " : "ساعة ")
    }
    return timeStr;
  }



  let [addLike] = useAddLikeMutation()
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery()
  const checkUserLikedBefore = (blog, user) => {

    let userLike = blog?.likes?.find(like => like.user == user)
    if (userLike) {
      if (userLike.likeType == 'like') {
        setLike(true)
        setDisLike(false)
      } else if (userLike.likeType == 'disLike') {
        setLike(false)
        setDisLike(true)
      }
    }
  }

  useEffect(() => {
    getBlogById(blogId, false).then(({ data }) => {
      if (blogSuccess && userSuccess) {
        setBlog(_ => data?.blog)
        checkUserLikedBefore(data?.blog, userData?.data._id)

      }
    })

  }, [blogSuccess, userSuccess])
  const handleAddingLike = (likeType) => {
    if (likeType) {
      addLike({ likeType, blogId }).then(({ error, data }) => {
        if (error) {
          toast.error(lang == "en" ? "something Went Wrong While Adding Like To The Blog" : "حدث خطأ ما أثناء إضافة إعجاب إلى المدونة")
        }
        if (data) {

          if (data.toggling) {
            toast.success(lang == 'en' ? "Like Removed Successfully" : "تم حذف الإعجاب")
            setLike(false)
            setDisLike(false)
          }
          else {
            if (likeType == 'like') {
              toast.success(lang == 'en' ? "Like Added Successfully to The Blog" : "تمت إضافة الإعجاب بنجاح إلى المدونة")
              setLike(true)
              setDisLike(false)
            }
            else if (likeType == 'disLike') {
              toast.success(lang == 'en' ? "DisLike Added To The Blog" : "تمت إضافة عدم الإعجاب إلى المدونة")
              setLike(false)
              setDisLike(true)
            }

          }
          setBlog(_ => data?.blog)
        }
      })
    }
  }

  return (
    <HOC>
      <Box
        sx={{
          padding: {
            xl: 5,
            sm: 3,
            xs: 3,
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: chartBGColor,
            width: {
              xl: "70%",
              sm: "100%",
              xs: "100%",
            },
            margin: "0 auto",
            padding: {
              xl: "50px",
              sm: "20px",
              xs: "20px",
            },
            borderRadius: "20px",
          }}
        >
          {
            blogsError ? null :
              blogLoading ?
                <Spinner />
                : (
                  <>
                    {blogSuccess && blog ? <Box>
                      <Avatar
                        src={imageUrl + blogData?.blog?.image}
                        alt="im"
                        sx={{
                          width: "100%",
                          height: "50vh",
                          borderRadius: "10px",
                        }}
                      />

                      <Box my="50px">
                        <Typography
                          variant="h5"
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            fontWeight: "bold",
                          }}
                        >
                          {blog?.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            marginTop: "20px",
                            fontWeight: "bolder",
                          }}
                        >
                          {blog?.description}
                        </Typography>
                      </Box>
                      <Box
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          display: {
                            xl: "flex",
                            sm: "block",
                            xs: "block",
                          },
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          <Avatar
                            src={imageUrl + blog?.user?.image}
                          />
                          <Box paddingX="15px">
                            <Typography
                              variant="h6"
                              fontWeight={"bolder"}
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                                fontSize: "17px",
                              }}
                            >
                              {blog?.user[`fullName_${lang}`]}
                            </Typography>
                            <Typography
                              variant="body"
                              ontSize="small"
                              sx={{
                                margin: 0,
                                color: mode === "light" ? "#444" : "#8B8C89",
                              }}
                            >
                              {handleTime(blog?.createdAt)}
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          gap="10px"
                          sx={{
                            mt: {
                              sm: "20px",
                              xs: "20px",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: mode === "light" ? "#F8F8F8" : "#8B8C89",
                              borderRadius: "50%",
                              cursor: "pointer",
                              height: "30px",
                              width: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => handleAddingLike('like')}
                          >
                            <ThumbUpIcon
                              sx={{
                                fontSize: "20px",
                                color: like
                                  ? "#0057B7"
                                  : mode === "light"
                                    ? "#666"
                                    : "#FFFFFF",
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{ color: mode === "light" ? "#000" : "#fff" }}
                          >
                            {lang === "en" ? "Like" : "أغجبني"}
                          </Typography>{" "}
                          <Box
                            sx={{
                              ml: lang === "en" ? "10px" : undefined,
                              mr: lang !== "en" ? "10px" : undefined,
                              backgroundColor: mode === "light" ? "#F8F8F8" : "#8B8C89",
                              borderRadius: "50%",
                              cursor: "pointer",
                              height: "30px",
                              width: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => handleAddingLike('disLike')}
                          >
                            <ThumbDownAltIcon
                              sx={{
                                fontSize: "20px",
                                color: disLike
                                  ? "#0057B7"
                                  : mode === "light"
                                    ? "#666"
                                    : "#FFFFFF",
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{ color: mode === "light" ? "#000" : "#fff" }}
                          >
                            {lang === "en" ? "dislike" : "لم يعجبني"}
                          </Typography>
                        </Stack>
                      </Box>
                      <Comment
                        lang={lang}
                        mode={mode}
                        currentBlog={blogData?.blog}
                        setCurrentBlog={blogData?.blog}
                        testingComment={blogData?.blog}
                      />
                    </Box> : null}
                  </>
                )}
        </Box>
      </Box>
    </HOC>
  );
}

export default SingleBlogPage;
