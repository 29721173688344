import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { companyId, token } from '../../App'

export const breaksApis = createApi({
  reducerPath: 'breaks',
  tagTypes: ['Break'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getBreaks: builder.query({
      query: (shift) => `break/${shift}`,
      providesTags: ['Break'],
    }),
    getBreak: builder.query({
      query: ({ shiftId, breakId }) => `/break/${shiftId}/${breakId}`,
      providesTags: ['Break']


    }),

    deleteBreak: builder.mutation({
      query: ({ shiftId, breakId }) => ({
        url: `/break/${shiftId}/${breakId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Break']


    }),

    addBreak: builder.mutation({
      query: (data) => ({
        url: `break`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Break'],
    }),
    updateBreak: builder.mutation({
      query: ({ data, shift, id }) => ({
        url: `break/${shift}/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Break'],
    }),
    assginBreak: builder.mutation({
      query: ({ data, shift, id }) => ({
        url: `break/assignUser/${shift}/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Break'],
    }),
    unAssignBreak: builder.mutation({
      query: ({ data, shift, id }) => ({
        url: `break/unassign/${shift}/${id}`,
        method: 'POST',
        body: { to: data },
      }),
      invalidatesTags: ['Break'],
    }),


  }),
})

export const { useGetBreaksQuery, useAddBreakMutation,
  useUpdateBreakMutation,
  useGetBreakQuery,
  useUnAssignBreakMutation,

  useDeleteBreakMutation,

  useAssginBreakMutation } = breaksApis
