import { createSlice } from "@reduxjs/toolkit";
!localStorage.getItem("mode") && localStorage.setItem("mode", "light");
const initialState = {
  value: localStorage.getItem("mode"),
};

export const modeSlice = createSlice({
  name: "Color Mode",
  initialState,
  reducers: {
    darkMode: (state) => {
      localStorage.setItem("mode", "dark");
      state.value = localStorage.getItem("mode");
    },
    lightMode: (state) => {
      localStorage.setItem("mode", "light");
      state.value = localStorage.getItem("mode");
    },
  },
});

export const { lightMode, darkMode } = modeSlice.actions;

export default modeSlice.reducer;
