import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, Stack, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import EmployeeMenu from "./EmployeeMenu";
import DepartmentMenu from "./DepartmentMenu";
import BranchMenu from "./BranchMenu";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetCatgeoriesQuery } from "../../../../redux/features/categoryApis";
import {
  useGetsubCatgeoriesQuery,
  useGetsubCatgeoryByIdQuery,
} from "../../../../redux/features/subCategoryApis";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// import { useGetEmployeesByDepartmentQuery } from "../../../../redux/features/employeeApi";
// import { useGetBranchesQuery } from "../../../../redux/features/branchesApi";
// import { useCreateAlertMutation } from "../../../../redux/features/alertsApis";
// import { useGetDepartmentsQuery } from "../../../../redux/features/departmentsApis";
import { useGetMeQuery } from "../../../../redux/features/userApi";
import { useCreateRequestMutation } from "../../../../redux/features/requestsApis";
import DateAndTimePicker from "../../../../components/dateTimePicker/DateTimePicker";
export default function AddRequestModal({ lang, mode }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const { modalTheme } = useTheme();
  //Create Request
  const [createRequest, { isError, error: nughtyError }] =
    useCreateRequestMutation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xl: "800px",
      sm: "90%",
      xs: "90%",
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    outline: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    height: "65%",
    overflowY: "scroll",
    direction: lang === "en" ? "ltr" : "rtl",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-track": {
      background: mode === "light" ? "#f1f1f1" : "#555",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: mode === "light" ? "#aac1fe" : "#252526",
      transition: "0.5s all",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    " &::-webkit-scrollbar-thumb:hover": {
      background: mode === "light" ? "#78a3e4" : "#252526",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },
  };
  const formik = useFormik({
    initialValues: {
      category: skipToken,
      subCategory: skipToken,
      description: "",
      title: "",
      branch: skipToken,
      department: skipToken,
      employee: [],
      start_date: null,
      end_date: null,
      haveTime: false,
    },
    validationSchema: Yup.object({
      category: Yup.string().required(
        lang === "en" ? "Category is requird" : "الفئة مطلوبة"
      ),
      subCategory: Yup.string().required(
        lang === "en" ? "Sub Category is requird" : "الفئة الفرعية مطلوبة"
      ),
      description: Yup.string().required(
        lang === "en" ? "Description is requird" : "الوصف مطلوب "
      ),
      title: Yup.string().when("subCategory", {
        is: "other",
        then: Yup.string().required(
          lang === "en" ? "Title is requird" : "العنوان مطلوب "
        ),
      }),
      branch: Yup.string().required(
        lang === "en" ? "choose a branch" : "اختار فرع "
      ),
      department: Yup.string().required(
        lang === "en" ? "choose a department" : "اختار قسم "
      ),
      employee: Yup.array().when("role", {
        is: true,
        then: Yup.array().min(1).required("choose"),
      }),
      start_date: Yup.date().when("haveTime", {
        is: true,
        then: Yup.date().required(
          lang === "en" ? "Start date is required" : "تاريخ البدء مطلوب"
        ),
      }),
      end_date: Yup.date().when("haveTime", {
        is: true,
        then: Yup.date().required(
          lang === "en" ? "End date is required" : "تاريخ الانتهاء مطلوب"
        ),
      }),
      haveTime: Yup.boolean(),
      role: Yup.string(),
    }),
    onSubmit: () => {
      const data = {
        description: values.description,
        subCategory: values.subCategory,
        startDate: values.start_date ? values.start_date : undefined,
        endDate: values.end_date ? values.end_date : undefined,
        ids: values.employee,
        title: values.title,
      };


      values.subCategory === "other"
        ? delete data.subCategory
        : delete data.title;

      data.startDate === null && delete data.startDate;
      data.endDate === null && delete data.endDate;
      createRequest(data).then((res) => {
        if (res.data) {
          toast.success(res.data.message_en);
          handleClose();
        }
        if (res.error) {
          res.error.data[`error_${lang}`]
            ? toast.error(res.error.data[`error_${lang}`])
            : toast.error(res.error.data);
        }
      });
    },
  });
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;
  //CATEGORY STORE
  let { data: dataCategories } = useGetCatgeoriesQuery();
  //SUB CATEGORY STORE
  let { data: dataSubCategories, isSuccess: subCategorySuccess } =
    useGetsubCatgeoriesQuery(values.category);
  //SUB CATEGORY DETAILS
  let { data: dataSubCategoryDetails, isSuccess: subCategoryDetailsSuccess } =
    useGetsubCatgeoryByIdQuery(
      values.subCategory == "other" ? skipToken : values.subCategory
    );
  // ME INFO
  let { data: dataMe } = useGetMeQuery();

  useEffect(() => {
    if (subCategoryDetailsSuccess) {
      values.haveTime = dataSubCategoryDetails?.subCategory?.haveTime;
    }
    if (dataMe) {
      values.role = dataMe?.data.role === "employee" ? false : true;
    }
  }, [dataSubCategoryDetails, subCategoryDetailsSuccess]);
  const handleChangeStartDate = (dateValue) => {
    formik.setValues({
      ...formik.values,
      start_date: dateValue,
    });
  };
  const handleTouchStartDate = () => {
    if (values.start_date === null) {
      formik.setTouched({
        ...formik.touched,
        start_date: true,
      });
    } else {
      delete touched.start_date;
    }
  };
  const handleChangeEndDate = (dateValue) => {
    formik.setValues({
      ...formik.values,
      end_date: dateValue,
    });
  };
  const handleTouchEndDate = () => {
    if (values.end_date === null) {
      formik.setTouched({
        ...formik.touched,
        end_date: true,
      });
    } else {
      delete touched.end_date;
    }
  };

  return (
    <Stack
      sx={{
        marginTop: "40px",
        padding: "0 30px",
      }}
    >
      <Box>
        <Button
          disableRipple
          sx={{
            display: "block",
            backgroundColor: "#0077B6",
            color: "#fff",
            textTransform: "capitalize",

            "&:hover": {
              backgroundColor: "#0077B6",
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === "en" ? "Add" : "إضافة"}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                align="center"
                sx={{
                  color: mode === "light" ? "#000" : "#fff",
                }}
              >
                {lang === "en" ? "Create a new request" : "عمل طلب جديد"}
              </Typography>

              <Box
                sx={{
                  marginTop: "25px",
                  paddingBottom: "20px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Category Name" : "اسم الفئة"}
                </Typography>
                <select
                  style={{
                    outline: 0,
                    width: "100%",
                    backgroundColor: mode === "light" ? "#fff" : "#252526",
                    color: mode === "light" ? "#000" : "#fff",
                    padding: "8px 0",
                    marginTop: "3px",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    border:
                      errors.category && touched.category
                        ? "1px solid red"
                        : "1px solid transparent",
                  }}
                  value={values.category}
                  name="category"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option selected hidden>
                    {lang === "en" ? "select category" : "اختار فئة"}
                  </option>
                  {dataCategories?.categories?.map((_, i) => (
                    <option key={_._id} value={_._id}>
                      {`${_.categoryType}`}
                    </option>
                  ))}
                </select>
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    color: "red",
                    fontWeight: "bolder",
                    fontSize: "12px",
                  }}
                >
                  {errors.category && touched.category && errors.category}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  paddingBottom: "20px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Sub Category" : "فئة فرعية"}
                </Typography>
                <select
                  style={{
                    border:
                      errors.subCategory && touched.subCategory
                        ? "1px solid red"
                        : "1px solid transparent",
                    outline: 0,
                    width: "100%",
                    backgroundColor: mode === "light" ? "#fff" : "#252526",
                    color: mode === "light" ? "#000" : "#fff",
                    padding: "8px 0",
                    marginTop: "3px",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  }}
                  name="subCategory"
                  value={values.subCategory}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option selected hidden>
                    {lang === "en" ? "select sub category" : "اختار فئة فرعية"}
                  </option>
                  {dataSubCategories?.subCategories?.map((_, i) => (
                    <option key={i} value={_._id}>
                      {_.subType}
                    </option>
                  ))}
                  <option key="other" value="other">
                    {lang === "en" ? "other" : "اخري"}
                  </option>
                </select>
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    color: "red",
                    fontWeight: "bolder",
                    fontSize: "12px",
                  }}
                >
                  {errors.subCategory &&
                    touched.subCategory &&
                    errors.subCategory}
                </Typography>
              </Box>
              {/* title */}
              {values.subCategory === "other" && (
                <div>
                  <Box
                    sx={{
                      marginTop: "20px",
                      paddingBottom: "16px",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: lang === "en" ? "left" : "right",
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      {lang === "en" ? "Title" : "العنوان"}
                    </Typography>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        padding: "7px",
                        backgroundColor: mode === "light" ? "#fff" : "#252526",
                        color: mode === "light" ? "#000" : "#fff",
                        outline: 0,
                        border:
                          errors.title && touched.title
                            ? "1px solid red"
                            : "1px solid transparent",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "12px",
                      }}
                    >
                      {errors.title && touched.title && errors.title}
                    </Typography>
                  </Box>
                </div>
              )}
              {/* time */}
              {(dataSubCategoryDetails?.subCategory?.haveTime ||
                values.subCategory === "other") && (
                <Box marginTop={"25px"}>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: lang === "en" ? "left" : "right",
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                  >
                    {lang === "en" ? "Time" : "الوقت"}
                  </Typography>
                  <Grid container marginTop="15px" gap={2}>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                      <DateAndTimePicker
                        label={lang === "en" ? "Start Date" : "تاريخ البدء"}
                        value={values.start_date}
                        error={errors.start_date}
                        touched={touched.start_date}
                        handleChange={handleChangeStartDate}
                        handleBlur={handleTouchStartDate}
                      />
                    </Grid>
                    <Grid item xl={4} md={4} sm={12} xs={12}>
                      <DateAndTimePicker
                        label={lang === "en" ? "End Date" : "تاريخ الانتهاء"}
                        value={values.end_date}
                        error={errors.end_date}
                        touched={touched.end_date}
                        handleChange={handleChangeEndDate}
                        handleBlur={handleTouchEndDate}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Box
                sx={{
                  marginTop: "20px",
                  paddingBottom: "16px",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "description" : "الوصف"}
                </Typography>
                <textarea
                  type="text"
                  style={{
                    width: "100%",
                    padding: "7px",
                    backgroundColor: mode === "light" ? "#fff" : "#252526",
                    color: mode === "light" ? "#000" : "#fff",
                    outline: 0,
                    border:
                      errors.description && touched.description
                        ? "1px solid red"
                        : "1px solid transparent",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    height: "125px",
                  }}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    color: "red",
                    fontWeight: "bolder",
                    fontSize: "12px",
                  }}
                >
                  {errors.description &&
                    touched.description &&
                    errors.description}
                </Typography>
              </Box>

              {dataMe?.data?.role !== "employee" && (
                <Box>
                  <Grid container marginTop="15px" gap="20px 0">
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: lang === "en" ? "left" : "right",
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      {lang === "en" ? "Assign to" : "تعيين إلي"}
                    </Typography>
                    <Grid container marginTop="15px" gap="20px 0">
                      <Grid item xl={4} md={6} sm={12} xs={12}>
                        <BranchMenu
                          lang={lang}
                          mode={mode}
                          valuesBranch={values.branch}
                          setValues={setValues}
                          error={errors.branch}
                          touched={touched.branch}
                        />
                      </Grid>
                      <Grid item xl={4} md={6} sm={12} xs={12}>
                        <DepartmentMenu
                          lang={lang}
                          mode={mode}
                          setValues={setValues}
                          error={errors.department}
                          touched={touched.department}
                          valuesDepartment={values.department}
                          branch={values.branch}
                        />
                      </Grid>

                      <Grid item xl={4} md={6} sm={12} xs={12}>
                        <EmployeeMenu
                          lang={lang}
                          mode={mode}
                          setValues={setValues}
                          error={errors.employee}
                          touched={touched.employee}
                          department={values.department}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xl={4} md={6} sm={12} xs={12}>
                      <DepartmentMenu
                        lang={lang}
                        mode={mode}
                        setValues={setValues}
                        error={errors.department}
                        touched={touched.department}
                        valuesDepartment={values.department}
                        branch={values.branch}
                      />
                    </Grid>

                    <Grid item xl={4} md={6} sm={12} xs={12}>
                      <EmployeeMenu
                        lang={lang}
                        mode={mode}
                        setValues={setValues}
                        error={errors.employee}
                        touched={touched.employee}
                        department={values.department}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Stack
                marginTop="90px"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  type="submit"
                >
                  {lang === "en" ? "Create" : "اضافة"}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  type="button"
                  onClick={handleClose}
                >
                  {lang === "en" ? "Close" : "إلغاء"}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  );
}
