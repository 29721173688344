import { Avatar, Box, Button, Stack, useTheme } from "@mui/material";

import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import { imageUrl } from "../../../App";
import { useGetAlertsCompanyQuery } from "../../../redux/features/alertsApis";
import { useGetMeQuery } from "../../../redux/features/userApi";

export default function HomeAlerts({ chartBGColor, lang, mode }) {
  let { data: dataMe, isSuccess: successMe } = useGetMeQuery();
  const { cardTheme } = useTheme();
  const navigate = useNavigate();
  let {
    data: alertsData,
    isError,
    isSuccess: alertsSuccess,
  } = useGetAlertsCompanyQuery();
  useEffect(() => {
    if (alertsSuccess) {
    }
  }, [alertsSuccess]);
  return (
    <div className="mb-3">
      <Box
        sx={{
          backgroundColor: chartBGColor,
          borderRadius: "25px",
          p: {
            xl: 5,
            lg: 5,
            md: 5,
            sm: 2,
            xs: 2,
          },
        }}
      >
        <h5
          className={`fw-bolder mb-3 fw-bolder ${
            mode === "light" ? "text-dark" : "text-white"
          }`}
        >
          {lang === "en" ? "Alerts" : "التعاميم"}
        </h5>
        {/* {isError
          ? null
          : alertsSuccess
          ? [...alertsData?.alerts].map((alert, index) => (
              <div className="mt-4" key={index}>
                <div>
                  <h6 className={mode === "light" ? "text-dark" : "text-white"}>
                    {alert?.title}
                  </h6>
                  <p className={mode === "light" ? "text-dark" : "text-white"}>
                    {alert?.description.slice(0, 25) + "..."}
                  </p>
                </div>

                <div className="d-flex">
                  <div>
                    <div className="d-flex align-items-center">
                      <div style={{ width: "10%" }}>
                        <img
                          src={
                            imageUrl +
                            `${
                              successMe && dataMe.data.role === "root"
                                ? alert.to.image
                                : alert.from.image
                            }`
                          }
                          alt=""
                          className="w-50"
                        />
                      </div>
                      <span
                        className={
                          mode === "light" ? "text-dark" : "text-white"
                        }
                      >
                        {moment(alert?.createdAt).format("yyyy-mm-DD")}
                      </span>
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div style={{ width: "10%" }}>
                          <img src={alertIcon} alt="" className="w-50" />
                        </div>
                        <span
                          className={
                            mode === "light" ? "text-dark" : "text-white"
                          }
                        >
                          {moment(alert?.createdAt).format("hh:mm:ss")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <button className="rounded-pill px-3 py-1">Send</button>
                  </div>
                </div>
              </div>
            ))
          : null} */}
        {isError
          ? null
          : alertsSuccess
          ? [...alertsData?.alerts].map((alert, index) => (
              <div key={index} className="mb-3">
                <div
                  className={`p-3 position-relative`}
                  style={{
                    backgroundColor: cardTheme[`bg_${mode}`],
                    borderRadius: "10px",
                    height: "160px",
                  }}
                >
                  <div>
                    <div className="d-flex justify-content-between align-items-center ">
                      <div
                        className="d-flex flex-column mt-2 mx-3  overflow-hidden"
                        style={{
                          width: "75%",
                        }}
                      >
                        <h4
                          className={
                            mode === "light" ? "text-dark" : "text-white"
                          }
                        >
                          {alert.title}
                        </h4>
                        <p
                          // value={alertValue}
                          className={`w-100 ${
                            mode === "light" ? "text-dark" : "text-white"
                          }`}
                        >
                          {alert.description.slice(0, 25)}
                          {alert.description.length > 25 ? "..." : null}
                        </p>
                      </div>
                      {/* <Button
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: lang === "en" ? "15px" : undefined,
                          left: lang === "ar" ? "15px" : undefined,
                          minWidth: 0,
                          width: 0,
                        }}
                        onClick={() => handleDeleteAlert(alert?._id)}
                      >
                        <HighlightOffIcon sx={{ color: "#E04449" }} />
                      </Button> */}
                    </div>
                  </div>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={"20px"}
                    sx={{
                      position: "absolute",
                      bottom: "15px",
                      width: "100%",
                      px: "10px",
                    }}
                  >
                    <Avatar
                      alt={alert.title}
                      src={
                        imageUrl +
                        `${
                          successMe && dataMe.data.role === "root"
                            ? alert.to.image
                            : alert.from.image
                        }`
                      }
                    />
                  </Stack>
                </div>
              </div>
            ))
          : null}
      </Box>
    </div>
  );
}
