import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const payroleApi = createApi(

    {
        reducerPath: 'payrol',
        baseQuery: fetchBaseQuery({
            baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1',
            prepareHeaders: (headers) => {
                headers.set('Authentication', localStorage.getItem('userToken'))
                return headers;
            }
        }),
        endpoints: (builder) => ({
            getPayrolsByDepartments: builder.query({
                query: (departmetnQuery) => `/payrol/department${departmetnQuery ? departmetnQuery : ''}`,
                providesTags: ['Payrol']
            }),
            getPayrolsByUser: builder.query({
                query: (userId) => `/payrol/yearly/${userId ? userId : ''}`
            })
        })

    }
)

export const { useLazyGetPayrolsByDepartmentsQuery,

    useGetPayrolsByUserQuery } = payroleApi