import React, { useState } from "react";
import styles from "./Login.module.css";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as LogoIcon } from "./images/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import InputText from "../../components/inputText/InputText";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useLoginUserMutation } from "../../redux/features/userApi";
import { useSelector } from "react-redux";
import siteWhiteLofo from "../../assets/images/white_logo.png";
import { Avatar, Box } from "@mui/material";
const LoginPage = () => {
  const navigate = useNavigate();
  const [loginUser] = useLoginUserMutation();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const getUser = (e) => { };
  const handleNavigationBasedOnRole = (data) => {
    if (data.role == "root") {
      navigate("/profile");
    } else if (data.role == "admin" || data.role == "employee") {
      localStorage.setItem("companyId", data.company);
      navigate("/");
    }
  };
  const { value: lang } = useSelector((state) => state).lang;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required(
          lang === "en" ? "Email is required" : "البريد الالكتروني مطلوب"
        ),
      password: Yup.string().required(
        lang === "en" ? "Password is required" : "كلمة المرور مطلوبة"
      ),
    }),
    onSubmit: (values) => {
      loginUser(values)
        .then(({ data }) => {
          console.log(data);
          if (!['root', 'admin', "employee"].includes(data?.data?.role)) {
            return toast.error(
              lang == "en"
                ? "you cannt access the system"
                : "   لا تمتتلك الصلاحيه لدخول هنا  "
            );
          } else {
            localStorage.setItem("userToken", data.token);
            handleNavigationBasedOnRole(data.data);
          }

        })
        .catch((e) => {
          toast.error(
            lang == "en"
              ? "Invalid Email Or Password"
              : "البريد الإلكتروني أو كلمة السر خاطئة"
          );
          setError(
            lang == "en"
              ? "Invalid Email Or Password"
              : "البريد الإلكتروني أو كلمة السر خاطئة"
          );
        });
    },
  });
  const { value: mode } = useSelector((state) => state).mode;

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;
  return (
    <div
      className="d-flex align-items-center w-100 "
      style={{
        height: "100vh",
        backgroundColor: mode === "dark" ? "#252525" : undefined,
        direction: lang === "en" ? "ltr" : "rtl",
      }}
    >
      <Box
        sx={{
          p: 4,
          width: {
            xl: 0.4,
            lg: 0.4,
            md: 0.7,
            sm: 0.9,
            xs: 0.9,
          },
          my: 3,
          mx: "auto",
          boxShadow:
            mode === "light"
              ? "rgba(17, 17, 26, 0.1) 0px 0px 16px"
              : "#454545 0px 0px 16px",
        }}
      >
        {error ? (
          <div div className="alert alert-danger text-center">
            {error}
          </div>
        ) : undefined}
        <div className="">
          <div className="d-flex justify-content-center">
            {mode === "light" ? (
              <LogoIcon className="w-100" />
            ) : (
              <Avatar
                src={siteWhiteLofo}
                alt="img"
                sx={{
                  height: "120px",
                  width: "120px",
                }}
              />
            )}
          </div>
          <h2 className="text-center my-4">
            {lang === "en" ? "Login" : "تسجيل الدخول"}
          </h2>
          {/* ====================================inputs=================================== */}
          <div className={`mx-auto mt-4  ${styles.form_container}`}>
            <form className="mx-auto " onSubmit={handleSubmit}>
              <InputText
                label={lang === "en" ? "Email" : "البريد الالكتروني"}
                placeholder={
                  lang === "en" ? "Enter Email" : "أدخل البريد الالكتروني"
                }
                type="email"
                name="email"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <InputText
                label={lang === "en" ? "Password" : "كلمة المرور"}
                placeholder={
                  lang === "en" ? "Enter Password" : "أدخل كلمة المرور"
                }
                type="password"
                name="password"
                value={values.password}
                handleChange={handleChange}
                error={errors.password}
                touched={touched.password}
                handleBlur={handleBlur}
              />

              {/* ===============================================check----------------------------- */}
              <div
                className={`mt-3 d-flex justify-content-between p-2 ${styles.check}`}
              >
                <div className="d-flex mb-2 ">
                  <input type="checkbox" id="rememberMe" />
                  <label
                    htmlFor="rememberMe"
                    className={`mx-2 ${mode === "light" ? "text-dark" : "text-white"
                      }`}
                  >
                    {lang === "en" ? "Remember me" : "احجزني"}
                  </label>
                </div>
                <Link to="" style={{ color: "#0077B6" }}>
                  {lang === "en" ? "Forget Password?" : "نسيت كلمة المرور؟"}
                </Link>
              </div>

              {/* -------------------------------------button-------------------------------------- */}
              <div className=" m-auto my-4">
                <button
                  className="w-100 p-2"
                  type="submit"
                  style={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                  }}
                >
                  {isLoading ? (
                    <i className="fa-solid fa-spin fa-spinner"></i>
                  ) : lang === "en" ? (
                    "Login"
                  ) : (
                    "تسجيل الدخول"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default LoginPage;
