import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { companyId, token } from '../../App'

export const branchesApi = createApi({
    reducerPath: 'brach',
    tagTypes: ['Branch'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
        prepareHeaders: (headers) => {
            headers.set('Authentication', localStorage.getItem('userToken'))
            return headers
        },
    }),
    endpoints: (builder) => ({
        getBranches: builder.query({
            query: () => `branch/${localStorage.getItem('companyId')}`,
            providesTags: ['Branch'],
        }),
        getBranchesWit: builder.query({
            query: () => `branch/data/${localStorage.getItem('companyId')}`,
            providesTags: ['Branch'],
        }),
        deleteBranch: builder.mutation({
            query: (id) => ({
                url: `branch/${localStorage.getItem('companyId')}/${id}`,
                method: 'Delete'
            }),
            invalidatesTags: ['Branch']
        }),
        createBranch: builder.mutation({
            query: (data) => ({
                url: `/branch/${localStorage.getItem('companyId')}`,
                body: data,
                method: 'POST',
            }),
            invalidatesTags: ['Branch']
        }),
        updateBranch: builder.mutation({
            query: ({ data, id }) => ({
                url: `/branch/${localStorage.getItem('companyId')}/${id}`,
                body: { name: data },
                method: 'PUT',
            }),
            invalidatesTags: ['Branch']
        }),
        getBranchById: builder.query({
            query: (branchId) => `/branch/${localStorage.getItem('companyId')}/${branchId}`
        })

    }),

})

export const { useGetBranchesQuery, useGetBranchesWitQuery, useDeleteBranchMutation,
    useUpdateBranchMutation,
    useCreateBranchMutation,
    useGetBranchByIdQuery,
} = branchesApi
