import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { companyId, token } from '../../App'

export const overtimeApis = createApi({
  reducerPath: 'overtimes',
  tagTypes: ['Overtime'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getAllOverTime: builder.query({
      query: (shift) => `over/${shift}`,
      providesTags: ['Overtime'],
    }),
    getOverTime: builder.query({
      query: ({ shift, id }) => `over/${shift}/${id}`,
      providesTags: ['Overtime'],
    }),
    deleteOverTime: builder.mutation({
      query: ({ shiftId, overTimeId }) => ({
        url: `over/${shiftId}/${overTimeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Overtime'],
    }),
    addOver: builder.mutation({
      query: (data) => ({
        url: `over`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Overtime'],
    }),
    updateOver: builder.mutation({
      query: ({ data, shift, id }) => ({
        url: `over/${shift}/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Overtime'],
    }),
    assginOver: builder.mutation({
      query: ({ data, shift, id }) => ({
        url: `over/assignUser/${shift}/${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Overtime'],
    }),

    unAssingOver: builder.mutation({
      query: ({ data, shift, id }) => {
        return {
          url: `over/unassign/${shift}/${id}`,
          method: 'POST',
          body: { userId: data },
        }
      },
      invalidatesTags: ['Overtime'],
    })
  }),
})

export const { useGetOverTimeQuery, useAddOverMutation, useUpdateOverMutation, useAssginOverMutation,
  useUnAssingOverMutation, useGetAllOverTimeQuery
  , useDeleteOverTimeMutation } = overtimeApis
