import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
  departmentsApis,
  useGetDepartmentsByBranchQuery,
} from "../../../redux/features/departmentsApis";
import {
  contractsApi,
  useGetAllContractsQuery,
} from "../../../redux/features/contractsApi";
import { useGetMeQuery } from "../../../redux/features/userApi";
import { useSelector } from "react-redux";
import { textTransform } from "@mui/system";
import ReduxStore from "../../../redux/store";

export default function FilterContractsModal({ lang, mode, setError }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: {
      xl: "800px",
      sm: "70%",
      xs: "90%",
    },
    height: {
      xl: "auto",
      lg: "auto",
      md: "70%",
      sm: "70%",
      xs: "70%",
    },
    bgcolor: mode === "light" ? "#fff" : "#252525",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: {
      xl: 10,
      lg: 10,
      md: 5,
      sm: 5,
      xs: 5,
    },
    p: 4,
    overflowY: {
      xl: "hidden",
      lg: "hidden",
      md: "scroll",
      sm: "scroll",
      xs: "scroll",
    },
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-track": {
      background: mode === "light" ? "#f1f1f1" : "#555",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: mode === "light" ? "#aac1fe" : "#000",
      transition: "0.5s all",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    " &::-webkit-scrollbar-thumb:hover": {
      background: mode === "light" ? "#78a3e4" : "#000",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setBranch("");
    setDepartment('')
    setError(false)
    setSkipDepartmentToken(skipToken)
  };

  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const { cardTheme } = useTheme();
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();

  let [skipContractSToken, setSkipContractsToken] = React.useState(undefined);

  let {
    data: contractsData,
    isSuccess: contractsSuccess,
    isLoading: contractsLoading,
    isError: contractsError,
    error: contsError,
    refetch: contractsRefetch,
  } = useGetAllContractsQuery(skipContractSToken);

  let {
    data: branchesData,
    isSuccess: branchesSuccess,
    isLoading: branchesLoading,
    isError: branchesError,
  } = useGetBranchesQuery();
  let [department, setDepartment] = React.useState("");
  let [skipDepartmentToken, setSkipDepartmentToken] = React.useState(skipToken);
  let {
    data: depsData,
    isSuccess: departmentsSuccess,
    isLoading: departmentLoding,
    isUninitialized: debsUnIntialized,
  } = useGetDepartmentsByBranchQuery(skipDepartmentToken);


  let [branch, setBranch] = React.useState("");
  const handleBranch = (clickedBranch) => {
    setBranch(clickedBranch);
  };

  const handleDepartment = (clickedDepartment) => {
    setDepartment(clickedDepartment);
  };

  React.useEffect(() => {
    if (userSuccess) {
      if (userData?.data?.role == "root") {
      } else if (userData?.data?.role == "admin") {
        setBranch((_) => userData?.data?.branch);
      }
      let query = "";
      if (branch) {
        setSkipDepartmentToken(branch);
        departmentsApis.util.invalidateTags(["Department"]);
        query += `?branch=${branch}`;
        if (branchesSuccess) {
          setSkipContractsToken(query);

          contractsApi.util.invalidateTags(["Contract"]);
        }
      }
      if (department) {
        query += `${branch ? `&` : "?"}department=${department}`;
        if (departmentsSuccess) {
          setSkipContractsToken(query);

          contractsApi.util.invalidateTags(["Contract"]);
        }
      }
    }
    if (contractsError) {
      setError(true)
    }
  }, [
    userSuccess,
    branchesSuccess,
    contractsSuccess,
    branch,
    contractsError,
    department,
    departmentsSuccess,
  ]);
  return (
    <Stack direction="row" justifyContent="flex-end">
      <Button onClick={handleOpen}>
        <FilterListIcon
          sx={{
            color: mode === "light" ? "#000" : "#fff",
          }}
        />
        <Typography
          sx={{
            textTransform: "capitalize",
            color: mode === "light" ? "#000" : "#fff",
            margin: "0 5px",
          }}
        >
          {lang === "en" ? "Filter" : "تصفية"}
        </Typography>
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              variant="h5"
              sx={{
                mt: "20px",
                color: mode === "dark" ? "#fff" : "#8B8C89",
                pb: "10px",
                borderBottom: `1px solid ${mode === "light" ? "#333" : "#aaa"}`,
              }}
            >
              {lang === "en" ? "Branch" : "الفرع"}
            </Typography>
            {branchesSuccess && !branchesLoading && (
              <Box
                sx={{
                  display: "block",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  mt: "25px",
                }}
              >
                <Grid container>
                  {branchesSuccess
                    ? branchesData?.data.map((br) => (
                      <Grid
                        item
                        xl={4}
                        md={6}
                        sm={12}
                        xs={12}
                        sx={{ pb: "20px" }}
                      >
                        <Button
                          disableRipple
                          textTransform="capitalize"
                          sx={{
                            bgcolor: "transparent",
                            border: 1,
                            borderColor:
                              br?._id === branch
                                ? "#81D8D0 !important"
                                : mode === "light"
                                  ? "#8B8C89 !important"
                                  : "#fff",
                            borderRadius: 4,
                            height: "auto",
                            padding: "5px 10px",
                            fontWeight: "bold",
                            color:
                              br?._id === branch
                                ? "#81D8D0 !important"
                                : mode === "light"
                                  ? "#8B8C89"
                                  : "#fff",
                            width: {
                              xl: "80%",
                              lg: "80%",
                              md: "90%",
                              sm: "100%",
                              xs: "100%",
                            },
                            display: "block",
                            mx: "auto",
                            backgroundColor:
                              br?._id === branch
                                ? "#F2F8FD !important"
                                : "transparent",
                            "&:hover": {
                              color: mode === "light" ? "#8B8C89" : "#fff",
                              borderColor:
                                br?._id === branch
                                  ? "#81D8D0"
                                  : mode === "light"
                                    ? "#8B8C89"
                                    : "#fff",
                              bgcolor: "transparent",
                            },
                          }}
                          onClick={() => handleBranch(br?._id)}
                        >
                          {br?.name.length > 15
                            ? `${br?.name.slice(0, 15)}...`
                            : br?.name}
                        </Button>
                      </Grid>
                    ))
                    : null}
                </Grid>
              </Box>
            )}

            <Typography
              variant="h5"
              sx={{
                color: mode === "dark" ? "#fff" : "#8B8C89",
                pb: "10px",
                borderBottom: `1px solid ${mode === "light" ? "#333" : "#aaa"}`,
                mt: "70px",
              }}
            >
              {lang === "en" ? "Department" : "القسم"}
            </Typography>
            <Grid container mt="25px">
              {!branchesError
                ? depsData?.data.map((dep) => {
                  return (
                    <Grid
                      item
                      xl={4}
                      md={6}
                      sm={12}
                      xs={12}
                      sx={{ pb: "20px" }}
                      value={dep?._id}
                    >
                      <Button
                        disableRipple
                        textTransform="capitalize"
                        sx={{
                          bgcolor: "transparent",
                          border: 1,
                          borderColor:
                            dep?._id === department
                              ? "#81D8D0 !important"
                              : mode === "light"
                                ? "#8B8C89 !important"
                                : "#fff",
                          borderRadius: 4,
                          height: "auto",
                          padding: "5px 10px",
                          fontWeight: "bold",
                          color:
                            dep?._id === department
                              ? "#81D8D0 !important"
                              : mode === "light"
                                ? "#8B8C89"
                                : "#fff",
                          width: {
                            xl: "80%",
                            lg: "80%",
                            md: "90%",
                            sm: "100%",
                            xs: "100%",
                          },
                          display: "block",
                          mx: "auto",
                          backgroundColor:
                            dep?._id === department
                              ? "#F2F8FD !important"
                              : "transparent",
                          "&:hover": {
                            color: mode === "light" ? "#8B8C89" : "#fff",
                            borderColor:
                              dep?._id === department
                                ? "#81D8D0"
                                : mode === "light"
                                  ? "#8B8C89"
                                  : "#fff",
                            bgcolor: "transparent",
                          },
                        }}
                        onClick={() => handleDepartment(dep?._id)}
                      >
                        {dep?.name.length > 15
                          ? `${dep?.name.slice(0, 15)}...`
                          : dep?.name}
                      </Button>
                    </Grid>
                  );
                })
                : null}
            </Grid>
            <Box
              sx={{
                mt: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Button
                onClick={handleClose}
                sx={{
                  display: "block",
                  backgroundColor: "#0077B6",
                  color: "#fff",
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  boxShadow: "0 !important",

                  "&:hover": {
                    boxShadow: "0 !important",
                    color: "#fff",
                  },
                }}
                variant="contained"
              >
                {lang === "en" ? "Filter" : "تصفيو"}
              </Button>
              <Button
                sx={{
                  display: "block",
                  borderRadius: "20px",

                  backgroundColor: "transparent !important",
                  textTransform: "capitalize",
                  border: 1,
                  borderColor: "#0077B6",
                  boxShadow: 0,
                  color: "#0077B6 !important",
                  "&:hover": {
                    borderColor: "#0077B6",
                    backgroundColor: "#0077B6",
                    boxShadow: 0,
                  },
                }}
                variant="contained"
                onClick={handleClose}
              >
                {lang === "en" ? "Cancel" : "إلغاء"}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Stack>
  );
}
