import React from "react";
import { useGetAllBlogsQuery } from "../../../redux/features/blogsApi";
import { imageUrl } from "../../../App";
import { Avatar, Box, Grid } from "@mui/material";
export default function HomeBlogs({ mode, lang, chartBGColor }) {
  //   const [t, { language }] = useTranslation();
  let { data, isSuccess } = useGetAllBlogsQuery(true);
  return (
    <div className="mb-3">
      <Box
        sx={{
          borderRadius: "25px",
          height: "100%",
          backgroundColor: chartBGColor,
          p: {
            lg: 4,
            md: 2,
            sm: 2,
            xs: 2,
          },
        }}
      >
        <h5
          className={`fw-bolder mb-3 ${
            mode === "light" ? "text-dark" : "text-white"
          }`}
        >
          {lang === "en" ? "Blogs" : "المدونات"}
        </h5>
        <Grid container>
          {isSuccess &&
            data?.blogs?.slice(0, 4)?.map((data, index) => (
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={6}
                xs={12}
                key={index}
                pb="15px"
              >
                <div
                  className="d-flex flex-column text-center  py-3 "
                  style={{
                    borderRadius: "10px",
                    fontSize: "12px",
                    backgroundColor: mode === "light" ? "#F2F8FD" : "#252525",
                    width: "95%",
                    margin: "0 auto",
                  }}
                >
                  <div>
                    {" "}
                    <Avatar
                      src={imageUrl + data?.user?.image}
                      alt=""
                      sx={{ margin: "0 auto" }}
                    />
                  </div>
                  <h5
                    className={`fw-bold mt-3 ${
                      mode === "light" ? "text-dark" : "text-white"
                    }`}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    {data?.title}
                  </h5>
                  <p className={mode === "light" ? "text-dark" : "text-white"}>
                    {data.description.slice(0, 30) + "..."}
                  </p>
                </div>
              </Grid>
            ))}
          <Grid
            item
            xl={6}
            lg={6}
            md={12}
            sm={6}
            xs={12}
            pb="15px"
            sx={{
              height: "130px",
            }}
          >
            <div
              className="d-flex flex-column text-center h-100 py-3"
              style={{
                borderRadius: "10px",
                fontSize: "12px",
                backgroundColor: mode === "light" ? "#F2F8FD" : "#252525",
                width: "95% ",
                margin: "0 auto",
              }}
            >
              <div className="d-flex align-items-center  w-100 h-100 d-flex align-items-center  justify-content-center">
                <div
                  className="rounded-pill border d-flex align-items-center  justify-content-center "
                  style={{ width: "30px", height: "30px" }}
                >
                  <i
                    className={`fa-solid fa-plus ${
                      mode === "light" ? "text-dark" : "text-white"
                    }`}
                  ></i>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
