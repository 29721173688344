import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Grid, Stack, useTheme } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useGetMeQuery } from "../../../../redux/features/userApi";
import { useEffect } from "react";
import { useGetContractByUserQuery } from "../../../../redux/features/contractsApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Spinner from "../../../spinner/Spinner";
import { imageUrl } from "../../../../App";
import moment from "moment";
import DisplayDepartments from "./../../../../components/Abc/departments/displayDepartments/DisplayDepartments";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useParams } from "react-router";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ContractPanel = ({ value }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { value: lang } = useSelector((state) => state).lang;
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();
  const [skipId, setSkipeId] = React.useState(skipToken);
  const { id } = useParams();
  const { cardTheme } = useTheme();
  const { value: mode } = useSelector((state) => state).mode;
  let {
    data: contractData,
    isSuccess: contractSuccess,
    isLoading: contractLoading,
  } = useGetContractByUserQuery(skipId);
  useEffect(() => {
    const location = window.location.pathname.startsWith("employee", 1);
    if (
      userSuccess &&
      !location &&
      (userData?.data?.role != "root" || userData?.data?.role != "admin")
    ) {
      setSkipeId(userData?.data?._id);
    } else {
      setSkipeId(id);
    }
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      {contractLoading ? (
        <Spinner />
      ) : contractSuccess ? (
        <TabPanel value={value}>
          <Card
            sx={{
              bgcolor: cardTheme[`bg_${mode}`],
              width: {
                xl: 0.5,
                lg: 0.6,
                md: 0.75,
                sm: 0.9,
              },
              mx: {
                lg: 0,
                md: 0,
                sm: "auto",
              },
            }}
          >
            {/* <CardHeader
              title={contractData?.contract?.employee[`fullName_${lang}`]}
              subheader={`${moment(contractData?.contract?.createdAt)
                .locale(lang)
                .format("MMMM")}`}
            /> */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              px={2}
              mt="20px"
            >
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                {contractData?.contract?.employee[`userName_${lang}`]
                  ?.toUpperCase()
                  ?.slice(0, 1)}
              </Avatar>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: mode === "dark" ? "#ddd" : undefined,
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {contractData?.contract?.employee[`fullName_${lang}`]}
                </Typography>
                <Typography
                  sx={{ color: mode === "dark" ? "#ddd" : undefined }}
                >
                  {`${moment(contractData?.contract?.createdAt)
                    .locale(lang)
                    .format("MMMM")}`}
                </Typography>
              </Box>
            </Stack>
            <CardMedia
              component="img"
              image={imageUrl + contractData?.contract?.employee?.image}
              alt="Paella dish"
              sx={{
                p: "20px",
                color: mode === "dark" ? "#ddd" : undefined,
              }}
            />

            <Grid container>
              <Grid lg={4} md={6} xs={12}>
                <CardContent sx={{ padding: "20px" }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `Branch: ${contractData?.contract?.branch?.name}`
                      : `فرع:${contractData?.contract?.branch?.name}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `Department: ${contractData?.contract?.department?.name}`
                      : `قسم:${contractData?.contract?.department?.name}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `Duration: ${contractData?.contract?.duration}`
                      : `المدة:${contractData?.contract?.duration} شهر`}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid lg={4} md={6} xs={12}>
                <CardContent sx={{ padding: "20px" }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `Start Date: ${moment(
                          contractData?.contract?.startDate
                        ).format("yyyy/MM/DD")}`
                      : `تاريخ البدء:${moment(
                          contractData?.contract?.startDate
                        ).format("yyyy/MM/DD")}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `End Date: ${moment(
                          contractData?.contract?.endDate
                        ).format("yyyy/MM/DD")}`
                      : `نهاية العقد:${moment(
                          contractData?.contract?.endDate
                        ).format("yyyy/MM/DD")}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `salary: ${contractData?.contract?.salary}`
                      : `الراتب:${contractData?.contract?.duration}`}
                  </Typography>
                </CardContent>
              </Grid>

              <Grid lg={4} md={6} xs={12}>
                <CardContent sx={{ padding: "20px" }}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `Shift: ${contractData?.contract?.employee?.shift?.name}`
                      : `الدوام:${contractData?.contract.employee?.shift?.name}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `Shift Start Time: ${contractData?.contract?.employee?.shift?.time["start_hour"]} :${contractData?.contract?.employee?.shift?.time["start_mins"]} `
                      : `وقت بداية الدوام:${contractData?.contract?.employee?.shift?.time["start_hour"]} :${contractData?.contract?.employee?.shift?.time["start_mins"]}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: mode === "dark" ? "#ddd" : undefined,
                    }}
                  >
                    {lang == "en"
                      ? `Shift End Time: ${contractData?.contract?.employee?.shift?.time["end_hour"]} :${contractData?.contract?.employee?.shift?.time["end_mins"]} `
                      : `وقت نهاية الدوام:${contractData?.contract?.employee?.shift?.time["end_hour"]} :${contractData?.contract?.employee?.shift?.time["end_mins"]}`}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </TabPanel>
      ) : null}
    </>
  );
};

export default ContractPanel;
