import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import GoogleMapReact from 'google-map-react';

import { useCreateBranchMutation } from "../../../redux/features/branchesApi";
export default function AddBranchModal({ lang, mode }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const { modalTheme } = useTheme();
  const [center, setCenter] = useState({ lat: 0, lng: 0, });
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleMapClick = (event) => {
    setCenter({
      lat: event.lat,
      lng: event.lng
    });
  };
  const handleApiLoaded = (map, maps) => {
    const geocoder = new maps.Geocoder();
    geocoder.geocode({ address: 'Saudi Arabia' }, (results, status) => {
      if (status === 'OK') {
        setCenter({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        });
      }
    });
  };

  const AnyReactComponent = ({ text }) => <div><img src={text} /></div>;



  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const [createBranch, { isError: addError }] = useCreateBranchMutation()
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const formik = useFormik({
    initialValues: {
      branchName: "",
    },
    validationSchema: Yup.object({
      branchName: Yup.string().required(
        lang === "en" ? "Branch name is required" : "اسم الفرع مطلوب"
      ),
    }),
    onSubmit: (values) => {
      createBranch({
        name: values.branchName,
        long: center.lng,
        lat: center.lat

      }).then(({ data, error }) => {
        if (data) {
          toast.success(lang == 'en' ? "Branch Created Successfully" : "تمت إضافة الفرع")
        }
      })
      handleClose();
    },
  });
  useEffect(() => {
    if (addError) {
      toast.error(lang == 'en' ? "Cant Add Branch" : "لا يمكن إضافة فرع")
      handleClose()
    }
  }, [addError])

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    formik;
  return (
    <Stack
      sx={{
        marginTop: "40px",
        padding: "0 30px",
      }}
    >
      <Box>
        <Button
          disableRipple
          sx={{
            display: "block",
            backgroundColor: "#0077B6",
            color: "#fff",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#0077B6",
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === "en" ? "Add Branch" : "إضافة فرع"}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                sx={{
                  color: mode === "light" ? "#000" : "#fff",
                }}
              >
                {lang === "en" ? "Create a new branch" : "عمل فرع جديدة"}
              </Typography>
              <Box
                marginTop={"30px"}
                pb={3}
                sx={{
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Branch Name" : "اسم الفرع"}
                </Typography>
                <input
                  type="text"
                  style={{
                    marginTop: "4px",
                    outline: 0,
                    display: "block",
                    borderRadius: 6,
                    width: "100%",
                    padding: "5px 10px",
                    direction: lang === "en" ? "ltr" : "rtl",
                    backgroundColor: mode === "light" ? "#fff" : "#252526",
                    color: mode === "light" ? "#000" : "#fff",
                    border:
                      errors.branchName && touched.branchName
                        ? "1px solid red"
                        : "1px solid transparent",
                  }}
                  value={values.branchName}
                  name="branchName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    color: "red",
                    fontWeight: "bolder",
                    fontSize: "small",
                  }}
                >
                  {errors.branchName &&
                    touched.branchName &&
                    errors.branchName}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Branch Location" : "موقع الفرع"}
                </Typography>
                <div style={{ height: '500px', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDtTmxkJPGWAPdGp1Qol9ErytmhuidiR6g' }}
                    defaultCenter={{ lat: 40.7128, lng: -74.006 }}
                    center={center}
                    zoom={10}

                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    onClick={handleMapClick}

                    style={{ position: 'relative', width: '100%', height: '100%' }}
                    options={{
                      styles: [
                        {
                          featureType: 'poi',
                          elementType: 'labels',
                          stylers: [{ visibility: 'off' }]
                        }
                      ]
                    }}

                  >
                    {center && (
                      <AnyReactComponent
                        lat={center.lat}
                        lng={center.lng}
                        text='https://maps.google.com/mapfiles/kml/paddle/red-circle.png'
                      />
                    )}
                  </GoogleMapReact>
                </div>
              </Box>
              <Stack
                marginTop="90px"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  type="submit"
                >
                  {lang === "en" ? "Add" : "اضافة"}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  onClick={handleClose}
                >
                  {lang === "en" ? "Close" : "إلغاء"}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  );
}
