import React, { useEffect } from "react";
import styles from "./Alerts.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import { Alert, Avatar, Button, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import {
  useCreateAlertMutation,
  useDeleteAlertMutation,
  useGetAlertsCompanyQuery,
} from "../../../redux/features/alertsApis";
import { useGetMeQuery } from "../../../redux/features/userApi";
import Spinner from "../../spinner/Spinner";
import { imageUrl } from "../../../App";
import { useGetCatgeoriesQuery } from "../../../redux/features/categoryApis";
import {
  useGetsubCatgeoriesQuery,
  useGetsubCatgeoryByIdQuery,
} from "../../../redux/features/subCategoryApis";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import { useGetDepartmentsQuery } from "../../../redux/features/departmentsApis";
import { useGetEmployeesByDepartmentQuery } from "../../../redux/features/employeeApi";
import { toast } from "react-toastify";
import AddAlertModal from "./AddAlertModal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

export const colourOptions = [
  { value: "ocean1", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" },
];
function AlertsPage() {
  const navigate = useNavigate();
  const [t, { language }] = useTranslation();
  const [alertValue, setAlertValue] = useState("");
  const [title, setTitle] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [skipAlertToken, setSkipAlertToken] = useState(skipToken);
  const [skipSubToken, setSkipSubToken] = useState(skipToken);
  const [skipDepartment, setSkipDepartment] = useState(skipToken);
  const [skipEmployee, setSkipEmployee] = useState(skipToken);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [errors, setErrors] = useState("");
  const [badErrors, setBadErrors] = useState("");
  // ME INFO
  let { data: dataMe, isSuccess: successMe } = useGetMeQuery();
  const [selectedCategory, setSelectedCategory] = useState("");
  const { modalTheme } = useTheme();
  // ALERTS STORE
  let {
    data: dataAlerts,
    isSuccess: alertsSuccess,
    isLoading: alertsLoading,
    error: error,
  } = useGetAlertsCompanyQuery();
  //CATEGORY STORE
  let { data: dataCategories } = useGetCatgeoriesQuery();
  //SUB CATEGORY STORE
  let { data: dataSubCategories, isSuccess: subCategorySuccess } =
    useGetsubCatgeoriesQuery(skipAlertToken);
  //SUB CATEGORY DETAILS
  let { data: dataSubCategoryDetails, isSuccess: subCategoryDetailsSuccess } =
    useGetsubCatgeoryByIdQuery(skipSubToken);
  //GET BRANCHES
  let { data: branchData, isSuccess: branchSuccess } = useGetBranchesQuery();
  //GET DEPARTMENTS
  let { data: departmentData, isSuccess: departmentSuccess } =
    useGetDepartmentsQuery(skipDepartment);
  //GET EMPLOYESS
  let { data: employeesData, isSuccess: employeesSuccess } =
    useGetEmployeesByDepartmentQuery(skipEmployee);
  //CREATE ALERT
  const [createAlert, { isError, error: nughtyError }] =
    useCreateAlertMutation();
  const addAlert = () => {
    const data = {
      description: alertValue,
      subCategory: selectedSubCategory,
      startDate: start,
      endDate: end,
      ids: assignedEmployees,
      title: title,
    };
    selectedSubCategory === "other"
      ? delete data.subCategory
      : delete data.title;
    data.startDate === null && delete data.startDate;
    data.endDate === null && delete data.endDate;
    for (const [key] of Object.entries(data)) {
      if (
        data[key] == "" ||
        (data[key] === null && dataSubCategoryDetails?.subCategory?.haveTime) ||
        (selectedSubCategory == "other" && key === "title" && data[key] === "")
      ) {
        return setErrors(
          `The ${key === "ids" ? "employees" : key} is requierd`
        );
      } else {
        setErrors("");
      }
    }
    if (errors === "") {
      createAlert(data).then((res) => {
        if (res.data) {
          toast.success(res.data.message_en);
          handleClose();
        }
        if (res.error) {
          res.error.data[`error_${lang}`]
            ? setBadErrors(res.error.data[`error_${lang}`])
            : setBadErrors(res.error.data);
        }
      });
    }
  };
  useEffect(() => {
    // to skip the sub Categories
    if (selectedCategory !== "") setSkipAlertToken(selectedCategory);
    // to skip the details sub Categories
    if (selectedSubCategory !== "" && selectedSubCategory !== "other")
      setSkipSubToken(selectedSubCategory);
    // to skip the departments if not found branch id
    if (selectedBranch !== "") setSkipDepartment(selectedBranch);
    // to skip the employees if not found department id
    if (selectedDepartment !== "") setSkipEmployee(selectedDepartment);
  }, [
    subCategorySuccess,
    subCategoryDetailsSuccess,
    selectedCategory,
    selectedSubCategory,
    dataSubCategoryDetails,
    departmentSuccess,
    branchSuccess,
    selectedBranch,
    selectedDepartment,
    employeesSuccess,
  ]);

  //-----------------------------------handle inputs-------------------------------------
  const [userinfo, setUserInfo] = useState({
    employees: [],
    branches: [],
    departments: [],
  });

  //-------------------------------------------------modal---------------------------------------

  const [modalShow, setModalShow] = useState(false);

  const [modalState, setModalState] = useState();
  const handleClose = () => {
    setModalShow(false);
    setModalState(false);
  };

  const [assignedEmployees, setAssignedEmployees] = useState([]);

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const { cardTheme, blueColor } = useTheme();

  const [deleteAlert] = useDeleteAlertMutation();
  const handleDeleteAlert = (id) => {
    deleteAlert(id).then(({ data }) => {
      if (data) {
        toast.success(
          lang == "en" ? "Alert Deleted Successfully" : "تم حذف الطلب بنجاح"
        );
      }
    });
  };
  return (
    <HOC>
      <div className={styles.component}>
        {/*---------------------------------------------container------------------------------------------------*/}
        <div className={` overflow-hidden p-3  `}>
          <div
            className="p-5"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className="text-center"
              style={{
                marginLeft: language === "en" && "auto",
                marginRight: language === "ar" && "auto",
                marginBottom: "30px",
                color: mode === "light" ? "#000" : "#fff",
              }}
            >
              {lang === "en" ? "Alerts" : "التعميمات"}
            </h1>
            <div className={`row g-4 p-3 ${styles.rows}`}>
              {/* ------------------------------------ mapping ------------------------------------------------------------------------- */}
              {/* AlertList */}
              {alertsLoading ? (
                <Spinner />
              ) : alertsSuccess ? (
                dataAlerts?.alerts?.map((alert, index) => (
                  <div key={index} className={` col-md-6 col-lg-4 col-xxl-3 `}>
                    <div
                      className={`${styles.columContent} p-3, position-relative`}
                      style={{
                        backgroundColor: cardTheme[`bg_${mode}`],
                        borderRadius: "10px",
                        height: "160px",
                      }}
                    >
                      <div>
                        <div className="d-flex justify-content-between align-items-center ">
                          <div
                            className="d-flex flex-column mt-2 mx-3  overflow-hidden"
                            style={{
                              width: "75%",
                            }}
                          >
                            <h4
                              className={
                                mode === "light" ? "text-dark" : "text-white"
                              }
                            >
                              {alert.title}
                            </h4>
                            <p
                              value={alertValue}
                              className={`w-100 ${
                                mode === "light" ? "text-dark" : "text-white"
                              }`}
                            >
                              {alert.description.slice(0, 25)}
                              {alert.description.length > 25 ? "..." : null}
                            </p>
                          </div>
                          <Button
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: lang === "en" ? "15px" : undefined,
                              left: lang === "ar" ? "15px" : undefined,
                              minWidth: 0,
                              width: 0,
                            }}
                            onClick={() => handleDeleteAlert(alert?._id)}
                          >
                            <HighlightOffIcon sx={{ color: "#E04449" }} />
                          </Button>
                        </div>
                      </div>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop={"20px"}
                        sx={{
                          position: "absolute",
                          bottom: "15px",
                          width: "100%",
                          px: "10px",
                        }}
                      >
                        <Avatar
                          alt={alert.title}
                          src={
                            imageUrl +
                            `${
                              successMe && dataMe.data.role === "root"
                                ? alert?.to?.image
                                : alert?.from?.image
                            }`
                          }
                        />
                        <Button
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: 0,
                            color: mode === "light" ? "#333" : "#ddd",
                            border: 1,
                            borderColor: mode === "light" ? "#333" : "#ddd",
                            padding: 0,
                            height: "30px",
                            borderRadius: "12px",
                            fontWeight: "bold",
                            "&:hover": {
                              boxShadow: 0,
                              backgroundColor: "transparent",
                              color: mode === "light" ? "#333" : "#ddd",
                              borderColor: mode === "light" ? "#333" : "#ddd",
                            },
                          }}
                          variant="contained"
                          onClick={() => navigate(`/alerts/${alert._id}`)}
                        >
                          {lang === "en" ? "open" : "فتح"}
                        </Button>
                      </Stack>
                    </div>
                  </div>
                ))
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Alert
                    severity="error"
                    align
                    className="alert alert-success"
                    sx={{
                      border: "1px solid red",
                      bgcolor: "transparent",
                      color: mode === "light" ? "#000" : "#fff",
                      fontWeight: "bolder",
                      svg: {
                        mx: "10px",
                      },
                    }}
                  >
                    {lang === "en"
                      ? error?.data?.error_en
                      : "لا يمكنك (إضافة أو تحديث أو الحصول على أو حذف) أي طلب في الشركة لأنك لست صاحب الشركة"}
                  </Alert>
                </Stack>
              )}
              {successMe && dataMe.data.role === "root" && (
                <AddAlertModal lang={lang} mode={mode} />
              )}
            </div>
          </div>
        </div>
      </div>
    </HOC>
  );
}

export default AlertsPage;
