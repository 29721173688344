import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../App';
export const UserApi = createApi({
    reducerPath: 'user',
    tagTypes: ['User'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
        prepareHeaders: (headers) => {
            headers.set('Authentication', localStorage.getItem('userToken'))
            return headers
        }
    }),
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (userData) => ({
                url: 'auth/login',
                method: 'POST',
                body: userData,
            })
        }),
        getMe: builder.query({
            query: () => `user/me`,
            providesTags: ["User"]
        }),
        getUserById: builder.query({
            query: (id) => `user/${id}`,
            providesTags: ["User"]
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `user/updateUser/${data._id}`,
                body: data,
                method: 'PUT',
            }),
            invalidatesTags: ['User']
        })
    }),


})

export const {
    useLoginUserMutation,
    useGetMeQuery,
    useUpdateUserMutation,
    useGetUserByIdQuery

} = UserApi;