import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Stack } from '@mui/material'
import DateAndTimePicker from '../../dateTimePicker/DateTimePicker'
import { useTheme } from '@emotion/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import {
  useAddOverMutation,
  useUpdateOverMutation,
} from '../../../redux/features/overTimeApis'
import { useParams } from 'react-router'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

export default function EditOverTime({ lang, mode, data }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    resetForm()
  }
  const { id } = useParams()
  const [updateOverTime] = useUpdateOverMutation()
  const { modalTheme } = useTheme()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      lg: '800px',
      md: '70%',
      sm: '90% ',
      xs: '90%',
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    outline: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
    height: 'auto',
    direction: lang === 'en' ? 'ltr' : 'rtl',
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-track': {
      background: mode === 'light' ? '#f1f1f1' : '#555',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: mode === 'light' ? '#aac1fe' : '#252526',
      transition: '0.5s all',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    ' &::-webkit-scrollbar-thumb:hover': {
      background: mode === 'light' ? '#78a3e4' : '#252526',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },
  }
  const formik = useFormik({
    initialValues: { start_date: data.start, end_date: data.end },
    validationSchema: Yup.object({
      start_date: Yup.date().required(),
      end_date: Yup.date().required(),
    }),
    onSubmit: () => {
      let dataUpdated = {
        start: values.start_date,
        end: values.end_date,
        shift: id,
      }
      updateOverTime({ data: dataUpdated, shift: id, id: data._id }).then(
        ({ error, data }) => {
          if (data) {
            toast.success(data.message_en)
            handleClose()
            resetForm()
          }
          if (error) {
            error.data[`error_${lang}`]
              ? toast.error(error.data[`error_${lang}`])
              : toast.error(error.data)
          }
        },
      )
    },
  })
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    resetForm,
    setTouched,
  } = formik
  const handleChangeDate = (dateValue) => {
    setValues({
      ...values,
      date: dateValue,
    })
  }
  const handleTouchDate = () => {
    if (values.date === null) {
      formik.setTouched({
        ...formik.touched,
        date: true,
      })
    } else {
      delete touched.date
    }
  }

  const handleChangeStartDate = (dateValue) => {
    setValues({
      ...values,
      start_date: dateValue,
    })
  }
  const handleTouchStartDate = () => {
    if (values.start_date === null) {
      setTouched({
        ...touched,
        start_date: true,
      })
    } else {
      delete touched.start_date
    }
  }
  const handleChangeEndDate = (dateValue) => {
    setValues({
      ...values,
      end_date: dateValue,
    })
  }
  const handleTouchEndDate = () => {
    if (values.end_date === null) {
      setTouched({
        ...touched,
        end_date: true,
      })
    } else {
      delete touched.end_date
    }
  }

  return (
    <div>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          sx={{
            position: 'absolute',
            top: '5px',
            right: lang === 'en' ? '45px' : undefined,
            left: lang === 'ar' ? '40px' : undefined,
            minWidth: 0,
            width: 0,
          }}
          onClick={handleOpen}
        >
          <MoreHorizIcon sx={{ color: '#black' }} />
        </Button>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              sx={{
                color: mode === 'light' ? '#000' : '#fff',
                my: '40px',
              }}
            >
              {lang === 'en'
                ? 'Update the overtime'
                : 'تحديث الفترة الاضافيه '}
            </Typography>

            <Box sx={{ my: '30px' }}>
              <DateAndTimePicker
                label={lang === 'en' ? 'Start Date' : 'تاريخ البدء'}
                value={values.start_date}
                error={errors.start_date}
                touched={touched.start_date}
                handleChange={handleChangeStartDate}
                handleBlur={handleTouchStartDate}
              />
            </Box>
            <DateAndTimePicker
              label={lang === 'en' ? 'End Date' : 'تاريخ الانتهاء'}
              value={values.end_date}
              error={errors.end_date}
              touched={touched.end_date}
              handleChange={handleChangeEndDate}
              handleBlur={handleTouchEndDate}
            />
            <Stack
              marginTop="60px"
              direction="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: '#0077B6',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#0077B6',
                    color: '#fff',
                  },
                }}
                type="submit"
              >
                {lang === 'en' ? 'Update' : 'تحديث'}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#0077B6',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#0077B6',
                    color: '#fff',
                  },
                }}
                type="button"
                onClick={handleClose}
              >
                {lang === 'en' ? 'Close' : 'إلغاء'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  )
}
