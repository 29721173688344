import React from "react";
import { useSelector } from "react-redux";
import HOC from "../../../components/HOC/HOC";
import { useLocation } from "react-router-dom";
import { Avatar, Box, Typography } from "@mui/material";
import { imageUrl } from "../../../App";
import moment from "moment";
import { useLazyGetEmployeesNotificationsQuery } from "../../../redux/features/notificationsApi";

const NotificationsPage = () => {
  const { state } = useLocation();
  const { notify } = state;
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  let [getEmpsNotification] = useLazyGetEmployeesNotificationsQuery();
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  return (
    <HOC>
      <Box className={` overflow-hidden p-3  `}>
        <Box
          sx={{
            display: {
              xl: "flex",
              lg: "flex",
              md: "flex",
              sm: "block",
              xs: "block",
            },
            alignItems: "center",
            justifyContent: "flex-start",
            borderRadius: 3,
            backgroundColor: chartBGColor,
            position: "relative",
            p: 2.5,
          }}
        >
          <Avatar src={imageUrl + notify?.employee?.image} />
          <Box
            sx={{
              px: { xl: 2, lg: 2, md: 2, sm: 0, xs: 0 },
              py: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                color: mode === "dark" ? "#fff" : undefined,
              }}
            >
              {" "}
              {notify[`title_${lang}`]}
            </Typography>
            <Typography sx={{ color: mode === "dark" ? "#fff" : undefined }}>
              {" "}
              {notify[`des_${lang}`]}
            </Typography>
            <Typography
              sx={{
                position: "absolute",
                top: "5px",
                left: lang === "ar" ? "10px" : undefined,
                right: lang === "en" ? "10px" : undefined,
                color: mode === "dark" ? "#fff" : undefined,
              }}
            >
              {notify?.createdAt
                ? moment(notify?.createdAt).format("yyyy-mm-DD")
                : moment(new Date()).format("yyyy-mm-DD")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </HOC>
  );
};

export default NotificationsPage;
