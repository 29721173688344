import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Stack, Typography, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useState } from "react";

export default function AddChildModel({ setDataModel, mode, lang }) {
  const { modalTheme } = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: modalTheme.Box[`bg_${mode}`],
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    direction: lang === "en" ? "ltr" : "rtl",
  };
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [inputError, setInputError] = useState(null);
  const handleOnSubmit = (data) => {
    if (data) {
      setDataModel(data);
      handleClose();
    } else {
      setInputError(lang === "en" ? "Fill this field" : "املأ هذا الحقل");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setInputError(null);
    setData(null);
  };
  const ButtonStyled = styled(Button)({
    backgroundColor: "#0077B6",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0077B6",
      color: "#fff",
    },
  });

  const handleChange = (text) => {
    setData(text);
    if (text) {
      setInputError(null);
    } else {
      setInputError(lang === "en" ? "Fill this field" : "أملاأ هذا الحقل");
    }
  };

  return (
    <Box>
      <Button
        onClick={handleOpen}
        disableRipple
        sx={{
          borderRadius: "50%",
          padding: "15px 5px",
          height: 0,
          minWidth: 0,
          border: 1,
          borderColor: mode === "light" ? "#000" : "#fff",
        }}
      >
        <AddOutlinedIcon
          sx={{
            color: mode === "light" ? "#000" : "#fff",
          }}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <Typography
            variant="h5"
            sx={{ color: mode === "dark" ? "#fff" : undefined }}
          >
            {lang === "en" ? "Add New Company" : "إضافة شركة جديدة"}
          </Typography>
          <Box
            sx={{
              margin: "20px 0 30px",
              padding: "30px 0 40px",
              borderTop: 1,
              borderBottom: 1,
              borderColor: mode === "light" ? "divider" : "#a8a8a8",
            }}
          >
            <Box sx={{ position: "relative", pb: "21px" }}>
              <Typography
                variant="h6"
                sx={{
                  m: 0,
                  color: mode === "dark" ? "#fff" : undefined,
                }}
              >
                {lang === "en" ? "Company Name" : "اسم الشركة"}
              </Typography>

              <input
                style={{
                  display: "block",
                  width: "100%",
                  outline: 0,
                  padding: "8px",
                  borderRadius: 4,
                  backgroundColor: mode === "dark" ? "#252525" : undefined,
                  color: mode === "dark" ? "#fff" : undefined,
                  border: `1px solid ${inputError ? "red" : "transparent"}`,
                }}
                value={data}
                onChange={(event) => handleChange(event.target.value)}
                onBlur={(event) =>
                  !event.target.value
                    ? setInputError(
                        lang === "en" ? "Field this field" : "املأ هذا الحقل"
                      )
                    : undefined
                }
              />
              <Typography
                sx={{ color: "red", position: "absolute", bottom: 0 }}
              >
                {inputError}
              </Typography>
            </Box>
          </Box>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <ButtonStyled disableRipple onClick={() => handleOnSubmit(data)}>
              {lang === "en" ? "Add" : "أضف"}
            </ButtonStyled>
            <ButtonStyled disableRipple onClick={handleClose}>
              {lang === "en" ? "close" : "إلغاء"}
            </ButtonStyled>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}
