import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../App'
export const requsetApis = createApi({
  reducerPath: 'request',
  tagTypes: ['Request'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getRequsetsSend: builder.query({
      query: () => `request/send?company=${localStorage.getItem('companyId')}`,
      providesTags: ['Request'],
    }),
    getRequsetsReci: builder.query({
      query: () =>
        `request/recevie?company=${localStorage.getItem('companyId')}`,
      providesTags: ['Request'],
    }),
    updateRequest: builder.mutation({
      query: ({ status, _id }) => ({
        url: `/request/${_id}`,
        method: 'PUT',
        body: {
          status,
        },
      }),

      invalidatesTags: ['Request'],
    }),

    deleteRequest: builder.mutation({
      query: (id) => ({
        url: `/request/${id}`,
        method: 'DELETE',

      }),
      invalidatesTags: ['Request']
    }),
    createRequest: builder.mutation({
      query: (data) => ({
        url: `request`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Request'],
    }),
  }),
})

export const {
  useGetRequsetsSendQuery,
  useGetRequsetsReciQuery,
  useUpdateRequestMutation,
  useCreateRequestMutation,
  useDeleteRequestMutation,
  //   useCreateAlertMutation,

} = requsetApis
