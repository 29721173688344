import TabPanel from "@mui/lab/TabPanel";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import moSalahImg from "../images/Salah.jpg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useSelector } from "react-redux";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import InputTextProfile from "../InputTextProfile";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import ChildEditModel from "./EditModel";
import AddChildModel from "./AddChildModel";
import GoogleMapReact from 'google-map-react';

import {
  useGetMeQuery,
  useUpdateUserMutation,
} from "../../../../redux/features/userApi";
import moment from "moment";
import { useUploadImageMutation } from "../../../../redux/features/uploadsApi";
import { AiOutlinePlus } from "react-icons/ai";
import { imageUrl } from "../../../../App";
import { IoFingerPrint } from "react-icons/io5";
import { wait } from "@testing-library/user-event/dist/utils/misc/wait";
import axios from "axios";
import { useAttenduserMutation } from "../../../../redux/features/attendeApis";

const ProfilePanel = ({ value }) => {
  const { value: lang } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;
  let { data: userData, isSuccess: userSuccess, isLoading } = useGetMeQuery();
  let [updateUser] = useUpdateUserMutation();
  const nationalities = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian/Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ];
  const formik = useFormik({
    initialValues: {
      _id: "",
      name: "",
      email: "",
      password: "",
      position: "",
      userName_en: "",
      userName_ar: "",
      phone: "",
      role: "",
      birthDate: "",
      nationality: "",
      address: "",
      image: "",
    },
    validationSchema: Yup.object({
      fullName_en: Yup.string(),
      fullName_ar: Yup.string(),
      email: Yup.string().email().required(),
      position: Yup.string().required(),
      userName_en: Yup.string(),
      userName_ar: Yup.string(),
      phone: Yup.string().required(),

      birthDate: Yup.date(),
      nationality: Yup.string().required(),
      address: Yup.string(),
      image: Yup.string(),
    }),
    onSubmit: () => {
      let obj = { ...values };
      obj[lang == "en" ? "fullName_en" : "fullName_ar"] = values.name;
      delete obj["name"];

      toast.success("Submitted Successfully");
      updateUser(obj).then(({ data, error }) => {
        if (data) {
        }
      });

      // formik.resetForm();
    },
  });

  // mutate the data of the formik object on the run time

  useEffect(() => {
    if (userData) {
      formik.setValues({
        _id: userData?.data?._id,
        fullName_en:
          lang == "en" ? userData?.data[`fullName_${lang}`] : undefined,
        fullName_ar:
          lang == "ar" ? userData?.data[`fullName_${lang}`] : undefined,
        email: userData?.data?.email,
        position:
          userData?.data?.position ||
          (userData?.data?.role == "employee"
            ? "employee"
            : userData?.data?.role == "admin"
              ? "admin"
              : "Owner"),
        userName_en:
          lang == "en" ? userData?.data[`userName_${lang}`] : undefined,
        userName_ar:
          lang == "ar" ? userData?.data[`userName_${lang}`] : undefined,
        phone: userData?.data?.phone,

        birthDate: moment(userData?.data?.birthDate || new Date()).format(
          "YYYY-MM-DD"
        ),
        role: userData?.data?.role,
        nationality: userData?.data?.nationality,
        address: userData?.data?.address,
      });
    }
  }, [userData]);

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    formik;

  const onFileClicked = () => {
    imageRef.current.click();
  };
  let [uploadImage] = useUploadImageMutation();
  const [file, setFile] = useState(null);
  const imageRef = useRef(null);

  const handleUploadFile = (e) => {
    // call the upload method for uploading the image
    if (e.target.files[0]) {
      setFile((_) => e.target.files[0]);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      uploadImage(formData).then(({ data }) => {
        formik.setFieldValue("image", data.filename);
      });
    }
  };

  const renderFileLogic = () => {
    return (
      <Box
        sx={{
          height: "15em",
          background: mode === "light" ? "#E0F2FF" : "#252525",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0.5em",
        }}
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="upload-img"
          onChange={handleUploadFile}
          ref={imageRef}
        />

        {file ? (
          <img
            src={URL.createObjectURL(file)}
            alt="personal Profile"
            style={{
              width: "90%",
              height: "90%",
              objectFit: "cover",
              cursor: "pointer",
              borderRadius: "0.5em",
            }}
            onClick={onFileClicked}
          />
        ) : userSuccess ? (
          <Box
            sx={{
              background: mode === "light" ? "#E0F2FF" : "#252525",
              height: "15em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0.5em",
              px: 0,
            }}
          >
            <img
              src={imageUrl + userData?.data?.image}
              alt="personal Profile"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "0.5em",
              }}
              onClick={onFileClicked}
            />
          </Box>
        ) : (
          <Box
            sx={{
              background: "#E0F2FF",
              height: "15em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {userData?.data?.role !== "root" && (
              <AiOutlinePlus
                style={{ cursor: "pointer" }}
                onClick={onFileClicked}
                size={50}
                color={mode == "dark" ? "white" : "black"}
              />
            )}
          </Box>
        )}
      </Box>
    );
  };

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [location, setLocation] = useState(null);

  const [attendUser] = useAttenduserMutation();
  const assignUser = () => {
    axios.get("https://geolocation-db.com/json/").then(({ data }) => {
      console.log(data, "daataa");
    });
  };
  const attend = () => {
    attendUser({ lat: location.lat, long: location.lng }).then(({ error, data }) => {
      if (data) {
        data?.message_en
          ? toast.success(data?.message_en)
          : toast.success(data?.error_en);
      }
      if (error) {
        error.data[`error_en`]
          ? toast.error(error.data[`error_en`])
          : toast.error(error.data);
      }
    });
  };

  function handleSuccess(position) {
    setLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
  }

  function handleFailure(error) {
    console.log(error);
  }

  function getUserLocation() {
    navigator.geolocation.getCurrentPosition(handleSuccess, handleFailure);
  }
  useEffect(() => {
    getUserLocation()
    assignUser();
  }, []);
  console.log(location);
  const AnyReactComponent = ({ text }) => <div><img src={text} /></div>;

  return (
    <TabPanel value={value}>
      {/* Box Grid For test */}
      <Box
        sx={{
          width: "100%",
          margin: "0 auto",
        }}
      >
        <Grid container gap={"20%"}>
          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
            {renderFileLogic()}
          </Grid>
          <div style={{ height: '100vh', width: '100%' ,display:"none" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyDtTmxkJPGWAPdGp1Qol9ErytmhuidiR6g' }}
              // defaultCenter={{ lat: location.lat, lng: location.lng }}
              defaultZoom={30}
              center={location}
              onGoogleApiLoaded={({ map, maps }) => {
                getUserLocation();
              }}
            >
              {location && (
                <AnyReactComponent
                  lat={location.lat}
                  lng={location.lng}
                  text='https://maps.google.com/mapfiles/kml/paddle/red-circle.png'
                />
              )}
            </GoogleMapReact>
          </div>
        </Grid>
        <Grid container mt="15px">
          {userData?.data?.role !== "root" && (
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <Box
                style={{
                  background: mode === "light" ? "#F5F5F5" : "#252525",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IoFingerPrint
                  size={100}
                  style={{
                    cursor: "pointer",
                    color: mode === "dark" ? "#fff" : undefined,
                  }}
                  onClick={() => attend()}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          margin: "70px auto 0",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "fullName" : "الاسم بالكامل"}
                name={`fullName_${lang}`}
                value={values[`fullName_${lang}`]}
                error={errors[`fullName_${lang}`]}
                touched={touched[`fullName_${lang}`]}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "email" : "البريد الالكتروني"}
                name="email"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "password" : "كلمة المرور"}
                name="password"
                value={values.password}
                error={errors.password}
                touched={touched.password}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "position" : "المنصب"}
                name="position"
                value={values.position}
                error={errors.position}
                touched={touched.position}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "username" : "الاسم المستخدم"}
                name={`userName_${lang}`}
                value={values[`userName_${lang}`]}
                error={errors[`userName_${lang}`]}
                touched={touched[`userName_${lang}`]}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "phone number" : "رقم الهاتف"}
                name="phone"
                value={values.phone}
                error={errors.phone}
                touched={touched.phone}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "role" : "الدور"}
                name="role"
                value={values.role}
                error={errors.role}
                touched={touched.role}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                type="date"
                label={lang === "en" ? "Birth Date" : "تاريخ الميلاد"}
                name="birthDate"
                value={values.birthDate}
                error={errors.birthDate}
                touched={touched.birthDate}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item lg={6} md={12} xs={12}>
              <Box
                with="100%"
                sx={{
                  paddingBottom: "20px",
                  position: "relative",
                }}
              >
                <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
                  {lang === "en" ? "nationality" : "الجنسية"}
                </Typography>
                <Box
                  sx={{
                    width: {
                      lg: "92%",
                      md: "95%",
                      sm: "100%",
                      xs: "100%",
                    },
                  }}
                >
                  <select
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "8px",
                      borderRadius: "2",
                      outline: 0,
                      border: `1px solid ${errors.nationality && touched.nationality
                        ? "red"
                        : "transparent"
                        }  `,
                      backgroundColor:
                        errors.nationality && touched.nationality
                          ? undefined
                          : mode === "dark"
                            ? "#252525"
                            : "#E0F2FF",
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                    name="nationality"
                    value={values.nationality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option hidden select>
                      {lang === "en" ? "Select Nationality" : "اختار جنسية"}
                    </option>
                    {nationalities.map((nationality) => (
                      <option key={nationality} value={nationality}>
                        {nationality}
                      </option>
                    ))}
                  </select>
                </Box>
                <Typography
                  sx={{
                    color: "#f00",
                    fontWeight: 600,
                    margin: 0,
                    position: "absolute",
                    bottom: "0",
                    padding: "0 3px",
                    fontSize: "12px",
                  }}
                >
                  {errors.nationality && touched.nationality
                    ? errors.nationality
                    : undefined}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <InputTextProfile
                label={lang === "en" ? "address" : "العنوان"}
                name="address"
                value={values.address}
                error={errors.address}
                touched={touched.address}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            type="submit"
            sx={{
              margin: "30px auto 0",
              padding: "10px 30px",
              "&:hover": {
                color: "#fff",
              },
            }}
          >
            {lang === "en" ? "Update" : "تحديث"}
          </Button>
        </form>
      </Box>
    </TabPanel>

  );
};

export default ProfilePanel;
