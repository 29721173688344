import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { token } from "../../App";

export const companyApi = createApi({
  reducerPath: "company",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.team-hr.co:8080/teamHR/api/v1/",
    prepareHeaders: (headers) => {
      headers.set("Authentication", localStorage.getItem('userToken'));
      return headers;
    },
  }),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => `company`,
      providesTags: ["Company"],
    }),
    createCompany: builder.mutation({
      query: (data) => ({
        url: `company`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Company"],
    }),
    updateCompany: builder.mutation({
      query: (data) => ({
        url: `company/${data?._id}`,
        method: "PUT",
        body: { name: data?.name },
      }),
      invalidatesTags: ["Company"],
    }),
    deleteCompany: builder.mutation({
      query: (id) => ({
        url: `company/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Company"],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companyApi;
