import React, { useEffect } from "react";
import styles from "./DisplayDepartments.module.css";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { toast } from "react-toastify";

import AddDepartmentModal from "../modals/AddDepartmentModal";
import {
  useDeleteDepartmentMutation,
  useGetDepartmentsByBranchQuery,
} from "../../../redux/features/departmentsApis";
import dayjs from "dayjs";
import Spinner from "../../../pagees/spinner/Spinner";
import { useTheme } from "@emotion/react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import UpdateDepartment from "../modals/updateDepartment";
import { useGetMeQuery } from "../../../redux/features/userApi";
import { useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import DeleteModel from "../../../pagees/privates/profile/mui/DeleteModel";

// a7a ya abdo mfesh departments btrg3 m3 elbranch
export default function BranchDepartments({ mode, lang, branchId, userData }) {
  const [skipDepsToken, setSkipDepsToken] = useState(skipToken);
  const [confirmDelete, setConfirmDelete] = useState(null);

  let {
    data: depsData,
    isSuccess: depsSuccess,
    isLoading: depsLoading,
  } = useGetDepartmentsByBranchQuery(skipDepsToken);
  useEffect(() => {
    if (branchId) {
      setSkipDepsToken(branchId);
    }
    if (depsSuccess) {
    }
  }, [depsSuccess]);
  const { cardTheme } = useTheme();
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const deleteRecivedRequest = () => toast.success("Deleted");
  let { data: DataMe, isSuccess: success } = useGetMeQuery();

  let [deleteDepartment] = useDeleteDepartmentMutation();
  const handleDeleteDepartment = (id) => {
    deleteDepartment({ branch: branchId, department: id }).then(
      ({ data, error }) => {
        if (data) {
          toast.success(
            lang == "en"
              ? "Department Deleted Successfully"
              : "تم حذف القسم بنجاح"
          );
        } else if (error) {
          toast.error(
            lang == "en" ? "Department Cant be Deleted " : "لا يمكن حذف القسم"
          );
        }
      }
    );
  };
  useEffect(() => {
    if (confirmDelete) {
      handleDeleteDepartment(confirmDelete);
    }
  }, [confirmDelete]);
  return (
    <div
      className="p-sm-4 p-2 pb-5"
      style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
    >
      <h1
        className={`text-center my-5  ${
          mode === "light" ? "text-dark" : "text-white"
        }`}
      >
        {lang === "en" ? "Departments" : "الاقسام"}
      </h1>{" "}
      <Grid container sx={{ mx: 0, px: 0 }}>
        {depsLoading ? (
          <Spinner />
        ) : depsSuccess ? (
          [...depsData?.data]?.map((data, index) => {
            return (
              <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
                <Box
                  key={index}
                  sx={{
                    width: {
                      xl: "92.5%",
                      lg: "92.5%",
                      md: "92.5%",
                      sm: "90%",
                      xs: "90%",
                    },
                    margin: "0 auto",
                  }}
                >
                  <div
                    className={` ${styles.columContent} position-relative`}
                    style={{
                      backgroundColor: cardTheme[`bg_${mode}`],
                      borderRadius: "10px",
                    }}
                  >
                    {success && DataMe?.data?.role != "employee" && (
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          top: "6px",
                          left: 0,
                          width: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        <UpdateDepartment data={data} lang={lang} mode={mode} />
                        <DeleteModel
                          itemId={data?._id}
                          setConfirmDelete={setConfirmDelete}
                          confirm_en={`Are You Sure That You Want To Delete This Department Cause 
                          it will delete all the related data like 
                          And Shifts And Breaks And Over Time ... etc
                          `}
                          confrim_ar={`
                          هل أنت متأكد من أنك تريد حذف سبب هذا القسم
 سيتم حذف جميع البيانات ذات الصلة مثل
      و نوبات عمل و استراحات و بمرور الوقت ... الخ
                          `}
                        />
                      </Box>
                    )}

                    <div className="p-2 w-100 ">
                      <div
                        className="d-sm-flex d-block align-items-center gap-3"
                        style={{
                          color: mode === "light" ? "#000" : "#fff",
                        }}
                      >
                        <Box
                          sx={{
                            width: 0.8,
                            mt: 2,
                            height: "70px",
                          }}
                        >
                          <h4
                            className={
                              mode === "light" ? "text-dark" : "text-white"
                            }
                            style={{
                              wordWrap: "break-word",
                            }}
                          >
                            {data?.name.length > 50
                              ? data?.name.slice(0, 50) + "..."
                              : data?.name}
                          </h4>
                        </Box>
                      </div>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap="10px"
                        mt="25px"
                        mb="15px"
                      >
                        <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                        <Typography
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                          }}
                        >
                          {dayjs(data.createdAt).format("DD MMM YY-H:m")}
                        </Typography>
                      </Stack>
                    </div>
                  </div>
                </Box>
              </Grid>
            );
          })
        ) : null}
      </Grid>
      {success && DataMe?.data?.role !== "employee" && (
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <AddDepartmentModal lang={lang} mode={mode} />
        </Box>
      )}
    </div>
  );
}
