import { Box, Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HOC from '../../../components/HOC/HOC'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import styled from '@emotion/styled'
import ProfileEmp from './mui/profileEmploye'
import ContractPanel from './mui/ContractPanel'
import DocumentPanel from './mui/DocumentPanel'
import PayrollPanel from './mui/PayrollPanel'
import { toast } from 'react-toastify'
import { useGetMeQuery, UserApi } from '../../../redux/features/userApi'
import Spinner from '../../spinner/Spinner'
import ErrorScreen from '../../errorScreen/ErrorScreen'
import { useNavigate } from 'react-router-dom'

export const EmployeeProfile = () => {
  const navigate = useNavigate()
  const { value: mode } = useSelector((state) => state).mode
  const chartBGColor = mode === 'light' ? '#fff' : '#3E3E42'
  const { value: lang } = useSelector((state) => state).lang
  const [value, setValue] = React.useState('profile')
  let { data, isLoading, isSuccess, isError, refetch } = useGetMeQuery(
    localStorage.getItem('userToken'),
  )

  useEffect(() => {
    setValue(!localStorage.getItem('companyId') ? 'companies' : 'profile')
    // refetch()
  }, [])
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const TabStyled = styled(Tab)({
    color: '#8B7F7F',
    border: '0px',
    borderRadius: '6px',
    minHeight: 0,
    minWidth: 0,
    textTransform: 'capitalize',
    marginLeft: lang === 'en' ? '5px' : undefined,
    padding: '7px 12px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: mode === 'light' ? '#000' : '#fff',
    },
    '&.Mui-selected': {
      backgroundColor: '#0077B6',
      color: '#fff',
    },
  })

  return (
    <>
      {' '}
      <HOC>
        <div className="logic-wrapper">
          {!isLoading ? (
            isError ? (
              <ErrorScreen />
            ) : (
              <Box
                sx={{
                  padding: '20px',
                }}
              >
                <Box
                  bgcolor={chartBGColor}
                  sx={{
                    borderRadius: '15px',
                  }}
                >
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                          sx={{
                            padding: '20px',
                            '.MuiTabs-indicator': {
                              display: 'none',
                            },
                          }}
                        >
                          <TabStyled
                            label={lang === 'en' ? 'profile' : 'الملف الشخصي'}
                            value="profile"
                          />
                          <TabStyled
                            label={lang === 'en' ? 'contract' : 'العقد'}
                            value="contract"
                          />
                          <TabStyled
                            label={lang === 'en' ? 'document' : 'الوثيقة'}
                            value="document"
                          />
                          <TabStyled
                            label={lang === 'en' ? 'payroll' : 'كشف الرواتب'}
                            value="payroll"
                          />
                        </TabList>
                      </Box>
                      <ProfileEmp value="profile" />
                      <ContractPanel value="contract" />
                      <DocumentPanel value="document" />
                      <PayrollPanel value="payroll" />
                    </TabContext>
                  </Box>
                </Box>
              </Box>
            )
          ) : (
            <Spinner />
          )}
        </div>
      </HOC>
    </>
  )
}
