import { Box } from "@mui/material";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";

function FormikInputAndErrors({ name, formik, type }) {
  const { value: lang } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;

  let { values, errors, handleChange, handleBlur, touched } = formik;

  const handleLabels = {
    fullName_en: ["Full Name in English", "الاسم الكامل باللغة الإنجليزية,"],
    fullName_ar: ["full Name in Arabic", "الاسم الكامل بالعربي"],
    userName_en: ["User Name in English", "اسم المستخدم باللغة الإنجليزية"],
    userName_ar: ["User Name in Arabic", "اسم المستخدم بالعربية"],
    email: [" Email", "بريدك الالكتروني"],
    position: [" Job Title", "المسمى الوظيفي"],
    password: [" Password", "كلمة مرورك"],
    nationalId: [" NationalId", "الهوية الوطنية"],
    phone: [" phone", "الهاتف"],
    branch: [" Branch", "أدخل فرعك"],
    department: ["  Department", "أدخل قسمك"],
    company: ["  Company", "أدخل شركتك"],
    shift: ["  Shift", "أدخل التحول الخاص بك"],
    birthDate: [" birthDate", "تاريخ الولادة"],
    nationality: ["nationality", "جنسية"],
    address: ["address", "عنوانك"],
  };
  const handlePlaceHolders = () => {
    let temp =
      name == "birthDate"
        ? "YYYY/MM/DD"
        : lang === "en"
        ? "Write here..."
        : "اكتب هنا...";
    return temp;
  };
  const handleLanguage = (name) => {
    return lang == "en" ? handleLabels[name][0] : handleLabels[name][1];
  };
  const handleCloseClicked = (name) => {
    formik.setFieldValue(name, "");
  };
  const handleformikErrors = (name) => {
    return (
      <>
        {errors[name] && touched[name] && (
          <p
            style={{
              position: "absolute",
              margin: "0px 4px",
              color: "red",
              fontSize: "11px",
              fontFamily: "cursive",
              fontWeight: "bolder",
            }}
          >
            {errors[name] && errors[name]}
          </p>
        )}
      </>
    );
  };
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12 px-0">
        <Box
          className="position-relative pb-4"
          sx={{
            margin: "0 auto",
            width: {
              lg: "94%",
              md: "94%",
              sm: "94%",
              xs: "100%",
            },
          }}
        >
          <label
            htmlFor="employeeName"
            className={`form-label  fw-bold ${
              mode === "light" ? "text-dark" : "text-white"
            }`}
          >
            {handleLanguage(name)}
          </label>
          <div
            className={`form-control d-flex justify-content-between align-items-center 
          ${touched[name] && errors[name] ? "border-danger" : undefined}
          `}
            style={{
              backgroundColor: mode === "light" ? "#fff" : "#252526",
            }}
          >
            <input
              name={name}
              className="border-0"
              placeholder={handlePlaceHolders()}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[name]}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                color: mode === "light" ? "#000" : "#fff",
              }}
              type={type ? type : "text"}
            />
            {values[name] ? (
              <AiOutlineClose
                style={{
                  cursor: "pointer",
                  color: mode === "light" ? "#000" : "#fff",
                }}
                onClick={() => handleCloseClicked(name)}
              />
            ) : undefined}
          </div>
          {handleformikErrors(name)}
        </Box>
      </div>
    </>
  );
}

export default FormikInputAndErrors;
