import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { useRef } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Divider,
  FormLabel,
  InputBase,
  Radio,
  Stack,
  Typography,
} from "@mui/material";

import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";
import { switchLanguage } from "../../../../redux/slices/LanguageSlice";
export default function LangMenu({ lang, mode }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  function directionRight() {
    dispatch(switchLanguage());
  }
  function directionLeft() {
    dispatch(switchLanguage());
  }
  return (
    <Box
      sx={{
        display: {
          xl: "block",
          lg: "block",
          md: "block",
          sm: "block",
          xs: "none",
        },
        mr: lang === "en" ? "5px" : undefined,
        ml: lang === "ar" ? "2px" : undefined,
      }}
    >
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          endIcon={
            <KeyboardArrowDownIcon
              sx={{
                m: 0,
                color: mode === "light" ? "#000" : "#fff",
              }}
            />
          }
          sx={{
            "&:hover .svg_icon": {
              color: mode === "light" ? "#000" : "#fff",
            },
          }}
        >
          <LanguageIcon
            className="svg_icon"
            sx={{
              color: open ? (mode === "light" ? "#000" : "#fff") : "#757575",
              ml: lang === "ar" ? 1 : undefined,
            }}
          />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            width: "auto",
            zIndex: 2,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}>
              <Paper
                sx={{
                  backgroundColor: mode === "light" ? "#fff" : "#252525",
                  px: "10px",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{color : mode === "dark" ? "#fff" : undefined }}
                  >
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={lang}
                        name="radio-buttons-group"
                        disableRipple
                      >
                        {["en", "ar"].map((language, langIndex) => (
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              py={1.5}
                              gap={1.5}
                              px={2}
                            >
                              <input
                                type="radio"
                                checked={language === lang ? true : false}
                                value={language}
                                id={language}
                                name="language"
                                onChange={() => {
                                  lang === "en"
                                    ? directionLeft()
                                    : directionRight();
                                  setOpen(false);
                                }}
                                sx={{
                                  cursor: "pointer",
                                }}
                                F
                              />
                              <Typography
                                component={"label"}
                                htmlFor={language}
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                {langIndex === 0
                                  ? lang === "en"
                                    ? "English - EN"
                                    : "الانجليزية"
                                  : lang === "en"
                                  ? "Arabic - AR"
                                  : "العربية"}
                              </Typography>
                            </Stack>
                            {langIndex === 0 ? (
                              <Divider
                                variant="fullWidth"
                                sx={{ bgcolor: mode === "light" ? "#111" : "#fff" }}
                              />
                            ) : undefined}
                          </>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Box>
  );
}
