import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "./i18n";
import { BrowserRouter as Routers } from "react-router-dom";
import ReduxStore from "./redux/store";
import { ThemeProvider } from "@mui/material";
import theme from "./components/theme/theme";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={ReduxStore}>
    <ThemeProvider theme={theme}>
      <Routers>
        <App />
      </Routers>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
