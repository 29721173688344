import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useState } from "react";
import { ReactComponent as PinkIcon } from "./images/pink-icon.svg";
import { useGetTasksQuery } from "../../../redux/features/tasksApi";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";

import { imageUrl } from "../../../App";
import { Avatar, AvatarGroup, Box, Grid, Typography } from "@mui/material";
export default function HomeTask({ mode, lang, chartBGColor }) {
  const [t] = useTranslation();
  const [date, setDate] = useState([new Date(), new Date()]);
  const { data, isSuccess } = useGetTasksQuery();
  return (
    <div
      className="p-sm-5 p-3 mb-3"
      style={{ backgroundColor: chartBGColor, borderRadius: "15px" }}
    >
      <h5
        className={`fw-bold my-3 ${
          mode === "light" ? "text-dark" : "text-white"
        }`}
      >
        {lang === "en" ? "Tasks" : "المهمات"}
      </h5>
      <Grid mt="20px" container /* className="row g-3" */>
        {/* {isSuccess &&
        data?.tasks?.slice(0, 2).map((data, index) => (
          <div className="col-lg-6" key={index}>
            <div
              className="d-flex flex-column px-2 py-3 mb-3 "
              style={{
                borderRadius: '10px',
                border: '1px solid var(--div-border)',
              }}
            >
              <div>
                <h4
                  className={`fw-bold ${
                    mode === 'light' ? 'text-dark' : 'text-white'
                  }`}
                >
                  {data?.title}
                </h4>
                <p
                  className={mode === 'light' ? 'text-dark' : 'text-white'}
                >
                  {data?.description?.slice(0, 30)}
                </p>
              </div>

              {data?.to?.map((user) => (
                <div className="w-50 d-flex gap-2">
                  <img
                    src={imageUrl + user.image}
                    alt=""
                    className="w-25"
                  />
                </div>
              ))}
              <div
                className={`d-flex  align-items-center  px-2 ms-auto  mt-3 border ${
                  mode === 'light' ? 'border-light' : 'border-white'
                }  `}
                style={{
                  // backgroundColor: "var( --gray-bg)",
                  borderRadius: '10px',
                }}
              >
                <div
                  className="  d-flex align-items-center "
                  style={{ width: '20%' }}
                >
                  <PinkIcon className="w-50" />
                </div>
                <div className="d-flex  align-items-center  justify-content-between w-100 py-2">
                  <div className="d-flex flex-column  ">
                    <span
                      className={`fw-bold text-center ${
                        mode === 'light' ? 'text-dark' : 'text-white'
                      }`}
                    >
                      {lang === 'en' ? 'From' : 'من'}
                    </span>
                    <span
                      className={
                        mode === 'light' ? 'text-dark' : 'text-white'
                      }
                    >
                      {dayjs(`${data?.start}`).format('DD-MM-YYYY:H:m')}
                    </span>
                  </div>

                  <div className=" d-flex flex-column">
                    <span
                      className={`fw-bold text-center ${
                        mode === 'light' ? 'text-dark' : 'text-white'
                      }`}
                    >
                      {lang === 'en' ? 'To' : 'إلي'}
                    </span>
                    <span
                      className={
                        mode === 'light' ? 'text-dark' : 'text-white'
                      }
                    >
                      {dayjs(`${data?.end}`).format('DD-MM-YYYY:H:m')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))} */}
        {isSuccess &&
          data?.tasks?.slice(0, 2).map((data, index) => (
            <Grid xl={6} lg={6} md={12} sm={12} xs={12} key={index} pb="20px">
              <div
                className="d-flex flex-column mx-auto p-3 mb-3 "
                style={{
                  borderRadius: "10px",
                  backgroundColor: mode === "light" ? "#F2F8FD" : "#252525",
                  width: "95%",
                }}
              >
                <div>
                  <h4
                    className={`fw-bold ${
                      mode === "light" ? "text-dark" : "text-white"
                    }`}
                  >
                    {data?.title}
                  </h4>
                  <p
                    className={mode === "light" ? "text-dark" : "text-white"}
                    title={
                      data?.description.length > 30
                        ? data?.description
                        : undefined
                    }
                  >
                    {data?.description?.slice(0, 30)}
                  </p>
                </div>
                <AvatarGroup
                  sx={{
                    justifyContent: "flex-end",
                  }}
                >
                  {data?.to?.map((user) => (
                    <Avatar src={imageUrl + user.image} alt="" />
                  ))}
                </AvatarGroup>
                <div className="">
                  <div className="mt-3 d-flex align-items-center gap-2">
                    <CalendarMonthIcon
                      sx={{ color: "#FF2366", fontSize: "20px" }}
                    />
                    <div className="d-flex align-items-center w-100 py-2">
                      <div className="d-flex flex-column  ">
                        <span
                          className={`fw-bold text-center ${
                            mode === "light" ? "text-dark" : "text-white"
                          }`}
                        >
                          {lang === "en" ? "From" : "من"}
                        </span>
                        <span
                          className={
                            mode === "light" ? "text-dark" : "text-white"
                          }
                        >
                          {dayjs(`${data?.start}`).format("DD-MM-YYYY:H:m")}
                        </span>
                      </div>

                      <div className=" d-flex flex-column">
                        <span
                          className={`fw-bold text-center ${
                            mode === "light" ? "text-dark" : "text-white"
                          }`}
                        >
                          {lang === "en" ? "To" : "إلي"}
                        </span>
                        <span
                          className={
                            mode === "light" ? "text-dark" : "text-white"
                          }
                        >
                          {dayjs(`${data?.end}`).format("DD-MM-YYYY:H:m")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <Box mt="15px">
                  {data?.status === "completed" ? (
                    <Avatar
                      alt="Remy Sharp"
                      sx={{
                        height: "75px",
                        width: "75px",
                        borderRadius: "8px",
                      }}
                      src="https://apps.odoo.com/web/image/loempia.module/131513/icon_image?unique=c0d17d9"
                    />
                  ) : (
                    <SyncProblemIcon
                      sx={{
                        color: "#E17203",
                        fontSize: "70px",
                      }}
                    />
                  )}
                </Box>
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
