import { Box, InputBase, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const InputTextProfile = ({
  label,
  value,
  name,
  error,
  touched,
  handleChange,
  handleBlur,
  type,
}) => {
  const { value: mode } = useSelector((state) => state).mode;
  return (
    <Box
      with="100%"
      sx={{
        paddingBottom: "20px",
        position: "relative",
      }}
    >
      <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
        {label}
      </Typography>
      <InputBase
        sx={{
          width: {
            lg: "92%",
            md: "95%",
            sm: "100%",
            xs: "100%",
          },
          display: "block",
          padding: "8px",
          borderRadius: "2",
          outline: 0,
          border: `1px solid ${error && touched ? "red" : "transparent"}  `,
          backgroundColor:
            error && touched
              ? undefined
              : mode === "dark"
              ? "#252525"
              : "#E0F2FF",
          color: mode === "light" ? "#000" : "#fff",
        }}
        name={name}
        type={type == "date" ? "date" : "text"}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={name == "role" ? true : false}
      />
      <Typography
        sx={{
          color: "#f00",
          fontWeight: 600,
          margin: 0,
          position: "absolute",
          bottom: "0",
          padding: "0 3px",
          fontSize: "12px",
        }}
      >
        {error && touched && error}
      </Typography>
    </Box>
  );
};

export default InputTextProfile;
