import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
export const departmentsApis = createApi({
  reducerPath: 'department',
  tagTypes: ['Department'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getDepartments: builder.query({
      query: (id) => `department/${id}`,
      providesTags: ['Department'],
    }),
    getDepartmentsByBranch: builder.query({
      query: (branchId) => `/department/${branchId}`,
      providesTags: ['Department'],
    }),
    createDepartment: builder.mutation({
      query: (data) => ({
        url: `department`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Department'],
    }),
    updateDepartment: builder.mutation({
      query: ({ data, branch, id }) => ({
        url: `department/${branch}/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Department'],
    }),
    deleteDepartment: builder.mutation({
      query: ({ branch, department }) => ({
        url: `department/${branch}/${department}`,
        method: 'DELETE',

      }),
      invalidatesTags: ['Department']
    })


  }),
})

export const {
  useGetDepartmentsQuery,
  useGetDepartmentsByBranchQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useLazyGetDepartmentsByBranchQuery

} = departmentsApis
