import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const subscriptionsApi = createApi({
    reducerPath: 'subscription',
    tagTypes: ['Subscription'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
        prepareHeaders: (headers) => {
            headers.set('Authentication', localStorage.getItem('userToken'))
            return headers;
        },

    }),
    endpoints: (builder) => ({
        getSubscriptionByUser: builder.query({
            query: () => `/subscription/getSubscriptionByUser`,
            providesTags: ['Subscription']
        })
    })
})


export const { useGetSubscriptionByUserQuery } = subscriptionsApi