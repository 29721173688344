import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import HOC from "../../../components/HOC/HOC";
import { useGetReqAndAlertByIdQuery } from "../../../redux/features/categoryApis";
import Spinner from "../../spinner/Spinner";
import { useParams } from "react-router";
import { imageUrl } from "../../../App";
import { useGetMeQuery } from "../../../redux/features/userApi";

const SingleAlertPage = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const { id } = useParams();
  //GET ALERT
  const {
    data: data,
    isSuccess: success,
    isLoading: loading,
  } = useGetReqAndAlertByIdQuery(id);
  // ME INFO
  let { data: dataMe, isSuccess: successMe } = useGetMeQuery();
  return (
    <HOC>
      {success ? (
        <Box padding={3}>
          <Box
            sx={{
              backgroundColor: chartBGColor,
              borderRadius: "15px",
              paddingTop: "50px",
            }}
          >
            <Typography
              align="center"
              variant="h5"
              sx={{
                fontWeight: "bolder",
                textAlign: "center",
                color: mode === "light" ? "#000" : "#fff",
              }}
            >
              {data?.data?.type === "alert"
                ? lang === "en"
                  ? "Details Alert"
                  : "تفاصيل التعويم"
                : lang === "en"
                ? "Details Request"
                : "نفاصيل الطلب"}
            </Typography>
            <Box
              sx={{
                padding: 4,
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  {data.data.title}
                </Typography>
                <Typography
                  variant="paragraph"
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                ></Typography>
              </Box>
              <Box marginTop={3}>
                <Typography
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  {data.data.description}
                </Typography>
                <Typography
                  variant="paragraph"
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                ></Typography>
              </Box>
              <Box marginTop={3}>
                <Typography
                  variant="h6"
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  {lang === "en" ? "Assign to" : "تعيين إلي"}
                </Typography>
                <AvatarGroup
                  sx={{
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={
                      imageUrl +
                      `${
                        successMe && dataMe.data.role === "root"
                          ? data.data.to?.image
                          : data.data.from?.image
                      }`
                    }
                  />
                </AvatarGroup>
                {
                  data?.data?.type === "requset" &&
                  <Stack direction="row" justifyContent="flex-start" mt="30px">
                  {/* {data?.data?.status && (
                    
                  )} */}
                  <Alert
                    severity={data?.data?.status ? "success" : "warning"}
                    variant="outlined"
                  >
                    <Typography
                      component="span"
                      fontWeight={"bolder"}
                      sx={{
                        color: data?.data?.status ? "#418945" : "#EF7B1C",
                        mx: lang === "ar" ? "10px" : undefined,
                      }}
                    >
                      {lang === "en" ? "Status" : "الحالة"}
                    </Typography>
                    :{" "}
                    <Typography
                      component="span"
                      sx={{
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      { data?.data?.status
                        ? lang === "en"
                          ? "Accepted!"
                          : "تمت الموافقة"
                        : lang === "en"
                        ? "Pending"
                        : "معلق"}
                    </Typography>
                  </Alert>
                </Stack>
                }
              
              </Box>
            </Box>
          </Box>
        </Box>
      ) : loading ? (
        <Spinner />
      ) : null}
    </HOC>
  );
};

export default SingleAlertPage;
