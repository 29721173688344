import TabPanel from "@mui/lab/TabPanel";
import {
  Avatar,
  Box,
  Grid,
  Modal,
  Stack,
  Typography,
  useTheme,
  Button,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { imageUrl } from "../../../../App";
import {
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetUserDocumentsQuery,
} from "../../../../redux/features/documentsApi";
import { useUploadImageMutation } from "../../../../redux/features/uploadsApi";
import Spinner from "../../../spinner/Spinner";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
const DocumentPanel = ({ value }) => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;

  const { modalTheme } = useTheme();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setErrorFile("");
  };

  const onFileClicked = () => {
    imageRef.current.click();
  };
  let [uploadImage] = useUploadImageMutation();
  const [file, setFile] = useState(null);
  const [errorFile, setErrorFile] = useState("");
  const imageRef = useRef(null);

  const handleUploadFile = (e) => {
    // call the upload method for uploading the image
    if (e.target.files[0]) {
      setFile((_) => e.target.files[0]);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      setErrorFile("");
      // uploadImage(formData).then(({ data }) => {
      //   // should get the data then use the it with docuemnt and save it for the user
      // })
    }
  };

  const renderFileLogic = () => {
    return (
      <Box
        sx={{
          height: "15em",
          background: mode === "light" ? "#E0F2FF" : "#444",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0.5em",
          width: "200px",
          mx: "auto",
          border: 1,
          borderColor: errorFile ? "red !important" : "transparent",
        }}
      >
        <input
          type="file"
          accept="/*"
          style={{ display: "none" }}
          id="upload-img"
          onChange={handleUploadFile}
          ref={imageRef}
        />

        {file ? (
          file.type.startsWith("image") ? (
            <img
              src={URL.createObjectURL(file)}
              alt="personal Profile"
              style={{
                width: "90%",
                height: "90%",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "0.5em",
              }}
              onClick={onFileClicked}
            />
          ) : (
            <Box
              sx={{
                background: "transparent",
                height: "15em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  margin: "10px 0",
                  color: mode === "dark" ? "#fff" : undefined,
                }}
              >
                {file?.name}
              </p>
              <p
                style={{
                  margin: "10px 0",
                  color: mode === "dark" ? "#fff" : undefined,
                }}
              >
                {file?.size}
              </p>
            </Box>
          )
        ) : (
          <Box
            sx={{
              height: "15em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <AiOutlinePlus
              style={{ cursor: "pointer" }}
              onClick={onFileClicked}
              size={50}
              color={mode == "dark" ? "white" : "black"}
            />
          </Box>
        )}
      </Box>
    );
  };
  const modalBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xl: "600px",
      sm: "50%",
      xs: "90%",
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    outline: 0,
    boxShadow: 24,
    p: 4,
    pb: 7,
    borderRadius: "20px",
    direction: lang === "en" ? "ltr" : "rtl",
  };
  const [createDocument] = useCreateDocumentMutation();
  const [showes, setShowes] = useState([]);

  const handleSubmitFile = () => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      uploadImage(formData).then(({ data, error }) => {
        if (data) {

          createDocument(data?.filename).then(({ data, error }) => {
            if (data) {
              toast.success(
                lang == "en"
                  ? "Your Document is Been Uploaded Successfully"
                  : "تم تحميل المستند الخاص بك بنجاح"
              );
              handleClose();
            } else if (error) {
              toast.error(
                lang == "en" ? "Technical Error Happenend" : "حدث خطأ فني"
              );
            }
          });
          setFile(null);
        } else if (error) {
          toast.error(lang == "en" ? "Cant Upload File" : "");
        }
      });
    } else {
      setErrorFile(lang === "en" ? "upload file" : "قم بتحميل صورة");
    }
  };

  let {
    data: docsData,
    isSuccess: docsSuccess,
    isLoading: docsLoading,
  } = useGetUserDocumentsQuery();
  const handleShow = (index) => {
    let tempShowes = showes.map((show, showIndex) =>
      showIndex == index ? !show : show
    );
    setShowes((_) => tempShowes);
  };
  let [deleteDocument] = useDeleteDocumentMutation();
  const handleDeleteDocument = (id) => {
    deleteDocument(id).then(({ data, error }) => {
      if (data) {
        toast.success(data[`message_${lang}`]);
      } else if (error) {
        toast.error(error[`error_${lang}`]);
      }
    });
  };
  useEffect(() => {
    if (docsSuccess) {
      setShowes((_) => docsData?.documents.map((doc) => false));
    }
  }, [docsSuccess]);
  return (
    <TabPanel value={value}>
      <Grid container spacing={2}>
        {docsLoading ? (
          <Spinner />
        ) : docsSuccess ? (
          docsData?.documents.map((document, index) => {
            const indexOfdot = document?.path?.indexOf(".");
            const fileExtension = document?.path?.slice(
              indexOfdot + 1,
              document?.path?.length
            );
            return (
              <Grid
                item
                xl={3}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                sx={{ pb: "30px" }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: {
                      lg: 0.8,
                      md: 0.8,
                      sm: 0.9,
                      xs: 1,
                    },
                    mx: "auto",
                    height: "250px",
                    borderRadius: "10px",
                    bgcolor: mode === "light" ? "#F2F8FD" : "#252525",
                    position: "relative",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <Avatar
                      src="https://uas.edu.kw/wp-content/uploads/2018/12/folder-icon.png"
                      sx={{
                        height: "150px",
                        width: "150px",
                        borderRadius: 0,
                        objectFit: "contain",
                        mx: "auto",
                      }}
                    />
                    <Typography
                      sx={{
                        mt: "5px",
                        fontWeight: "bolder",
                        color: mode === "dark" ? "#fff" : undefined,
                      }}
                    >
                      {fileExtension}
                    </Typography>
                  </Box>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                      position: "absolute",
                      top: "6px",
                      left: 0,
                      width: 1,
                    }}
                  >
                    <a
                      href={imageUrl + document?.path}
                      alt="ss"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {fileExtension === "png" ||
                      fileExtension === "jpg" ||
                      fileExtension === "pdf" ? (
                        <RemoveRedEyeIcon
                          sx={{
                            fontSize: "22px",
                            color: mode === "light" ? "#444" : "#aaa",
                            cursor: "pointer",
                            "&:hover": {
                              color: mode === "light" ? "#000" : "#ffff",
                            },
                          }}
                          onClick={() => handleShow(index)}
                        />
                      ) : (
                        <DownloadIcon
                          sx={{
                            fontSize: "22px",
                            color: mode === "light" ? "#444" : "#aaa",
                            cursor: "pointer",
                            "&:hover": {
                              color: mode === "light" ? "#000" : "#ffff",
                            },
                          }}
                          onClick={() => handleShow(index)}
                        />
                      )}
                    </a>
                    <CancelIcon
                      sx={{
                        fontSize: "22px",
                        mx: "6px",
                        color: mode === "light" ? "#444" : "#aaa",
                        cursor: "pointer",
                        "&:hover": {
                          color: mode === "light" ? "#000" : "#ffff",
                        },
                      }}
                      onClick={() => handleDeleteDocument(document?._id)}
                    />
                  </Stack>
                </Stack>
              </Grid>
            );
          })
        ) : null}
        <Grid item xl={3} lg={4} md={6} sm={12} xs={12} sx={{ pb: "30px" }}>
          <Box
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: {
                lg: 0.8,
                md: 0.8,
                sm: 0.9,
                xs: 1,
              },
              mx: "auto",
              height: "250px",
              borderRadius: "10px",
              bgcolor: mode === "light" ? "#F2F8FD" : "#252525",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                backgroundColor: "transparent",
                border: 2,
                borderColor:
                  mode === "light" ? "#333 !important " : "#aaa !important",
                height: "60px",
                width: "50px",
                borderRadius: "50%",
              }}
              onClick={handleOpen}
            >
              <AddIcon
                sx={{
                  color: mode === "light" ? "#333" : "#aaa",
                  fontSize: "30px",
                }}
              />
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
            >
              <Box sx={modalBoxStyle}>
                <Typography
                  sx={{
                    color: mode === "dark" ? "#fff" : undefined,
                    mt: "20px",
                    mb: "50px",
                    textAlign: "center",
                  }}
                  variant="h4"
                >
                  {lang === "en" ? "Add Document" : "اضافة مستند"}
                </Typography>
                {renderFileLogic()}
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "red",
                    textAlign: "center",
                    fontWeight: "bolder",
                    mt: "20px",
                  }}
                >
                  {errorFile}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    mt: "50px",
                    gap: "20px",
                  }}
                >
                  <Button
                    onClick={handleSubmitFile}
                    sx={{
                      margin: "20px 0",
                      backgroundColor: "#0077B6 !important",
                      color: "#fff !important",
                    }}
                  >
                    {lang === "en" ? "Upload" : "رفع"}
                  </Button>
                  <Button
                    onClick={handleClose}
                    sx={{
                      margin: "20px 0",
                      backgroundColor: "transparent !important",
                      color:
                        mode === "light"
                          ? "#0077B6 !important"
                          : "#fff !important",
                      border: 1,
                      borderColor:
                        mode === "light"
                          ? "#0077B6 !important"
                          : "#fff !important",
                    }}
                  >
                    {lang === "en" ? "Cancel" : "إلغاء"}
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default DocumentPanel;
