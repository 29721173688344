import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import HOC from '../../../components/HOC/HOC'
import BranchEmployees from '../../../components/singleBranch/BranchEmployees'
import BranchShifts from '../../../components/singleBranch/BranchShifts'
import BranchDepartments from '../../../components/singleBranch/branchDepartments/BranchDepartments'
import {
  useGetBranchByIdQuery,
  useGetBranchesQuery,
} from '../../../redux/features/branchesApi'
import { useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import Spinner from '../../spinner/Spinner'
import { useGetShiftsByBranchQuery } from '../../../redux/features/shiftsApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetMeQuery } from '../../../redux/features/userApi'

const SingleBranchPage = () => {
  const { value: mode } = useSelector((state) => state).mode
  const { value: lang } = useSelector((state) => state).lang
  const chartBGColor = mode === 'light' ? '#fff' : '#3E3E42'
  const { Idbranch } = useParams()

  let [skipBranchById, setSkipBranchToken] = useState(skipToken)
  let {
    data: branchData,
    isSuccess: branchSuccess,
    isLoading: branchLoading,
  } = useGetBranchByIdQuery(skipBranchById)
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery()
  useEffect(() => {
    if (Idbranch) {
      setSkipBranchToken(Idbranch)
    }
    if (branchSuccess) {
    }
    if (userSuccess) {
      if (userData?.data?.role == "employee") {
        // toast.error(
        //   lang == "en"
        //     ? "You Dont have the pervilege to access The Branch Details"
        //     : "ليس لديك حق الوصول إلى تفاصيل الفرع"
        // );
      }
    }
  }, [branchSuccess, userSuccess])

  return (
    <HOC>
      <div>
        {branchLoading ? (
          <Spinner />
        ) : branchSuccess ? (
          <div className={` overflow-hidden p-3`}>
            <BranchDepartments
              mode={mode}
              lang={lang}
              branchId={branchData?.data?._id}
              userData={userData}
            />
            <BranchShifts mode={mode} lang={lang} id={branchData?.data?._id} />
            {/* <BranchEmployees mode={mode} lang={lang} userData={userData} /> */}
          </div>
        ) : null}
      </div>
    </HOC>
  )
}

export default SingleBranchPage
