import { Box, Container, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HOC from "../../../components/HOC/HOC";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import styled from "@emotion/styled";
import ProfilePanel from "./mui/ProfilePanel";
import ContractPanel from "./mui/ContractPanel";
import DocumentPanel from "./mui/DocumentPanel";
import PayrollPanel from "./mui/PayrollPanel";
import { toast } from "react-toastify";
import { useGetMeQuery, UserApi } from "../../../redux/features/userApi";
import CompaniesPanel from "./mui/CompaniesPanel";
import SubscriptionsPanel from "./mui/SubscriptionsPanel";
import Spinner from "../../spinner/Spinner";
import ErrorScreen from "../../errorScreen/ErrorScreen";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import ReduxStore from "../../../redux/store";
import { taskApi } from "../../../redux/features/tasksApi";
import { alertApi } from "../../../redux/features/alertsApis";
import { empolyeeApi } from "../../../redux/features/employeeApi";
import { branchesApi } from "../../../redux/features/branchesApi";
import { blogsApi } from "../../../redux/features/blogsApi";
import { companyApi } from "../../../redux/features/companyApi";
import { requsetApis } from "../../../redux/features/requestsApis";
import { attendApis } from "../../../redux/features/attendeApis";
import { contractsApi } from "../../../redux/features/contractsApi";
import { shiftsApi } from "../../../redux/features/shiftsApi";
import { breaksApis } from "../../../redux/features/breaksApis";
import { departmentsApis } from "../../../redux/features/departmentsApis";
import { overtimeApis } from "../../../redux/features/overTimeApis";
import { commentsApi } from "../../../redux/features/commentsApi";
import { payroleApi } from "../../../redux/features/payrolApi";
import { documentApi } from "../../../redux/features/documentsApi";
import { notificationsApi } from "../../../redux/features/notificationsApi";
export const ReturnProfile = () => {
  const navigate = useNavigate();
  const { value: mode } = useSelector((state) => state).mode;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const { value: lang } = useSelector((state) => state).lang;
  const [value, setValue] = React.useState("profile");
  let { data, isLoading, isSuccess, isError, refetch } = useGetMeQuery(
    localStorage.getItem("userToken")
  );
  useEffect(() => {
    setValue(!localStorage.getItem("companyId") ? "companies" : "profile");
  }, []);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    ReduxStore.dispatch(UserApi.util.resetApiState());
    ReduxStore.dispatch(taskApi.util.resetApiState());
    ReduxStore.dispatch(alertApi.util.resetApiState());
    ReduxStore.dispatch(branchesApi.util.resetApiState());
    ReduxStore.dispatch(empolyeeApi.util.resetApiState());
    ReduxStore.dispatch(blogsApi.util.resetApiState());
    ReduxStore.dispatch(companyApi.util.resetApiState());
    ReduxStore.dispatch(requsetApis.util.resetApiState());
    ReduxStore.dispatch(attendApis.util.resetApiState());
    ReduxStore.dispatch(contractsApi.util.resetApiState());
    ReduxStore.dispatch(shiftsApi.util.resetApiState());
    ReduxStore.dispatch(breaksApis.util.resetApiState());
    ReduxStore.dispatch(overtimeApis.util.resetApiState());
    ReduxStore.dispatch(departmentsApis.util.resetApiState());
    ReduxStore.dispatch(shiftsApi.util.resetApiState());
    ReduxStore.dispatch(commentsApi.util.resetApiState());
    ReduxStore.dispatch(departmentsApis.util.resetApiState());
    ReduxStore.dispatch(payroleApi.util.resetApiState());
    ReduxStore.dispatch(breaksApis.util.resetApiState());
    ReduxStore.dispatch(documentApi.util.resetApiState());
    ReduxStore.dispatch(notificationsApi.util.resetApiState());
      localStorage.clear();
    navigate("/login");
  };
  const TabStyled = styled(Tab)({
    color: "#8B7F7F",
    border: "0px",
    borderRadius: "6px",
    minHeight: 0,
    minWidth: 0,
    textTransform: "capitalize",
    marginLeft: lang === "en" ? "5px" : undefined,
    padding: "7px 12px",
    "&:hover": {
      backgroundColor: "transparent",
      color: mode === "light" ? "#000" : "#fff",
    },
    "&.Mui-selected": {
      backgroundColor: "#0077B6",
      color: "#fff",
    },
  });
  return (
    <>
      <div className="logic-wrapper">
        {!isLoading ? (
          isError ? (
            <ErrorScreen />
          ) : (
            <Box
              sx={{
                padding: "20px",
                direction: lang === "en" ? "ltr" : "rtl",
              }}
            >
              <Box
                sx={{
                  borderRadius: "15px",
                  bgcolor: chartBGColor,
                }}
              >
                <Stack direction="row" justifyContent="flex-end" py="25px">
                  <Box
                    sx={{
                      backgroundColor: "#0077B6",
                      color: "#fff",
                      width: "fit-content",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      mr: lang === "en" ? "30px" : undefined,
                      ml: lang !== "en" ? "30px" : undefined,
                      padding: "5px",
                      cursor: "pointer",
                      fontSize: {
                        lg: "20px",
                        md: "16px",
                        sm: "15px",
                        xs: "15px",
                      },
                    }}
                    onClick={handleLogout}
                  >
                    <span style={{ fontSize: "18px" }}>
                      {lang === "en" ? "Logout" : "تسجيل الخروج"}
                    </span>{" "}
                    <FiLogOut
                      size={20}
                      style={{
                        color: "#fff",
                        transform:
                          lang === "ar" ? "rotateY(180deg)" : undefined,
                      }}
                    />
                  </Box>
                </Stack>
                <Box sx={{ width: "100%", typography: "body1", mt: 0 }}>
                  <TabContext value={value}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                          padding: "20px",
                          display: "block",
                          ".MuiTabs-indicator": {
                            display: "none",
                          },
                        }}
                      >
                        <TabStyled
                          label={lang === "en" ? "profile" : "الملف الشخصي"}
                          value="profile"
                        />

                        {data?.data?.role == "root" ? (
                          <TabStyled
                            label={lang === "en" ? "companies" : "الشركات"}
                            value="companies"
                          />
                        ) : null}
                        {data?.data.role == "root" ? (
                          <TabStyled
                            label={
                              lang === "en" ? "subScription" : "الاشتراكات"
                            }
                            value="subscriptions"
                          />
                        ) : null}
                        {data?.data?.role == "employee" ||
                        data?.data?.role == "admin" ? (
                          <TabStyled
                            label={lang === "en" ? "contract" : "العقد"}
                            value="contract"
                          />
                        ) : null}
                        {data?.data?.role !== "root" ? (
                          <TabStyled
                            label={lang === "en" ? "document" : "الوثيقة"}
                            value="document"
                          />
                        ) : null}
                        {data?.data?.role == "employee" ||
                        data?.data?.role == "admin" ? (
                          <TabStyled
                            label={lang === "en" ? "payroll" : "كشف الرواتب"}
                            value="payroll"
                          />
                        ) : null}
                      </TabList>
                    </Box>
                    <ProfilePanel value="profile" />
                    <ContractPanel value="contract" />
                    <DocumentPanel value="document" />
                    <PayrollPanel value="payroll" />
                    {data?.data?.role == "root" ? (
                      <CompaniesPanel value="companies" />
                    ) : null}
                    {data?.data?.role == "root" ? (
                      <SubscriptionsPanel value="subscriptions" />
                    ) : null}
                  </TabContext>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};
