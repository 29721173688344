
import { useSelector } from "react-redux";
import NavTop from "../main_layout/navTop/NavTop";
import Sidebar from "../main_layout/sidebar/Sidebar";
import "./HOC.css";
const HOC = ({ children }) => {
  const { value: language } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;
  const logOut = () => alert("log out successed");
  return (
    <div className={`HOC_privates_${language} ${mode}-theme_HOC`}>
      <NavTop />
      <Sidebar logOut={logOut} />
      {children}
    </div>
  );
};
export default HOC;
