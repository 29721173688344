import React, { useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetSubscriptionByUserQuery } from "../../../../redux/features/subscrptionApis";
import moment from "moment";
function SubscriptionsPanel({ value }) {
  const { value: lang } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;
  const { cardTheme } = useTheme();
  const { data: subsData, isSuccess: subSuccess, isLoading: subsLoading, isError: subsError } = useGetSubscriptionByUserQuery()
  let [timeleft, setTimeLeft] = useState(null)

  console.log('subScriptionDAta: ', subsData?.subscription)


  const getTheTimeLeft = (start, end) => {

    let time = {}
    let startDate = moment(start)
    let endDate = moment(end)
    let diff = endDate.diff(startDate)
    console.log('difference: ', diff)
    let duration = moment.duration(diff)
    time = { years: duration.years(), months: duration.months(), days: duration.days() }
    setTimeLeft(_ => time)
    console.log('frontZeft: ', time)


  }
  useEffect(() => {
    console.log('subScucues; ', subSuccess)
    if (subSuccess) {
      console.log('enter')
      getTheTimeLeft(subsData?.subscription?.startDate, subsData?.subscription?.endDate)
    }
  }, [subSuccess])
  return (

    <TabPanel value={value}>
      {
        subsError ? null :
          subSuccess ?
            <Grid item xl={5} lg={10}>
              <Box
                sx={{
                  margin: {
                    xl: "0 40px",
                    lg: "0 0 0 0",
                  },
                  bgcolor: mode === "light" ? "#F2F8FD" : "#252525",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  py="20px"
                >
                  <AttachMoneyIcon
                    sx={{
                      color: "#1976D2",
                    }}
                  />
                  <Typography
                    sx={{
                      color: mode === "light" ? "#000" : "#fff",
                    }}
                  >
                    {lang === "en" ? "Subscription" : "الاشتراك"}
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    backgroundColor: cardTheme[`bg_${mode}`],
                    marginTop: "10px",
                    borderRadius: 3,
                    padding: "20px 15px",
                  }}
                >
                  {/* time left */}
                  <Box>
                    <Stack direction="row" alignItems="center">
                      <HourglassBottomIcon
                        sx={{
                          color: "#FF2366",
                        }}
                      />
                      <Typography
                        variant="h6"
                        fontSize="medium"
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >

                        {lang === "en" ? "Time Left" : " الوقت المتبقي"}
                      </Typography>
                    </Stack>
                    <Box
                      mt={1}
                      sx={{
                        display: {
                          lg: "flex",
                          md: "flex",
                          sm: "block",
                          xs: "block",
                        },
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: mode === "light" ? "#fff" : "#333",
                        borderRadius: 2,
                        px: 2,
                        py: 1,
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >

                        {lang == 'en' ? `years : ` + timeleft?.years :
                          `سنين : ` + timeleft?.years
                        }
                      </Typography>
                      <Typography
                        sx={{
                          color: mode === "dark" ? "#fff" : undefined,
                          my: {
                            lg: 0,
                            md: 0,
                            sm: "6px",
                            xs: "6px",
                          },
                        }}
                      >
                        {lang == 'en' ? `months : ` + timeleft?.months :

                          `الشهور : ` + timeleft?.months
                        }
                      </Typography>
                      <Typography
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >
                        {lang == 'en' ? `days : ` + timeleft?.days

                          : `الأيام : ` + timeleft?.days
                        }
                      </Typography>
                    </Box>
                  </Box>
                  {/* Allowance */}
                  <Box mt={3}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <CheckCircleIcon
                        sx={{
                          color: "#02BC77",
                        }}
                      />
                      <Typography
                        variant="h6"
                        fontSize="medium"
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >
                        {lang === "en" ? "Allowance" : "مخصص"}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: {
                          lg: "flex",
                          md: "flex",
                          sm: "block",
                          xs: "block",
                        },
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: mode === "light" ? "#fff" : "#333",
                        borderRadius: 2,
                        px: 2,
                        py: 1,
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >
                        {lang == 'en' ? `Maximum companies: ` + subsData?.subscription?.companiesAllowed

                          : `اقصي عدد شركات : ` + subsData?.subscription?.companiesAllowed
                        }
                      </Typography>
                      <Typography
                        sx={{
                          color: mode === "dark" ? "#fff" : undefined,
                          mt: {
                            lg: 0,
                            md: 0,
                            sm: "6px",
                            xs: "6px",
                          },
                        }}
                      >
                        {lang == 'en' ? `Maximum employees: ` + subsData?.subscription?.employeesAllowed

                          : `اقصي عدد موظفين : ` + subsData?.subscription?.employeesAllowed
                        }
                      </Typography>
                    </Box>
                  </Box>
                  {/* package */}
                  <Box mt={3}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <CardGiftcardIcon
                        sx={{
                          color: "#A52A2A",
                        }}
                      />
                      <Typography
                        variant="h6"
                        fontSize="medium"
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >

                        {lang === "en" ? "package" : `باقة  `}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: {
                          lg: "flex",
                          md: "flex",
                          sm: "block",
                          xs: "block",
                        },
                        alignItems: "center",
                        justifyContent: "space-between",
                        bgcolor: mode === "light" ? "#fff" : "#333",
                        borderRadius: 2,
                        px: 2,
                        py: 1,
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="medium"
                          sx={{ color: mode === "dark" ? "#fff" : undefined }}
                        >
                          {lang == 'en' ? `type : ` + subsData?.subscription?.package?.name_en :
                            `نوع : ` + subsData?.subscription?.package?.name_ar
                          }
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          color: mode === "dark" ? "#fff" : undefined,
                          my: {
                            lg: 0,
                            md: 0,
                            sm: "6px",
                            xs: "6px",
                          },
                        }}
                      >
                        {lang == 'en' ? `price : ` + subsData?.subscription?.paid_USD + `$`
                          :
                          `السعر : ` + subsData?.subscription?.paid_SR + `SAR`
                        }

                      </Typography>
                      {/* <Typography
                        sx={{ color: mode === "dark" ? "#fff" : undefined }}
                      >
                        {
                          lang == 'en' ? `
                          ${`Activation state : ` + subsData?.subscription?.isExpired ? `Expired` : `منتهية الصلاحية`}
                          `: `حالة التنشيط :` + !subsData?.subscription?.isExpired ? `منتهية الصلاحية` : `ساري المفعول`
                        }
                      </Typography> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid> : null}
    </TabPanel>
  );
}

export default SubscriptionsPanel;
