import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { FormControl, Grid, NativeSelect, Stack, useTheme } from '@mui/material'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useCreateShiftMutation } from '../../../redux/features/shiftsApi'
import { useParams } from 'react-router'
import CloseIcon from '@mui/icons-material/Close'

export default function AddShiftModal({ lang, mode }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    formik.resetForm()
  }
  const [createShift] = useCreateShiftMutation()
  const { modalTheme } = useTheme()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      lg: '700px',
      md: '500px',
      sm: 0.8,
      xs: 0.87,
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
    direction: lang === 'en' ? 'ltr' : 'rtl',
    height: '75%',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-track': {
      background: mode === 'light' ? '#f1f1f1' : '#555',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: mode === 'light' ? '#aac1fe' : '#000',
      transition: '0.5s all',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    ' &::-webkit-scrollbar-thumb:hover': {
      background: mode === 'light' ? '#78a3e4' : '#000',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },
  }
  const { Idbranch } = useParams()
  const formik = useFormik({
    initialValues: {
      title: '',
      start_day: 0,
      end_day: 0,
      start_hour: 0,
      end_hour: 0,
      start_mins: 0,
      end_mins: 0,
      late_hour: 0,
      late_mins: 0,
      leav_hour: 0,
      leav_mins: 0,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        lang === 'en' ? 'The title is requierd' : ' الاسم مطلوب',
      ),
      start_day: Yup.number().required(
        lang === 'en' ? 'Select start day' : 'اختار يوم البدء',
      ),
      end_day: Yup.number().required(
        lang === 'en' ? 'Select end day' : 'اختار يوم الانتهاء',
      ),
      start_hour: Yup.number().required(
        lang === 'en' ? 'Select start hour' : 'اختار ساعة البدء',
      ),
      end_hour: Yup.number().required(
        lang === 'en' ? 'Select end hour' : 'اختار ساعة الانتهاء',
      ),
      start_mins: Yup.number(),
      end_mins: Yup.number(),
      late_hour: Yup.number(),
      late_mins: Yup.number(),
      leav_hour: Yup.number(),
      leav_mins: Yup.number(),
    }),
    onSubmit: () => {
      let data = {
        name: values.title,
        start_day: Number(values.start_day),
        end_day: Number(values.end_day),
        branch: Idbranch,
        time: {
          start_hour: values.start_hour,
          end_hour: values.end_hour,
          start_mins: values.start_mins,
          end_mins: values.end_mins,
        },
        allows: {
          lateTime: {
            hours: values.late_hour,
            mins: values.late_mins,
          },
          leavingTime: {
            hours: values.leav_hour,
            mins: values.leav_mins,
          },
        },
      }
      createShift(data).then(({ error, data }) => {
        if (data) {
          toast.success(data.message_en)
          handleClose()
          resetForm()
        }
        if (error) {
          error.data[`message_${lang}`]
            ? toast.error(error.data[`message_${lang}`])
            : toast.error(error.data)
        }
      })
    },
  })
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    resetForm,
    handleChange,
  } = formik

  // let { data: branches } = useCallback(useGetBranchesQuery())
  const days = {
    1: lang === "en" ? "Sunday" : "الأحد",
    2: lang === "en" ? "Monday" : "الاثنين",
    3: lang === "en" ? "Tuseday" : "الثلاثاء",
    4: lang === "en" ? "Wednesday" : "الأربعاء",
    5: lang === "en" ? "Thursday" : "الخميس",
    6: lang === "en" ? "Friday" : "جمعة",
    0: lang === "en" ? "Saturday" : "السبت",
  }
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        marginTop: '40px',
        padding: '0 30px',
      }}
    >
      <Box border={1} borderColor="divider">
        <Button
          sx={{
            display: 'block',
            width: '100%',
            backgroundColor: '#0077B6',
            color: '#fff',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: '#0077B6',
              color: '#fff',
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === 'en' ? 'Add Shift' : 'إضافة دوام'}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  type="button"
                  sx={{
                    bgcolor: '#1976D2',
                    color: '#fff',
                    minWidth: 0,
                    height: '30px',
                    width: '30px',
                    borderRadius: '50%',
                    '&:hover': {
                      bgcolor: '#1976D2',
                      color: '#fff',
                    },
                  }}
                  disableRipple
                  onClick={handleClose}
                >
                  <CloseIcon
                    fontSize="small"
                    sx={{ color: mode === 'dark' ? '#fff' : undefined }}
                  />
                </Button>
              </Stack>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: mode === 'light' ? '#000' : '#fff',
                  textAlign: 'center',
                  my: '30px',
                }}
              >
                {lang === 'en' ? 'Create a new shift' : 'عمل دوام جديد'}
              </Typography>
              <Box
                sx={{
                  marginTop: '10px',
                  paddingBottom: '20px',
                  position: 'relative',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === 'en' ? 'left' : 'right',
                    color: mode === 'light' ? '#000' : '#fff',
                  }}
                >
                  {lang === 'en' ? 'Title' : 'العنوان'}
                </Typography>
                <input
                  type="string"
                  placeholder={lang == 'ar' ? 'اسم الدوام  ' : 'Name of shift '}
                  style={{
                    width: '100%',
                    padding: '7px',
                    backgroundColor: mode === 'light' ? '#fff' : '#252526',
                    color: mode === 'light' ? '#000' : '#fff',
                    outline: 0,
                    border:
                      errors.title && touched.title
                        ? '1px solid red'
                        : '1px solid transparent',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  }}
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    color: 'red',
                    fontWeight: 'bolder',
                    fontSize: '12px',
                  }}
                >
                  {errors.title && touched.title && errors.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Typography
                  variant="h6"
                  color={mode === 'dark' ? '#DDD' : undefined}
                >
                  {lang === 'en' ? 'Days' : 'أيام'}
                </Typography>
                <Grid container gap="40px">
                  <Grid item xl={3} xs={12}>
                    <Stack
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                      }}
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'From' : 'من'}
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          disableUnderline
                          defaultValue={30}
                          value={values.start_day}
                          name="start_day"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            borderBottom:
                              errors.start_day && touched.start_day
                                ? '1px solid red'
                                : mode === 'light'
                                ? '1px solid #999'
                                : '1px solid #aaa',
                            color:
                              errors.start_day && touched.start_day
                                ? 'red'
                                : mode === 'light'
                                ? '#000'
                                : '#fff',
                            '.MuiSvgIcon-root': {
                              color: mode === 'dark' ? '#ddd' : undefined,
                            },
                          }}
                        >
                          <option hidden selected>
                            {lang === 'en' ? 'Start' : 'البدء'}
                          </option>
                          {Object.entries(days).map((day, index) => (
                            <option value={day[0]} className={'text-dark'}>
                              {day[1]}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.start_day &&
                          touched.start_day &&
                          errors.start_day}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={3} xs={12}>
                    <Stack
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                      }}
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'To' : 'إلي'}
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          disableUnderline
                          defaultValue={30}
                          value={values.end_day}
                          name="end_day"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            borderBottom:
                              errors.end_day && touched.end_day
                                ? '1.2px solid red'
                                : mode === 'light'
                                ? '1.2px solid #999'
                                : '1.2px solid #aaa',
                            color:
                              errors.end_day && touched.end_day
                                ? 'red'
                                : mode === 'light'
                                ? '#000'
                                : '#fff',
                            '.MuiSvgIcon-root': {
                              color: mode === 'dark' ? '#ddd' : undefined,
                            },
                          }}
                        >
                          <option hidden selected>
                            {lang === 'en' ? 'Start' : 'البدء'}
                          </option>
                          {Object.entries(days).map((day, index) => (
                            <option value={day[0]} className={'text-dark'}>
                              {day[1]}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.end_day && touched.end_day && errors.end_day}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Typography
                  variant="h6"
                  color={mode === 'dark' ? '#DDD' : undefined}
                >
                  {lang === 'en' ? 'Hour' : 'ساعة'}
                </Typography>
                <Grid container gap="40px">
                  <Grid item xl={3} xs={12}>
                    <Stack
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                      }}
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'From' : 'من'}
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          disableUnderline
                          defaultValue={30}
                          value={values.start_hour}
                          name="start_hour"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            borderBottom:
                              errors.start_hour && touched.start_hour
                                ? '1px solid red'
                                : mode === 'light'
                                ? '1.2px solid #999'
                                : '1.2px solid #aaa',
                            color:
                              errors.start_hour && touched.start_hour
                                ? 'red'
                                : mode === 'light'
                                ? '#000'
                                : '#fff',
                            '.MuiSvgIcon-root': {
                              color: mode === 'dark' ? '#ddd' : undefined,
                            },
                          }}
                        >
                          <option hidden selected>
                            {lang === 'en' ? 'Start' : 'البدء'}
                          </option>
                          {[...Array(24)].map((_, index) => (
                            <option
                              key={index}
                              value={index + 1}
                              className="text-dark"
                            >
                              {`${index + 1}`}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.start_hour &&
                          touched.start_hour &&
                          errors.start_hour}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={3} xs={12}>
                    <Stack
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                      }}
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'To' : 'إلي'}
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          disableUnderline
                          defaultValue={30}
                          value={values.end_hour}
                          name="end_hour"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            borderBottom:
                              errors.end_hour && touched.end_hour
                                ? '1.2px solid red'
                                : mode === 'light'
                                ? '1.2px solid #999'
                                : '1.2px solid #aaa',
                            color:
                              errors.end_hour && touched.end_hour
                                ? 'red'
                                : mode === 'light'
                                ? '#000'
                                : '#fff',
                            '.MuiSvgIcon-root': {
                              color: mode === 'dark' ? '#ddd' : undefined,
                            },
                            overflowY: 'hidden',
                          }}
                        >
                          <option hidden selected>
                            {lang === 'en' ? 'End' : 'الانتهاء'}
                          </option>
                          {[...Array(24)].map((_, index) => (
                            <option
                              key={index}
                              value={index + 1}
                              className="text-dark"
                            >
                              {index + 1}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.end_hour && touched.end_hour && errors.end_hour}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              {/**mintes */}
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Typography
                  variant="h6"
                  color={mode === 'dark' ? '#DDD' : undefined}
                >
                  {lang === 'en' ? 'Mintes' : 'دقائق'}
                </Typography>
                <Grid container gap="40px">
                  <Grid item xl={3} xs={12}>
                    <Stack
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                      }}
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'From' : 'من'}
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          disableUnderline
                          defaultValue={30}
                          value={values.start_mins}
                          name="start_mins"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            borderBottom:
                              errors.start_mins && touched.start_mins
                                ? '1px solid red'
                                : mode === 'light'
                                ? '1.2px solid #999'
                                : '1.2px solid #aaa',
                            color:
                              errors.start_mins && touched.start_mins
                                ? 'red'
                                : mode === 'light'
                                ? '#000'
                                : '#fff',
                            '.MuiSvgIcon-root': {
                              color: mode === 'dark' ? '#ddd' : undefined,
                            },
                          }}
                        >
                          <option hidden selected>
                            {lang === 'en' ? 'Start' : 'البدء'}
                          </option>
                          {[...Array(59)].map((_, index) => (
                            <option
                              key={index}
                              value={index + 1}
                              className="text-dark"
                            >
                              {`${index + 1}`}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.start_mins &&
                          touched.start_mins &&
                          errors.start_mins}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xl={3} xs={12}>
                    <Stack
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                      }}
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'To' : 'إلي'}
                      </Typography>
                      <FormControl fullWidth>
                        <NativeSelect
                          disableUnderline
                          defaultValue={30}
                          value={values.end_mins}
                          name="end_mins"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          sx={{
                            borderBottom:
                              errors.end_mins && touched.end_mins
                                ? '1.2px solid red'
                                : mode === 'light'
                                ? '1.2px solid #999'
                                : '1.2px solid #aaa',
                            color:
                              errors.end_mins && touched.end_mins
                                ? 'red'
                                : mode === 'light'
                                ? '#000'
                                : '#fff',
                            '.MuiSvgIcon-root': {
                              color: mode === 'dark' ? '#ddd' : undefined,
                            },
                            overflowY: 'hidden',
                          }}
                        >
                          <option hidden selected>
                            {lang === 'en' ? 'End' : 'الانتهاء'}
                          </option>
                          {[...Array(59)].map((_, index) => (
                            <option
                              key={index}
                              value={index + 1}
                              className="text-dark"
                            >
                              {index + 1}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.end_mins && touched.end_mins && errors.end_mins}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Typography
                  variant="h6"
                  color={mode === 'dark' ? '#DDD' : undefined}
                >
                  {lang === 'en' ? 'Allows' : 'السمحيات'}
                </Typography>
                <Grid container gap="40px">
                  <Grid item xl={3} xs={12}>
                    <Box
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                        display: {
                          lg: 'flex',
                          md: 'flex',
                          sm: 'block',
                          xs: 'block',
                        },
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'late time' : 'السماح بتأخر '}
                      </Typography>
                      <FormControl
                        fullWidth
                        sx={{
                          mt: {
                            xl: 0,
                            lg: 0,
                            md: 0,
                            sm: '5px',
                            xs: '5px',
                          },
                        }}
                      >
                        <input
                          type="number"
                          placeholder={lang == 'ar' ? ' السعات ' : '   Hours '}
                          style={{
                            width: '100%',
                            padding: '7px',
                            backgroundColor:
                              mode === 'light' ? '#fff' : '#252526',
                            color: mode === 'light' ? '#000' : '#fff',
                            outline: 0,
                            border:
                              errors.late_hour && touched.late_hour
                                ? '1px solid red'
                                : '1px solid transparent',
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                          }}
                          name="late_hour"
                          value={values.late_hour}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.late_hour &&
                          touched.late_hour &&
                          errors.late_hour}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={3} xs={12}>
                    <Box
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                        display: {
                          lg: 'flex',
                          md: 'flex',
                          sm: 'block',
                          xs: 'block',
                        },
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'late time' : 'المساح بتأخر '}
                      </Typography>
                      <FormControl
                        fullWidth
                        sx={{
                          mt: {
                            xl: 0,
                            lg: 0,
                            md: 0,
                            sm: '5px',
                            xs: '5px',
                          },
                        }}
                      >
                        <input
                          type="number"
                          placeholder={lang == 'ar' ? ' الدقائق ' : '  Mintes '}
                          style={{
                            width: '100%',
                            padding: '7px',
                            backgroundColor:
                              mode === 'light' ? '#fff' : '#252526',
                            color: mode === 'light' ? '#000' : '#fff',
                            outline: 0,
                            border:
                              errors.late_mins && touched.late_mins
                                ? '1px solid red'
                                : '1px solid transparent',
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                          }}
                          name="late_mins"
                          value={values.late_mins}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.late_mins &&
                          touched.late_mins &&
                          errors.late_mins}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Typography
                  variant="h6"
                  color={mode === 'dark' ? '#DDD' : undefined}
                >
                  {lang === 'en' ? 'Allows' : 'السمحيات'}
                </Typography>
                <Grid container gap="40px">
                  <Grid item xl={3} xs={12}>
                    <Box
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                        display: {
                          lg: 'flex',
                          md: 'flex',
                          sm: 'block',
                          xs: 'block',
                        },
                      }}
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'Leaving Time' : ' وقت المغادرة '}
                      </Typography>
                      <FormControl
                        fullWidth
                        sx={{
                          mt: {
                            xl: 0,
                            lg: 0,
                            md: 0,
                            sm: '5px',
                            xs: '5px',
                          },
                        }}
                      >
                        <input
                          type="number"
                          placeholder={lang == 'ar' ? ' سعات  ' : '   Hours '}
                          style={{
                            width: '100%',
                            padding: '7px',
                            backgroundColor:
                              mode === 'light' ? '#fff' : '#252526',
                            color: mode === 'light' ? '#000' : '#fff',
                            outline: 0,
                            border:
                              errors.leav_hour && touched.leav_hour
                                ? '1px solid red'
                                : '1px solid transparent',
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                          }}
                          name="leav_hour"
                          value={values.leav_hour}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.leav_hour &&
                          touched.leav_hour &&
                          errors.leav_hour}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={3} xs={12}>
                    <Box
                      sx={{
                        gap: '10px',
                        position: 'relative',
                        paddingBottom: 2,
                        display: {
                          lg: 'flex',
                          md: 'flex',
                          sm: 'block',
                          xs: 'block',
                        },
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="span"
                        color={mode === 'dark' ? '#DDD' : undefined}
                      >
                        {lang === 'en' ? 'Leaving Time' : ' وقت المغادرة '}
                      </Typography>
                      <FormControl
                        fullWidth
                        sx={{
                          mt: {
                            xl: 0,
                            lg: 0,
                            md: 0,
                            sm: '5px',
                            xs: '5px',
                          },
                        }}
                      >
                        <input
                          type="number"
                          placeholder={
                            lang == 'ar' ? ' الدقائق  ' : '   Mintes '
                          }
                          style={{
                            width: '100%',
                            padding: '7px',
                            backgroundColor:
                              mode === 'light' ? '#fff' : '#252526',
                            color: mode === 'light' ? '#000' : '#fff',
                            outline: 0,
                            border:
                              errors.leav_mins && touched.leav_mins
                                ? '1px solid red'
                                : '1px solid transparent',
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                          }}
                          name="leav_mins"
                          value={values.leav_mins}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                      <Typography
                        variant="paragraph"
                        sx={{
                          marginX: '5px',
                          color: 'red',
                          fontWeight: 'bolder',
                          position: 'absolute',
                          bottom: 0,
                        }}
                      >
                        {errors.leav_mins &&
                          touched.leav_mins &&
                          errors.leav_mins}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  marginTop: '70px',
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#0077B6',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#0077B6',
                      color: '#fff',
                    },
                  }}
                  type="submit"
                >
                  {lang === 'en' ? 'Create' : 'اضافة'}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  )
}
