import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../App'
export const categoryApis = createApi({
  reducerPath: 'category',
  tagTypes: ['Category'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getCatgeories: builder.query({
      query: () => `category?company=${localStorage.getItem('companyId')}`,
      providesTags: ['Category'],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `category/${id}`,
        method: 'Delete',

      }),
      invalidatesTags: ['Category']
    }),

    // getAlertsCompany: builder.query({
    //   query: () =>
    //     `request/alerts?company=${localStorage.getItem('companyId')}`,
    //   providesTags: ['Category'],
    // }),
    // createEmployee: builder.mutation({
    //   query: (employeeData) => ({
    //     url: `user/employees`,
    //     method: 'POST',
    //     body: employeeData,
    //   }),
    //   invalidatesTags: ['Alert'],
    // }),
    addCategory: builder.mutation({
      query: ({ name }) => ({
        url: `category`,
        method: `POST`,
        body: {
          categoryType: name,
          company: localStorage.getItem('companyId'),
        },
      }),
      invalidatesTags: ['Category'],
    }),
    getReqAndAlertById: builder.query({
      query: (id) =>
        `request/${id}`,
      providesTags: ['Category'],
    }),
    // deleteEmployee: builder.mutation({
    //   query: (id) => ({
    //     url: `user/employee/${id}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: ['Alert'],
    // }),
  }),
})

export const {
  useGetCatgeoriesQuery,
  useAddCategoryMutation,
  useGetReqAndAlertByIdQuery,
  useDeleteCategoryMutation,

  // useCreateEmployeeMutation,
  // useGetEmployeesByCompanyQuery
} = categoryApis
