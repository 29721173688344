import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import moment from "moment";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../spinner/Spinner";

const PayrollTabel = ({ isLoading, isSuccess, data }) => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;

  const branches = useMemo(() => data, [data, data?.length]);

  const handleWorkingHourse = (payrol) => {
    let { lateTime, originalTime } = payrol;

    let diff = (originalTime - lateTime).toString().split(".");

    let res = "";
    if (diff.length > 1) {
      res =
        parseInt(diff[0]) + " : " + parseInt((diff[1].slice(0, 1) / 10) * 60);
    } else {
      res = parseInt(diff[0]) + " : " + "00";
    }
    // split value by .
    return res;
  };

  const handleDeductionSalary = (payrol) => {
    return Math.abs(payrol?.salary - payrol?.dailySalary).toFixed(1);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isSuccess ? (
        <TableContainer
          component={Paper}
          sx={{
            marginTop: "10px",
            backgroundColor: "transparent",
            border: 1,
            borderColor: mode === "light" ? "#F5F5F5" : "#252526",
            borderRadius: "17px",
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              overflow: "hidden",

              borderRadius: "6px",
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: mode === "light" ? "#F5F5F5" : "#252526",
              }}
            >
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    border: 0,
                  }}
                >
                  {lang === "en" ? "Name" : "الاسم"}
                </TableCell>
                <TableCell
                  sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
                  align="center"
                >
                  {lang === "en" ? "Date" : "التاريخ"}
                </TableCell>
                <TableCell
                  sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
                  align="center"
                >
                  {lang === "en" ? "Deduction" : "الاستنتاج"}
                </TableCell>
                <TableCell
                  sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
                  align="center"
                >
                  {lang === "en" ? " Work Hours" : "ساعات العمل"}
                </TableCell>
                <TableCell
                  sx={{ color: mode === "light" ? "#000" : "#fff", border: 0 }}
                  align="center"
                >
                  {lang === "en" ? "Salary" : "المرتبات"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...data].map((td, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      mode === "light" ? "transparent" : "#3E3E42",
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    align="center"
                    sx={{
                      color: mode === "light" ? "#000" : "#fff",
                      border: 0,
                    }}
                  >
                    {td?.employee[`fullName_${lang}`]}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: mode === "light" ? "#000" : "#fff",
                      border: 0,
                    }}
                    align="center"
                  >
                    {moment(td?.employee?.createdAt).format("MMMM")} -
                    {moment(td?.employee?.createdAt).format("yyyy")}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: mode === "light" ? "#000" : "#fff",
                      border: 0,
                    }}
                    align="center"
                  >
                    {handleDeductionSalary(td)}$
                  </TableCell>
                  <TableCell
                    sx={{
                      color: mode === "light" ? "#000" : "#fff",
                      border: 0,
                    }}
                    align="center"
                  >
                    {handleWorkingHourse(td)}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: mode === "light" ? "#000" : "#fff",
                      border: 0,
                    }}
                    align="center"
                  >
                    ${td?.salary.toFixed(1)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};
export default PayrollTabel;
