import { Avatar, Box, Button, InputBase } from "@mui/material";
import React, { useState } from "react";

const TextWriter = ({ lang, mode, avatarSrc, placeholder,
  handleSubmit, option, handleReply
}) => {
  const [title, setTitle] = useState('')
  const ontitleSumbit = () => {
    if (option == 'comment')
      handleSubmit(title)
    else {
      //todo
      handleReply(title)
      //something else
    }
    setTitle('')
  }
  return (
    <Box
      sx={{
        display: {
          xl: "flex",
          sm: "block",
          xs: "block",
        },
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "20px",
        marginY: "10px",
        width: {
          xl: "85%",
          xs: "100%",
        },
        position: "relative",
      }}
    >
      <Avatar
        src={avatarSrc}
        alt="hamaki"
        sx={{
          height: {
            xs: "35px",
          },
          width: {
            xs: "35px",
          },
        }}
      />
      <Box
        sx={{
          backgroundColor: mode === "light" ? "#F8F8F8" : "#252525",
          width: "100%",
          display: "block",
          borderRadius: "25px",
          position: "relative",
          mt: {
            sm: "10px",
            xs: "10px",
          },
        }}
      >
        <InputBase
          value={title}
          onChange={(e) => {
            setTitle(_ => e.target.value)
          }}
          type="text"
          sx={{
            backgroundColor: "transparent",
            color: mode === "light" ? "#000" : "#fff",
            width: "100%",
            padding: "8px 15px",
          }}
          placeholder={placeholder}
        />
        <Button
          onClick={ontitleSumbit}
          sx={{
            borderRadius: "25px",
            position: "absolute",
            right: lang === "en" ? "6px" : undefined,
            left: lang !== "en" ? "6px" : undefined,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "#1976D2",
            zIndex: 2,
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1976D2",
              color: "#fff",
            },
          }}
        >
          {lang === "en" ? "Add" : "إضافة"}
        </Button>
      </Box>
    </Box>
  );
};

export default TextWriter;
