import { useTranslation } from "react-i18next";
import React from "react";

import { useSelector } from "react-redux";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export default function HomePerformance({ mode, lang, chartBGColor }) {
  const [t] = useTranslation();
  const data = {
    labels,

    datasets: [
      {
        label: lang === "en" ? "2018 Report" : "تقرير 2018",
        data: [20, 40, 30, 40, 10, 5, 30],
        tension: 0.3,
        backgroundColor: ["#4791FF"],

        borderColor: "#4791FF",
      },
      {
        label: lang === "en" ? "2019 Report" : "تقرير 2019",
        data: [0, 30, 10, 40, 50, 30, 40, 10],
        tension: 0.3,
        backgroundColor: ["#02BC77"],
        borderColor: "#02BC77",
      },
    ],
  };
  const options = {
    scales: {
      x: {
        ticks: {
          color: mode == "light" ? "black" : "white",
          font: {
            size: 10,
          },
        },
      },
      y: {
        ticks: {
          color: mode == "light" ? "black" : "white",
          font: {
            size: 10,
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: mode == "light" ? "black" : "white",
          boxHeight: 4,
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 10,
          },
        },
      },

      title: {
        display: true,
        text: "Tasks",
        color: mode == "light" ? "black" : "white",
      },
    },
  };
  return (
    <div className="h-100">
      <div
        className="p-3 h-100"
        style={{ backgroundColor: chartBGColor, borderRadius: "20px" }}
      >
       
        <div>
          <Link
            to="/attendees"
            style={{
              color: mode === "light" ? "#000" : "#fff",
              textDecoration: "none",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
          {lang === "en" ? "Performance" : "الاداء"}
            
          </Link>
        </div>
        <div className="container h-100">
          <div className=" h-100">
            <Line options={options} data={data} className="" />
          </div>
        </div>
      </div>
    </div>
  );
}
