import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./NavStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { switchLanguage } from "../../../redux/slices/LanguageSlice";
import NotificationsMenu from "./mui/NotificationsMenu";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import moSalahImage from "./images/Salah.jpg";
import MobileSidebar from "./mui/MobileSidebar";
import { useGetMeQuery } from "../../../redux/features/userApi";
import LangMenu from "./mui/LangMenu";
import { imageUrl } from "../../../App";
import {
  useLazyGetAdminOrRootNotificationsQuery,
  useLazyGetEmployeesNotificationsQuery,
} from "../../../redux/features/notificationsApi";
const NavTop = ({ test }) => {
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const [_, { changeLanguage }] = useTranslation();
  const navigete = useNavigate();
  const dispatch = useDispatch();

  function lightMode() {
    document.body.classList.add("light-theme");
    document.body.classList.remove("dark-theme");
  }
  function directionRight() {
    dispatch(switchLanguage());
  }
  function directionLeft() {
    dispatch(switchLanguage());
  }
  function returnUserRole(role) {
    let roles = {
      admin_en: "admin",
      admin_ar: "مشرف",
      root_en: "root",
      root_ar: "المالك",
      employee_en: "employee",
      employee_ar: "الموظف",
    };
    return roles[`${role}_${lang}`];
  }

  useEffect(() => {
    lightMode();
  }, []);

  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);

  return (
    <nav className={`layout_nav layout_nav_${mode} layout_nav_${lang}`}>
      <MobileSidebar lang={lang} mode={mode} />
      <Stack direction="row" alignItems="center">
        <NotificationsMenu
          lang={lang}
          mode={mode}
          userSuccess={userSuccess}
          userData={userData}
        />
        {/* <Button
          disableRipple
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ChatIcon
            sx={{
              color: "#757575",
              "&:hover": {
                color: mode === "light" ? "#000" : "#fff",
              },
            }}
          />
        </Button> */}
        <LangMenu mode={mode} lang={lang} />
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{
            borderLeft: lang === "en" ? 1 : 0,
            borderRight: lang === "ar" ? 1 : 0,
            borderLeftColor:
              lang === "en"
                ? mode === "light"
                  ? "#B2B2B2"
                  : "#dddd"
                : undefined,
            borderRightColor:
              lang === "ar"
                ? mode === "light"
                  ? "#B2B2B2"
                  : "#dddd"
                : undefined,
            padding: "0 20px",
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={imageUrl + userData?.data.image}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigete("/profile")}
          />
          <Box
            textAlign="center"
            sx={{
              display: {
                sm: "block",
                xs: "none",
              },
            }}
          >
            {userSuccess ? (
              <Typography
                fontFamily={lang === "ar" ? "inherit" : undefined}
                variant="h6"
                sx={{
                  fontSize: "16px",
                  color: mode === "light" ? "black" : "white",
                }}
              >
                {userData?.data[`userName_${lang}`]}
              </Typography>
            ) : null}
            {userSuccess ? (
              <Typography
                fontFamily={lang === "ar" ? "inherit" : undefined}
                sx={{
                  fontSize: "13px",
                  color: mode === "light" ? "black" : "white",
                }}
              >
                {returnUserRole(userData?.data?.role)}
              </Typography>
            ) : null}
          </Box>
          <Button
            disableRipple
            variant="contained"
            sx={{
              display: {
                xl: "none",
                lg: "none",
                md: "none",
                sm: "none",
                xs: "block",
              },
              padding: "5px 10px",
              minWidth: "auto",
              height: "0",
              "&:hover": {
                color: "#fff",
              },
            }}
            onClick={() => (lang === "en" ? directionLeft() : directionRight())}
          >
            {lang === "en" ? "AR" : "EN"}
          </Button>
        </Stack>
      </Stack>
    </nav>
  );
};

export default NavTop;
