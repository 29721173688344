import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const shiftsApi = createApi({
  reducerPath: 'shifts',
  tagTypes: ['Shift'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getShiftsByBranch: builder.query({
      query: (shiftId) => ({ url: `shift/${shiftId}`, method: 'GET' }),
      providesTags: ['Shift']
    }),

    createShift: builder.mutation({
      query: (data) => ({
        url: `shift`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Shift'],
    }),
    updateShift: builder.mutation({
      query: ({ data, branch, id }) => ({
        url: `shift/${branch}/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Shift'],
    }),
    deleteShift: builder.mutation({
      query: ({ branch, id }) => ({
        url: `shift/${branch}/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Shift']
    })
  }),
})

export const { useGetShiftsByBranchQuery, useCreateShiftMutation, useUpdateShiftMutation,

  useDeleteShiftMutation, useLazyGetShiftsByBranchQuery } = shiftsApi
