import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import transilationEN from "./local/en.json";
import transilationAR from "./local/ar.json";

import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: transilationEN,
  },
  ar: {
    translation: transilationAR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspence: false,
    },
  });

export default i18n;
