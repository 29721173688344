import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid, Stack } from "@mui/material";
import DateAndTimePicker from "../../dateTimePicker/DateTimePicker";
import { useTheme } from "@emotion/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  useAddOverMutation,
  useUpdateOverMutation,
} from "../../../redux/features/overTimeApis";
import { useParams } from "react-router";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetMeQuery } from "../../../redux/features/userApi";
import {
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from "../../../redux/features/tasksApi";
import {
  useGetEmployeesByBranchQuery,
  useGetEmployeesByCompanyQuery,
  useGetEmployeesByShiftQuery,
} from "../../../redux/features/employeeApi";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import TaskEmployeeMenu from "../../../pagees/privates/tasks/employeeMenu/TaskEmployeMenu";
import { useAssginBreakMutation } from "../../../redux/features/breaksApis";

export default function AssginBreak({ lang, mode, data, branchId, shiftId }) {
  const [open, setOpen] = useState(false);
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();
  const [disableAssigning, setDisableAssigning] = useState(false);
  const [skipBranchesToken, setSkipBranchesToken] = useState(skipToken);
  const [assinUser] = useAssginBreakMutation();
  const { id, IdBreak } = useParams();

  const formik = useFormik({
    initialValues: {
      shift: skipToken,
      to: [],
    },
    validationSchema: Yup.object({
      to: Yup.array().min(1).required(),
    }),

    onSubmit: () => {
      let dataUpdated = {
        userId: values.to,
      };
      assinUser({ data: dataUpdated, shift: id, id: IdBreak }).then(
        ({ error, data }) => {
          if (data) {
            toast.success(data.message_en);
            handleClose();
          }
          if (error) {
            error.data[`error_en`]
              ? toast.error(error.data[`error_en`])
              : toast.error(error.data);
          }
        }
      );
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    setValues,
    handleBlur,
    handleSubmit,
  } = formik;

  let { data: empsByBranch, isSuccess: success } = useGetEmployeesByShiftQuery(
    values.shift
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const [updateOverTime] = useUpdateOverMutation();
  const { modalTheme } = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      lg: "800px",
      md: "70%",
      sm: "90% ",
      xs: "90%",
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    outline: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    height: "auto",
    direction: lang === "en" ? "ltr" : "rtl",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-track": {
      background: mode === "light" ? "#f1f1f1" : "#555",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: mode === "light" ? "#aac1fe" : "#252526",
      transition: "0.5s all",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    " &::-webkit-scrollbar-thumb:hover": {
      background: mode === "light" ? "#78a3e4" : "#252526",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },
  };

  useEffect(() => {
    if (userSuccess) {
      setSkipBranchesToken(true);
    }
    if (id) {
      setValues({ ...values, shift: id });
    }
  }, [userSuccess, userData?.data?.role, success, empsByBranch]);

  return (
    <div>
      <Button
        sx={{
          display: "block",
          backgroundColor: "#0077B6",
          color: "#fff",
          textTransform: "capitalize",

          "&:hover": {
            backgroundColor: "#0077B6",
            color: "#fff",
          },
        }}
        onClick={handleOpen}
      >
        {lang === "en" ? "Assign user to Break" : "اضافة موظف للبريك"}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h5"
              sx={{
                color: mode === "light" ? "#000" : "#fff",
                my: "40px",
                textAlign: "center",
              }}
            >
              {lang === "en" ? "Assign Break to user" : "اضافة موظف للبريك"}
            </Typography>
            <Grid container marginTop="15px" spacing={2}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    paddingBottom: "20px",
                    position: "relative",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      color: "red",
                      fontWeight: "bolder",
                      fontSize: "12px",
                    }}
                  >
                    {errors.branch && touched.branch && errors.branch}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Assign to" : "تعيين الي"}
                </Typography>
                <Box sx={{ mt: "3px" }}>
                  <TaskEmployeeMenu
                    lang={lang}
                    mode={mode}
                    setValues={formik.setValues}
                    error={errors.to}
                    touched={touched.to}
                    disableAssigning={disableAssigning}
                    EmpsBranchSuccess={true}
                    empsByBranch={empsByBranch}
                    userData={userData}
                  />
                </Box>
              </Grid>
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: "25px" }}
            >
              <Button
                type="submit"
                sx={{
                  bgcolor: "#1976D2",
                  color: "#fff",

                  paddingX: "40px",
                  "&:hover": {
                    bgcolor: "#1976D2",
                    color: "#fff",
                  },
                }}
              >
                {lang === "en" ? "Submit" : "تقديم"}
              </Button>
              <Button
                type="button"
                sx={{
                  bgcolor: "#1976D2",
                  color: "#fff",

                  paddingX: "40px",
                  "&:hover": {
                    bgcolor: "#1976D2",
                    color: "#fff",
                  },
                }}
                onClick={handleClose}
              >
                {lang === "en" ? "Close" : "إلغاء"}
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
