import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../App'
export const alertApi = createApi({
  reducerPath: 'alert',
  tagTypes: ['Alert'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getAlerts: builder.query({
      query: () => `request/alerts`,
      providesTags: ['Alert'],
    }),
    getAlertsCompany: builder.query({
      query: () =>
        `request/alerts?company=${localStorage.getItem('companyId')}`,
      providesTags: ['Alert'],
    }),
    deleteAlert: builder.mutation({
      query: (id) => ({
        url: `/request/${id}`,
        method: 'DELETE',

      }),
      invalidatesTags: ['Alert']
    }),
    createAlert: builder.mutation({
      query: (data) => ({
        url: `request`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Alert'],
    }),

  }),
})

export const {
  useGetAlertsQuery,
  useGetAlertsCompanyQuery,
  useCreateAlertMutation,
  useDeleteAlertMutation

} = alertApi
