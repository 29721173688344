import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useDeleteBreakMutation,
  useGetBreaksQuery,
} from "../../redux/features/breaksApis";
import { Spinner } from "react-bootstrap";
import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { imageUrl } from "../../App";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import AddShiftBreakModal from "./add_modals/AddShiftBeakModal";
import { useGetMeQuery } from "../../redux/features/userApi";
import EditBreak from "./edit_models/editeBreakModel";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function ShiftBreaks({ mode, lang, id }) {
  const [skipId, setSkipeId] = useState(skipToken);
  const navigate = useNavigate();
  const { cardTheme } = useTheme();
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const deleteRecivedRequest = () => toast.success("Deleted");
  let { data: DataMe, isSuccess: success } = useGetMeQuery();
  useEffect(() => {
    if (id) {
      setSkipeId(id);
    }
  }, []);
  const { data, isSuccess, isLoading } = useGetBreaksQuery(skipId);

  const { Idbranch, id: IdShift } = useParams();
  const params = useParams();

  const [deleteBreak] = useDeleteBreakMutation();
  const handleDeleteBreak = (breakId) => {
    deleteBreak({ shiftId: id, breakId }).then(({ data, error }) => {
      if (data) {
        toast.success(
          lang == "en" ? "Break Deleted Successfully" : "تم حذف الاستراحة بنجاح"
        );
      } else if (error) {
        toast.error(
          lang == "en"
            ? "Break Cant Be Deleted Some Error Happened"
            : "لا يمكن حذف الاستراحة حدث خطأ ما"
        );
      }
    });
  };

  return (
    <div className={`overflow-hidden`}>
      <div
        className="p-sm-4 p-3 pb-5"
        style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
      >
        <h3
          className={`px-sm-3 px-0 my-5 text-sm-${
            lang === "en" ? "start" : "end"
          } text-center  ${mode === "light" ? "text-dark" : "text-white"}`}
        >
          {lang === "en" ? "Breakstime" : "اوقات الاسنراحه"}
        </h3>
        <Grid container sx={{ mx: 0, px: 0 }}>
          {isLoading ? (
            <Spinner />
          ) : !isSuccess ? null : (
            data?.data?.map((data, index) => (
              <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
                <Box
                  key={index}
                  sx={{
                    width: {
                      xl: "92.5%",
                      lg: "92.5%",
                      md: "92.5%",
                      sm: "100%",
                      xs: "100%",
                    },
                    margin: "0 auto",
                    px: 1,
                    pt: 1,
                    backgroundColor: cardTheme[`bg_${mode}`],
                    position: "relative",
                    borderRadius: "15px",
                  }}
                >
                  {success && DataMe?.data?.role !== "employee" && (
                    <>
                      <Button
                        sx={{
                          position: "absolute",
                          top: "5px",
                          right: lang === "en" ? "15px" : undefined,
                          left: lang === "ar" ? "15px" : undefined,
                          minWidth: 0,
                          width: 0,
                          backgroundColor: "transparent",
                        }}
                        onClick={() => handleDeleteBreak(data?._id)}
                      >
                        <HighlightOffIcon sx={{ color: "#E04449" }} />
                      </Button>
                      <EditBreak mode={mode} lang={lang} data={data} />
                    </>
                  )}
                  <div className="p-2 w-100">
                    <div
                      className="d-sm-flex d-block align-items-center gap-3"
                      style={{
                        color: mode === "light" ? "#000" : "#fff",
                      }}
                    >
                      {/**name of shift */}
                      <div className="d-flex flex-column">
                        <h4
                          className={
                            mode === "light" ? "text-dark" : "text-white"
                          }
                        >
                          {data?.shift?.name?.toUpperCase()}
                        </h4>
                      </div>
                    </div>
                    {!data?.isOpen ? (
                      <Grid container sx={{ mx: 0, px: 0 }}>
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                          mt="25px"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            sx={{
                              mt: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                                fontSize: 17,
                                fontWeight: "600",
                              }}
                            >
                              {lang === "en" ? "From" : "من"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            sx={{
                              mt: "10px",
                            }}
                          >
                            <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                            <Typography
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                              }}
                            >
                              {data?.start.hours + ":" + data?.start.mins}
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          xs={12}
                          mt="25px"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            sx={{
                              mt: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                                fontSize: 17,
                                fontWeight: "600",
                              }}
                            >
                              {lang === "en" ? "To" : "الي"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            sx={{
                              mt: "10px",
                            }}
                          >
                            <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                            <Typography
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                              }}
                            >
                              {data?.end.hours + ":" + data?.end.mins}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container sx={{ mx: 0, px: 0 }}>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          mt="25px"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            sx={{
                              mt: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                                fontSize: 17,
                                fontWeight: "600",
                              }}
                            >
                              {lang === "en"
                                ? "Flexible  Break"
                                : "استراحة مرنة"}
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            sx={{
                              mt: "10px",
                            }}
                          >
                            <CalendarMonthIcon sx={{ color: "#FA6796" }} />

                            <Typography
                              sx={{
                                color: mode === "light" ? "#000" : "#fff",
                              }}
                            >
                              {data.duration.hours + ":" + data.duration.mins}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}

                    <AvatarGroup
                      sx={{
                        justifyContent: "flex-end",
                        my: "20px",
                      }}
                    >
                      {data?.users &&
                        data?.users
                          .slice(0, 5)
                          .map((emp) => (
                            <Avatar
                              alt="Remy Sharp"
                              src={`${imageUrl}${emp?.image}`}
                            />
                          ))}
                    </AvatarGroup>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      marginY={"20px"}
                    >
                      {success && DataMe?.data?.role !== "employee" && (
                        <>
                          <Button
                            sx={{
                              backgroundColor: "#0077B6",
                              color: "#fff",
                              paddingY: 0.5,
                              marginX: 1,
                              "&:hover": {
                                backgroundColor: "#0077B6",
                                color: "#fff",
                              },
                            }}
                            onClick={() => navigate(`breaks/${data?._id}`)}
                          >
                            {lang === "en" ? "open" : "فتح"}
                          </Button>
                        </>
                      )}
                    </Stack>
                  </div>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
        <Box mt="40px">
          {success && DataMe?.data?.role !== "employee" && (
            <AddShiftBreakModal lang={lang} mode={mode} />
          )}
        </Box>
      </div>
    </div>
  );
}
