import React from "react";
import "./spinner.css";
function Spinner() {
  return (
    <div className="spinner-wrapper">
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}

export default Spinner;
