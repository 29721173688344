import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Grid, InputBase, Stack } from '@mui/material'
import DateAndTimePicker from '../../dateTimePicker/DateTimePicker'
import { useTheme } from '@emotion/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import {
  useAddBreakMutation,
  useUpdateBreakMutation,
} from '../../../redux/features/breaksApis'
import { useParams } from 'react-router'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

export default function EditBreak({ lang, mode, data }) {
  React.useEffect(() => { },[data])

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    resetForm()
  }
  const [updateBreak] = useUpdateBreakMutation()
  const { modalTheme } = useTheme()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      lg: '800px',
      md: '70%',
      sm: '90% ',
      xs: '90%',
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    outline: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
    height: 'auto',
    direction: lang === 'en' ? 'ltr' : 'rtl',
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-track': {
      background: mode === 'light' ? '#f1f1f1' : '#555',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: mode === 'light' ? '#aac1fe' : '#252526',
      transition: '0.5s all',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    ' &::-webkit-scrollbar-thumb:hover': {
      background: mode === 'light' ? '#78a3e4' : '#252526',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },
  }

  const { id } = useParams()
  const formik = useFormik({
    initialValues: {
      isOpen: data?.isOpen?.toString(),
      hours: data?.duration?.hours,
      mins: data?.duration?.mins,
      start_date: new Date().setHours(data?.start?.hours, data?.start?.mins),
      end_date: new Date().setHours(data?.end?.hours, data?.end?.mins),
    },
    validationSchema: Yup.object({
      isOpen: Yup.string().required(),
      hours: Yup.number().when('isOpen', {
        is: 'true',
        then: Yup.number(),
      }),
      mins: Yup.number().when('isOpen', {
        is: 'true',
        then: Yup.number(),
      }),
      start_date: Yup.string().when('isOpen', {
        is: 'false',
        then: Yup.string(),
      }),
      end_date: Yup.string().when('isOpen', {
        is: 'false',
        then: Yup.string(),
      }),
    }),
    onSubmit: () => {
      let dataUpdated = {
        duration: {
          hours: values.hours,
          mins: values.mins,
        },
        start: {
          hours: new Date(values.start_date).getHours(),
          mins: new Date(values.start_date).getMinutes(),
        },
        end: {
          hours: new Date(values.end_date).getHours(),
          mins: new Date(values.end_date).getMinutes(),
        },
        isOpen: values.isOpen === 'true' ? true : false,
      }
      !values.isOpen || values.isOpen === 'false'
        ? delete dataUpdated.duration
        : delete dataUpdated.start && delete dataUpdated.end
      updateBreak({ data: dataUpdated, shift: id, id: data._id }).then(
        ({ error, data }) => {
          if (data) {
            toast.success(data.message_en)
            handleClose()
            resetForm()
          }
          if (error) {
            error.data[`error_${lang}`]
              ? toast.error(error.data[`error_${lang}`])
              : toast.error(error.data)
          }
        },
      )
    },
  })
  const {
    values,
    errors,
    touched,

    handleSubmit,
    setValues,
    resetForm,
    setTouched,
  } = formik
  const handleChangeDate = (dateValue) => {
    setValues({
      ...values,
      date: dateValue,
    })
  }

  const handleTouchDate = () => {
    if (values.date === null) {
      formik.setTouched({
        ...formik.touched,
        date: true,
      })
    } else {
      delete touched.date
    }
  }

  const handleChangeStartDate = (dateValue) => {
    setValues({
      ...values,
      start_date: dateValue,
    })
  }
  const handleTouchStartDate = () => {
    if (values.start_date === null) {
      setTouched({
        ...touched,
        start_date: true,
      })
    } else {
      delete touched.start_date
    }
  }

  const handleChangeEndDate = (dateValue) => {
    setValues({
      ...values,
      end_date: dateValue,
    })
  }
  const handleTouchEndDate = () => {
    if (values.end_date === null) {
      setTouched({
        ...touched,
        end_date: true,
      })
    } else {
      delete touched.end_date
    }
  }
  return (
    <div>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          sx={{
            position: 'absolute',
            top: '5px',
            right: lang === 'en' ? '45px' : undefined,
            left: lang === 'ar' ? '40px' : undefined,
            minWidth: 0,
            width: 0,
          }}
          onClick={handleOpen}
        >
          <MoreHorizIcon sx={{ color: '#black' }} />
        </Button>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h5"
              component="h2"
              align="center"
              sx={{
                color: mode === 'light' ? '#000' : '#fff',
                my: '40px',
              }}
            >
              {lang === 'en' ? 'Update the break' : 'تحديث فترة راحة '}
            </Typography>
            <Typography
              onChang
              variant="h6"
              sx={{
                textAlign: lang === 'en' ? 'left' : 'right',
                color: mode === 'light' ? '#000' : '#fff',
              }}
            >
              {lang === 'en' ? 'Type of break' : 'نوع الاستراحة'}
            </Typography>
            <select
              style={{
                width: '95%',
                padding: '6px 0',
                backgroundColor: mode === 'light' ? '#fff' : '#252526',
                color: mode === 'light' ? '#000' : '#fff',
                border:
                  errors.isOpen && touched.isOpen
                    ? '1px solid red'
                    : `1px solid ${mode === 'light' ? '#ddd' : '#aaa'}`,
                outline: 0,
                borderRadius: '4px',
                marginTop: '7px',
              }}
              value={values.isOpen}
              onChange={(e) =>
                setValues(
                  { ...values, isOpen: e.target.value },
                 ( values.isOpen === 'true' )
                    ? {
                        ...values,
                        start_date: null,
                        end_date: null,
                        mins: values.mins,
                        hours: values.mins,
                      }
                    : {
                        ...values,
                        start_date: values.start_date,
                        end_date: values.end_date,
                        mins: null,
                        hours: null,
                      },
                )
              }
              onBlur={(_) => setTouched({ ...touched, isOpen: true })}
            >
              <option hidden select>
                {lang === 'en' ? 'Type of break' : 'اختار نوع الراحه'}
              </option>
              <option value={true}>{lang === 'en' ? 'Open' : 'مفتوح '}</option>
              <option value={false}>{lang === 'en' ? 'Close' : 'مغلق'}</option>
            </select>
            {values.isOpen === 'true' ? (
              <>
                <Grid item xl={3} md={6} sm={12} xs={12} mt="15px">
                  <Box sx={{ position: 'relative', pb: '21px' }}>
                    <Typography
                      onChang
                      variant="h6"
                      sx={{
                        textAlign: lang === 'en' ? 'left' : 'right',
                        color: mode === 'light' ? '#000' : '#fff',
                      }}
                    >
                      {lang === 'en' ? 'Duration' : '  المدة الزمنية'}
                    </Typography>
                    <Typography
                      variant="span"
                      color={mode === 'dark' ? '#DDD' : undefined}
                    >
                      {lang === 'en' ? 'Hours' : 'الساعات'}
                    </Typography>
                    <InputBase
                      type="number"
                      sx={{
                        borderRadius: '7px',
                        width: {
                          xl: '90%',
                          lg: '90%',
                          md: '90%',
                          sm: '100%',
                          xs: '100%',
                        },
                        padding: '7px',
                        backgroundColor: mode === 'light' ? '#fff' : '#252526',
                        color: mode === 'light' ? '#000' : '#fff',
                        outline: 0,
                        border:
                          errors.hours && touched.hours
                            ? '1px solid red'
                            : '1px solid transparent',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      }}
                      name="hours"
                      value={values.hours}
                      onChange={(e) =>
                        setValues({ ...values, hours: e.target.value })
                      }
                      onBlur={(_) => setTouched({ ...touched, hours: true })}
                    />
                  </Box>
                </Grid>
                <Grid item xl={3} md={6} sm={12} xs={12} mt="15px">
                  <Box sx={{ position: 'relative', pb: '21px' }}>
                    <Typography
                      variant="span"
                      color={mode === 'dark' ? '#DDD' : undefined}
                    >
                      {lang === 'en' ? 'Mintes' : 'الدقائق'}
                    </Typography>
                    <InputBase
                      type="number"
                      sx={{
                        borderRadius: '7px',
                        width: {
                          xl: '90%',
                          lg: '90%',
                          md: '90%',
                          sm: '100%',
                          xs: '100%',
                        },
                        padding: '7px',
                        backgroundColor: mode === 'light' ? '#fff' : '#252526',
                        color: mode === 'light' ? '#000' : '#fff',
                        outline: 0,
                        border:
                          errors.mins && touched.mins
                            ? '1px solid red'
                            : '1px solid transparent',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      }}
                      name="mins"
                      value={values.mins}
                      onChange={(e) =>
                        setValues({ ...values, mins: e.target.value })
                      }
                      onBlur={(_) => setTouched({ ...touched, mins: true })}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Box sx={{ my: '30px' }}>
                  <DateAndTimePicker
                    label={lang === 'en' ? 'Start Date' : 'تاريخ البدء'}
                    value={values.start_date}
                    name="start_date"
                    error={errors.start_date}
                    touched={touched.start_date}
                    handleChange={handleChangeStartDate}
                    handleBlur={handleTouchStartDate}
                  />
                </Box>
                <DateAndTimePicker
                  label={lang === 'en' ? 'End Date' : 'تاريخ الانتهاء'}
                  value={values.end_date}
                  error={errors.end_date}
                  name="end_date"
                  touched={touched.end_date}
                  handleChange={handleChangeEndDate}
                  handleBlur={handleTouchEndDate}
                />
              </>
            )}
            <Stack
              marginTop="60px"
              direction="row"
              justifyContent="space-between"
            >
              <Button
                sx={{
                  backgroundColor: '#0077B6',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#0077B6',
                    color: '#fff',
                  },
                }}
                type="submit"
              >
                {lang === 'en' ? 'Update' : 'تحديث'}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#0077B6',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#0077B6',
                    color: '#fff',
                  },
                }}
                type="submit"
                onClick={handleClose}
              >
                {lang === 'en' ? 'Close' : 'إلغاء'}
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </div>
  )
}
