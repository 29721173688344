import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LogoIcon } from "./images/logo.svg";
import { ReactComponent as LightLogoIcon } from "./images/lightLogo.svg";
import { ReactComponent as LightIcon } from "./images/light.svg";
import { ReactComponent as DarkIcon } from "./images/dark.svg";
import { darkMode, lightMode } from "../../../redux/slices/modeSlice";
import { AiOutlineHome, AiOutlineBranches } from "react-icons/ai";
import { MdOutlineBeenhere } from "react-icons/md";
import { FaBlogger, FaMoneyBillAlt, FaTasks } from "react-icons/fa";
import { RiGitPullRequestLine } from "react-icons/ri";
import { AiOutlineAlert } from "react-icons/ai";
import { MdGroups } from "react-icons/md";
import { RiMoneyDollarBoxLine, RiMoneyDollarCircleFill } from "react-icons/ri";
import { useGetMeQuery, UserApi } from "../../../redux/features/userApi";
import ReduxStore from "../../../redux/store";

import { taskApi } from "../../../redux/features/tasksApi";
import { alertApi } from "../../../redux/features/alertsApis";
import { empolyeeApi } from "../../../redux/features/employeeApi";
import { branchesApi } from "../../../redux/features/branchesApi";
import { blogsApi } from "../../../redux/features/blogsApi";
import { companyApi } from "../../../redux/features/companyApi";
import { requsetApis } from "../../../redux/features/requestsApis";
import { attendApis } from "../../../redux/features/attendeApis";
import { contractsApi } from "../../../redux/features/contractsApi";
import { shiftsApi } from "../../../redux/features/shiftsApi";
import { breaksApis } from "../../../redux/features/breaksApis";
import { departmentsApis } from "../../../redux/features/departmentsApis";
const Sidebar = ({ logOut }) => {
  const { value: language } = useSelector((state) => state).lang;
  const { value: mode } = useSelector((state) => state).mode;
  const { data, isSuccess } = useGetMeQuery();
  const { navigate } = useNavigate();
  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    backgroundColor: "#81D8D0",
    color: "#81D8D0",
  });
  const iconStyle = {
    height: "20px",
    width: "20px",
  };
  function handleStatus(btn) {
    setStatus({ backgroundColor: "#e3faf8", color: "#81D8D0", activeBtn: btn });
  }
  const routes = [
    {
      title: language === "en" ? "Home" : "الرئيسية",
      link: "/",
      icon: <AiOutlineHome style={iconStyle} />,
    },
    {
      title: language === "en" ? "Attendees" : "الحضور",
      link: "/attendees",
      icon: <MdOutlineBeenhere style={iconStyle} />,
    },
    // {
    //   title: language === "en" ? "Breaks" : "فترة الراحة",
    //   link: "/breaks",
    //   icon: <BsFileBreak style={iconStyle} />,
    // },
    {
      title: language === "en" ? "Tasks" : "المهمات",
      link: "/tasks",
      icon: <FaTasks style={iconStyle} />,
    },
    {
      title: language === "en" ? "Requests" : "الطلبات",
      link: "/requests",
      icon: <RiGitPullRequestLine style={iconStyle} />,
    },
    {
      title: language === "en" ? "Alerts" : "التعميمات",
      link: "/alerts",
      icon: <AiOutlineAlert style={iconStyle} />,
    },
    {
      title: language === "en" ? "Branches" : "الفروع",
      link: "/branches",
      icon: <AiOutlineBranches style={iconStyle} />,
    },
    // {
    //   title: language === "en" ? "Shifts" : "الدوام",
    //   link: "/shifts",
    //   icon: <ImShift style={iconStyle} />,
    // },
    {
      title: language === "en" ? "Employees" : "الموظفين",
      link: "/employees",
      icon: <MdGroups style={iconStyle} />,
    },
    {
      title: language === "en" ? "Contracts" : "العقود",
      link: "/contracts",
      icon: <RiMoneyDollarBoxLine style={iconStyle} />,
    },
    // {
    //   title: language === "en" ? "Over time" : "وقت اضافي",
    //   link: "/overtime",
    //   icon: <TfiLayoutMediaOverlayAlt2 style={iconStyle} />,
    // },
    {
      title: language === 'en' ? 'Salaries' : 'الرواتب',
      link: '/salaries',
      icon: <FaMoneyBillAlt style={iconStyle} />,
    },
    {
      title: language === "en" ? "Payrolls" : "كشف الرواتب",
      link: "/payrolls",
      icon: <RiMoneyDollarCircleFill style={iconStyle} />,
    },
    {
      title: language === "en" ? "Blogs" : "المدونات",
      link: "/blogs",
      icon: <FaBlogger style={iconStyle} />,
    },
  ];
  const { pathname } = useLocation();

  const handleLogout = () => {
    ReduxStore.dispatch(UserApi.util.resetApiState());
    ReduxStore.dispatch(taskApi.util.resetApiState());
    ReduxStore.dispatch(alertApi.util.resetApiState());
    ReduxStore.dispatch(branchesApi.util.resetApiState());
    ReduxStore.dispatch(empolyeeApi.util.resetApiState());
    ReduxStore.dispatch(blogsApi.util.resetApiState());
    ReduxStore.dispatch(companyApi.util.resetApiState());
    ReduxStore.dispatch(requsetApis.util.resetApiState());
    ReduxStore.dispatch(attendApis.util.resetApiState());
    ReduxStore.dispatch(contractsApi.util.resetApiState());
    ReduxStore.dispatch(shiftsApi.util.resetApiState());
    ReduxStore.dispatch(breaksApis.util.resetApiState());
    ReduxStore.dispatch(departmentsApis.util.resetApiState());
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <div
        className={`${
          styles.sideBar
        } ${mode}-theme flow_direction_${language}  ${
          language === "en" ? "start-0" : "end-0"
        }`}
        style={{
          "&::-webkit-scrollbar": {
            width: "10px",
            borderRadius: language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
          },

          "&::-webkit-scrollbar-track": {
            background: mode === "light" ? "#f1f1f1" : "#555",
            borderRadius: language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
          },

          "&::-webkit-scrollbar-thumb": {
            background: mode === "light" ? "#aac1fe" : "#000",
            transition: "0.5s all",
            borderRadius: language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
          },

          "&::-webkit-scrollbar-thumb:hover": {
            background: mode === "light" ? "#78a3e4" : "#000",
            borderRadius: language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
          },
        }}
      >
        <div>
          {/* <LogoIcon className={`${isActive ? 'd-none ' : 'd-block'} `} />
        <LogodarkIcon className={`${isActive ? 'd-block' : 'd-none'} `} /> */}
          {mode === "light" ? <LogoIcon /> : <LightLogoIcon />}
        </div>

        <div>
          <ul
            style={{
              height: "90%",
              overflowY: "",
              "&::-webkit-scrollbar": {
                width: "10px",
                borderRadius:
                  language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
              },

              "&::-webkit-scrollbar-track": {
                background: mode === "light" ? "#f1f1f1" : "#555",
                borderRadius:
                  language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
              },

              "&::-webkit-scrollbar-thumb": {
                background: mode === "light" ? "#aac1fe" : "#000",
                transition: "0.5s all",
                borderRadius:
                  language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
              },

              " &::-webkit-scrollbar-thumb:hover": {
                background: mode === "light" ? "#78a3e4" : "#000",
                borderRadius:
                  language === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
              },
            }}
          >
            {routes.map((item, index) =>
              data?.data?.role !== "employee" ? (
                <li
                  key={index}
                  className={styles.navItem}
                  style={{
                    backgroundColor:
                      item.link === pathname
                        ? mode === "light"
                          ? "#F3FBFA"
                          : "#444B4E"
                        : "transparent",
                    borderRadius: "6px",
                    marginTop: "10%",
                  }}
                >
                  <Link
                    to={item.link}
                    className="d-flex gap-2 align-items-center"
                    style={
                      item.link === pathname
                        ? { color: status.color }
                        : { color: "var(--text-color)" }
                    }
                    onClick={() => {
                      handleStatus(index);
                    }}
                  >
                    {" "}
                    {item.icon}
                    {item.title}
                  </Link>
                </li>
              ) : (
                item.title !== "Contracts" &&  item.title !== "العقود"&&  (
                  <li
                    key={index}
                    className={styles.navItem}
                    style={{
                      backgroundColor:
                        item.link === pathname
                          ? mode === "light"
                            ? "#F3FBFA"
                            : "#444B4E"
                          : "transparent",
                      borderRadius: "6px",
                      marginTop: "10%",
                    }}
                  >
                    <Link
                      to={item.link}
                      className="d-flex gap-2 align-items-center"
                      style={
                        item.link === pathname
                          ? { color: status.color }
                          : { color: "var(--text-color)" }
                      }
                      onClick={() => {
                        handleStatus(index);
                      }}
                    >
                      {" "}
                      {item.icon}
                      {item.title}
                    </Link>
                  </li>
                )
              )
            )}
          </ul>
        </div>

        <div className={`${styles.mode} rounded-pill mx-3`}>
          <div className="d-flex justify-content-between  align-items-center">
            <div
              className={`${styles.lightMode}  rounded-pill ${
                mode === "light"
                  ? "bg-light text-dark "
                  : "bg-transparent text-light"
              } `}
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(lightMode())}
            >
              <LightIcon fill={mode === "dark" ? "#fff" : undefined} />
              <span>{language === "en" ? "Light" : "ضوئي"}</span>
            </div>
            <div
              className={`${styles.darkMood} rounded-pill ${
                mode == "dark" ? "bg-light text-black " : ""
              }    `}
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(darkMode())}
            >
              <DarkIcon style={{ direction: "ltr" }} />
              <span>{language === "en" ? "Dark" : "مظلم"}</span>
            </div>
          </div>
        </div>

        <div
          className="mt-3 py-1 w-75"
          style={{ borderRadius: "10px", cursor: "pointer" }}
          onClick={handleLogout}
        >
          <i
            className="fa-solid fa-right-from-bracket mx-2"
            style={{ color: "red" }}
          ></i>
          <span
            className={`fw-bold fs-6 mx-2 ${
              mode === "light" ? "text-dark" : "text-white"
            }`}
          >
            {language === "en" ? "Logout" : "تسجيل الخروج"}
          </span>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
