import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextWriter from "./TextWriter";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAddReplyMutation, useDeleteCommentMutation, usePostCommentMutation } from "../../../redux/features/commentsApi";
import { useGetMeQuery } from "../../../redux/features/userApi";
import { imageUrl } from "../../../App";
import moment from "moment";
const Comment = ({ lang, mode, currentBlog, setCurrentBlog, testingComment }) => {

  let [comment, setComment] = useState('')

  let [replys, setReplys] = useState([])

  const [blog, setBlog] = useState(currentBlog)




  useEffect(() => {

  }, [comment])


  let [postComment] = usePostCommentMutation()
  let [addReply] = useAddReplyMutation()
  let [deleteComment] = useDeleteCommentMutation()
  const handleDeleteComment = (commentId) => {
    deleteComment({ blogId: currentBlog?._id, commentId }).then(({ data, error }) => {
      if (error) {

      }
      if (data) {
        setBlog(_ => data?.blog)
      }
    })
  }
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery()
  const handleSubmit = (data) => {
    if (userSuccess) {

      postComment({
        title: data,
        user: userData?.data?._id,
        blog: currentBlog?._id,

      }).then(({ data, error }) => {
        if (error) {
        }
        if (data) {
          setBlog(_ => data?.blog)


        }

      })
    }
  }
  const handleReply = (title) => {
    if (userSuccess && comment) {
      let temp = {
        data: { title, user: userData?.data?._id, blog: currentBlog?._id },
        commentId: comment,
      }
      addReply(
        temp
      ).then(({ data, error }) => {
        if (error) {
        }
        if (data) {
          setBlog(_ => data?.blog)


        }

      })
    }
  }


  const [showing, setShowing] = useState({
    inputField: false,
    replies: false,
    commentField: "",
    commentReplies: "",
  });
  const showingReplayInput = (comment) => {
    setShowing({
      ...showing,
      inputField: !showing.inputField,
      commentField: !showing.inputField ? comment._id : "",
    });
  };
  const showingReplies = (index, option) => {

    if (replys[0]) {
      let temps = [...replys]
      temps[index][option] = !temps[index][option]

      setReplys(_ => temps)
    }

  };
  useEffect(() => {
    let temps = []
    if (blog.comments[0]) {
      blog.comments.forEach(_ => {
        temps.push({ 'reply': false, 'showReplys': false })
      })
      setReplys(_ => [...temps])
    }
  }, [blog?.comments])
  // const handleTime = (time) => {
  //   let timeStr = ``
  //   time = Math.abs(12 - new Date(time).getHours())
  //   if (lang == 'en') {
  //     timeStr = time + ' hours ago ';
  //   }
  //   if (lang == 'ar') {
  //     timeStr = "منذ " + time + (time >= 1 && time <= 9 ? " ساعات  " : "ساعة ")
  //   }
  //   return timeStr;
  // }
  const handleTime = (time) => {
    const startDate = moment(new Date())
    const endDate = moment(time)
    const diff = endDate.diff(startDate)
    const duration = moment.duration(diff)
    let timeStr = ``
    time = Math.abs(duration.hours());
    if (lang == 'en') {
      timeStr = time + ' hours ago ';
    }
    if (lang == 'ar') {
      timeStr = "منذ " + time + (time >= 1 && time <= 9 ? " ساعات  " : "ساعة ")
    }
    return timeStr;
  }
  const checkBeforDeleteComment = (comment) => {
    if (userSuccess) {
      if (userData?.data?.role == 'admin' || userData?.data?.role == 'root')
        return true
      else {

        if (comment?.user?._id == userData?.data?._id)
          return true
      }
      return false
    }
    return false
  }
  return (
    <Box
      sx={{
        borderTop: 1,
        borderColor: mode === "light" ? "#ddd" : "#ccc",
        mt: "30px",
      }}
    >
      <Box sx={{ my: "40px" }}>
        {userSuccess ? <TextWriter

          handleSubmit={handleSubmit}
          option="comment"
          lang={lang}
          mode={mode}
          avatarSrc={imageUrl + userData?.data?.image}
          placeholder={lang === "en" ? "Write comment" : "اكتب تعليق"}
        /> : null}
      </Box>
      <Typography
        variant="h5"
        sx={{
          color: mode === "light" ? "#000" : "#fff",
          fontWeight: "bolder",
          fontSize: "19px",
          borderBottom: 1,
          borderColor: mode === "light" ? "#ddd" : "#ccc",
          pb: "15px",
        }}
      >
        {lang === "en" ? "Comments" : "التعليقات"}
      </Typography>
      {/* Comment */}
      {blog && blog?.comments.map((comment, commentIndex) => {
        return (
          <Box key={comment._id}>
            {/* Comment Content */}
            <Box
              sx={{
                display: {
                  xl: "flex",
                  xs: "block",
                },
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "20px",
                mt: "50px",
                width: {
                  xl: "85%",
                  xs: "100%",
                },
              }}
            >
              <Avatar
                src={
                  imageUrl + comment?.user?.image
                }
                alt="hamaki"
                sx={{
                  height: {
                    xs: "35px",
                  },
                  width: {
                    xs: "35px",
                  },
                }}
              />
              <Box
                sx={{
                  backgroundColor: mode === "light" ? "#F8F8F8" : "#252525",
                  color: mode === "light" ? "#000" : "#fff",
                  borderRadius: "25px",
                  padding: "15px",
                  position: "relative",
                  width: "100%",
                  mt: {
                    xs: "10px",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: mode === "light" ? "#666" : "#898B89",
                    position: "absolute",
                    right: lang === "en" ? "15px" : undefined,
                    left: lang === "ar" ? "15px" : undefined,
                    top: "40px",
                    fontSize: "11px",
                    fontWeight: "bolder",
                  }}
                >
                  {handleTime(comment?.createdAt)}
                </Typography>
                <Button
                  sx={{
                    color: mode === "light" ? "#666" : "#898B89",
                    position: "absolute",
                    right: lang === "en" ? "-5px" : undefined,
                    left: lang === "ar" ? "-5px" : undefined,
                    top: 0,
                    fontSize: "11px",
                    fontWeight: "bolder",
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {checkBeforDeleteComment(comment) ? <DeleteOutlineIcon
                    onClick={() => handleDeleteComment(comment._id)}
                    sx={{
                      color: mode === "dark" ? "#ccc" : undefined,
                      fontSize: "meduim",
                    }}
                  /> : null}
                </Button>
                <Typography
                  variant="h6"
                  sx={{
                    color: mode === "light" ? "#000" : "#fff",
                    fontSize: "17px",
                    width: "80%",
                    fontWeight: "bolder",
                  }}
                >
                  {comment?.user[`userName_${lang}`]}
                </Typography>
                <Typography
                  paragraph
                  sx={{ fontSize: "13px", mt: 1, width: "80%" }}
                >
                  {comment?.title}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "transparent",
                      color: "#02B2D7",
                      padding: 0,
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#02B2D7",
                      },
                    }}
                    onClick={() => {
                      showingReplies(commentIndex, 'reply');
                      setComment(comment._id)
                    }}
                  >
                    {lang === "en" ? "Replay" : "رد"}
                  </Button>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "transparent",
                      color: "#02B2D7",
                      padding: 0,

                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "#02B2D7",
                      },
                    }}
                    onClick={() => {
                      showingReplies(commentIndex, 'showReplys')

                    }}
                  >
                    {lang === "en" ? "Show Reblies" : "إظهار الرد"}
                  </Button>
                </Stack>
              </Box>
            </Box>
            {/* End Comment Content */}

            {(replys[0] && comment?.replys[0])
              && replys[commentIndex].showReplys
              ? comment?.replys?.map((reply) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: {
                        xl: "20px",
                        xs: "10px",
                      },
                      mt: "10px",
                      width: {
                        xl: "77.5%",
                        xs: "95%",
                      },
                      ml: {
                        xl: "7.5%",
                        xs: "2.5%",
                      },
                    }}
                  >
                    <Avatar
                      src={imageUrl + reply?.user?.image}
                    />
                    <Box
                      sx={{
                        backgroundColor: mode === "light" ? "#F8F8F8" : "#252525",
                        color: mode === "light" ? "#000" : "#fff",
                        borderRadius: "25px",
                        padding: "15px",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: mode === "light" ? "#666" : "#898B89",
                          position: "absolute",
                          right: lang === "en" ? "15px" : undefined,
                          left: lang === "ar" ? "15px" : undefined,
                          top: "40px",
                          fontSize: "11px",
                          fontWeight: "bolder",
                        }}
                      >
                        {handleTime(reply?.createdAt)}
                      </Typography>
                      <Button
                        sx={{
                          color: mode === "light" ? "#666" : "#898B89",
                          position: "absolute",
                          right: lang === "en" ? "-5px" : undefined,
                          left: lang === "ar" ? "-5px" : undefined,
                          top: 0,
                          fontSize: "11px",
                          fontWeight: "bolder",
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        {checkBeforDeleteComment(reply) ? <DeleteOutlineIcon
                          onClick={() => handleDeleteComment(reply._id)}
                          sx={{
                            color: mode === "dark" ? "#ccc" : undefined,
                            fontSize: "meduim",
                          }}
                        /> : null}
                      </Button>
                      <Typography
                        variant="h6"
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                          fontSize: "17px",
                          width: "80%",
                          fontWeight: "bolder",
                        }}
                      >
                        {reply?.user[`userName_${lang}`]}
                      </Typography>
                      <Typography
                        paragraph
                        sx={{ fontSize: "13px", mt: 1, width: "80%" }}
                      >
                        {reply?.title}
                      </Typography>
                    </Box>
                  </Box>
                )
              })
              : undefined}
            {replys[0] && replys[commentIndex]?.reply ? (
              <TextWriter
                handleReply={handleReply}
                lang={lang}
                mode={mode}
                avatarSrc={imageUrl + userData?.data?.image}
                placeholder={lang === "en" ? "Write reply" : "اكتب رد"}
              />
            ) : undefined}
          </Box>
        )
      })}
    </Box>
  );
};

export default Comment;
