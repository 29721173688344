import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const commentsApi = createApi({
    reducerPath: 'comment',
    tagTypes: ['Comment'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1',
        prepareHeaders: (headers) => {
            headers.set("Authentication", localStorage.getItem('userToken'))
            return headers;
        }
    }),
    endpoints: (builder) => ({
        postComment: builder.mutation({
            query: (data) => ({
                url: '/comment',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Comment']
        }),
        addReply: builder.mutation({
            query: ({ commentId, data }) => ({
                url: `/comment/reply/${data?.blog}/${commentId}`,
                method: 'POST',
                body: { ...data }
            }),
            invalidatesTags: ['Comment']
        }),
        deleteComment: builder.mutation({
            query: ({ blogId, commentId }) => ({
                url: `/comment/delete/${blogId}/${commentId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Comment']
        })
    })
})

export const {
    usePostCommentMutation,
    useDeleteCommentMutation,
    useAddReplyMutation
} = commentsApi

