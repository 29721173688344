import TabPanel from '@mui/lab/TabPanel'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Paper from '@mui/material/Paper'
import { useSelector } from 'react-redux'
import { useGetMeQuery } from '../../../../redux/features/userApi'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetPayrolsByUserQuery } from '../../../../redux/features/payrolApi'
import Spinner from '../../../spinner/Spinner'
import moment from 'moment'
import { useParams } from 'react-router'

const PayrollPanel = ({ value }) => {
  const { value: mode } = useSelector((state) => state).mode
  const { value: lang } = useSelector((state) => state).lang
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery()
  let [skipPayrolToken, setSkipPayrolToken] = useState(skipToken)
  let {
    data: payrolData,
    isSuccess: payrolSuccess,
    isLoading: payrolLoading,
  } = useGetPayrolsByUserQuery(skipPayrolToken)
  let [payrols, setPayrols] = useState([])
  const { id } = useParams()

  const getTheTotSalary = (payrols) => {
    let res = 0
    payrols.forEach((payrol) => {
      res += payrol?.salary
    })
    return res.toFixed(1)
  }

  const getTheTotalWorkingHours = (payrols) => {
    let totalWorksHours = 0
    let totalDifferenceHours = 0

    payrols.forEach((payrol) => {
      totalWorksHours += payrol?.originalTime

      let diff = Math.abs(payrol?.originalTime - payrol?.lateTime)
      totalDifferenceHours += diff
    })
    return `${totalWorksHours}/${totalDifferenceHours}`
  }
  const handleWorkingHourse = (payrol) => {
    let { lateTime, originalTime } = payrol
    let res = ''

    if (lateTime > originalTime) {
      res = '00' + ' : ' + '00'
    } else {
      let diff = (originalTime - lateTime).toString().split('.')

      if (diff.length > 1) {
        res =
          parseInt(diff[0]) + ' : ' + parseInt((diff[1].slice(0, 1) / 10) * 60)
      } else {
        res = parseInt(diff[0]) + ' : ' + '00'
      }
    }

    // split value by .
    return res
  }

  useEffect(() => {
    const location = window.location.pathname.startsWith('employee', 1)
    if (
      userSuccess &&
      !location &&
      (userData?.data?.role !== 'root' || userData?.data?.role !== 'admin')
    ) {
      setSkipPayrolToken(userData?.data?._id)
    } else {
      location && setSkipPayrolToken(id)
    }
    if (payrolSuccess) {
      setPayrols((_) => Object.entries(payrolData?.years))
    }
  }, [userSuccess, payrolSuccess])
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <TabPanel value={value}>
      <Box>
        {payrolLoading ? (
          <Spinner />
        ) : payrolSuccess ? (
          [...payrols].map((payrol, branchIndex) => (
            <Accordion
              key={branchIndex}
              expanded={expanded === branchIndex}
              onChange={handleChange(branchIndex)}
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 0,
                marginTop: '20px',
                border: 0,
                overflow: 'hidden',
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: mode === 'light' ? '#000' : '#fff',
                      border: 0,
                    }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  bgcolor: 'transparent',
                  border: 1,
                  borderColor: mode === 'light' ? 'divider' : '#aaa',
                  borderRadius: '30px',
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  sx={{
                    color: mode === 'light' ? '#000' : '#fff',
                  }}
                >
                  <Typography>{payrol[0]}</Typography>
                  <Typography>
                    {getTheTotalWorkingHours(
                      payrolSuccess && payrolData?.payrols,
                    )}
                  </Typography>
                  <Typography>
                    {getTheTotSalary(payrolData?.payrols)}$
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: '10px',
                    backgroundColor: 'transparent',
                    border: 1,
                    borderColor: mode === 'light' ? '#F5F5F5' : '#252526',
                    borderRadius: '17px',
                  }}
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      overflow: 'hidden',

                      borderRadius: '6px',
                    }}
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        backgroundColor:
                          mode === 'light' ? '#F5F5F5' : '#252526',
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align={lang === 'en' ? 'left' : 'right'}
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            border: 0,
                            px: 2,
                          }}
                        >
                          {lang === 'en' ? 'Date' : 'التاريخ'}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            border: 0,
                          }}
                          align="center"
                        >
                          {lang === 'en' ? 'Work Hours' : 'ساعات العمل'}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            border: 0,
                          }}
                          align="center"
                        >
                          {lang === 'en' ? 'Salary' : 'المرتب'}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: mode === 'light' ? '#000' : '#fff',
                            border: 0,
                          }}
                          align="center"
                        >
                          {lang === 'en' ? ' Shift Type' : 'نوع الدوام'}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[...payrol[1]].map((nestedPayrol, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              mode === 'light' ? 'transparent' : '#3E3E42',
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell
                            align={lang === 'en' ? 'left' : 'right'}
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                              border: 0,
                              px: 2,
                            }}
                          >
                            {moment(new Date(nestedPayrol?.createdAt)).format(
                              'yyyy/MM/DD',
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                              border: 0,
                            }}
                            align="center"
                          >
                            {handleWorkingHourse(nestedPayrol)}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                              border: 0,
                            }}
                            align="center"
                          >
                            {nestedPayrol?.salary.toFixed(1)}$
                          </TableCell>
                          <TableCell
                            sx={{
                              color: mode === 'light' ? '#000' : '#fff',
                              border: 0,
                            }}
                            align="center"
                          >
                            {nestedPayrol?.type}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))
        ) : null}
      </Box>
    </TabPanel>
  )
}

export default PayrollPanel
