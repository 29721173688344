import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "./images/arrow.svg";
import { ReactComponent as LikeIcon } from "./images/like.svg";
import { ReactComponent as DisLikeIcon } from "./images/dislike.svg";
import { ReactComponent as CommentIcon } from "./images/comment.svg";

import styles from "./Blogs.module.css";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
} from "../../../redux/features/blogsApi";
import { useGetMeQuery } from "../../../redux/features/userApi";
import Spinner from "../../spinner/Spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { imageUrl } from "../../../App";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
function AllBlogsPage({ value }) {
  const [skipBlogsToken, setSkipBlogsToken] = useState(skipToken);
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery();
  let {
    data: BlogsData,
    isSuccess: BlogsSuccess,
    isLoading: blogLoading,
    refetch: blogRefetch,
    isError,
    blogsError,
  } = useGetAllBlogsQuery(skipBlogsToken);
  let [deleteBlog, { isError: deleteError, isSuccess: deleteSuccess }] =
    useDeleteBlogMutation();
  const { value: mode } = useSelector((state) => state).mode;

  useEffect(() => {
    if (userSuccess) {
      if (userData?.data?.role == "root") {
        setSkipBlogsToken(true);
      } else setSkipBlogsToken(false);
    }
    if (BlogsSuccess) {
      blogRefetch();
    }
  }, [userSuccess, BlogsSuccess]);

  const [status, setStatus] = useState({
    backgroundColor: "var(--buttonBg)",
    color: "#FF2366",
    activeBtn: 1,
  });
  const [modalShow, setModalShow] = useState();
  function handleStatus(btn, modal) {
    setStatus({
      backgroundColor: "var(--buttonBg)",
      color: "#FF2366",
      activeBtn: btn,
    });
    setModalShow(modal);
  }
  const handleClose = () => {
    setModalShow(false);
  };

  const { value: lang } = useSelector((state) => state).lang;

  const handleDeleteBlog = (id) => {
    deleteBlog(id).then(({ data, error }) => {
      if (!error) {
        toast.success(
          lang == "en" ? "Blog Deleted Successfully" : "تم حذف المدونة بنجاح"
        );
      } else {
        toast.error(
          lang == "en" ? "Blog Can't Be Deleted " : "لا يمكن حذف المدونة"
        );
      }
    });
  };
  const checkAccessForBeforDelete = (userBlog) => {
    if (userData?.data.role == "admin" || userData?.data?.role == "root")
      return true;
    else if (userData?.data?.role == "employee") {
      if (userData?.data._id == userBlog?._id) return true;
      else return false;
    }
    return undefined;
  };
  const handleTime = (time) => {
    const startDate = moment(new Date());
    const endDate = moment(time);
    const diff = endDate.diff(startDate);
    const duration = moment.duration(diff);
    let timeStr = ``;
    time = Math.abs(duration.hours());
    if (lang == "en") {
      timeStr = time + " hours ago ";
    }
    if (lang == "ar") {
      timeStr = "منذ " + time + (time >= 1 && time <= 9 ? " ساعات  " : "ساعة ");
    }
    return timeStr;
  };
  const getTime = (blog) => {
    let time = ``;

    if (lang == "en") {
      time = `time ${moment(blog?.createdAt).format("hh:mm:ss")}`;
    } else {
      time = ` الوقت : ${moment(blog?.createdAt).format("hh:mm:ss")}`;
    }
    return time;
  };
  const getTheStatsForLikeAndCommentAndDislike = (blog) => {
    let stats = { comments: 0, like: 0, disLike: 0 };
    // get comments length
    stats["comments"] = blog?.comments.length;
    if (blog?._id == "63c5817c6a68d21615aea768") {
    }
    // get the likes stats
    stats["like"] = blog?.likes.filter(
      (like) => like.likeType == "like"
    ).length;
    stats["disLike"] = blog?.likes.filter(
      (like) => like.likeType == "disLike"
    ).length;
    return stats;
  };
  return (
    <TabPanel value={value}>
      <div
        className="tab-pane fade show active"
        id="pills-allBlogs"
        role="tabpanel"
        aria-labelledby="pills-allBlogs-tab"
      >
        <div className="row  g-2 ">
          {/* ================================================Grid===================================================== */}
          {/* <p className={mode === "light" ? "text-dark" : "text-white"}>
            {lang === "en" ? "This is All Blogs" : "هذه كل المدونات"}
          </p> */}
          {
          blogsError?null:
          
          blogLoading ? (
            <Spinner />
          ) : BlogsSuccess ? (
            [...BlogsData?.blogs]?.map((blog, index) => {
              return (
                <div key={index} className="col-lg-6 col-md-6 col-sm-12 p-3">
                  <div
                    className={`position-relative p-3`}
                    style={{
                      backgroundColor: mode === "light" ? "#fff" : "#3E3E42",
                      borderRadius: "25px",
                    }}
                  >
                    <h3
                      className={`fw-bold my-3 ${
                        mode === "light" ? "text-dark" : "text-white"
                      }`}
                    >
                      {blog?.title}
                    </h3>
                    <Link to={`/blogDetails/${blog._id}`}>
                      {/* <div>
                    <img src={image} alt="" className="w-100" />
                  </div> */}
                    </Link>
                    {/* ==============================card body======================================== */}
                    <div>
                      <p
                        className={`mt-3 ${
                          mode === "light" ? "text-dark" : "text-white"
                        }`}
                      >
                        {blog?.description.slice(0, 50)}
                      </p>
                      <Link
                        to={`/blogDetails/${blog?._id}`}
                        style={{
                          color: "#00B4D8",
                          fontSize: "12px",
                        }}
                      >
                        {lang === "en" ? "Read More" : "قراءة المزيد"}

                        <ArrowIcon className="mx-1 " />
                      </Link>
                    </div>

                    {/* ===========================card footer======================================= */}
                    <Box
                      sx={{
                        display: {
                          xl: "flex",
                          lg: "flex",
                          md: "block",
                          sm: "block",
                          xs: "block",
                        },

                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="d-flex align-items-center mt-2">
                        <div
                          className="mx-2"
                          style={{
                            width: "4em",
                            height: "4em",
                          }}
                        >
                          <img
                            src={`${imageUrl}${blog?.image}`}
                            alt=""
                            className="w-100"
                            style={{
                              borderRadius: "50%",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>
                        <div className="">
                          <h6
                            className={`w-100 fw-bold p-0 ${
                              mode === "light" ? "text-dark" : "text-white"
                            }`}
                          >
                            {/* {blog?.user?.[`userName_${lang}`]} */}
                            {lang === "en"
                              ? blog?.user?.userName_en
                              : blog?.user?.userName_ar}
                          </h6>
                          {
                            <span
                              className={`p-0 ${
                                mode === "light" ? "text-dark" : "text-white"
                              }`}
                              style={{ fontSize: "14px" }}
                            >
                              {handleTime(blog?.createdAt)}
                            </span>
                          }
                        </div>
                      </div>

                      <Box
                        className="d-flex  mt-md-0 mt-2"
                        sx={{
                          fontSize: "14px",
                          mt: {
                            xl: 0,
                            lg: 0,
                            md: "25px",
                            sm: "25px",
                            xs: "25px",
                          },
                        }}
                      >
                        <div className="mx-1">
                          <LikeIcon />
                          {getTheStatsForLikeAndCommentAndDislike(blog).like >
                            0 && (
                            <span
                              className={
                                mode === "light" ? "text-dark" : "text-white"
                              }
                            >
                              {
                                getTheStatsForLikeAndCommentAndDislike(blog)
                                  .like
                              }
                            </span>
                          )}
                        </div>
                        <div className="mx-1">
                          <DisLikeIcon />
                          {getTheStatsForLikeAndCommentAndDislike(blog)
                            .disLike > 0 && (
                            <span
                              className={
                                mode === "light" ? "text-dark" : "text-white"
                              }
                            >
                              {
                                getTheStatsForLikeAndCommentAndDislike(blog)
                                  .disLike
                              }
                            </span>
                          )}
                        </div>
                        <div className="mx-1">
                          <CommentIcon />
                          {getTheStatsForLikeAndCommentAndDislike(blog)
                            .comments > 0 && (
                            <span
                              className={
                                mode === "light" ? "text-dark" : "text-white"
                              }
                            >
                              {
                                getTheStatsForLikeAndCommentAndDislike(blog)
                                  .comments
                              }
                            </span>
                          )}
                        </div>
                      </Box>
                    </Box>
                    {checkAccessForBeforDelete(blog?.user) ? (
                      <div className="deleteIconWrapper">
                        <HighlightOffIcon
                          sx={{
                            position: "absolute",
                            top: "20px",
                            right: lang == "en" ? "20px" : undefined,
                            left: lang == "ar" ? "20px" : undefined,
                            cursor: "pointer",
                            color: "#E04449",
                          }}
                          onClick={() => handleDeleteBlog(blog?._id)}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </div>
    </TabPanel>
  );
}

export default AllBlogsPage;
