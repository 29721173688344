import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLoginUserMutation } from "../../redux/features/userApi";
const PrivateRoutes = () => {
  // if the user was on the path of profile check if the location is been saved then 

  

  const companyId = localStorage.getItem("companyId");
  const userToken = localStorage.getItem("userToken");

  return <>{companyId || userToken ? <Outlet /> : <Navigate to="/login" />}</>;
};
export default PrivateRoutes;
