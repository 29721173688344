import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCreateDepartmentMutation } from "../../../redux/features/departmentsApis";
import { useParams } from "react-router";

export default function AddDepartmentModal({ lang, mode }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };
  const { Idbranch } = useParams();

  const { modalTheme } = useTheme();
  const [createDepartment] = useCreateDepartmentMutation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    direction: lang === "en" ? "ltr" : "rtl",
  };
  const formik = useFormik({
    initialValues: {
      department: "",
    },
    validationSchema: Yup.object({
      department: Yup.string().required(
        lang === "en" ? "Department is Required" : "القسم مطلوب"
      ),
    }),
    onSubmit: () => {
      let data = {
        name: values.department,
        branch: Idbranch,
      };
      createDepartment(data).then(({ error, data }) => {
        if (data) {
          toast.success(data.message_en);
          handleClose();
          resetForm();
        }
        if (error) {
          error.data[`message_${lang}`]
            ? toast.error(error.data[`message_${lang}`])
            : toast.error(error.data);
        }
      });
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  } = formik;
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        padding: "0 30px",
      }}
    >
      <Box border={1} borderColor="divider">
        <Button
          sx={{
            display: "block",
            width: "100%",
            backgroundColor: "#0077B6",
            color: "#fff",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#0077B6",
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === "en" ? "Add Department" : "إضافة قسم"}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                sx={{
                  color: mode === "light" ? "#000" : "#fff",
                }}
              >
                {lang === "en" ? "Create a new department" : "عمل قسم جديد"}
              </Typography>
              <Box
                marginTop={"30px"}
                pb={3}
                sx={{
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Department Name" : "اسم القسم"}
                </Typography>
                <input
                  type="text"
                  style={{
                    marginTop: "4px",
                    outline: 0,
                    display: "block",
                    borderRadius: 6,
                    width: "100%",
                    padding: "5px 10px",
                    direction: lang === "en" ? "ltr" : "rtl",
                    backgroundColor: mode === "light" ? "#fff" : "#252526",
                    color: mode === "light" ? "#000" : "#fff",
                    border:
                      errors.department && touched.department
                        ? "1px solid red"
                        : "1px solid transparent",
                  }}
                  value={values.department}
                  name="department"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    color: "red",
                    fontWeight: "bolder",
                    fontSize: "small",
                  }}
                >
                  {errors.department && touched.department && errors.department}
                </Typography>
              </Box>

              <Stack
                marginTop="90px"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  type="submit"
                >
                  {lang === "en" ? "Create" : "اضافة"}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  onClick={handleClose}
                >
                  {lang === "en" ? "Close" : "إلغاء"}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  );
}
