import React, { useEffect, useState } from "react";
import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import PayrollTabel from "./PayrollTabel";
import { useGetBranchesQuery } from "../../../redux/features/branchesApi";
import Spinner from "../../spinner/Spinner";
import {
  departmentsApis,
  useGetDepartmentsByBranchQuery,
} from "../../../redux/features/departmentsApis";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import ReduxStore from "../../../redux/store";
import { useLazyGetPayrolsByDepartmentsQuery } from "../../../redux/features/payrolApi";

function PayrollsPage() {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  const [expanded, setExpanded] = useState(false);
  const [nestedExpanded, setNestedExpanded] = useState(false);

  // handleDepartment based on the branch

  const [states, setStates] = useState(false);
  const handleChange = (panel, branch) => (_, isExpanded) => {
    if (branch?.deps[0]) {
      setExpanded(isExpanded ? panel : false);
      setNestedExpanded(false);
    }
  };
  const handleChangeNested = (panel, depId) => (_, isExpanded) => {
    setNestedExpanded(isExpanded ? panel : false);
    handlePayrolForEachDepartment(depId);
    setStates((prev) => !prev);
  };

  // branches Query
  let {
    data: branchesData,
    isSuccess: branchesSuccess,
    isLoading: branchesLoading,
  } = useGetBranchesQuery();
  let [
    payrolTrigger,
    {
      isLoading: payrolLoading,
      isSuccess: payrolSuccess,
      isError,
      data: payrolData,
    },
  ] = useLazyGetPayrolsByDepartmentsQuery();

  const handlePayrolForEachDepartment = (depId) => {
    payrolTrigger(`?department=${depId}`);
  };
  return (
    <HOC>
      <div>
        <div className={`overflow-hidden p-3  `}>
          <div
            className="p-4"
            style={{
              backgroundColor: chartBGColor,
              borderRadius: "25px",
              height: "vh",
            }}
          >
            <h1
              className="text-center my-5"
              style={{
                marginLeft: lang === "en" && "auto",
                marginRight: lang === "ar" && "auto",
                color: mode === "light" ? "#000" : "#fff",
                marginBottom: "30px",
              }}
            >
              {lang === "en" ? "Payrolls" : "كشف الرواتب"}
            </h1>

            <div
              className="tab-pane fade mt-4"
              id="pills-payroll"
              role="tabpanel"
              aria-labelledby="pills-payroll-tab"
              tabIndex="0"
            ></div>

            <div>
              {branchesLoading ? (
                <Spinner />
              ) : branchesSuccess ? (
                [...branchesData?.data].map((branch, branchIndex) => {
                  return (
                    <Accordion
                      key={branchIndex}
                      expanded={expanded === branchIndex}
                      onChange={handleChange(branchIndex, branch)}
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: 0,
                        marginTop: "20px",
                        border: 0,
                        overflow: "hidden",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: mode === "light" ? "#000" : "#fff",
                              border: 0,
                            }}
                          />
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                          bgcolor: "transparent",
                          border: 1,
                          borderColor: mode === "light" ? "divider" : "#aaa",
                          borderRadius: "30px",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                            color: mode === "light" ? "#000" : "#fff",
                          }}
                        >
                          {lang === "en" ? "Branch" : "فرع"} {branch?.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {branchesSuccess
                          ? [...branch?.deps].map((dep, departmentIndex) => (
                              <Accordion
                                key={departmentIndex}
                                expanded={nestedExpanded === departmentIndex}
                                onChange={handleChangeNested(
                                  departmentIndex,
                                  dep?._id
                                )}
                                sx={{
                                  backgroundColor: "transparent",
                                  boxShadow: 0,
                                  marginTop: "20px",
                                  border: 0,
                                  borderRadius: "25px",
                                  overflow: "hidden",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                      sx={{
                                        color:
                                          mode === "light" ? "#000" : "#fff",
                                        border: 0,
                                      }}
                                    />
                                  }
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                  sx={{
                                    bgcolor:
                                      mode === "light" ? "#F2F8FD" : "#252526",
                                    border: 1,
                                    borderColor:
                                      mode === "light" ? "divider" : "#aaa",
                                    borderRadius: "30px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      width: "33%",
                                      flexShrink: 0,
                                      color: mode === "light" ? "#000" : "#fff",
                                    }}
                                  >
                                    {lang === "en" ? "Department" : "قسم"}{" "}
                                    {dep?.name}
                                  </Typography>
                                </AccordionSummary>
                                {branchesSuccess ? (
                                  <AccordionDetails>
                                    <PayrollTabel
                                      data={payrolData?.payrol}
                                      isSuccess={payrolSuccess}
                                      isLoading={payrolLoading}
                                    />
                                  </AccordionDetails>
                                ) : null}
                              </Accordion>
                            ))
                          : null}
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : null}
            </div>
          </div>

          {/* ============================================================drop down 2================================= */}

          {/* <div className="dropdown"> */}
        </div>
      </div>
    </HOC>
  );
}
export default PayrollsPage;
