import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  Stack,
  InputBase,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DepartmentMenu = ({
  mode,
  lang,
  setValues,
  error,
  touched,
  valuesDepartment,
  departmentData,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChooseDepartment = (departmentId) => {
    setValues((currentValues) => ({
      ...currentValues,
      department: departmentId,
    }));
  };
  return (
    <Box
      sx={{
        width: {
          xl: "90%",
          lg: "90%",
          md: "90%",
          xs: "100%",
        },
      }}
    >
      <Box
        sx={{
          paddingBottom: "25px",
          position: "relative",
        }}
      >
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            direction: lang === "en" ? "ltr" : "rtl",
            border: 1,
            borderColor: error && touched ? "red" : "transparent",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: mode === "dark" ? "#fff" : undefined }}
              />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              backgroundColor: mode === "light" ? "#fff" : "#252526",
            }}
          >
            <Typography sx={{ color: mode === "light" ? "#000" : "#fff" }}>
              {lang === "en" ? "Department" : "القسم"}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <List
              sx={{
                width: "100%",
                padding: 0,
              }}
            >
              {departmentData?.data.map((department) => {
                return (
                  <ListItem key={department._id} disablePadding disableGutters>
                    <ListItemButton
                      disableRipple
                      sx={{
                        cursor: "default",
                        backgroundColor: mode === "light" ? "#fff" : "#252526",
                        paddingX: "10px",
                        width: "100%",
                        "&:hover": {
                          backgroundColor:
                            mode === "light" ? "#fff" : "#252526",
                        },
                      }}
                    >
                      <ListItemText
                        sx={{
                          color: mode === "light" ? "#000" : "#fff",
                          textAlign: lang === "en" ? "left" : "right",
                        }}
                        id={department._id}
                        primary={department.name}
                      />
                      <Stack direction="row" alignItems="center">
                        
                        <InputBase
                          type="radio"
                          checked={valuesDepartment === department._id}
                          name="department"
                          value={department._id}
                          onChange={(_) =>
                            handleChooseDepartment(department._id)
                          }
                          sx={{
                            height: "15px",
                            width: "15px",
                            accentColor: "#00635D",
                            cursor: "pointer",
                          }}
                        />
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Typography
          sx={{
            position: "absolute",
            bottom: 0,
            color: "red",
            fontWeight: "bolder",
            fontSize: "12px",
          }}
        >
          {error && touched && error}
        </Typography>
      </Box>
    </Box>
  );
};

export default DepartmentMenu;
