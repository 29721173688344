import React from "react";
import styles from "./overtime.module.css";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";

import makeAnimated from "react-select/animated";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import { useQuery } from "react-query";
import axios from "axios";

import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import { Button, Card, Stack, Typography } from "@mui/material";

const Option = (props) => {
  return (
    <HOC>
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    </HOC>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

export const colourOptions = [
  { value: "ocean1", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "forest", label: "Forest" },
  { value: "slate", label: "Slate" },
  { value: "silver", label: "Silver" },
];

function OverTimePage() {
  const [alertValue, setAlertValue] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertList, setAlertList] = useState([]);
  const [branches, setBranches] = useState([
    { id: 0, name: "Cairo" },
    { id: 1, name: "Dubai" },
    { id: 2, name: "Saudi Arabia" },
  ]);

  const [departments, setDepartments] = useState([
    { id: 0, name: "Development" },
    { id: 1, name: "Design" },
    { id: 2, name: "Marketing" },
    { id: 3, name: "Accountance" },
  ]);

  const [employees, setEmployees] = useState([
    { id: 0, name: "Ahmed" },
    { id: 1, name: "Mohammed" },
    { id: 2, name: "Eman" },
    { id: 3, name: "Ali" },
    { id: 4, name: "Khalid" },
  ]);

  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);

  // -----------------------------------handle inputs-------------------------------------

  const [userinfo, setUserInfo] = useState({
    employees: [],
    branches: [],
    departments: [],
  });

  const handleUserInfo = (e) => {};

  const [modalShow, setModalShow] = useState(false);

  const [search, setSearchParams] = useSearchParams();

  const [modalState, setModalState] = useState();
  const showModalState = () => {
    setModalState(true);
  };
  const handleClose = () => {
    setModalShow(false);
    setModalState(false);
  };

  // -----------------------------------------------------------

  function addAlert() {}
  // -------------------------------------------------------------

  const [alertDetails, setAlertDetails] = useState({});

  function alertDetail(index) {
    setAlertList([...alertList]);
    setModalShow(true);

    setAlertDetails(alertList[index]);
  }

  // -----------------------------------------------------------------------------

  const navigate = useNavigate();
  function getAlertValue(e) {
    setAlertValue(e.target.value);
  }
  function getAlertTitle(e) {
    setAlertTitle(e.target.value);
  }

  const [optionSelected, setOptionSelected] = useState(null);

  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  const [assignedEmployees, setAssignedEmployees] = useState([]);

  const submitRequest = async () => {};

  const [shiftsList, setShiftsList] = useState([]);

  const [shiftDetails, setShiftDetails] = useState({});

  const handleGetShifts = async (branchId) => {
    // try {
    //   const {data} = await axios.get(`${BASE_URL}/shift/${branchId}`)
    //   setShiftsList(data.data)
    // } catch (error) {
    // }
  };
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  return (
    <HOC>
      <div className={styles.component}>
        {/*---------------------------------------------container------------------------------------------------*/}
        <div className={` overflow-hidden p-3  `}>
          <div
            className="p-5"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <h1
              className="text-center"
              style={{
                marginLeft: lang === "en" && "auto",
                marginRight: lang === "ar" && "auto",
                color: mode === "light" ? "#000" : "#fff",
                marginBottom: "30px",
              }}
            >
              {lang === "en" ? "OverTime " : "وقت إضافي"}
            </h1>
            <div className={`row g-4 p-3 ${styles.rows}`}>
              {/* map */}
              
              {/* ShiftsList */}
              <h1
                className={`text-center my-5 ${
                  mode === "light" ? "text-dark" : "text-white"
                }`}
              >
                {lang === "en" ? "Shifts" : "الدوام"}
              </h1>
              {[...Array(4)].map((_, index) => (
                <div key={index} className={` col-md-6 col-lg-4 col-xxl-3 `}>
                  <div
                    className={`${styles.columContent} p-3`}
                    style={{
                      backgroundColor: mode === "light" ? "#E4E4E4" : "#333333",
                      borderRadius: "25px",
                    }}
                  >
                    <div className="p-2 w-75 ">
                      <div className="d-flex justify-content-between align-items-center ">
                        <div className="d-flex flex-column mx-3  overflow-hidden">
                          <h4
                            className={
                              mode === "light" ? "text-dark" : "text-white"
                            }
                          >
                            {lang === "en" ? "Shift Name" : "اسم الدوام"}
                          </h4>

                          <p value={alertValue} className="w-100 p-3  ">
                            {/* {alert.alertValue} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`w-100  p-2 d-flex  ${styles.footer}`}>
                      <div className="d-flex flex-column">
                        {/* <span>Branche:{userinfo.branches}</span>
                      <span>Employees:{userinfo.employees}</span>

                      <span>Department:{userinfo.departments}</span> */}
                      </div>
                      <button
                        className={`py-2 px-3 h-25 rounded ${
                          lang === "en" ? "me-auto" : "ms-auto"
                        }`}
                        // onClick={() => alertDetail(index)}

                        // setSearchParams({...search,'shift': _id})
                      >
                        {lang === "en" ? "Open" : "فتح"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* ===================================================================================================================================================================== */}

            {/*/---------------------------------------------- Alert modal-------------------------------------------*/}

            <div className={`${styles.iconContainer} my-5`}>
              <button
                className="rounded p-2 gap-2  fs-6 "
                onClick={() => setModalShow(true)}
              >
                {lang === "en" ? "Add Over Time" : "إضافة وقت اضافي"}

                <i className="fa-solid fa-plus mx-2"></i>
              </button>
            </div>
            <Modal
              show={modalState}
              onHide={handleClose}
              size="lg"
              className={`p-4 ${styles.modal} `}
              style={{ overflow: "hidden", height: "fit-content" }}
            >
              <Modal.Header>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  className=" w-100 border-none"
                >
                  <h3
                    className="h1 text-center "
                    style={{ color: "var(--text-color)" }}
                  >
                    {lang === "en" ? "Alerts" : "منبهات"}
                  </h3>
                </Modal.Title>
              </Modal.Header>

              {/*/---------------------------------------modal body-------------------------------------------------------------------*/}
              <Modal.Body
                style={{
                  color: "var(--text-color)",
                  overflow: "auto",
                  maxHeight: "50%",
                }}
                className={styles.modalContent}
              >
                <div className="container">
                  <h3>{lang === "en" ? "New Alerts" : "منبهات جديدة"}</h3>

                  {/*/------------------------------- inputs------------------------------------*/}

                  <label
                    htmlFor=""
                    className="fw-bold my-3"
                    style={{ color: "var(--text-color)" }}
                  >
                    {lang === "en" ? "Title" : "العنوان"}
                  </label>
                  <input
                    type="text"
                    placeholder={
                      lang === "en" ? "Write here..." : "اكتب هنا..."
                    }
                    className="form-control"
                    onChange={getAlertTitle}
                  />

                  <label
                    htmlFor=""
                    className="fw-bold my-3"
                    style={{ color: "var(--text-color)" }}
                  >
                    {lang === "en" ? "Discription" : "الوصف"}
                  </label>
                  <textarea
                    onChange={getAlertValue}
                    name=""
                    id=""
                    cols="30"
                    rows="6"
                    className=" p-2"
                    placeholder={
                      lang === "en" ? "Write here..." : "اكتب هنا..."
                    }
                  ></textarea>

                  {/* <!-- //------------------------------- branches Accordion------------------------------------//  --> */}
                  <label htmlFor="" className="fw-bold my-3">
                    {lang === "en" ? "Assign to" : "تعيين إلي"}
                  </label>

                  <div className={`d-flex mb-5  ${styles.accordionsContainer}`}>
                    {/* <Accordion className="mx-2 ">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        style={{
                          backgroundColor: "var(--icon-color)",
                          color: "var(--text-color)",
                          fontSize: "10px",
                        }}
                      >
                        Branche
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ul className="px-2">
                          <li className="d-flex juistify-content-between align-items-center">
                            <label className="">All</label>
                            <input type="checkbox" className="bg-danger" />
                          </li>
                          {branches.map((branche) => (
                            <li
                              key={branche.id}
                              className="  d-flex juistify-content-between align-items-center"
                            >
                              <div className="w-25">
                                {" "}
                                <UserIcon className="w-100" />
                              </div>
                              <label
                                htmlFor={`${branche.name}`}
                                className="mx-2"
                              >
                                {branche.name}
                              </label>

                              <input
                                type="checkbox"
                                className=" ms-auto"
                                name="branches"
                                id={`${branche.name}`}
                                value={`${branche.name}`}
                                onChange={handleUserInfo}
                              />
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}

                    {/* <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={colourOptions[0]
        name="color"
        options={colourOptions}
        onChange={(selected)=>}
      /> */}

                    <ReactSelect
                      options={branchList.map((branch) => ({
                        label: branch.name,
                        value: branch._id,
                      }))}
                      // getValue={branchList.map(branch=>branch.name)}
                      // isMulti
                      isSearchable={true}
                      // isClearable={true}
                      closeMenuOnSelect={true}
                      // hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      //   MultiValue,
                      //   animatedComponents,
                      // }}
                      onChange={(selected) => {
                        setSelectedBranch(selected);
                      }}
                      allowSelectAll={true}
                      value={selectedBranch?.name}
                      menuShouldScrollIntoView={false}
                      placeholder={lang === "en" ? "Branch" : "فرع"}
                      // lassName="mx-2 "
                    />

                    {/* <Select
        className="basic-single"
        classNamePrefix="select"
        // defaultValue={branchList[0].name}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        isClearable={true}
        // isRtl={isRtl}
        isSearchable={true}
        name="color"
        options={branchList.map(branch=>({label:branch.name , value:branch._id}))}
        onChange={(selected)=>setSelectedBranch(selected)}
      /> */}

                    {/* <ReactSelect
                    options={colourOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                      MultiValue,
                      animatedComponents,
                    }}
                    onChange={handleChange}
                    allowSelectAll={true}
                    value={optionSelected}
                    menuShouldScrollIntoView={false}
                    placeholder="Department"
                    lassName="mx-2 "
                  /> */}

                    <ReactSelect
                      options={departmentList.map((branch) => ({
                        label: branch.name,
                        value: branch._id,
                      }))}
                      // getValue={branchList.map(branch=>branch.name)}
                      // isMulti
                      isSearchable={true}
                      // isClearable={true}
                      closeMenuOnSelect={true}
                      // hideSelectedOptions={false}
                      // components={{
                      //   Option,
                      //   MultiValue,
                      //   animatedComponents,
                      // }}
                      onChange={(selected) => {
                        setSelectedDepartment(selected);
                      }}
                      allowSelectAll={true}
                      value={selectedBranch?.name}
                      menuShouldScrollIntoView={false}
                      placeholder={lang === "en" ? "Department" : "قسم"}
                    />

                    <ReactSelect
                      options={employeesList.map((employee) => ({
                        label: employee.fullName_en,
                        value: employee._id,
                      }))}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{
                        Option,
                        MultiValue,
                        animatedComponents,
                      }}
                      onChange={(selected) => {
                        setSelectedEmployees(selected);
                        const filteredIds = selected.map((employee) => {
                          return employee.value;
                        });

                        setAssignedEmployees(filteredIds);
                     
                      }}
                      allowSelectAll={true}
                      value={selectedEmployees}
                      menuShouldScrollIntoView={false}
                      placeholder={lang === "en" ? "Employee" : "الموظف"}
                      lassName="mx-2"
                    />

                    {/* <Accordion className="mx-2 ">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        style={{
                          backgroundColor: "var(--icon-color)",
                          color: "var(--text-color)",
                        }}
                      >
                        Department{" "}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ul className="px-2">
                          {departments.map((department) => (
                            <li
                              key={department.id}
                              className="  d-flex juistify-content-between align-items-center"
                            >
                              <div className="w-25">
                                {" "}
                                <UserIcon className="w-100" />
                              </div>
                              <label
                                htmlFor={`${department.name}`}
                                className="mx-2"
                              >
                                {department.name}
                              </label>

                              <input
                                type="checkbox"
                                className=" ms-auto"
                                name="departments"
                                id={`${department.name}`}
                                value={`${department.name}`}
                                onChange={handleUserInfo}
                              />
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}

                    {/* <Accordion className="mx-2">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        style={{
                          backgroundColor: "var(--icon-color)",
                          color: "var(--text-color)",
                        }}
                      >
                        Employee{" "}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ul className="px-2">
                          {employees.map((employee) => (
                            <li
                              key={employee.id}
                              className="  d-flex juistify-content-between align-items-center"
                            >
                              <div className="w-25">
                                {" "}
                                <UserIcon className="w-100" />
                              </div>
                              <label
                                htmlFor={`${employee.name}`}
                                className="mx-2"
                              >
                                {employee.name}
                              </label>

                              <input
                                onChange={handleUserInfo}
                                type="checkbox"
                                name="employees"
                                id={`${employee.name}`}
                                value={`${employee.name}`}
                                className=" ms-auto"
                              />
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}

                    <div className="d-flex justify-content-center align-items-center mx-2">
                      <label className="mx-2">
                        {lang === "en" ? "All" : "جميع"}
                      </label>
                      <input type="checkbox" />
                    </div>
                  </div>
                </div>
              </Modal.Body>

              {/* <!-- //-----------------------------------modal footer------------------------------------------//  --> */}

              <Modal.Footer className="d-flex justify-content-between p-3">
                <div className={styles.closeIcon}>
                  <button
                    className=" rounded-pill px-3 py-1"
                    onClick={submitRequest}
                  >
                    {lang === "en" ? "Send" : "إرسال"}
                  </button>
                </div>
                <div className={styles.closeIcon}>
                  <button
                    className=" rounded-pill px-3 py-1"
                    onClick={handleClose}
                  >
                    {lang === "en" ? "Close" : "إلغاء"}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        {/* ---------------------------------------------------------------------------------------------------------------------------------------- */}

        {/* -----------------------------------------------------Alert Details modal------------------------------------------------------- */}

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          className={`p-4 ${styles.modal} `}
          style={{ overflow: "auto" }}
        >
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className=" w-100 border-none"
            >
              <h3
                className="h1 text-center "
                style={{ color: "var(--text-color)" }}
              >
                {lang === "en" ? "Alert" : "منبه"}
              </h3>
            </Modal.Title>
          </Modal.Header>

          {/*/---------------------------------------modal body-------------------------------------------------------------------*/}
          <Modal.Body
            style={{
              color: "var(--text-color)",
              overflow: "auto",
              maxHeight: "50%",
            }}
          >
            <div className="container">
              <h3>{lang === "en" ? "title" : "العنوان"}</h3>

              <p>{alertDetails.alertTitle} </p>

              {/*/------------------------------- inputs------------------------------------*/}
              <label
                htmlFor=""
                className="fw-bold my-3"
                style={{ color: "var(--text-color)" }}
              >
                {lang === "en" ? "Discription" : "الوصف"}
              </label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="6"
                className=" p-2"
                value={alertDetails.alertValue}
                readOnly
              ></textarea>

              {/* <!-- //------------------------------- branches Accordion------------------------------------//  --> */}

              <label htmlFor="" className="fw-bold my-3">
                {lang === "en" ? "Assigned to" : "تعيين إلي"}
              </label>

              <div className="d-flex mb-5   ">
                {lang === "en" ? "persons" : "أشخاص"}
              </div>
            </div>
          </Modal.Body>

          {/* <!-- //-----------------------------------modal footer------------------------------------------//  --> */}
          <Modal.Footer className="w-100">
            <div className={styles.closeIcon}>
              <button
                className=" rounded-pill px-3 py-1 me-auto"
                onClick={handleClose}
              >
                {lang === "en" ? "Close" : "إلغاء"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </HOC>
  );
}
export default OverTimePage;
