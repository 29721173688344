import React from "react";
import { Link } from "react-router-dom";
import HomeAttendance from "./HomeAttendance";
import HomePerformance from "./HomePerformance";
import HomeBranch from "./HomeBranch";
import HomeBlogs from "./HomeBlogs";
import HomeTask from "./HomeTask";
import HomeSalaries from "./HomeSalaries";
import HomeRequest from "./HomeRequest";
import HomeAlerts from "./HomeAlerts";
import { useSelector } from "react-redux";
const Home = () => {
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  return (
    <div className="mx-auto py-4 pt-3 ">
      <div style={{ width: "97.5%", margin: "0 auto", marginBottom: "15px" }}>
        <Link to="/attendees" style={{ textDecoration: "none" }}>
          <HomeAttendance mode={mode} lang={lang} chartBGColor={chartBGColor} />
        </Link>
      </div>
      <div className="row mx-auto g-3" style={{ width: "97.5%" }}>
        <div className="col-lg-8">
          <Link to="/branches" style={{ textDecoration: "none" }}>
            <HomeBranch mode={mode} lang={lang} chartBGColor={chartBGColor} />
          </Link>
          <Link to="/tasks" style={{ textDecoration: "none" }}>
            {<HomeTask mode={mode} lang={lang} chartBGColor={chartBGColor} />}{" "}
          </Link>
          <Link to="/requests" style={{ textDecoration: "none" }}>
            {
              <HomeRequest
                mode={mode}
                lang={lang}
                chartBGColor={chartBGColor}
              />
            }{" "}
          </Link>
        </div>
        <div className="col-lg-4">
          <Link to="/blogs" style={{ textDecoration: "none" }}>
            <HomeBlogs mode={mode} lang={lang} chartBGColor={chartBGColor} />
          </Link>
          <Link to="/salaries" style={{ textDecoration: "none" }}>
            {
              <HomeSalaries
                mode={mode}
                lang={lang}
                chartBGColor={chartBGColor}
              />
            }
          </Link>
          <Link to="/alerts" style={{ textDecoration: "none" }}>
            {<HomeAlerts mode={mode} lang={lang} chartBGColor={chartBGColor} />}{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Home;
