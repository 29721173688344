import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./salaries.module.css";
import Modal from "react-bootstrap/Modal";

import { useState } from "react";
import { BiFilter } from "react-icons/bi";
import axios from "axios";
import HOC from "../../../components/HOC/HOC";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material";
import { useGetAllContractsQuery } from "../../../redux/features/contractsApi";
// import { makeStyles } from "@mui/material";
function SalariesPage() {
  //  -------------------------------------------------modal---------------------------------------
  const [modalState, setModalState] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const showModalState = () => {
    setModalState(true);
    setModalShow(true);
  };

  const [filterByBranch, setFilterByBranch] = useState(null);
  const [filterByDepartment, setFilterByDepartment] = useState(null);
  ////////////////////////////////////////////////////////////////////

  const filterContracts = async () => {
    toast.success("Filterd");
  };
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;
  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";
  // const useStyles = makeStyles({
  //   button: {
  //     backgroundColor: "yellow",
  //     color: "black",
  //     "&:hover": {
  //       backgroundColor: "olive",
  //     },
  //   },
  // });
  const { cardTheme } = useTheme();
  let { data, isSuccess } = useGetAllContractsQuery();
  return (
    <HOC>
      <div>
        <div className={`overflow-hidden p-3`}>
          <div
            className="px-4 py-5"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            {/* <div className="d-flex justify-content-between mx-5 align-items-center">
              <h3
                className={` ${mode === 'light' ? 'text-dark' : 'text-white'} `}
                // style={{ marginLeft: language === "en" && "auto", marginRight: language === "ar" && "auto", marginBottom: "30px" }}
              >
                {lang === 'en' ? 'Salaries' : 'المرتبات'}
              </h3>
              <div
                onClick={showModalState}
                className="d-flex border border-white p-2 rounded align-items-center gap-2 cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{
                  backgroundColor: mode === 'light' ? '#444' : '#ddd',
                  cursor: 'pointer',
                }}
              >
                <BiFilter fill={mode === 'light' ? '#fff' : '#000'} />
                <h5
                  className={`mb-0 ${
                    mode === 'light' ? 'text-white' : 'text-dark'
                  } `}
                >
                  {lang === 'en' ? 'Filter' : 'تصفية'}
                </h5>
              </div>
            </div> */}

            <TableContainer
              component={Paper}
              sx={{
                marginTop: "40px",
                backgroundColor: "transparent",
                border: 1,
                borderColor: mode === "light" ? "#F5F5F5" : "#252526",
                borderRadius: "6px",
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                  overflow: "hidden",

                  borderRadius: "6px",
                }}
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    backgroundColor: mode === "light" ? "#F2F8FD" : "#252525",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        color: mode === "light" ? "#000" : "#fff",
                        border: 0,
                        fontFamily: "'Baloo Bhaijaan 2', cursive",
                        fontWeight: "bolder",
                      }}
                      align="center"
                    >
                      {lang === "en" ? "Name" : " الاسم"}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: mode === "light" ? "#000" : "#fff",
                        border: 0,
                        fontFamily: "'Baloo Bhaijaan 2', cursive",
                        fontWeight: "bolder",
                      }}
                      align="center"
                    >
                      {lang === "en" ? "Job" : " الوظيفة"}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: mode === "light" ? "#000" : "#fff",
                        border: 0,
                        fontFamily: "'Baloo Bhaijaan 2', cursive",
                        fontWeight: "bolder",
                      }}
                      align="center"
                    >
                      {lang === "en" ? "Salary" : " المرتب"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isSuccess &&
                    data?.contracts?.map((_, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            mode === "light" ? "transparent" : "#3E3E42",
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            border: 0,
                            fontFamily: "'Baloo Bhaijaan 2', cursive",
                          }}
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {_.employee[`fullName_${lang}`]}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            border: 0,
                            fontFamily: "'Baloo Bhaijaan 2', cursive",
                          }}
                          align="center"
                        >
                          {_?.employee?.position}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: mode === "light" ? "#000" : "#fff",
                            border: 0,
                            fontFamily: "'Baloo Bhaijaan 2', cursive",
                          }}
                          align="center"
                        >
                          {_.salary}$
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="modal fade" id="exampleModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {lang === "en" ? "Branch" : "الفرع"}
                </h5>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-wrap">
                  {/* branch List */}
                  {[...Array(20)].map((_, index) => (
                    <span
                      className="p-2 border rounded m-2"
                      style={{
                        backgroundColor:
                          filterByBranch === index ? "rgba(0,0,0,0.1)" : null,
                        cursor: "pointer",
                      }}
                      // onClick={() => setFilterByBranch(index)}
                    >
                      {index + 1}
                    </span>
                  ))}
                </div>
              </div>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {lang === "en" ? "Department" : "القسم"}
                </h5>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-wrap">
                  {/* Department List */}
                  {[...Array(3)].map((_, index) => (
                    <span
                      className="p-2 border rounded m-2"
                      style={{
                        backgroundColor:
                          filterByDepartment === index
                            ? "rgba(0,0,0,0.1)"
                            : null,
                        cursor: "pointer",
                      }}
                      // onClick={() => setFilterByDepartment(index)}
                    >
                      {lang === "en" ? "mohamed" : "محمد"}
                    </span>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={filterContracts}
                >
                  {lang === "en" ? "Done" : "تمت"}
                </button>
                {/* <button className="btn btn-secondary" onClick={handleClose}>
                {lang === "en" ? "Cancel" : "إلغاء"}
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </HOC>
  );
}
export default SalariesPage;
