import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { Grid, InputBase, Stack, useTheme } from '@mui/material'
import { toast } from 'react-toastify'
import EmployeeMenu from './menu/EmployeeMenu'
import DepartmentMenu from './menu/DepartmentMenu'
import BranchMenu from './menu/BranchMenu'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Box, Button, Typography } from '@mui/material'

import { useGetMeQuery } from '../../../redux/features/userApi'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCreateContractMutation } from '../../../redux/features/contractsApi'
import { useGetBranchesQuery } from '../../../redux/features/branchesApi'
import {
  empolyeeApi,
  useGetAllEmpsBYBranchAndCompanyQuery,
} from '../../../redux/features/employeeApi'
import { useGetDepartmentsByBranchQuery } from '../../../redux/features/departmentsApis'
import DateAndTimePicker from '../../../components/dateTimePicker/DateTimePicker'

export default function AddContractModal({ lang, mode, branches }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    resetForm()
  }

  const { modalTheme } = useTheme()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xl: '800px',
      sm: '90%',
      xs: '90%',
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    outline: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
    height: '75%',
    overflowY: 'scroll',
    direction: lang === 'en' ? 'ltr' : 'rtl',
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-track': {
      background: mode === 'light' ? '#f1f1f1' : '#555',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: mode === 'light' ? '#aac1fe' : '#252526',
      transition: '0.5s all',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    ' &::-webkit-scrollbar-thumb:hover': {
      background: mode === 'light' ? '#78a3e4' : '#252526',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },
  }
  const formik = useFormik({
    initialValues: {
      startDate: null,
      salary: null,
      duration: null,
      durationStay: null,
      branch: '',
      department: '',
      employee: '',
      startStay: null,
      accountName: null,
      accountNumber: null,
      balance: null,
      countryStay: 'Saudi',
    },
    validationSchema: Yup.object({
      startDate: Yup.date().required(
        lang === 'en'
          ? 'Start date for contract is requird'
          : 'تاريخ البدء للعقد مطلوب ',
      ),
      startStay: Yup.date().required(
        lang === 'en'
          ? 'Start date for stay is requird'
          : 'تاريخ البدء للبقاء مطلوب ',
      ),
      salary: Yup.number().required(
        lang === 'en' ? 'Salary is requird' : ' المرتب مطلوب ',
      ),
      duration: Yup.number().required(
        lang === 'en'
          ? 'Duration for contract is requird'
          : ' المده الزمنيه للعقد مطلوبه  ',
      ),
      durationStay: Yup.number().required(
        lang === 'en'
          ? 'Duration for stay is requird'
          : ' المده الزمنيه للبقاء مطلوبه  ',
      ),
      branch: Yup.string().required(
        lang === 'en' ? 'choose a branch' : 'اختار فرع ',
      ),
      department: Yup.string().required(
        lang === 'en' ? 'choose a department' : 'اختار قسم ',
      ),
      countryStay: Yup.string().required(
        lang === 'en' ? 'Country stay is requird' : ' بلد الاقامه مطلوبه ',
      ),
      employee: Yup.string().required('choose'),
    }),
    onSubmit: (values) => {
      let Data = {
        ...values,
        bankAccount: {
          accountName: values.accountName,
          accountNumber: values.accountNumber,
          balance: values.balance,
        },
      }
      !values.accountName &&
        !values.accountNumber &&
        !values.balance &&
        delete delete Data['bankAccount']

      delete Data['accountName']
      delete Data['accountNumber']
      delete Data['balance']


      createContract(Data).then(({ data, error }) => {
        if (error) {
          error.data[`error_${lang}`]
            ? toast.error(error.data[`error_${lang}`])
            : toast.error(error.data)
        }
        if (data) {
          toast.success(data.message_en)

          handleClose()
        }
      })
    },
  })

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik
  let { data: userData, isSuccess: userSuccess } = useGetMeQuery()
  let { data: branchesData, isSuccess: branchesSuccess } = useGetBranchesQuery()
  let [skiptDepartmentToken, setSkipDepartmentToken] = useState(skipToken)
  let {
    data: depsData,
    isSuccess: depsSuccess,
    isError: depsError,
  } = useGetDepartmentsByBranchQuery(skiptDepartmentToken)
  let [disableDepartment, setDisableDepartment] = useState(true)
  let [skipEmpTokenWithQuery, setSkipEmpTokenWithQuery] = useState('')
  let {
    data: empsData,
    isSuccess: empsSuccess,
  } = useGetAllEmpsBYBranchAndCompanyQuery(skipEmpTokenWithQuery)
  let [createContract] = useCreateContractMutation()

  useEffect(() => {
    empolyeeApi.util.invalidateTags(['Employee'])
    let query = '?'
    if (branchesSuccess) {
      setDisableDepartment(false)
      if (values.branch) {
        query += `branch=${values.branch}`
        setSkipDepartmentToken(values.branch)
      }
      if (values.department) {
        query += `${
          values.branch
            ? `&department=${values.department}`
            : `department=${values.department}`
        }`
      }
      if (values.branch || values.department) {
        setSkipEmpTokenWithQuery(query)
        empolyeeApi.util.invalidateTags(['Employee'])
      }

      if (empsSuccess) {
      }
    }
  }, [
    branchesSuccess,
    values.branch,
    depsSuccess,
    values.department,
    empsSuccess,
  ])
  const handleChangeStartDate = (dateValue) => {
    formik.setValues({
      ...formik.values,
      startDate: dateValue,
    })
  }
  const handleTouchStartDate = () => {
    if (values.startDate === null) {
      formik.setTouched({
        ...formik.touched,
        startDate: true,
      })
    } else {
      delete touched.startDate
    }
  }
  const handleChangeStartDateStay = (dateValue) => {
    formik.setValues({
      ...formik.values,
      startStay: dateValue,
    })
  }
  const handleTouchStartDateStay = () => {
    if (values.startStay === null) {
      formik.setTouched({
        ...formik.touched,
        startStay: true,
      })
    } else {
      delete touched.startStay
    }
  }
  return (
    <Stack
      sx={{
        marginTop: '40px',
        padding: '0 30px',
      }}
    >
      <Box>
        <Button
          disableRipple
          sx={{
            display: 'block',
            backgroundColor: '#0077B6',
            color: '#fff',
            textTransform: 'capitalize',

            '&:hover': {
              backgroundColor: '#0077B6',
              color: '#fff',
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === 'en' ? 'Add' : 'إضافة'}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                align="center"
                sx={{
                  color: mode === 'light' ? '#000' : '#fff',
                }}
              >
                {lang === 'en' ? 'Create a new contract' : 'عمل عقد جديد'}
              </Typography>

              {/** startDate */}
              <Box marginTop={'25px'}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === 'en' ? 'left' : 'right',
                    color: mode === 'light' ? '#000' : '#fff',
                  }}
                >
                  {lang === 'en' ? 'Date' : 'الوقت'}
                </Typography>
                <Grid container marginTop="15px">
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <Box
                      sx={{
                        width: {
                          xl: '90%',
                          lg: '90%',
                          md: '90%',
                          sm: '100%',
                          xs: '100%',
                        },
                      }}
                    >
                      <DateAndTimePicker
                        label={
                          lang === 'en'
                            ? 'Start Date for contract'
                            : ' تاريخ البدء للعقد'
                        }
                        value={values.startDate}
                        error={errors.startDate}
                        touched={touched.startDate}
                        handleChange={handleChangeStartDate}
                        handleBlur={handleTouchStartDate}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <Box
                      sx={{
                        width: {
                          xl: '90%',
                          lg: '90%',
                          md: '90%',
                          sm: '100%',
                          xs: '100%',
                        },
                      }}
                    >
                      <DateAndTimePicker
                        label={
                          lang === 'en'
                            ? 'Start Date for stay'
                            : ' تاريخ البدء للبقاء'
                        }
                        value={values.startStay}
                        error={errors.startStay}
                        touched={touched.startStay}
                        handleChange={handleChangeStartDateStay}
                        handleBlur={handleTouchStartDateStay}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/** durtions */}
              <Box marginTop={'25px'}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === 'en' ? 'left' : 'right',
                    color: mode === 'light' ? '#000' : '#fff',
                  }}
                >
                  {lang === 'en' ? 'Durtions' : 'المده'}
                </Typography>
                <Grid container marginTop="15px">
                  <Grid xl={6} md={6} sm={12} xs={12}>
                    <Box sx={{ position: 'relative', pb: '21px' }}>
                      <Typography
                        onChang
                        variant="h6"
                        sx={{
                          textAlign: lang === 'en' ? 'left' : 'right',
                          color: mode === 'light' ? '#000' : '#fff',
                        }}
                      >
                        {lang === 'en' ? 'Durtion for contract' : 'المده للعقد'}
                      </Typography>
                      <InputBase
                        type="number"
                        placeholder={
                          lang === 'en'
                            ? 'the durtion with month'
                            : 'االمده بالشهر'
                        }
                        sx={{
                          borderRadius: '7px',
                          padding: '7px',
                          backgroundColor:
                            mode === 'light' ? '#fff' : '#252526',
                          color: mode === 'light' ? '#000' : '#fff',
                          outline: 0,
                          width: {
                            xl: '90%',
                            lg: '90%',
                            md: '90%',
                            sm: '100%',
                            xs: '100%',
                          },
                          border:
                            errors.duration && touched.duration
                              ? '1px solid red'
                              : '1px solid transparent',
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        }}
                        name="duration"
                        value={values.duration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Typography
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          color: 'red',
                          fontWeight: 'bolder',
                          fontSize: '12px',
                        }}
                      >
                        {errors.duration && touched.duration
                          ? lang === 'en'
                            ? 'Duration is required'
                            : 'مدة البقاء مطلوبة'
                          : undefined}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <Box sx={{ position: 'relative', pb: '21px' }}>
                      <Typography
                        onChang
                        variant="h6"
                        sx={{
                          textAlign: lang === 'en' ? 'left' : 'right',
                          color: mode === 'light' ? '#000' : '#fff',
                        }}
                      >
                        {lang === 'en' ? 'Durtion for stay' : 'المده للبقاء'}
                      </Typography>
                      <InputBase
                        type="number"
                        sx={{
                          borderRadius: '7px',
                          width: {
                            xl: '90%',
                            lg: '90%',
                            md: '90%',
                            sm: '100%',
                            xs: '100%',
                          },
                          padding: '7px',
                          backgroundColor:
                            mode === 'light' ? '#fff' : '#252526',
                          color: mode === 'light' ? '#000' : '#fff',
                          outline: 0,
                          border:
                            errors.durationStay && touched.durationStay
                              ? '1px solid red'
                              : '1px solid transparent',
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        }}
                        name="durationStay"
                        value={values.durationStay}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Typography
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          color: 'red',
                          fontWeight: 'bolder',
                          fontSize: '12px',
                        }}
                      >
                        {errors.durationStay && touched.durationStay
                          ? lang === 'en'
                            ? 'Duration for stay is required'
                            : 'فترة البقاء مطلوبة'
                          : undefined}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12} sx={{ mt: '10px' }}>
                    <Box
                      sx={{
                        marginTop: '10px',
                        paddingBottom: '16px',
                        position: 'relative',
                      }}
                    >
                      <Typography
                        onChang
                        variant="h6"
                        sx={{
                          textAlign: lang === 'en' ? 'left' : 'right',
                          color: mode === 'light' ? '#000' : '#fff',
                        }}
                      >
                        {lang === 'en' ? 'Salary' : 'المرتب'}
                      </Typography>
                      <InputBase
                        type="number"
                        sx={{
                          borderRadius: '7px',
                          width: {
                            xl: '90%',
                            lg: '90%',
                            md: '90%',
                            sm: '100%',
                            xs: '100%',
                          },
                          padding: '7px',
                          backgroundColor:
                            mode === 'light' ? '#fff' : '#252526',
                          color: mode === 'light' ? '#000' : '#fff',
                          outline: 0,
                          border:
                            errors.salary && touched.salary
                              ? '1px solid red'
                              : '1px solid transparent',
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        }}
                        name="salary"
                        value={values.salary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Typography
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          color: 'red',
                          fontWeight: 'bolder',
                          fontSize: '12px',
                        }}
                      >
                        {errors.salary && touched.salary
                          ? lang === 'en'
                            ? 'Salary is required'
                            : 'المرتب مطلوب'
                          : undefined}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <Box
                      sx={{
                        marginTop: '20px',
                        paddingBottom: '16px',
                        position: 'relative',
                      }}
                    >
                      <Typography
                        onChang
                        variant="h6"
                        sx={{
                          textAlign: lang === 'en' ? 'left' : 'right',
                          color: mode === 'light' ? '#000' : '#fff',
                        }}
                      >
                        {lang === 'en' ? 'Country Stay' : 'بلد الاقامه'}
                      </Typography>
                      <InputBase
                        type="text"
                        sx={{
                          borderRadius: '7px',
                          width: {
                            xl: '90%',
                            lg: '90%',
                            md: '90%',
                            sm: '100%',
                            xs: '100%',
                          },
                          padding: '7px',
                          backgroundColor:
                            mode === 'light' ? '#fff' : '#252526',
                          color: mode === 'light' ? '#000' : '#fff',
                          outline: 0,
                          border:
                            errors.countryStay && touched.countryStay
                              ? '1px solid red'
                              : '1px solid transparent',
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        }}
                        name="countryStay"
                        value={values.countryStay}
                        readOnly
                      />
                      <Typography
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          color: 'red',
                          fontWeight: 'bolder',
                          fontSize: '12px',
                        }}
                      >
                        {errors.countryStay &&
                          touched.countryStay &&
                          errors.countryStay}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12} mt="15px">
                    <Box sx={{ position: 'relative', pb: '21px' }}>
                      <Typography
                        onChang
                        variant="h6"
                        sx={{
                          textAlign: lang === 'en' ? 'left' : 'right',
                          color: mode === 'light' ? '#000' : '#fff',
                        }}
                      >
                        {lang === 'en' ? 'Account Name' : 'اسم الحساب'}
                      </Typography>
                      <InputBase
                        type="text"
                        sx={{
                          borderRadius: '7px',
                          width: {
                            xl: '90%',
                            lg: '90%',
                            md: '90%',
                            sm: '100%',
                            xs: '100%',
                          },
                          padding: '7px',
                          backgroundColor:
                            mode === 'light' ? '#fff' : '#252526',
                          color: mode === 'light' ? '#000' : '#fff',
                          outline: 0,
                          border: '1px solid transparent',
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        }}
                        name="accountName"
                        value={values.accountName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12} mt="15px">
                    <Box sx={{ position: 'relative', pb: '21px' }}>
                      <Typography
                        onChang
                        variant="h6"
                        sx={{
                          textAlign: lang === 'en' ? 'left' : 'right',
                          color: mode === 'light' ? '#000' : '#fff',
                        }}
                      >
                        {lang === 'en' ? 'Number' : 'الرقم'}
                      </Typography>
                      <InputBase
                        type="number"
                        sx={{
                          borderRadius: '7px',
                          width: {
                            xl: '90%',
                            lg: '90%',
                            md: '90%',
                            sm: '100%',
                            xs: '100%',
                          },
                          padding: '7px',
                          backgroundColor:
                            mode === 'light' ? '#fff' : '#252526',
                          color: mode === 'light' ? '#000' : '#fff',
                          outline: 0,
                          border:
                            errors.accountNumber && touched.accountNumber
                              ? '1px solid red'
                              : '1px solid transparent',
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        }}
                        name="accountNumber"
                        value={values.accountNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12} mt="15px">
                    <Box sx={{ position: 'relative', pb: '21px' }}>
                      <Typography
                        onChang
                        variant="h6"
                        sx={{
                          textAlign: lang === 'en' ? 'left' : 'right',
                          color: mode === 'light' ? '#000' : '#fff',
                        }}
                      >
                        {lang === 'en' ? 'Balance' : 'الرصيد'}
                      </Typography>
                      <InputBase
                        type="number"
                        sx={{
                          borderRadius: '7px',
                          width: {
                            xl: '90%',
                            lg: '90%',
                            md: '90%',
                            sm: '100%',
                            xs: '100%',
                          },
                          padding: '7px',
                          backgroundColor:
                            mode === 'light' ? '#fff' : '#252526',
                          color: mode === 'light' ? '#000' : '#fff',
                          outline: 0,
                          border:
                            errors.balance && touched.balance
                              ? '1px solid red'
                              : '1px solid transparent',
                          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                        }}
                        name="balance"
                        value={values.balance}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box marginTop={'20px'}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === 'en' ? 'left' : 'right',
                    color: mode === 'light' ? '#000' : '#fff',
                  }}
                >
                  {lang === 'en' ? 'Assign to' : 'تعيين إلي'}
                </Typography>
                <Grid container marginTop="15px" gap="20px 0">
                  <Grid item xl={4} md={6} sm={12} xs={12}>
                    <BranchMenu
                      lang={lang}
                      mode={mode}
                      branches={branchesData?.data}
                      valuesBranch={values.branch}
                      setValues={setValues}
                      error={errors.branch}
                      touched={touched.branch}
                    />
                  </Grid>
                  <Grid item xl={4} md={6} sm={12} xs={12}>
                    {depsSuccess ? (
                      <DepartmentMenu
                        disabled={disableDepartment}
                        lang={lang}
                        mode={mode}
                        setValues={setValues}
                        error={errors.department}
                        touched={touched.department}
                        valuesDepartment={values.department}
                        departments={[...depsData?.data]}
                      />
                    ) : null}
                  </Grid>

                  <Grid item xl={4} md={6} sm={12} xs={12}>
                    {values.department ? (
                      <EmployeeMenu
                        lang={lang}
                        mode={mode}
                        setValues={setValues}
                        error={errors.employee}
                        touched={touched.employee}
                        employees={[...empsData?.users]}
                        type="contract"
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>

              <Stack
                marginTop="90px"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: '#0077B6',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#0077B6',
                      color: '#fff',
                    },
                  }}
                  type="submit"
                >
                  {lang === 'en' ? 'Create' : 'اضافة'}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#0077B6',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#0077B6',
                      color: '#fff',
                    },
                  }}
                  type="button"
                  onClick={handleClose}
                >
                  {lang === 'en' ? 'Close' : 'إلغاء'}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  )
}
