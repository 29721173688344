import React, { useCallback } from "react";
import styles from "./DisplayBranches.module.css";
// import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";

import {
  branchesApi,
  useDeleteBranchMutation,
  useGetBranchesWitQuery,
} from "../../../../redux/features/branchesApi";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Spinner from "../../../../pagees/spinner/Spinner";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";

import { useGetMeQuery } from "../../../../redux/features/userApi";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import AddBranchModal from "../../../../pagees/privates/branches/AddBranchModal";
import EditBranchModal from "../../../../pagees/privates/branches/EditBranchModal";
import ErrorScreen from "../../../../pagees/errorScreen/ErrorScreen";
import DeleteModel from "../../../../pagees/privates/profile/mui/DeleteModel";
export default function DisplayBranches({ lang, mode }) {
  const navigate = useNavigate(); 
  let {
    data: branches,
    isLoading: branchesLoading,
    isError: branchesError,
    isSuccess: branchesSuccess,
  } = useGetBranchesWitQuery();

  useEffect(() => {
    if (branches) {
      branchesApi.util.invalidateTags(["Branch"]);
    }
  });

  const [user, setUser] = useState({
    role: "root",
  });
  const [
    deleteBranch,
    {
      isError: deleteError,
      isSuccess: deleteScuccess,
      isUninitialized: deleteUninitialized,
    },
  ] = useDeleteBranchMutation();
  useEffect(() => {
    if (deleteError && !deleteUninitialized) {
      toast.error(
        lang == "en" ? "Error While Deleteing Branch" : "خطأ أثناء حذف الفرع"
      );
    } else if (deleteScuccess && !deleteUninitialized) {
      toast.success(
        lang == "en" ? "Branch Deleted Successfully" : "تم حذف الفرع بنجاح"
      );
    }
  }, [deleteError, deleteUninitialized, deleteScuccess]);
  const { data: userData, isSuccess: userSuccess } = useGetMeQuery();
  const showOrHideTheDeleteIconBasedONTheRole = (targetBranch) => {
    if (userSuccess) {
      if (userData?.data?.role != "employee") {
        if (localStorage.getItem("companyId") === targetBranch?.company)
          return true;
        return false;
      }
    }
  };

  const handleDeleteBranch = (id) => {
    deleteBranch(id);
  };

  const { cardTheme } = useTheme();

  // ME INFO
  let { data: dataMe, isSuccess: successMe } = useGetMeQuery();
  const [confirm ,setConfirm]=useState(null)
  useEffect(()=>{
    if(confirm){
      handleDeleteBranch(confirm)
    }

  },[confirm])
  return (
    <>
      {branchesError ? (
        <ErrorScreen
          data={lang === "en" ? "Brnaches Are Not found" : "الفروع غير موجودة"}
        />
      ) : branchesLoading ? (
        <Spinner />
      ) : branchesSuccess ? (
        <div>
          <div className="p-sm-0 p-4">
            <h1
              className={`text-center mb-4 ${
                mode === "light" ? "text-dark" : "text-white"
              }`}
            >
              {lang === "en" ? "Branches" : "الفروع"}
            </h1>
            <div className={`row p-sm-5 p-0 ${styles.rows}`}>
              <Grid container sx={{ mx: 0, px: 0 }}>
                {false ? (
                  <Spinner />
                ) : (
                  branches?.data?.map((br, index) => (
                    <Grid item xl={4} lg={6} md={12} sm={12} xs={12} mt="25px">
                      <Box
                        key={index}
                        sx={{
                          width: {
                            xl: "92.5%",
                            lg: "92.5%",
                            md: "92.5%",
                            sm: "100%",
                            xs: "100%",
                          },
                          margin: "0 auto",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: cardTheme[`bg_${mode}`],
                            position: "relative",
                            borderRadius: "10px",
                            padding: "20px",
                            height: {
                              lg: "200px",
                              md: "200px",
                              sm: "200px",
                            },
                          }}
                        >
                          {/* <Button
                              sx={{
                                position: 'absolute',
                                bottom: '10px',
                                right: lang === 'en' ? '15px' : undefined,
                                left: lang === 'ar' ? '15px' : undefined,
                                minWidth: 0,
                                width: 0,
                              }}
                              onClick={() => toast.success('branch deleted')}
                            >
                              <HighlightOffIcon
                                sx={{
                                  color: mode === 'dark' ? '#fff' : undefined,
                                }}
                              />
                            </Button> */}
                          {/**delete icon with button */}
                          {showOrHideTheDeleteIconBasedONTheRole(br) ? (
                            // <Box
                            //   sx={{
                            //     position: "absolute",
                            //     top: "5px",
                            //     display: "flex",
                            //     justifyContent: "flex-end",
                            //     alignItems: "center",
                            //     width: 1,
                            //     left: 0,
                            //   }}
                            // >
                            //
                            // </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-end"
                              sx={{
                                position: "absolute",
                                top: "10px",
                                width: 1,
                                left: 0,
                              }}
                            >
                              <EditBranchModal
                                lang={lang}
                                mode={mode}
                                branchId={br?._id}
                              />
                              <DeleteModel
                              itemId={br?._id}
                               setConfirmDelete={setConfirm}
                                confirm_en={`are Your Sure You Want To Delete This Branch
                               BeCause It Will Delete All The Related data Like Users And Department etc ...`}

                                confirm_ar={`هل أنت متأكد أنك تريد حذف هذا الفرع
                               لأنه سيحذف جميع البيانات ذات الصلة مثل المستخدمين والقسم وما إلى ذلك ...`}
                              />

                              {/* <DeleteIcon
                                sx={{
                                  color: mode === "light" ? "#333" : "#aaa",
                                  margin: "0 15px",
                                }}
                                onClick={() => handleDeleteBranch(br?._id)}
                              /> */}
                            </Stack>
                          ) : null}
                          <div>
                            <Box>
                              <Typography
                                variant="h6"
                                sx={{
                                  color: mode === "light" ? "#000" : "#fff",
                                  width: "90%",
                                  fontSize: {
                                    md: "17px",
                                    sm: "15px",
                                    xs: "15px",
                                  },
                                }}
                              >
                                {br.name.length > 35
                                  ? br.name.slice(0, 35) + "..."
                                  : br.name}
                              </Typography>
                            </Box>
                            {/* {req.endDate ? (
                     <Stack
                       direction="row"
                       alignItems="center"
                       gap="10px"
                       sx={{
                         mt: "10px",
                       }}
                     >
                       <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                       <Typography
                         sx={{
                           color: mode === "light" ? "#000" : "#fff",
                         }}
                       >
                         {new Intl.DateTimeFormat("en-GB").format(
                           new Date(req.endDate)
                         )}
                         -{" "}
                         {new Intl.DateTimeFormat("en-GB").format(
                           new Date(req.startDate)
                         )}
                       </Typography>
                     </Stack>
                   ) : (
                     <Typography>
                       {lang === "en" ? "Free" : "بدون وقت"}
                     </Typography>
                   )} */}

                            <Stack
                              direction="row"
                              alignItems="center"
                              gap="10px"
                              marginTop="25px"
                            >
                              <CalendarMonthIcon sx={{ color: "#FA6796" }} />
                              <Typography
                                sx={{
                                  color: mode === "light" ? "#000" : "#fff",
                                  fontSize: {
                                    lg: "18px",
                                    md: "16px",
                                    sm: "14px",
                                  },
                                }}
                              >
                                {dayjs(br.createdAt).format("DD MMM YY-H:m")}
                              </Typography>
                            </Stack>
                          </div>
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            marginY={"20px"}
                          >
                            <Button
                              sx={{
                                backgroundColor: "#0077B6",
                                color: "#fff",
                                paddingY: 0.5,
                                marginX: 1,
                                "&:hover": {
                                  backgroundColor: "#0077B6",
                                  color: "#fff",
                                },
                              }}
                              onClick={() => navigate(`/branches/${br._id}`)}
                            >
                              {lang === "en" ? "open" : "فتح"}
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            </div>
            {/* <AddBranchModal langp={lang} mode={mode} /> */}
          </div>
        </div>
      ) : null}
      {successMe && dataMe.data?.role !== "employee" ? (
        <AddBranchModal lang={lang} mode={mode} />
      ) : undefined}
    </>
  );
}
