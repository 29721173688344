import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { BsFileBreak } from "react-icons/bs";
import { ImShift } from "react-icons/im";
import { AiOutlineHome, AiOutlineBranches } from "react-icons/ai";
import { MdOutlineBeenhere } from "react-icons/md";
import { FaBlogger, FaTasks } from "react-icons/fa";
import { RiGitPullRequestLine } from "react-icons/ri";
import { AiOutlineAlert } from "react-icons/ai";
import { MdGroups } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
import { TfiLayoutMediaOverlayAlt2 } from "react-icons/tfi";
import { RiMoneyDollarBoxLine, RiMoneyDollarCircleFill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import { Avatar, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import lightLogo from "../images/White_logo.png";
import darkLogo from "../images/black_logo.png";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { darkMode, lightMode } from "../../../../redux/slices/modeSlice";
import { useDispatch } from "react-redux";
import DehazeIcon from "@mui/icons-material/Dehaze";
const MobileSidebar = ({ lang, mode }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, setState] = React.useState({
    left: false,

    right: false,
  });
  const dispatch = useDispatch();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const iconStyle = {
    height: "20px",
    width: "20px",
  };
  const routes = [
    {
      title: lang === "en" ? "Home" : "الرئيسية",
      link: "/",
      icon: <AiOutlineHome style={iconStyle} />,
    },
    {
      title: lang === "en" ? "Attendees" : "الحضور",
      link: "/attendees",
      icon: <MdOutlineBeenhere style={iconStyle} />,
    },
    // {
    //   title: lang === "en" ? "Breaks" : "فترة الراحة",
    //   link: "/breaks",
    //   icon: <BsFileBreak style={iconStyle} />,
    // },
    {
      title: lang === "en" ? "Tasks" : "المهمات",
      link: "/tasks",
      icon: <FaTasks style={iconStyle} />,
    },
    {
      title: lang === "en" ? "Requests" : "الطلبات",
      link: "/requests",
      icon: <RiGitPullRequestLine style={iconStyle} />,
    },
    {
      title: lang === "en" ? "Alerts" : "التعميمات",
      link: "/alerts",
      icon: <AiOutlineAlert style={iconStyle} />,
    },
    {
      title: lang === "en" ? "Branches" : "الفروع",
      link: "/branches",
      icon: <AiOutlineBranches style={iconStyle} />,
    },
    // {
    //   title: lang === "en" ? "Shifts" : "الدوام",
    //   link: "/shifts",
    //   icon: <ImShift style={iconStyle} />,
    // },
    {
      title: lang === "en" ? "Employees" : "الموظفين",
      link: "/employees",
      icon: <MdGroups style={iconStyle} />,
    },
    {
      title: lang === "en" ? "Contracts" : "العقود",
      link: "/contracts",
      icon: <RiMoneyDollarBoxLine style={iconStyle} />,
    },
    // {
    //   title: lang === "en" ? "Over time" : "وقت اضافي",
    //   link: "/overtime",
    //   icon: <TfiLayoutMediaOverlayAlt2 style={iconStyle} />,
    // },
    {
      title: lang === "en" ? "Salaries" : "الرواتب",
      link: "/salaries",
      icon: <FaMoneyBillAlt style={iconStyle} />,
    },
    {
      title: lang === "en" ? "Payrolls" : "كشف الرواتب",
      link: "/payrolls",
      icon: <RiMoneyDollarCircleFill style={iconStyle} />,
    },
    {
      title: lang === "en" ? "Blogs" : "المدونات",
      link: "/blogs",
      icon: <FaBlogger style={iconStyle} />,
    },
  ];
  // React.useEffect(() => {
  //   window.onclick = (e) => {
  //     if (e.target.className.includes("MuiButtonBase-root")) {
  //       setTimeout(() => dispatch(switchLanguage()), 500);
  //     }
  //   };
  // });
  const list = (anchor) => (
    <Box
      bgcolor={mode === "light" ? "#fff" : "#333333"}
      sx={{
        height: "100%",
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        direction: lang === "en" ? "ltr" : "rtl",
      }}
      role="presentation"
      onKeyDown={() => setOpenDrawer(false)}
    >
      <Stack mt="10px" direction="row" justifyContent="flex-end">
        <Button
          sx={{
            backgroundColor: "transparent",
            color: mode === "light" ? "#000" : "#fff",
            "&:hover": {
              color: mode === "light" ? "#000" : "#fff",
            },
          }}
          onClick={() => setOpenDrawer(false)}
        >
          <CloseIcon />
        </Button>
      </Stack>
      <Stack>
        <Avatar
          alt="Remy Sharp"
          src={mode === "light" ? darkLogo : lightLogo}
          sx={{
            cursor: "pointer",
            width: "70px",
            height: "70px",
            objectFit: "contain",
          }}
        />
      </Stack>
      <List>
        {routes.map((item) => (
          <ListItemButton
            key={item.title}
            disablePadding
            onClick={() => {
              navigate(item.link);
              toggleDrawer(anchor, false);
            }}
            sx={{
              padding: 0,
              backgroundColor: item.link === pathname ? "#E3FAF8" : undefined,
              color:
                item.link === pathname
                  ? "#81D8D0"
                  : mode === "light"
                  ? "#000"
                  : "#fff",
              "&:hover": {
                backgroundColor: item.link === pathname ? "#E3FAF8" : undefined,
              },
            }}
          >
            <ListItemButton justifyContent="flex-start">
              <Stack>{item.icon}</Stack>
              <ListItemText
                primary={item.title}
                sx={{
                  textAlign: lang === "en" ? "left" : "right",
                  fontSize: "20px",
                  margin: "1px 20px",
                  fontFamily:
                    lang === "ar"
                      ? '"Roboto", "Helvetica", "Arial", sans-serif'
                      : undefined,
                }}
              />
            </ListItemButton>
          </ListItemButton>
        ))}
      </List>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "80%",
          backgroundColor: mode === "light" ? "#E4E4E4" : "#252526",
          margin: "0 auto",
          borderRadius: "25px",
          padding: "2px",
        }}
      >
        <Button
          startIcon={<WbSunnyIcon />}
          disableRipple
          sx={{
            backgroundColor: mode === "light" ? "#fff" : undefined,
            borderRadius: "20px",
            color: mode === "light" ? "#000" : "#fff",
            fontSize: "12px",
            "&:hover": {
              backgroundColor: mode === "light" ? "#fff" : undefined,
              color: mode === "light" ? "#000" : "#fff",
            },
          }}
          onClick={() => dispatch(lightMode())}
        >
          {lang === "en" ? "Light" : "ضوئي"}
        </Button>
        <Button
          startIcon={<DarkModeIcon />}
          disableRipple
          sx={{
            margin: "0 2px",
            backgroundColor: mode === "dark" ? "#fff" : undefined,
            borderRadius: "20px",
            color: "#000",
            fontSize: "12px",

            "&:hover": {
              backgroundColor: mode === "dark" ? "#fff" : undefined,
              color: "#000",
            },
          }}
          onClick={() => dispatch(darkMode())}
        >
          {lang === "en" ? "Dark" : "مظلم"}
        </Button>
      </Stack>
    </Box>
  );
  return (
    <div>
      <React.Fragment>
        <Button
          onClick={() => setOpenDrawer(true)}
          disableRipple
          sx={{
            transform: "rotateY(180deg)",
            display: {
              sm: "none",
              xs: "block",
            },
            "&:hover": {
              backgroundColor: "transparent",
              display: {
                sm: "none",
                xs: "block",
              },
            },
          }}
        >
          <DehazeIcon
            sx={{
              transform: "rotateY(360deg)",
              color: mode === "light" ? "#000" : "#fff",
            }}
          />
        </Button>
        <Drawer
          anchor={lang === "en" ? "left" : "right"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        >
          {list(lang === "en" ? "left" : "right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default MobileSidebar;
