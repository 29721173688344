import React from "react";
import { useSelector } from "react-redux";

const InputText = ({
  label,
  placeholder,
  type,
  name,
  value,
  error,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { value: mode } = useSelector((state) => state).mode;
  return (
    <div className="mt-3 pb-3 position-relative">
      <label
        htmlFor="userEmail"
        className={`fw-bold ${mode === "light" ? "text-dark" : "text-white"} `}
      >
        {label}
      </label>
      <input
        type={type}
        className={`d-block w-100 mt-1 p-2 rounded border border-${
          error && touched ? "danger" : "secondary"
        } `}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{
          outline: 0,
          backgroundColor: mode === "dark" ? "#252525" : undefined,
          color: mode === "light" ? "#000" : "#fff",
        }}
      />
      <p
        className="position-absolute text-danger fw-bold fs-7"
        style={{
          top: "80%",
          fontSize: "13px",
        }}
      >
        {error && touched ? error : undefined}
      </p>
    </div>
  );
};

export default InputText;
