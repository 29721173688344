import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const notificationsApi = createApi({
  reducerPath: 'notification',
  tagTypes: ['Notification'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getEmployeesNotifications: builder.query({
      query: () => `/notifiction/employee`,
      providesTags: ['Notification'],
    }),
    getAdminOrRootNotifications: builder.query({
      query: () => `/notifiction/admin`,
      providesTags: ['Notification'],
    }),
    updateNoti: builder.mutation({
      query: (id) => {
        return {
          url: `notifiction/${id}`,
          method: 'PUT',
        }
      },
      invalidatesTags: ['Notification'],
    }),
  }),
})

export const {
  useLazyGetEmployeesNotificationsQuery,
  useLazyGetAdminOrRootNotificationsQuery,
  useUpdateNotiMutation
} = notificationsApi
