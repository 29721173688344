import React from "react";
import HOC from "../../../components/HOC/HOC";
import { ReturnProfile } from "./ReturnProfile";
const ProfilePage = () => {
  return (
    <>
      {!localStorage.getItem("companyId") ? (
        <ReturnProfile />
      ) : (
        <HOC>
          <ReturnProfile />
        </HOC>
      )}
    </>
  );
};
export default ProfilePage;
