import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { token } from '../../App'
export const attendApis = createApi({
  reducerPath: 'attend',
  tagTypes: ['Attend'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1/',
    prepareHeaders: (headers) => {
      headers.set('Authentication', localStorage.getItem('userToken'))
      return headers
    },
  }),
  endpoints: (builder) => ({
    getCount: builder.query({
      query: () => `attendance/count?company=${localStorage.getItem('companyId')}`,
      providesTags: ['Attend'],
    }),
    getAttendes: builder.query({
      query: () => `attendance/attends?company=${localStorage.getItem('companyId')}`,
      providesTags: ['Attend'],
    }),
    attenduser: builder.mutation({
      query: (data) => ({
        url: `/attendance`,
        body: data,
        method: 'POST'
      })
    })
  }),
})

export const {
  useGetCountQuery,
  useGetAttendesQuery,
  //   useCreateAlertMutation,
  useAttenduserMutation
} = attendApis
