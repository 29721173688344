import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  FormControl,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { AiOutlinePlus } from "react-icons/ai";
import styles from "./Employees.module.css";
import * as Yup from "yup";

import FormikInputAndErrors from "../../../pagees/privates/employees/formikInput_errors/FormikInputAndErrors";
import { useCreateEmployeeMutation } from "../../../redux/features/employeeApi";
import {
  useGetBranchesQuery,
  useGetBranchesWitQuery,
} from "../../../redux/features/branchesApi";
import { useUploadImageMutation } from "../../../redux/features/uploadsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import ReduxStore from "../../../redux/store";
import {
  shiftsApi,
  useGetShiftsByBranchQuery,
} from "../../../redux/features/shiftsApi";
import CloseIcon from "@mui/icons-material/Close";
import {
  departmentsApis,
  useGetDepartmentsByBranchQuery,
} from "../../../redux/features/departmentsApis";
import { useGetMeQuery } from "../../../redux/features/userApi";
export default function AddEmployeeModal({ lang, mode }) {
  const [open, setOpen] = useState(false);
  const [dispableDepartment, setDisableDeparment] = useState(true);
  const [dispableShift, setDisableShift] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setFile(null);
    setDisableDeparment(true);
    setDisableShift(true);
  };

  const { modalTheme } = useTheme();
  let [createEmployee] = useCreateEmployeeMutation();

  const nationalities = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian/Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean",
  ];
  const formik = useFormik({
    initialValues: {
      fullName_en: "",
      fullName_ar: "",
      userName_en: "",
      userName_ar: "",
      nationalId: "",
      position: "",
      email: "",
      password: "",
      phone: "",
      address: "",
      nationality: "",
      birthDate: "",
      shift: "",
      department: "",
      branch: "",
      role: "",
    },
    validationSchema: Yup.object({
      fullName_en: Yup.string(),
      fullName_ar: Yup.string(),
      birthDate: Yup.date().required(
        lang == "en" ? "Birth date is required" : "تاريخ الميلاد مطلوب"
      ),
      userName_en: Yup.string(),
      userName_ar: Yup.string(),
      position: Yup.string(),
      address: Yup.string(),
      nationalId: Yup.string().required(
        lang == "en" ? "national Id Required" : "الرقم القومي مطلوب"
      ),
      email: Yup.string()
        .email(lang == "en" ? "Enter Valid Email" : "أدخل بريد إلكتروني صالح")
        .required(
          lang == "en" ? "Email is Required" : " البريد الالكتروني مطلوب"
        ),
      password: Yup.string(
        lang == "en"
          ? "password should be mixed between numbers and letters "
          : "يجب أن تكون كلمة المرور مختلطة بين الأرقام والحروف"
      ).required(lang == "en" ? "password is Required" : "كلمة المرور مطلوبة"),

      phone: Yup.string().required(
        lang == "en" ? "Phone Number is Required" : "رقم الهاتف مطلوب"
      ),
      branch: Yup.string().required(
        lang == "en" ? "Choose a branch" : "اختار فرع"
      ),
      department: Yup.string().required(
        lang == "en" ? "Choose a department" : "اختار قسم"
      ),
      shift: Yup.string().required(
        lang == "en" ? "Choose a shift" : "اختار دوام"
      ),
      nationality: Yup.string().required(
        lang === "en" ? "Choose a nationality" : "اختار جنسية"
      ),
      role: Yup.string().required(lang == "en" ? "Choose a role" : "اختار دور"),
    }),

    onSubmit: (values) => {
      Object.entries(values).map((ent) => {
        if (!values[ent[0]]) {
          delete values[ent[0]];
        }
      });
      createEmployee({
        ...values,
        company: localStorage.getItem("companyId"),
      }).then(({ data, error }) => {
        if (error) {
          error.data[`message_${lang}`]
            ?  toast.error(error.data[`message_${lang}`])
            :  toast.error(error.data);
        } else {
          toast.success(
            lang === "en"
              ? "Employee Added Successfulyy"
              : "تمت اضافة الموظف بنجاح"
          );
          handleClose();
        }
      });
    },
  });

  const onFileClicked = () => {
    imageRef.current.click();
  };
  let [uploadImage] = useUploadImageMutation();
  const [file, setFile] = useState(null);
  const imageRef = useRef(null);
  const handleUploadFile = (e) => {
    // call the upload method for uploading the image
    if (e.target.files[0]) {
      setFile((_) => e.target.files[0]);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      uploadImage(formData).then(({ data }) => {
        formik.setFieldValue("image", data.filename);
      });
    }
  };
  let { data: DataMe } = useGetMeQuery();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xl: "800px",
      lg: "700px",
      md: "75%",
      sm: "90%",
      xs: "90%",
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    height: "75%",
    overflowY: "scroll",
    direction: lang === "en" ? "ltr" : "rtl",
    "&::-webkit-scrollbar": {
      width: "10px",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    "&::-webkit-scrollbar-track": {
      background: mode === "light" ? "#f1f1f1" : "#555",
      borderRadius: "15px",
    },

    "&::-webkit-scrollbar-thumb": {
      background: mode === "light" ? "#aac1fe" : "#000",
      transition: "0.5s all",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },

    " &::-webkit-scrollbar-thumb:hover": {
      background: mode === "light" ? "#78a3e4" : "#000",
      borderRadius: lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
    },
  };
  let { data: branches } = useGetBranchesWitQuery();

  const { values, setValues, errors, touched, setTouched, handleSubmit } =
    formik;

  // adding Query for the department
  const [skipDepToken, setSkipDepToken] = useState(skipToken);
  const [skipShiftToken, setSkipShiftToken] = useState(skipToken);
  let { data: depsData, isSuccess: depsSuccess } =
    useGetDepartmentsByBranchQuery(skipDepToken);
  let { data: shiftsData, isSuccess: shiftsSuccess } =
    useGetShiftsByBranchQuery(skipShiftToken);
  useEffect(() => {
    ReduxStore.dispatch(shiftsApi.util.resetApiState());
    ReduxStore.dispatch(departmentsApis.util.resetApiState());
    if (formik.values.branch) {
      let branchId = formik.values.branch;
      setSkipDepToken(branchId);
      setSkipShiftToken(branchId);
      if (formik.values.shift) {
      }
    }
  }, [formik.values.branch, formik.values.shift]);
  useEffect(() => {
    if (depsData?.data.length != 0) {
      setDisableDeparment((_) => false);
    } else {
      setDisableDeparment((_) => true);
    }
  }, [depsSuccess]);

  useEffect(() => {
    if (shiftsData?.data.length != 0) {
      setDisableShift((_) => false);
    } else {
      setDisableShift((_) => true);
    }
  }, [shiftsSuccess]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        marginTop: "40px",
        padding: "0 30px",
      }}
    >
      <Box border={1} borderColor="divider">
        <Button
          sx={{
            display: "block",
            width: "100%",
            backgroundColor: "#0077B6",
            color: "#fff",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#0077B6",
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === "en" ? "Add Employee" : "إضافة موظف"}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <Box sx={style}>
            <Stack direction="row" justifyContent="space-between">
              <div className="col-md-3 mx-4 my-2">
                <div className=" d-flex align-items-center justify-content-center">
                  <div
                    style={{
                      width: "9rem",
                      height: "9rem",
                      border: "2px  dashed grey",
                    }}
                  >
                    {!file && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <AiOutlinePlus
                          size={25}
                          color="grey"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={onFileClicked}
                        />
                      </div>
                    )}

                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id="upload-img"
                      onChange={handleUploadFile}
                      ref={imageRef}
                    />

                    {file ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="personal Profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={onFileClicked}
                      />
                    ) : null}
                  </div>
                </div>
                <span className={mode === "light" ? "text-dark" : "text-white"}>
                  {lang === "en" ? "profile image" : "صورة الصفحة الشخصية"}
                </span>
              </div>
              <Button
                type="button"
                sx={{
                  bgcolor: "#1976D2",
                  color: "#fff",
                  minWidth: 0,
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  "&:hover": {
                    bgcolor: "#1976D2",
                    color: "#fff",
                  },
                }}
                disableRipple
                onClick={handleClose}
              >
                <CloseIcon
                  fontSize="small"
                  sx={{ color: mode === "dark" ? "#fff" : undefined }}
                />
              </Button>
            </Stack>
            {/*/------------------------------- inputs------------------------------------*/}

            <form onSubmit={handleSubmit}>
              <div className={`d-flex m-auto ${styles.modalContent}  `}>
                <div className=" row w-100 p-0">
                  <FormikInputAndErrors name="fullName_en" formik={formik} />
                  <FormikInputAndErrors name="fullName_ar" formik={formik} />
                  <FormikInputAndErrors name="position" formik={formik} />
                  <FormikInputAndErrors name="email" formik={formik} />
                  <FormikInputAndErrors
                    name="password"
                    formik={formik}
                    type="password"
                  />
                  {/* <FormikInputAndErrors name="branch" formik={formik} /> */}
                  <FormikInputAndErrors name="userName_en" formik={formik} />
                  <FormikInputAndErrors name="userName_ar" formik={formik} />
                  <FormikInputAndErrors name="phone" formik={formik} />
                  {/* The role is been sitting by default to employee */}
                  <div className="col-lg-6 col-md-6 col-sm-12 m-0 mb-3 px-0">
                    <Box
                      sx={{
                        position: "relative",
                        pb: 2.5,
                      }}
                    >
                      <FormControl
                        variant="standard"
                        sx={{
                          width: {
                            lg: "94%",
                            md: "94%",
                            sm: "94%",
                            xs: "100%",
                          },
                          mx: "auto",
                          display: "block",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "15px",
                            marginBottom: "5px",
                            color: mode === "light" ? "#000" : "#fff",
                            fontWeight: "bolder",
                          }}
                        >
                          {lang === "en" ? "Birth Date" : "تاريخ الميلاد"}
                        </Typography>
                        <InputBase
                          type="date"
                          sx={{
                            mx: "auto",
                            width: "100%",
                            px: "10px",
                            borderRadius: "4px",
                            backgroundColor:
                              mode === "light" ? "#fff" : "#252525",
                            color:
                              mode === "light" ? "#000 !important" : "#fff",
                            mt: "3px",
                            border: 1,

                            borderColor:
                              errors.birthDate && formik.touched.birthDate
                                ? "red"
                                : mode === "light"
                                ? "divider"
                                : "#ccc",
                            input: {
                              py: "7px",
                              minWidth: "100%",
                              Width: "100%",

                              backgroundColor:
                                mode === "dark" ? "#252525" : "#fff",
                              "&::-webkit-calendar-picker-indicator": {
                                backgroundColor:
                                  mode === "light" ? "#F2F8FD" : "#888",
                              },
                              "&:hover::-webkit-calendar-picker-indicator": {
                                backgroundColor:
                                  mode === "light" ? "#E0E0E0" : "#fff",
                              },
                            },
                          }}
                          name="birthDate"
                          value={values.end_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <Typography
                          sx={{
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                            fontWeight: "bolder",
                            fontSize: "12px",
                          }}
                        >
                          {errors.birthDate &&
                            formik.touched.birthDate &&
                            errors.birthDate}
                        </Typography>
                      </FormControl>
                    </Box>
                  </div>
                  <FormikInputAndErrors name="nationalId" formik={formik} />
                  <div className="col-lg-6 col-md-6 col-sm-12 m-0 mb-3 px-0 ">
                    <Box
                      className="position-relative pb-3 mx-auto"
                      sx={{
                        width: {
                          lg: "94%",
                          md: "94%",
                          sm: "94%",
                          xs: "100%",
                        },
                        mx: "auto",
                      }}
                    >
                      <label
                        style={{
                          color: mode === "dark" ? "#fff" : undefined,
                          fontWeight: "bolder",
                        }}
                      >
                        {lang === "en" ? "nationality" : "الجنسية"}
                      </label>
                      <select
                        style={{
                          width: "100%",
                          padding: "6px",
                          backgroundColor:
                            mode === "light" ? "#fff" : "#252526",
                          color: mode === "light" ? "#000" : "#fff",
                          border:
                            errors.nationality && touched.nationality
                              ? "1px solid red"
                              : `1px solid ${
                                  mode === "light" ? "#ddd" : "#aaa"
                                }`,
                          outline: 0,
                          borderRadius: "4px",
                          marginTop: "7px",
                        }}
                        name="nationality"
                        value={values.nationality}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option hidden select>
                          {lang === "en" ? "Select Nationality" : "اختار جنسية"}
                        </option>
                        {nationalities.map((nationality) => (
                          <option key={nationality} value={nationality}>
                            {nationality}
                          </option>
                        ))}
                      </select>
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: 0,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          margin: 0,
                        }}
                      >
                        {errors.nationality &&
                          touched.nationality &&
                          errors.nationality}
                      </p>
                    </Box>
                  </div>
                  <FormikInputAndErrors name="address" formik={formik} />
                  <div className="col-lg-6 col-md-6 col-sm-12 m-0 mb-3 px-0">
                    <Box
                      sx={{
                        position: "relative",
                        pb: 3,
                        mx: "auto",
                        width: {
                          lg: "94%",
                          md: "94%",
                          sm: "94%",
                          xs: "100%",
                        },
                      }}
                    >
                      <label
                        style={{
                          color: mode === "dark" ? "#fff" : undefined,
                          fontWeight: "bolder",
                        }}
                      >
                        {lang === "en" ? "Branch" : "الفرع"}
                      </label>
                      <select
                        style={{
                          width: "100%",
                          padding: "6px 0",
                          backgroundColor:
                            mode === "light" ? "#fff" : "#252526",
                          color: mode === "light" ? "#000" : "#fff",
                          border:
                            errors.branch && touched.branch
                              ? "1px solid red"
                              : `1px solid ${
                                  mode === "light" ? "#ddd" : "#aaa"
                                }`,
                          outline: 0,
                          borderRadius: "4px",
                          marginTop: "7px",
                        }}
                        value={values.branch}
                        onChange={(e) =>
                          setValues({ ...values, branch: e.target.value })
                        }
                        onBlur={(_) => setTouched({ ...touched, branch: true })}
                      >
                        <option hidden select>
                          {lang === "en" ? "Select branch" : "اختار فرع"}
                        </option>
                        {branches?.data?.map((branch) => (
                          <option
                            key={branch._id}
                            id={branch._id}
                            value={branch._id}
                          >
                            {branch.name}
                          </option>
                        ))}
                      </select>
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: 0,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          margin: 0,
                        }}
                      >
                        {errors.branch && touched.branch && errors.branch}
                      </p>
                    </Box>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 m-0 mb-3 px-0">
                    <Box
                      sx={{
                        position: "relative",
                        pb: 3,
                        mx: "auto",
                        width: {
                          lg: "94%",
                          md: "94%",
                          sm: "94%",
                          xs: "100%",
                        },
                      }}
                    >
                      <label
                        style={{
                          color: mode === "dark" ? "#fff" : undefined,
                          fontWeight: "bolder",
                        }}
                      >
                        {lang === "en" ? "Department" : "القسم"}
                      </label>
                      <select
                        disabled={dispableDepartment}
                        style={{
                          width: "100%",
                          padding: "6px 0",
                          backgroundColor:
                            mode === "light" ? "#fff" : "#252526",
                          color: mode === "light" ? "#000" : "#fff",
                          border:
                            errors.department && touched.department
                              ? "1px solid red"
                              : `1px solid ${
                                  mode === "light" ? "#ddd" : "#aaa"
                                }`,
                          outline: 0,
                          borderRadius: "4px",
                          marginTop: "7px",
                        }}
                        value={values.department}
                        onChange={(e) =>
                          setValues({ ...values, department: e.target.value })
                        }
                        onBlur={(_) =>
                          setTouched({ ...touched, department: true })
                        }
                      >
                        <option hidden select>
                          {lang === "en" ? "Select department" : "اختار قسم"}
                        </option>
                        {depsSuccess
                          ? [...depsData?.data].map((dep, index) => (
                              <option key={index} value={dep._id}>
                                {lang === "en" ? "department" : "قسم"}{" "}
                                {dep?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: 0,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          margin: 0,
                        }}
                      >
                        {errors.department &&
                          touched.department &&
                          errors.department}
                      </p>
                    </Box>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 m-0 mb-3 px-0 ">
                    <Box
                      sx={{
                        position: "relative",
                        pb: 3,
                        mx: "auto",
                        width: {
                          lg: "94%",
                          md: "94%",
                          sm: "94%",
                          xs: "100%",
                        },
                      }}
                    >
                      <label
                        style={{
                          color: mode === "dark" ? "#fff" : undefined,
                          fontWeight: "bolder",
                        }}
                      >
                        {lang === "en" ? "Shifts" : "الدوام"}
                      </label>
                      <select
                        id=""
                        disabled={dispableShift}
                        style={{
                          width: "100%",
                          padding: "6px 0",
                          backgroundColor:
                            mode === "light" ? "#fff" : "#252526",
                          color: mode === "light" ? "#000" : "#fff",
                          border:
                            errors.shift && touched.shift
                              ? "1px solid red"
                              : `1px solid ${
                                  mode === "light" ? "#ddd" : "#aaa"
                                }`,
                          outline: 0,
                          borderRadius: "4px",
                          marginTop: "7px",
                        }}
                        value={formik.values.shift}
                        onChange={(e) =>
                          setValues({ ...values, shift: e.target.value })
                        }
                        onBlur={(_) => setTouched({ ...touched, shift: true })}
                      >
                        <option hidden select>
                          {lang === "en" ? "Select Shift" : "اختار دوام"}
                        </option>
                        {shiftsSuccess
                          ? [...shiftsData.data].map((shift, index) => {
                              return (
                                <option
                                  value={shift?._id}
                                  key={index}
                                  id={shift._id}
                                >
                                  {lang === "en" ? "shift" : "دوام"}{" "}
                                  {shift.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: 0,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          margin: 0,
                        }}
                      >
                        {errors.shift && touched.shift && errors.shift}
                      </p>
                    </Box>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 m-0 mb-3 px-0">
                    <Box
                      sx={{
                        position: "relative",
                        pb: 3,
                        mx: "auto",
                        width: {
                          lg: "94%",
                          md: "94%",
                          sm: "94%",
                          xs: "100%",
                        },
                      }}
                    >
                      <label
                        style={{
                          color: mode === "dark" ? "#fff" : undefined,
                          fontWeight: "bolder",
                        }}
                      >
                        {lang === "en" ? "Role" : "الدور"}
                      </label>
                      <select
                        style={{
                          width: "100%",
                          padding: "6px",
                          backgroundColor:
                            mode === "light" ? "#fff" : "#252526",
                          color: mode === "light" ? "#000" : "#fff",
                          border:
                            errors.role && touched.role
                              ? "1px solid red"
                              : `1px solid ${
                                  mode === "light" ? "#ddd" : "#aaa"
                                }`,
                          outline: 0,
                          borderRadius: "4px",
                          marginTop: "7px",
                        }}
                        value={values.role}
                        onChange={(e) =>
                          setValues({ ...values, role: e.target.value })
                        }
                        onBlur={(_) => setTouched({ ...touched, role: true })}
                      >
                        <option hidden select>
                          {lang === "en" ? "Select Role" : "اختار دور المهام"}
                        </option>
                        {DataMe?.data?.role === "root" ? (
                          <>
                            <option value="admin">
                              {lang === "en" ? "admin" : "مشرف"}
                            </option>
                            <option value="employee">
                              {lang === "en" ? "employee" : "موظف"}
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="employee">
                              {lang === "en" ? "employee" : "موظف"}
                            </option>
                            <option value="admin">
                              {lang === "en" ? "admin" : "مشرف"}
                            </option>
                          </>
                        )}
                      </select>
                      <p
                        style={{
                          color: "red",
                          position: "absolute",
                          bottom: 0,
                          fontSize: "12px",
                          fontWeight: "bolder",
                          margin: 0,
                        }}
                      >
                        {errors.role && touched.role && errors.role}
                      </p>
                    </Box>
                  </div>
                </div>
              </div>
              <Box>
                <Button
                  type="submit"
                  sx={{
                    bgcolor: "#1976D2",
                    color: "#fff",

                    paddingX: "40px",
                    "&:hover": {
                      bgcolor: "#1976D2",
                      color: "#fff",
                    },
                  }}
                >
                  {lang === "en" ? "Submit" : "تقديم"}
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </Box>
    </Stack>
  );
}
