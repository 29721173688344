import React from "react";

import DisplayBranches from "../../../components/Abc/branches/displayBranches/DisplayBranches";
import HOC from "../../../components/HOC/HOC";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

function BranchesPage() {
  // const [search] = useSearchParams();
  const { value: mode } = useSelector((state) => state).mode;
  const { value: lang } = useSelector((state) => state).lang;

  const chartBGColor = mode === "light" ? "#fff" : "#3E3E42";

  return (
    <HOC>
      <div>
        <div className={` overflow-hidden p-3  `}>
          <div
            className="p-4"
            style={{ backgroundColor: chartBGColor, borderRadius: "25px" }}
          >
            <DisplayBranches mode={mode} lang={lang} />
          </div>
        </div>
      </div>
    </HOC>
  );
}
export default BranchesPage;
