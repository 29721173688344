import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddCategoryMutation } from "../../../../redux/features/categoryApis";
export default function AddCategoryModal({ lang, mode }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const { modalTheme } = useTheme();
  let [
    addCategory
   ] = useAddCategoryMutation()
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: modalTheme.Box[`bg_${mode}`],
    border: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const formik = useFormik({
    initialValues: {
      categoryName: "",
    },
    validationSchema: Yup.object({
      categoryName: Yup.string().required(
        lang === "en" ? "Category name is required" : "اسم الفئة مطلوبة"
      ),
    }),
    onSubmit: () => {
 
      let data = {
        name:values.categoryName
      }
      addCategory(data).then((res) => {
        if (res.data) {
          toast.success(res.data.message_en);
          handleClose();
        }
        if (res.error) {
          res.error.data[`message_${lang}`]
            ?  toast.error(res.error.data[`message_${lang}`])
            :  toast.error(res.error.data);
        }
      })
      // handleClose()
      // toast.success("department added successfully");
    },
  });
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    formik;
  return (
    <Stack
      sx={{
        marginTop: "40px",
        padding: "0 30px",
      }}
    >
      <Box>
        <Button
          disableRipple
          sx={{
            display: "block",
            backgroundColor: "#0077B6",
            color: "#fff",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#0077B6",
              color: "#fff",
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === "en" ? "Add Category" : "إضافة فئة"}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                align="center"
                sx={{
                  color: mode === "light" ? "#000" : "#fff",
                }}
              >
                {lang === "en" ? "Create a new category" : "عمل فئة جديدة"}
              </Typography>
              <Box
                marginTop={"30px"}
                pb={3}
                sx={{
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === "en" ? "left" : "right",
                    color: mode === "light" ? "#000" : "#fff",
                  }}
                >
                  {lang === "en" ? "Category Name" : "اسم الفئة"}
                </Typography>
                <input
                  type="text"
                  style={{
                    marginTop: "4px",
                    outline: 0,
                    display: "block",
                    borderRadius: 6,
                    width: "100%",
                    padding: "5px 10px",
                    direction: lang === "en" ? "ltr" : "rtl",
                    backgroundColor: mode === "light" ? "#fff" : "#252526",
                    color: mode === "light" ? "#000" : "#fff",
                    border:
                      errors.categoryName && touched.categoryName
                        ? "1px solid red"
                        : "1px solid transparent",
                  }}
                  value={values.categoryName}
                  name="categoryName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    color: "red",
                    fontWeight: "bolder",
                    fontSize: "small",
                  }}
                >
                  {errors.categoryName &&
                    touched.categoryName &&
                    errors.categoryName}
                </Typography>
              </Box>
              <Stack
                marginTop="90px"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  type="submit"
                >
                  {lang === "en" ? "Create" : "اضافة"}
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#0077B6",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#0077B6",
                      color: "#fff",
                    },
                  }}
                  onClick={handleClose}
                >
                  {lang === "en" ? "Close" : "إلغاء"}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  );
}
