import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Stack, useTheme } from '@mui/material'

import { useFormik } from 'formik'

import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useCreateSubCategoryMutation } from '../../../redux/features/subCategoryApis'
import { useParams } from 'react-router'

export default function AddSubCategoryModal({ lang, mode }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const { modalTheme } = useTheme()
  const {id} = useParams()
  const handleClose = () => {
    setOpen(false)
    resetForm()
  }
  const [createSubCate] = useCreateSubCategoryMutation()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xl: '800px',
      sm: '90%',
      xs: '90%',
    },
    bgcolor: modalTheme.Box[`bg_${mode}`],
    direction: lang === 'en' ? 'ltr' : 'rtl',
    border: 0,
    outline: 0,
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
    height: 'auto',

    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-track': {
      background: mode === 'light' ? '#f1f1f1' : '#555',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: mode === 'light' ? '#aac1fe' : '#252526',
      transition: '0.5s all',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },

    ' &::-webkit-scrollbar-thumb:hover': {
      background: mode === 'light' ? '#78a3e4' : '#252526',
      borderRadius: lang === 'en' ? '0 15px 15px 0' : '15px 0 0 15px',
    },
  }
  const formik = useFormik({
    initialValues: {
      title: '',
      haveTime: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      haveTime: Yup.boolean().required(),
    }),
    onSubmit: () => {
      let data = {
        subType: values.title,
        haveTime: values.haveTime,
        category:id
      }
      createSubCate(data).then(({ error, data }) => {
        if (data) {
          toast.success(data.message_en)
          handleClose()
        }
        if (error) {
          error.data[`message_${lang}`]
            ? toast.error(error.data[`message_${lang}`])
            : toast.error(error.data)
        }
      })
    },
  })
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik

  return (
    <Stack
      sx={{
        marginTop: '40px',
        padding: '0 30px',
      }}
    >
      <Box>
        <Button
          disableRipple
          sx={{
            display: 'block',
            backgroundColor: '#0077B6',
            color: '#fff',
            textTransform: 'capitalize',

            '&:hover': {
              backgroundColor: '#0077B6',
              color: '#fff',
            },
          }}
          variant="contained"
          onClick={handleOpen}
        >
          {lang === 'en' ? 'Add Subcategory' : 'إضافة فئه فرعيه'}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            backgroundColor: modalTheme[`bgColor_${mode}`],
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                align="center"
                sx={{
                  color: mode === 'light' ? '#000' : '#fff',
                }}
              >
                {lang === 'en' ? 'Add Sub Category' : 'إضافة فئة فرعية'}
              </Typography>
              <Box
                sx={{
                  marginTop: '20px',
                  paddingBottom: '16px',
                  position: 'relative',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === 'en' ? 'left' : 'right',
                    color: mode === 'light' ? '#000' : '#fff',
                  }}
                >
                  {lang === 'en' ? 'Title' : 'العنوان'}
                </Typography>
                <input
                  type="text"
                  style={{
                    width: '100%',
                    padding: '7px',
                    backgroundColor: mode === 'light' ? '#fff' : '#252526',
                    color: mode === 'light' ? '#000' : '#fff',
                    outline: 0,
                    border:
                      errors.title && touched.title
                        ? '1px solid red'
                        : '1px solid transparent',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  }}
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    color: 'red',
                    fontWeight: 'bolder',
                    fontSize: '12px',
                  }}
                >
                  {errors.title && touched.title && errors.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '25px',
                  paddingBottom: '20px',
                  position: 'relative',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: lang === 'en' ? 'left' : 'right',
                    color: mode === 'light' ? '#000' : '#fff',
                  }}
                >
                  {lang === 'en' ? 'Have time' : 'يمتلك الوقت'}
                </Typography>
                <select
                  style={{
                    outline: 0,
                    width: '100%',
                    backgroundColor: mode === 'light' ? '#fff' : '#252526',
                    color: mode === 'light' ? '#000' : '#fff',
                    padding: '8px 0',
                    marginTop: '3px',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',

                    border:
                      errors.haveTime && touched.haveTime
                        ? '1px solid red'
                        : '1px solid transparent',
                  }}
                  name="haveTime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option selected hidden>
                    {lang === 'en'
                      ? 'Select true or false'
                      : 'أختار صحيح أو غير صحيح'}
                  </option>
                  {[true, false].map((boolean, idx) => (
                    <option value={boolean} key={boolean}>
                      {idx === 0
                        ? lang === 'en'
                          ? 'True'
                          : 'صحيح'
                        : lang === 'en'
                        ? 'False'
                        : 'ليس صحيح'}
                    </option>
                  ))}
                </select>
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    color: 'red',
                    fontWeight: 'bolder',
                    fontSize: '12px',
                  }}
                >
                  {errors.haveTime && touched.haveTime
                    ? lang === 'en'
                      ? 'Choose true or false'
                      : 'اختار صحيح او خاطئ'
                    : undefined}
                </Typography>
              </Box>

              <Stack
                marginTop="20px"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    backgroundColor: '#0077B6',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#0077B6',
                      color: '#fff',
                    },
                  }}
                  type="submit"
                >
                  {lang === 'en' ? 'Create' : 'اضافة'}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#0077B6',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#0077B6',
                      color: '#fff',
                    },
                  }}
                  type="button"
                  onClick={handleClose}
                >
                  {lang === 'en' ? 'Close' : 'إلغاء'}
                </Button>
              </Stack>
            </Box>
          </form>
        </Modal>
      </Box>
    </Stack>
  )
}
