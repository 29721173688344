import { createSlice } from "@reduxjs/toolkit";

!localStorage.getItem("lang") && localStorage.setItem("lang", "en");

const initialState = {
  value: localStorage.getItem("lang"),
};

const languageSlice = createSlice({
  name: "App_Language",
  initialState,
  reducers: {
    switchLanguage: (state) => {
      localStorage.getItem("lang") === "en"
        ? localStorage.setItem("lang", "ar")
        : localStorage.setItem("lang", "en");
      state.value = localStorage.getItem("lang");
    },
  },
});

export const { switchLanguage } = languageSlice.actions;
export default languageSlice.reducer;
