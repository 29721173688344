import "./App.css";
import React, { useEffect } from "react";
import AllRoutes from "./components/Router/Routes";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
export const imageUrl = "https://www.team-hr.co:8080/uploads/";
export let token;
export let companyId;
function App() {
  const navigate = useNavigate();

  const preventScaping = () => {
    if (
      localStorage.getItem("userToken") &&
      !localStorage.getItem("companyId") &&
      window.location.pathname != "/profile"
    ) {
      // means cant move to any where

      navigate("/profile");
    }
  };
  useEffect(() => {
    token = localStorage.getItem("userToken");
    companyId = localStorage.getItem("companyId");
    preventScaping();
    return () => {
      preventScaping();
    };
  }, [window.location.pathname, token, companyId]);

  const { value: mode } = useSelector((state) => state).mode;
  useEffect(() => {
    document.body.className = `${mode}_scrollbar`;
    document.body.style.backgroundColor =
      mode === "light" ? "#F8F8F8" : "#252526";
  }, [mode]);
  return (
    <>
      <AllRoutes />
      <ToastContainer autoClose={1000} />
    </>
  );
}

export default App;
