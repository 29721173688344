import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const contractsApi = createApi({
    reducerPath: 'contracts',
    tagTypes: ['Contract'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://www.team-hr.co:8080/teamHR/api/v1',
        prepareHeaders: (headers) => {
            headers.set('Authentication', localStorage.getItem('userToken'))
            return headers
        }

    }),
    endpoints: (builder) => ({
        getAllContracts: builder.query({
            query: (query = undefined) => `/contract/all/filter/${localStorage.getItem('companyId')}${query ? query : ''}`,
            providesTags: ['Contract']
        }),
        createContract: builder.mutation({
            query: (data) => ({
                url: '/contract',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Contract']
        }),
        getContractByUser: builder.query({
            query: (id) => `/contract/getContractByUser/${id ? id : ''}`,
            providesTags: ['Contract']
        }),
        updateContract: builder.mutation({
            query: ({ data, id }) => ({
                url: `/contract/${id}`,
                body: data,
                method: 'PUT',
            }),
            invalidatesTags: ['Contract']
        }),
        getContractById: builder.query({
            query: (id) => `contract/${id}`
        }),
        deleteContract: builder.mutation({
            query: (contractId) => ({
                url: `/contract/${contractId}`,
                method: 'DELETE'

            }),
            invalidatesTags: ['Contract']
        })

    })
})

export const { useGetAllContractsQuery,
    useCreateContractMutation,
    useGetContractByUserQuery,
    useUpdateContractMutation,
    useLazyGetContractByIdQuery,
    useDeleteContractMutation,
} = contractsApi