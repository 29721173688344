import { createTheme } from "@mui/material";

const theme = createTheme({
  modalTheme: {
    bgColor_light: "#00000099",
    bgColor_dark: "#dedede85",
    Box: {
      bg_light: "#F2F8FD",
      bg_dark: "#3E3E42",
    },
  },
  cardTheme: {
    bg_light: "#F2F8FD",
    bg_dark: "#252526",
  },
  blueColor: "#0077B6",
});
export default theme;
